import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

export default function ReshuffleModal({
  obj,
  openReshuffleModal,
  setOpenReshuffleModal,
  reshuffledLeads,
  conformReShuffleLeads,
}) {
  return (
    <Dialog
      open={openReshuffleModal}
      onClose={(e) => setOpenReshuffleModal(false)}
      fullWidth
      maxWidth='sm'
    >
      <Box
        display='flex'
        justifyContent='flex-end'
      >
        <Button
          onClick={(e) => setOpenReshuffleModal(false)}
          style={{ fontSize: '30px' }}
        >
          &times;
        </Button>
      </Box>

      <DialogContent>
        <DialogContentText>
          <Box>
            <Typography
              variant='h6'
              sx={{ mb: 2 }}
            >
              Reshuffle Details
            </Typography>
          </Box>

          {JSON.stringify(obj)}
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ textAlign: 'center', justifyContent: 'center' }}>
        <Button
          variant='contained'
          onClick={() => {
            conformReShuffleLeads();
            setOpenReshuffleModal(false);
          }}
        >
          Conform Reshuffle
        </Button>
      </DialogActions>
    </Dialog>
  );
}
