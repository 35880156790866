import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

function InteractionFileUploads({
  openFileUploads,
  setOpenFileUploads,
  uploadedFiles,
  setUploadedFiles,
}) {
  const handleUpload = (e) => {
    const MAX_ALLOWED = 10;

    const files = Array.from(e.target.files).map((file) => ({
      name: file.name,
      data: file,
    }));

    let extraFiles = files.length + uploadedFiles.length - MAX_ALLOWED;

    if (extraFiles > 0) {
      while (extraFiles > 0) {
        files.pop();
        extraFiles--;
      }
    }

    setUploadedFiles([...uploadedFiles, ...files]);
  };

  return (
    <Dialog
      open={openFileUploads}
      onClose={() => setOpenFileUploads(false)}
      maxWidth='md'
      fullWidth
      sx={{
        maxHeight: '70vh',
        scroll: 'auto',
      }}
    >
      <DialogActions>
        <Button onClick={(e) => setOpenFileUploads(false)}>Close</Button>
      </DialogActions>

      <DialogContent>
        <Typography
          variant='body2'
          mb='1em'
        >
          <b>
            *Maximum of 10 files can be selected. All the files must be of size
            less than 2 MB.
          </b>
        </Typography>

        <input
          type='file'
          id='interaction-files'
          name='interaction-files'
          multiple
          onChange={handleUpload}
        />

        {uploadedFiles.length > 0 && (
          <Stack
            spacing={1}
            mt={2}
          >
            {uploadedFiles.map((file, index) => {
              return (
                <Stack
                  sx={{
                    border: '1px dashed blue',
                    padding: '1px',
                  }}
                  direction='row'
                  justifyContent='space-between'
                  alignContent='center'
                >
                  <Typography
                    variant='body2'
                    alignSelf='center'
                  >
                    {file.name}
                  </Typography>

                  <IconButton
                    onClick={(e) => {
                      setUploadedFiles(
                        uploadedFiles.filter((f, i) => i !== index)
                      );
                    }}
                  >
                    <ClearIcon color='error' />
                  </IconButton>
                </Stack>
              );
            })}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default InteractionFileUploads;
