import React from 'react';
import { GetDataTableWithTitleAndFilters } from '../components/users/GetLeads';
import {
  currentAdminIsAdmin,
  getCurrentAdminName,
  getDatabaseFormattedDate,
} from '../utils/common';
import { getCurrentAdminId } from '../utils/common';
import { Typography } from '@mui/material';

export function HotLeads() {
  const getRecentLeadsParams = () => {
    let TwoDayAgo = new Date();
    TwoDayAgo.setDate(TwoDayAgo.getDate() - 2);
    // We don't care about the time
    // sevenDayAgo.setHours(0,0,0,0);

    let params = {
      created_after: getDatabaseFormattedDate(TwoDayAgo),
    };
    return params;
  };

  const getHotLeadsParams = () => {
    return {
      closed_at: null,
      is_hot: 1,
      ...(currentAdminIsAdmin() ?? { assignee: getCurrentAdminId() }),
    };
  };

  // const getRecentLeads = () => {
  //   const params = getRecentLeadsParams();
  //   return GetDataTableWithTitleAndFilters("All New leads", params)
  // }

  const getHotLeads = () => {
    const params = getHotLeadsParams();
    return GetDataTableWithTitleAndFilters('All Hot Leads', params);
  };

  return (
    <>
      <Typography marginTop='-3%'>{getHotLeads()}</Typography>
    </>
  );
}
