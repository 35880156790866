import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { base_url } from '../../Mode';
import AnswerRecorder from './AnswerRecorder';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import QuestionAccordian from './QuestionAccordian';
import { getHumanReadableTimeDifferenceFromNow } from '../../../utils/common';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

const AttendedQuestionResult = ({
  selectedQuestionId,
  selectedQuestionIndex,
  selectedModule,
  questionUrl,
  expectedAnswers,
  attempts,
  setAttempts,
  setNewStatus,
}) => {
  const [isReAttempt, setIsReAttempt] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const id = selectedQuestionId;

  useEffect(() => {
    getQuestionAttempts();
    setIsReAttempt(false);
  }, [id]);

  const getQuestionAttempts = () => {
    const url =
      base_url() +
      `/api/training-modules/${selectedModule}/module-question-attempts?id=${selectedQuestionId}`;
    axios
      .get(url)
      .then((res) => {
        setAttempts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box
      style={{
        maxHeight: '75vh',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {selectedQuestionId ? (
        <Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              style={{ marginTop: '20px' }}
              ml={2}
            >
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: '18px',
                }}
              >
                Question {selectedQuestionIndex}
              </Typography>
            </Box>
            {attempts.length != 0 && (
              <Box
                style={{
                  background: '#009af0',
                  marginRight: '3%',
                  marginBottom: '5px',
                  marginTop: '12px',
                  minWidth: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: '-5px',
                  padding: '8px',
                  paddingLeft: '8px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  style={{
                    color: 'white',
                    fontSize: '14px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    getQuestionAttempts();
                    setIsReAttempt((prev) => !prev);
                  }}
                >
                  {isReAttempt ? 'Back' : 'Re-Attempt'}
                </Typography>
              </Box>
            )}
          </Box>
          <Divider
            sx={{
              width: '95%',
              borderTop: '1px solid',
              color: 'gray',
              marginRight: '16px',
              marginLeft: '16px',
            }}
          />
          <QuestionAccordian expectedAnswers={expectedAnswers} />
          {!isReAttempt && attempts.length != 0 && (
            <Box
              style={{
                maxHeight: '66vh',
              }}
              pb={2}
            >
              <Box ml={2}>
                <Typography style={{ marginTop: '15px' }}>
                  Your answers
                </Typography>
              </Box>
              {attempts &&
                !isReAttempt &&
                attempts.map((attempt) => {
                  return (
                    <Box style={{ display: 'flex' }}>
                      <Box
                        style={{ marginTop: '8px' }}
                        ml={2}
                        mr={3}
                        key={attempt.id}
                      >
                        <audio
                          name='audioFile'
                          controls
                        >
                          <source
                            src={attempt.answer_voice}
                            type='audio/wav'
                          />
                          Your browser does not support the audio format.
                        </audio>
                      </Box>
                      <Box
                        mt={3}
                        ml={3}
                      >
                        {attempt.result === 'correct' ? (
                          <DoneIcon style={{ color: 'green' }} />
                        ) : (
                          <CloseIcon style={{ color: 'red' }} />
                        )}
                      </Box>
                      <Box
                        ml={5}
                        mt={3}
                      >
                        <Typography style={{ fontSize: '14px' }}>
                          {getHumanReadableTimeDifferenceFromNow(
                            attempt.created_at
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          )}
          {(attempts.length == 0 || isReAttempt) && (
            <Box
              style={{ marginTop: '10px' }}
              ml={2}
              mb={3}
            >
              <Typography>Attempt {attempts.length + 1}</Typography>
              <AnswerRecorder
                selectedQuestionId={selectedQuestionId}
                selectedModule={selectedModule}
                questionText={questionUrl}
                expectedAnswers={expectedAnswers}
                setAttempts={setAttempts}
                attempts={attempts}
                isReAttempt={isReAttempt}
                setIsReAttempt={setIsReAttempt}
                setErrorMessage={setErrorMessage}
                setHasError={setHasError}
                setIsSuccess={setIsSuccess}
                setSuccessMessage={setSuccessMessage}
                setNewStatus={setNewStatus}
              />
            </Box>
          )}

          {isSuccess && (
            <SuccessNotifier
              message={successMessage}
              setIsSuccess={setIsSuccess}
            />
          )}

          {hasError && (
            <ErrorNotifier
              message={errorMessage}
              setHasError={setHasError}
            />
          )}
        </Box>
      ) : (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          mt={18}
        >
          <Box>
            <QuestionAnswerIcon
              style={{ fontSize: '80px ', color: 'lightgray' }}
            />
          </Box>
          <Box>
            <Typography style={{ fontSize: '16px ', color: 'gray' }}>
              Select a question in this module for your attempt!
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AttendedQuestionResult;
