import React from 'react';
import { Box, Stepper, Step, StepLabel, StepIcon, Button } from '@mui/material';
import { leadForms } from './LayoutData';
const CreationProgress = ({ activeStep, errorStepper }) => {
  return (
    <Box
      py={5}
      sx={{ width: '100%' }}
    >
      <Stepper
        activeStep={activeStep}
        alternativeLabel
      >
        {leadForms.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={(props) => (
                <StepIcon
                  style={errorStepper === index ? { color: 'red' } : {}}
                  {...props}
                  icon={props.icon}
                  active={props.active || props.completed}
                  completed={false}
                />
              )}
            >
              {label.step_name}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CreationProgress;
