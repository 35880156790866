import React, { useEffect, useState } from 'react';
import PaymentDetails from './PaymentDetails';
import axios from 'axios';
import { base_url } from '../../../Mode';
import { useCurrentPostSalesLead } from '../../../CurrentPostSalesLeadContext';
import ErrorNotifier from '../../../ToastNotifications/ErrorNotifier';
import { Button, Grid, Stack } from '@mui/material';
import PostSalesPaymentNewRecord from './PostSalesPaymentNewRecord';
import SalesClosure from './SalseClosure';
import constants from '../../../../utils/constants';
// import { useCurrentLead } from '../../../CurrentLeadContext';

import {
  currentAdminIsAdmin,
  currentAdminIsFinance,
  currentAdminIsIC,
  currentAdminIsPgm,
} from '../../../../utils/common';
import SalesClosureTable from './SalesClosureTable';

const {
  PGM_HANDOVER_INITIATED,
  FINANCE_HANDOVER_INITIATED,
  PGM_HANDOVER_REJECTED,
  PGM_HANDOVER_APPROVED,
  IN_PROGRESS,
  COMPLETE_AMOUNT_PAID,
} = constants;

const PostSalesPaymentTab = () => {
  const { leadData, setLeadData, setToastNotificationMessage } =
    useCurrentPostSalesLead();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [salesClosureOpen, setSalesClosureOpen] = useState(true);

  const [editedForm, setEditedForm] = useState({});
  const [salesClosureData, setSalesClosureData] = useState([]);

  useEffect(() => {
    axios
      .get(`${base_url()}/api/post-sales-leads/${leadData.id}/payments`)
      .then((res) => {
        setPaymentDetails(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            'An error occured while fetching the payment details'
        );
      });
    return () => {};
  }, []);

  useEffect(() => {
    axios
      .get(`${base_url()}/api/post-sales-leads/${leadData.id}/sales-closures`)
      .then((res) => {
        setEditedForm(res.data ?? {});
        const dataArray = Array.isArray(res.data) ? res.data : [res.data];
        setSalesClosureData(dataArray);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {};
  }, []);

  const handleStatusUpdate = (status) => {
    const form = {
      ...editedForm,
    };

    if (status === PGM_HANDOVER_INITIATED) {
      if (paymentDetails.length === 0) {
        setToastNotificationMessage('error', 'Payment Details cannot be empty');
        return;
      }
    }

    if (Object.keys(form).length === 0 && status !== PGM_HANDOVER_REJECTED) {
      setToastNotificationMessage('error', 'Form cannot be empty');
      return;
    }
    axios
      .patch(`${base_url()}/api/post-sales-leads/${leadData.id}`, {
        status,
      })
      .then((res) => {
        setLeadData((prev) => ({
          ...prev,
          status: res.data.status,
        }));

        setToastNotificationMessage('success', 'Status updated successfully');
      })
      .catch((err) => {
        console.log(err);
        setToastNotificationMessage(
          'error',
          err.response?.data?.message ||
            'An error ocurred while updating the status'
        );
      });
  };

  const shouldShowInitiateFinanceHandover = () => {
    if (!leadData?.id || paymentDetails.length === 0) {
      return false;
    }

    let txnAmountSum = 0;
    let isEMI = false;
    paymentDetails.forEach((payment) => {
      if (payment.source_type === 'emi') isEMI = true;
      txnAmountSum += Number(payment.txn_amount);
    });

    if (currentAdminIsIC() && txnAmountSum >= isEMI ? 161000 : 158000) {
      return true;
    }

    return false;
  };

  const shouldShowInitiateHandover = () => {
    if (!editedForm.id) {
      return false;
    }
    if (Object.keys(editedForm).length === 0) {
      return false;
    }
    if (!currentAdminIsFinance()) {
      return false;
    }
    if (
      leadData.status === FINANCE_HANDOVER_INITIATED ||
      leadData.status === PGM_HANDOVER_REJECTED
    ) {
      return true;
    }
    return false;
  };

  const shouldShowRejectHandover = () => {
    if (!currentAdminIsPgm()) {
      return false;
    }
    if (leadData.status === PGM_HANDOVER_INITIATED) {
      return true;
    }
    return false;
  };

  const shouldShowApproveHandover = () => {
    if (!currentAdminIsPgm()) {
      return false;
    }
    if (leadData.status === PGM_HANDOVER_INITIATED) {
      return true;
    }
    return false;
  };

  const disableRecordActions = () => {
    // if (leadData.lead_status === COMPLETE_AMOUNT_PAID) {
    //   return true;
    // }
    // if (leadData.status === IN_PROGRESS) {
    //   return (
    //     !currentAdminIsIC() &&
    //     !currentAdminIsFinance() &&
    //     !currentAdminIsAdmin()
    //   );
    // }
    return (
      (!currentAdminIsFinance() && !currentAdminIsAdmin()) ||
      (leadData.status !== FINANCE_HANDOVER_INITIATED &&
        leadData.status !== PGM_HANDOVER_REJECTED)
    );
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {salesClosureOpen ? (
        <Grid
          item
          style={{
            width: '100%',
            height: 'auto',
            overflow: 'auto',
          }}
        >
          <Stack
            direction='row-reverse'
            spacing={1}
          >
            {/* <Button
              variant='contained'
              size='small'
              sx={{
                display: !shouldShowInitiateFinanceHandover() && 'none',
              }}
              onClick={(e) => handleStatusUpdate('finance_handover_initiated')}
            >
              Initiate Finance Handover
            </Button> */}

            <Button
              variant='contained'
              size='small'
              sx={{
                display: !shouldShowInitiateHandover() && 'none',
              }}
              onClick={(e) => handleStatusUpdate('pgm_handover_initiated')}
            >
              Initiate Handover
            </Button>

            <Button
              variant='contained'
              size='small'
              sx={{
                display: !shouldShowRejectHandover() && 'none',
              }}
              onClick={(e) => handleStatusUpdate('pgm_handover_rejected')}
            >
              Reject Handover
            </Button>

            <Button
              variant='contained'
              size='small'
              sx={{
                display: !shouldShowApproveHandover() && 'none',
              }}
              onClick={(e) => handleStatusUpdate('pgm_handover_approved')}
            >
              Approve Handover
            </Button>
            <Button
              variant='contained'
              onClick={(e) => setOpen(true)}
              sx={{
                display: disableRecordActions() && 'none',
              }}
            >
              Record a new payment
            </Button>
          </Stack>

          {open && (
            <PostSalesPaymentNewRecord
              setOpen={setOpen}
              open={open}
              setPaymentDetails={setPaymentDetails}
            />
          )}
          <PaymentDetails
            setHasError={setHasError}
            setErrorMessage={setErrorMessage}
            paymentDetails={paymentDetails}
            setPaymentDetails={setPaymentDetails}
            disableRecordActions={disableRecordActions}
          />
          <Stack
            direction='row'
            spacing={1}
            mt={2}
          >
            {!editedForm.id && (
              <Button
                variant='contained'
                size='small'
                onClick={() => setSalesClosureOpen(false)}
                sx={{
                  display: disableRecordActions() && 'none',
                }}
              >
                Sales Closure
              </Button>
            )}
          </Stack>

          {editedForm.id && (
            <SalesClosureTable
              salesClosureData={salesClosureData}
              setSalesClosureOpen={setSalesClosureOpen}
              disableRecordActions={disableRecordActions}
            />
          )}
        </Grid>
      ) : (
        <Stack
          sx={{
            width: '50%',
            margin: '0 auto',
          }}
        >
          <SalesClosure
            setSalesClosureOpen={setSalesClosureOpen}
            setSalesClosureData={setSalesClosureData}
            editedForm={editedForm}
            setEditedForm={setEditedForm}
          />
        </Stack>
      )}
    </>
  );
};

export default PostSalesPaymentTab;
