import React from 'react';
import {
  TextField,
  FormLabel,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { currentAdminIsFinance } from '../../../../utils/common';
import constants from '../../../../utils/constants';
import { useCurrentPostSalesLead } from '../../../CurrentPostSalesLeadContext';
const { FINANCE_HANDOVER_INITIATED, PGM_HANDOVER_REJECTED } = constants;

const FinanceHandoverForm = ({
  editedForm,
  setEditedForm,
  rawForm,
  setHasUpdate,
}) => {
  const { leadData } = useCurrentPostSalesLead();

  const shouldDisable = () => {
    return (
      !currentAdminIsFinance() ||
      (leadData.status !== FINANCE_HANDOVER_INITIATED &&
        leadData.status !== PGM_HANDOVER_REJECTED)
    );
  };

  return (
    <Stack spacing={2}>
      <FormLabel>{rawForm[1].title}</FormLabel>
      <RadioGroup
        value={editedForm[1]?.value ?? ''}
        onChange={(e) => {
          const tmp = {
            ...editedForm,
          };

          if (e.target.value === 'No') {
            delete tmp[2];
            delete tmp[3];
            delete tmp[4];
          }

          setHasUpdate(true);
          setEditedForm({
            ...tmp,
            [1]: {
              id: 1,
              title: rawForm[1].title,
              value: e.target.value,
            },
          });
        }}
      >
        {rawForm[1].options.map((option) => (
          <FormControlLabel
            key={option}
            value={option}
            control={<Radio />}
            label={option}
            disabled={shouldDisable()}
          />
        ))}
      </RadioGroup>

      <FormControl
        sx={{
          display: editedForm[1]?.value !== 'Yes' && 'none',
        }}
      >
        <FormLabel>{rawForm[2].title}</FormLabel>
        <Select
          placeholder={rawForm[2].title}
          value={editedForm[2]?.value ?? ''}
          onChange={(e) => {
            setHasUpdate(true);
            setEditedForm({
              ...editedForm,
              [2]: {
                id: 2,
                title: rawForm[2].title,
                value: e.target.value,
              },
            });
          }}
          name={rawForm[2].title}
          disabled={shouldDisable()}
        >
          {rawForm[2].options.map((option) => (
            <MenuItem
              key={option}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </Select>

        <TextField
          placeholder={rawForm[3].title}
          type='number'
          value={editedForm[3]?.value ?? ''}
          sx={{ mt: 1 }}
          onChange={(e) => {
            setHasUpdate(true);
            setEditedForm({
              ...editedForm,
              [3]: {
                id: 3,
                title: rawForm[3].title,
                value: e.target.value,
              },
            });
          }}
          fullWidth
          variant='outlined'
          disabled={shouldDisable()}
        />
      </FormControl>

      <FormControl
        sx={{
          display: editedForm[1]?.value !== 'Yes' && 'none',
        }}
      >
        <FormLabel>{rawForm[4].title}</FormLabel>
        <RadioGroup
          value={editedForm[4]?.value ?? ''}
          onChange={(e) => {
            const tmp = {
              ...editedForm,
            };
            setHasUpdate(true);
            setEditedForm({
              ...tmp,
              [4]: {
                id: 4,
                title: rawForm[4].title,
                value: e.target.value,
              },
            });
          }}
        >
          {rawForm[4].options.map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio />}
              label={option}
              disabled={shouldDisable()}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <TextField
        placeholder={rawForm[5].title}
        type='number'
        value={editedForm[5]?.value ?? ''}
        onChange={(e) => {
          setHasUpdate(true);
          setEditedForm({
            ...editedForm,
            [5]: {
              id: 5,
              title: rawForm[5].title,
              value: e.target.value,
            },
          });
        }}
        fullWidth
        variant='outlined'
        disabled={shouldDisable()}
      />

      <FormControl>
        <FormLabel>{rawForm[6].title}</FormLabel>
        <RadioGroup
          value={editedForm[6]?.value ?? ''}
          onChange={(e) => {
            const tmp = {
              ...editedForm,
            };
            setHasUpdate(true);
            setEditedForm({
              ...tmp,
              [6]: {
                id: 6,
                title: rawForm[6].title,
                value: e.target.value,
              },
            });
          }}
        >
          {rawForm[6].options.map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio />}
              label={option}
              disabled={shouldDisable()}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default FinanceHandoverForm;
