const { Typography } = require('@mui/material');

export function addRecencyText(created_at_d) {
  if (!created_at_d) return '--';
  const created_at = new Date(created_at_d);
  const today = new Date();
  let yesterday = new Date();
  yesterday = new Date(yesterday.setDate(yesterday.getDate() - 1));

  const days_old = Math.floor((today - created_at) / (24 * 60 * 60 * 1000));
  let displayText = days_old + ` day${days_old === 1 ? '' : 's'} old`;

  if (
    created_at.getDate() === today.getDate() &&
    created_at.getMonth() === today.getMonth() &&
    created_at.getFullYear() === today.getFullYear()
  ) {
    displayText = 'Today';
  } else if (
    created_at.getDate() === yesterday.getDate() &&
    created_at.getMonth() === yesterday.getMonth() &&
    created_at.getFullYear() === yesterday.getFullYear()
  ) {
    displayText = 'Yesterday';
  }
  const pallete = [
    '#E6EE9C',
    '#E6EE9C',
    '#F0F4C3',
    '#F0F4C3',
    '#FFFDE7',
    '#FFFDE7',
    '#FFFDE7',
  ];

  if (days_old <= 6) {
    return (
      <>
        &nbsp; &nbsp;
        <Typography
          background={pallete[days_old]}
          display={'inline'}
          fontSize='12px'
        >
          {displayText}
        </Typography>
      </>
    );
  }

  if (days_old <= 30) {
    return (
      <>
        &nbsp; &nbsp;
        <Typography
          display={'inline'}
          fontSize='12px'
        >
          {days_old} days ago
        </Typography>
      </>
    );
  }

  if (days_old <= 30 * 12) {
    return (
      <>
        &nbsp; &nbsp;
        <Typography
          display={'inline'}
          fontSize='12px'
        >
          {Math.round(days_old / 30)} months ago
        </Typography>
      </>
    );
  }

  return (
    <>
      &nbsp; &nbsp;
      <Typography
        display={'inline'}
        fontSize='12px'
      >
        {Math.round(days_old / 30 / 12)} years ago
      </Typography>
    </>
  );
}
