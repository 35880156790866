import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

const AudioPlayer = ({ audioSrc }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(50);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [hasEnded, setHasEnded] = useState(false);

  const handlePlayPause = () => {
    if (audioRef.current && (audioRef.current.paused || hasEnded)) {
      audioRef.current.play();
      setIsPlaying(true);
      setHasEnded(false);
    } else if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleVolumeChange = (_, newValue) => {
    setVolume(newValue);
    if (audioRef.current) {
      audioRef.current.volume = newValue / 100;
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
      setDuration(audioRef.current.duration);
    }
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
    setHasEnded(true);
  };

  const handleAudioPlay = () => {
    setIsPlaying(true);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0'
    )}`;
  };

  useEffect(() => {
    if (audioRef.current && !audioRef.current.paused) {
      setIsPlaying(true);
    }
  }, []);

  useEffect(() => {
    if (hasEnded) {
      setIsPlaying(false);
    }
  }, [hasEnded]);

  return (
    <Box>
      <audio
        ref={audioRef}
        src={audioSrc}
        onTimeUpdate={handleTimeUpdate}
        onLoadedData={() => setDuration(audioRef.current.duration)}
        onEnded={handleAudioEnded}
        onPlay={handleAudioPlay}
      />
      <Box
        display='flex'
        alignItems='center'
      >
        <IconButton onClick={handlePlayPause}>
          {isPlaying || !audioRef.current?.paused ? (
            <PauseIcon />
          ) : (
            <PlayArrowIcon />
          )}
        </IconButton>
        <Typography style={{ fontSize: '12px' }}>
          {formatTime(currentTime)}
        </Typography>
        <Slider
          value={currentTime}
          max={duration}
          onChange={(_, newValue) =>
            audioRef.current && (audioRef.current.currentTime = newValue)
          }
          sx={{ mx: 1, width: '150px' }}
        />
        <Typography style={{ fontSize: '12px' }}>
          {formatTime(duration)}
        </Typography>
      </Box>
    </Box>
  );
};

export default AudioPlayer;
