import { Alert, Box, Snackbar } from '@mui/material';
import React, { useState } from 'react';
import constants from '../../utils/constants';

const { MISSED_LEAD_NOTIFICATION } = constants;

const Missed10MinConnectNotifications = ({ data }) => {
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const renderNotification = (reason, message) => (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      key={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={5000} // 5 seconds
      onClose={handleClose}
    >
      <Alert
        severity='error'
        icon={false}
        sx={{
          backgroundColor: 'red',
          color: 'white',
          width: '100%',
          ...(reason === 'offline' && { fontWeight: 'bold' }),
        }}
        onClose={handleClose}
      >
        {message}
      </Alert>
    </Snackbar>
  );

  const getMessage = (reason) => {
    switch (reason) {
      case 'full_bucket':
        return (
          <>
            {MISSED_LEAD_NOTIFICATION}
            <br /> Reason: Lead Bucket Full.!😨
          </>
        );
      case 'focus_mode':
        return (
          <>
            {MISSED_LEAD_NOTIFICATION}
            <br /> Reason: Focus Mode 😫
          </>
        );
      case 'offline':
        return (
          <>
            {data?.count > 1
              ? `Oops! ${data?.count} leads slipped by.`
              : MISSED_LEAD_NOTIFICATION}
            <br /> Reason: You were Offline 😴
          </>
        );
      default:
        return null;
    }
  };

  return <Box>{renderNotification(data.reason, getMessage(data.reason))}</Box>;
};

export default Missed10MinConnectNotifications;
