import React, { useEffect, useState } from 'react';
import { useCSVReader } from 'react-papaparse';
import { newLeadsColumnsOrder } from '../../../utils/importLeadsColumnFormat';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
import { base_url } from '../../../components/Mode';
import CustomBackdrop from '../../CustomBackdrop';
import DisplayImportLeads from './DisplayImportLeads';
import DisplayLeadsModal from './DisplayLeadsModal';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function ImportLeads() {
  const { CSVReader } = useCSVReader();
  const [hasImported, setHasImported] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [columns, setColumns] = useState([]);
  const [unRecognizedColumns, setUnRecognizedColumns] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [serverResponse, setServerResponse] = useState({});
  const [isAddingLeads, setIsAddingLeads] = useState(false);
  const [utmCampaignType, setUtmCampaignType] = useState('');

  useEffect(() => {
    if (hasImported) {
      setServerResponse({});
    }
    return () => {};
  }, [hasImported]);

  const csvToJSON = (csvData) => {
    const result = [];
    /**
     * Reasoning for `-2`:
     * 1. First `-1`: Indexing in csv files will start from 1, and after importing, the indexing will start from 0.
     * 2. second `-1`: First row always contains the column names in the csv file.
     */
    const from = 0;
    const to = csvData.length;

    for (let i = from; i < to; i += 1) {
      const jsonData = {};
      let hasUtmCampaignType = false;

      for (let j = 0; j < columns.length; j++) {
        if ((csvData[i][j] ?? '') === '') {
          continue;
        }

        if (columns[j] === 'utm_campaign_type') {
          if (csvData[i][j] === utmCampaignType) {
            hasUtmCampaignType = true;
          }
        }

        jsonData[columns[j]] = csvData[i][j];
      }

      if (!hasUtmCampaignType && Object.keys(jsonData).length > 0) {
        setHasError(true);
        setErrorMessage(
          `'utm_campaign_type' field is mandatory. The accepted value is: ${utmCampaignType}.`
        );
        resetFilters();
        break;
      }

      if (Object.keys(jsonData).length > 0) {
        result.push(jsonData);
      }
    }
    return result;
  };

  const postData = () => {
    setServerResponse({});
    const data = csvToJSON(csvData);

    if (data.length === 0) {
      setHasError(true);
      setErrorMessage('No data to import');
      resetFilters();
      return;
    }

    setIsAddingLeads(true);

    axios
      .post(`${base_url()}/api/users/import`, {
        data,
        columns,
      })
      .then((res) => {
        console.log(res.data);
        setServerResponse(res.data);

        if (
          res.data.successLeadEntries.length > 0 ||
          res.data.successSignupEntries.length > 0
        ) {
          setIsSuccess(true);
          setSuccessMessage('Added leads successfully');
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not add to database'
        );
      })
      .finally(() => {
        resetFilters();
      });
  };

  const resetFilters = () => {
    setIsAddingLeads(false);
    setHasImported(false);
    setColumns([]);
    setCsvData([]);
    setUnRecognizedColumns('');
    setUtmCampaignType('');
  };

  const verifyImportedColumns = () => {
    const knownColumns = new Set(newLeadsColumnsOrder);
    const unknownColumns = [];

    columns.forEach((col) => {
      if (!knownColumns.has(col)) {
        unknownColumns.push(col);
      }
    });

    setUnRecognizedColumns(unknownColumns.join(', '));
    if (unknownColumns.length > 0) {
      setOpenConfirmation(true);
    } else {
      postData();
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      {isAddingLeads && <CustomBackdrop open={isAddingLeads} />}

      {openConfirmation && unRecognizedColumns && (
        <ConfirmationModal
          {...{
            openConfirmation,
            setOpenConfirmation,
            unRecognizedColumns,
            setUnRecognizedColumns,
            postData,
          }}
        />
      )}

      <CSVReader
        onUploadAccepted={(results) => {
          if (results.data.length > 0) {
            setColumns(results.data[0]);
            setCsvData(results.data.slice(1));
            setHasImported(true);
          }
        }}
      >
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
          <Stack spacing={5}>
            <Stack
              direction='row-reverse'
              spacing={2}
            >
              {!(acceptedFile && hasImported) && (
                <>
                  <Button
                    variant='contained'
                    {...getRootProps({
                      onClick: () => setUtmCampaignType('outbound'),
                    })}
                  >
                    Import outbound leads
                  </Button>
                  <Button
                    variant='contained'
                    {...getRootProps({
                      onClick: () => setUtmCampaignType('inbound'),
                    })}
                  >
                    Import inbound leads
                  </Button>
                </>
              )}

              {acceptedFile && hasImported && (
                <>
                  <Button
                    onClick={(e) => {
                      verifyImportedColumns();
                    }}
                    startIcon={<CloudUploadIcon />}
                    variant='contained'
                    sx={{ width: '150px' }}
                  >
                    upload
                  </Button>

                  <Button
                    onClick={(e) => {
                      resetFilters();
                    }}
                    variant='outlined'
                    sx={{ width: '150px', height: '40px' }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Stack>
            {acceptedFile && hasImported && (
              <>
                <div
                  style={{
                    width: 'inherit',
                    border: '1px solid #ccc',
                    height: 45,
                    lineHeight: 2.5,
                    paddingLeft: 10,
                  }}
                >
                  {acceptedFile.name}
                </div>
                <ProgressBar
                  style={{ width: 'inherit', backgroundColor: 'red' }}
                />
              </>
            )}
          </Stack>
        )}
      </CSVReader>

      {csvData.length > 0 && (
        <DisplayLeadsModal leadsData={csvToJSON(csvData)} />
      )}

      <br />
      {Object.keys(serverResponse).length > 0 && (
        <DisplayImportLeads serverResponse={serverResponse} />
      )}
    </>
  );
}

function ConfirmationModal({
  openConfirmation,
  setOpenConfirmation,
  unRecognizedColumns,
  postData,
}) {
  return (
    <Dialog
      open={openConfirmation}
      onClose={() => {
        setOpenConfirmation(false);
      }}
    >
      <DialogContent>
        <DialogContentText>
          <span>
            We couldn't recognize these columns: <b>{unRecognizedColumns}</b>.
            <br />
            Do you still want to continue?
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            setOpenConfirmation(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            setOpenConfirmation(false);
            postData();
          }}
          autoFocus
        >
          Yes, I'm sure
        </Button>
      </DialogActions>
    </Dialog>
  );
}
