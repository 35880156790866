import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import PostSalesLeadDetails from './PostSalesLeadDetails';
// import PostSalesTasks from '../PostSalesTasks/PostSalesTasks';
// import PostSalesHiddenTasks from '../PostSalesTasks/PostSalesHiddenTasks';
import EditPostSalesLeadDetails from './EditPostSalesLeadDetails';
import { useCurrentPostSalesLead } from '../../../CurrentPostSalesLeadContext';
import {
  currentAdminIsAdmin,
  currentAdminIsFinance,
  currentAdminIsPgm,
} from '../../../../utils/common';
import PostSalesProfileTab from './PostSalesProfileTab';
import SalesHandoverTab from './SalesHandoverTab';
import FinanceHandoverTab from './FinanceHandoverTab';
import PostSalesPaymentTab from './PostSalesPaymentTab';

export default function PostSalesLeadDetailsSection() {
  const [currentTab, setCurrentTab] = useState(0);
  const [openEditDetailsView, setOpenEditDetailsView] = useState(false);
  const { fetchPostSalesLeadData, leadData } = useCurrentPostSalesLead();

  const handleTabChange = (e, newTabValue) => {
    setCurrentTab(newTabValue);
  };

  const handleEditDetailsViewClose = () => {
    setOpenEditDetailsView(false);
    fetchPostSalesLeadData(leadData.id);
  };

  return (
    <>
      {!openEditDetailsView ? (
        <>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
            >
              <Tab label='Details' />
              {/* {!currentAdminIsFinance() && !currentAdminIsPgm() && (
                <Tab label='Next tasks' />
              )}
              {!currentAdminIsFinance() && !currentAdminIsPgm() && (
                <Tab label='Hidden tasks' />
              )} */}
              <Tab label='Profile' />
              {(currentAdminIsFinance() ||
                currentAdminIsAdmin() ||
                currentAdminIsPgm()) && <Tab label='Payment' />}
            </Tabs>
          </Box>

          <TabPanel
            value={currentTab}
            index={0}
          >
            <PostSalesLeadDetails {...{ setOpenEditDetailsView }} />
          </TabPanel>
          {/* {!currentAdminIsFinance() && !currentAdminIsPgm() && (
            <TabPanel
              value={currentTab}
              index={1}
            >
              <PostSalesTasks />
            </TabPanel>
          )}
          {!currentAdminIsFinance() && !currentAdminIsPgm() && (
            <TabPanel
              value={currentTab}
              index={2}
            >
              <PostSalesHiddenTasks />
            </TabPanel>
          )} */}
          <TabPanel
            value={currentTab}
            index={1}
          >
            <PostSalesProfileTab />
          </TabPanel>

          <TabPanel
            value={currentTab}
            index={2}
          >
            <PostSalesPaymentTab />
          </TabPanel>
        </>
      ) : (
        <Stack
          sx={{
            width: '50%',
            margin: '0 auto',
          }}
        >
          <EditPostSalesLeadDetails {...{ handleEditDetailsViewClose }} />
        </Stack>
      )}
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
