import { Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react';
import PostSalesLeadModal from './PostSalesLeadModal/PostSalesLeadModal';
import { splitByUnderscoresAndCapitalize } from '../../../utils/common';
import { useCurrentPostSalesLead } from '../../CurrentPostSalesLeadContext';
import { getTimePeriod } from '../../Sidebar';

function PostSalesLeadsTable({
  postSalesData,
  handlePageChange,
  handlePageSizeChange,
  fetchPostSalesData,
  perPage,
  page,
  totalRows,
  loading,
  setFilters,
}) {
  const [openDetailedPostSalesLead, setOpenDetailedPostSalesLead] =
    useState(false);
  const { getCurrentPostSalesLeadData, leadData } = useCurrentPostSalesLead();

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      minWidth: 80,
      maxWidth: 80,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      sortable: false,
      renderCell: (params) => {
        if (params.row.id) {
          return <Typography variant='body2'>{params.row.id}</Typography>;
        }

        return <span style={{ color: '#cecece' }}>Unavailable</span>;
      },
    },
    {
      field: 'lead_name',
      headerName: 'Lead name',
      minWidth: 200,
      maxWidth: 200,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      renderCell: (params) => {
        if (params.row.lead_name) {
          return (
            <Typography variant='body2'>
              {params.row.lead_name} #{params.row.lead_id}
            </Typography>
          );
        }

        return <span style={{ color: '#cecece' }}>Unavailable</span>;
      },
    },
    {
      field: 'poc_name',
      headerName: 'POC',
      minWidth: 200,
      maxWidth: 200,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      renderCell: (params) => {
        if (params.row.poc_name) {
          return <Typography variant='body2'>{params.row.poc_name}</Typography>;
        }
        return <span style={{ color: '#cecece' }}>Unavailable</span>;
      },
    },
    {
      field: 'token_amount_paid',
      headerName: 'Token amount paid',
      minWidth: 150,
      maxWidth: 150,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      sortable: false,
      renderCell: (params) => {
        if (params.row.token_amount_paid) {
          return (
            <Typography variant='body2'>
              ₹ {params.row.token_amount_paid}
            </Typography>
          );
        }
        return <span style={{ color: '#cecece' }}>Unavailable</span>;
      },
    },
    {
      field: 'tap_on',
      headerName: 'TAP on',
      minWidth: 150,
      maxWidth: 150,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: true,
      renderCell: (params) => {
        const res = ['Open for'];
        const timePeriod =
          (new Date() - new Date(params.row.created_at)) /
          (24 * 60 * 60 * 1000); // in days

        if (timePeriod >= 1) {
          res.push(`${Math.floor(timePeriod)} days`);
        } else if (timePeriod * 24 >= 1) {
          res.push(`${Math.floor(timePeriod * 24)} hours`);
        } else if (timePeriod * 24 >= 0.0166) {
          res.push(`${Math.floor(timePeriod * 24 * 60)} minutes`);
        } else {
          res.push(`${Math.floor(timePeriod * 24 * 60 * 60)} seconds`);
        }

        if (res.length <= 1) {
          return <span style={{ color: '#cecece' }}>Unavailable</span>;
        }
        return <Typography variant='body2'>{res.join(' ')}</Typography>;
      },
    },
    {
      field: 'last_comment_on',
      headerName: 'Last comment on',
      minWidth: 230,
      maxWidth: 230,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: true,
      renderCell: (params) => {
        if (!params.row.last_comment?.created_at) {
          return <span style={{ color: '#cecece' }}>Unavailable</span>;
        }

        let style = {};
        const res = ['No update for'];

        const lastCommentCreatedOn = new Date(
          params.row.last_comment?.created_at
        );
        let timePeriod =
          (new Date() - lastCommentCreatedOn) / (24 * 60 * 60 * 1000); // in days

        if (timePeriod >= 1) {
          res.push(`${Math.floor(timePeriod)} days`);
          style = {
            p: '0.3em',
            backgroundColor: '#ef9a9a',
          };
        } else {
          timePeriod *= 24; // in hours
          if (timePeriod >= 1) {
            res.push(`${Math.floor(timePeriod)} hours`);
          } else if (timePeriod >= 0.0166) {
            res.push(`${Math.floor(timePeriod * 60)} minutes`);
          } else {
            res.push(`${Math.floor(timePeriod * 60 * 60)} seconds`);
          }
        }

        if (res.length <= 1) {
          return <span style={{ color: '#cecece' }}>Unavailable</span>;
        }
        return (
          <Typography
            variant='body2'
            sx={style}
          >
            {res.join(' ')}
          </Typography>
        );
      },
    },
    // {
    //   field: 'payment_id',
    //   headerName: 'Payment id',
    //   minWidth: 150,
    //   maxWidth: 150,
    //   align: 'center',
    //   headerAlign: 'center',
    //   type: 'string',
    //   sortable: false,
    //   renderCell: (params) => {
    //     if (params.row.payment_id) {
    //       return (
    //         <Typography variant='body2'>{params.row.payment_id}</Typography>
    //       );
    //     }
    //     return <span style={{ color: '#cecece' }}>Unavailable</span>;
    //   },
    // },
    {
      field: 'payment_type',
      headerName: 'Payment type',
      minWidth: 150,
      maxWidth: 150,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      renderCell: (params) => {
        if ((params.row.payment_type ?? '') !== '') {
          return (
            <Typography variant='body2'>
              {splitByUnderscoresAndCapitalize(params.row.payment_type)}
            </Typography>
          );
        }
        return <span style={{ color: '#cecece' }}>Unavailable</span>;
      },
    },
    {
      field: 'emi_provider',
      headerName: 'EMI provider',
      minWidth: 150,
      maxWidth: 150,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      renderCell: (params) => {
        if ((params.row.emi_provider ?? '') !== '') {
          return (
            <Typography variant='body2'>
              {splitByUnderscoresAndCapitalize(params.row.emi_provider)}
            </Typography>
          );
        }
        return <span style={{ color: '#cecece' }}>Unavailable</span>;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      maxWidth: 150,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      renderCell: (params) => {
        if (params.row.status) {
          return (
            <Typography variant='body2'>
              {splitByUnderscoresAndCapitalize(params.row.status)}
            </Typography>
          );
        }
        return <span style={{ color: '#cecece' }}>Unavailable</span>;
      },
    },
  ];

  const handleDetailedModalClose = (e) => {
    setOpenDetailedPostSalesLead(false);
    fetchPostSalesData();
  };

  return (
    <Stack>
      <DataGrid
        rows={postSalesData}
        columns={columns}
        rowsPerPageOptions={[10, 25, 50, 75, 100]} // 100 is the max page size in non-pro version
        rowCount={totalRows}
        pagination
        paginationMode='server'
        page={page - 1}
        pageSize={perPage}
        onPageChange={(newPage) => handlePageChange(newPage + 1)}
        onPageSizeChange={(page) => handlePageSizeChange(page)}
        sortingMode='server'
        onSortModelChange={(sortOrders) => {
          if (sortOrders.length === 0) {
            setFilters((prev) => {
              return {
                status: prev['status'],
              };
            });
            return;
          }

          const key = sortOrders.at(-1).field;
          const val = sortOrders.at(-1).sort;
          setFilters((prev) => {
            return {
              status: prev['status'],
              [key]: val,
            };
          });
        }}
        autoHeight
        disableSelectionOnClick
        onRowClick={(params, event) => {
          setOpenDetailedPostSalesLead(true);
          getCurrentPostSalesLeadData(params.row.id);
        }}
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#e3f2fd',
            cursor: 'pointer',
          },
        }}
        loading={loading}
      />

      {openDetailedPostSalesLead && leadData && (
        <PostSalesLeadModal
          {...{
            openDetailedPostSalesLead,
            handleDetailedModalClose,
          }}
        />
      )}
    </Stack>
  );
}

export default PostSalesLeadsTable;
