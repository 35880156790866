import {
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
  Grid,
} from '@mui/material';
const Text = ({ textStyle1 = null, text, onClick, secondText }) => {
  const defaultStyle = {
    fontWeight: textStyle1?.fontWeight ? textStyle1.fontweight : 'normal',
    fontSize: textStyle1?.fontSize ? textStyle1.fontSize : '16px',
    fontFamily: 'Arial, sans-serif',
  };

  const textStyle = textStyle1 ? textStyle1 : defaultStyle;

  return (
    <>
      <Typography
        sx={textStyle}
        onClick={onClick}
      >
        {text}
      </Typography>
      {secondText && <Typography>{secondText}</Typography>}
    </>
  );
};
export default Text;
