import {
  Stack,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import Box from '@mui/material/Box';

export function ConfirmationModal({
  openConfirmation,
  setOpenConfirmation,
  handleAccept,
  massage,
}) {
  return (
    <Dialog
      open={openConfirmation}
      onClose={() => {
        setOpenConfirmation(false);
      }}
    >
      <DialogContent>
        <DialogContentText>
          <span>{massage}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            setOpenConfirmation(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            setOpenConfirmation(false);
            handleAccept();
          }}
          autoFocus
        >
          Yes, I'm sure
        </Button>
      </DialogActions>
    </Dialog>
  );
}
