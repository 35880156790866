import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';

export default function CampaignBankDetails({
  islocked,
  isApproved,
  isPaid,
  campaignDetails,
  createCampaignDetails,
  textFieldStyle,
}) {
  const textFieldStyleForFullWidth = {
    ...textFieldStyle,
    width: '85%',
    '& .MuiInputBase-root': {
      height: '2.5rem',
    },
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '3%',
        }}
      >
        <Typography style={{ width: '20%', marginTop: '1%' }}>
          Beneficiary Name:
        </Typography>
        <TextField
          disabled={
            islocked(campaignDetails) ||
            isApproved(campaignDetails) ||
            isPaid(campaignDetails)
          }
          name='beneficiary_name'
          type='text'
          id='beneficiary_name'
          value={campaignDetails.beneficiary_name}
          onChange={createCampaignDetails}
          fullWidth
          placeholder='Beneficiary Name'
          sx={textFieldStyleForFullWidth}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '3%',
        }}
      >
        <Typography style={{ width: '20%', marginTop: '1%' }}>
          Beneficiary Email:
        </Typography>
        <TextField
          disabled={
            islocked(campaignDetails) ||
            isApproved(campaignDetails) ||
            isPaid(campaignDetails)
          }
          name='beneficiary_email'
          type='text'
          id='beneficiary_email'
          value={campaignDetails.beneficiary_email}
          onChange={createCampaignDetails}
          fullWidth
          placeholder='Beneficiary Email'
          sx={textFieldStyleForFullWidth}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '3%',
        }}
      >
        <Typography style={{ width: '20%', marginTop: '1%' }}>
          Beneficiary IFSC:
        </Typography>
        <TextField
          disabled={
            islocked(campaignDetails) ||
            isApproved(campaignDetails) ||
            isPaid(campaignDetails)
          }
          name='beneficiary_ifsc'
          type='text'
          id='beneficiary_ifsc'
          onChange={createCampaignDetails}
          value={campaignDetails.beneficiary_ifsc}
          fullWidth
          placeholder='Beneficiary IFSC'
          sx={textFieldStyleForFullWidth}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '3%',
        }}
      >
        <Typography style={{ width: '20%', marginTop: '1%' }}>
          Account Number:
        </Typography>
        <TextField
          disabled={
            islocked(campaignDetails) ||
            isApproved(campaignDetails) ||
            isPaid(campaignDetails)
          }
          name='beneficiary_account_number'
          type='text'
          id='beneficiary_account_number'
          value={campaignDetails.beneficiary_account_number}
          onChange={createCampaignDetails}
          placeholder='Account Number'
          fullWidth
          sx={textFieldStyleForFullWidth}
        />
      </div>
    </>
  );
}
