import {
  TextField,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { likeIcon, dislikeIcon } from '../../../utils/training/common';

export function FeedbackModal({
  openConfirmation,
  setOpenConfirmation,
  handleAccept,
  isThumbsUp,
  setIsThumbsUp,
  feedbackText,
  setFeedbackText,
  setHasError,
  setErrorMessage,
}) {
  const [selectedThumb, setSelectedThumb] = useState(0);

  const handleNextModuleAccept = () => {
    if (!feedbackText) {
      setHasError(true);
      setErrorMessage('Please enter your feedback.');
      return;
    }
    setOpenConfirmation(false);
    handleAccept();
  };

  return (
    <Dialog
      open={openConfirmation}
      onClose={() => {
        setOpenConfirmation(false);
      }}
    >
      <DialogContent>
        <DialogContentText>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'left',
              marginLeft: '15px',
              width: '350px',
              marginRight: '15px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '20px',
                marginBottom: '10px',
              }}
            >
              Give Feedback
            </Typography>

            <Typography>Is this video helpful?</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                marginBottom: '10px',
                marginTop: '10px',
              }}
            >
              <span
                style={{ cursor: 'pointer', marginRight: '20px' }}
                onClick={() => {
                  setIsThumbsUp(true);
                  setSelectedThumb(1);
                }}
              >
                {likeIcon(selectedThumb)}
              </span>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsThumbsUp(false);
                  setSelectedThumb(2);
                }}
              >
                {dislikeIcon(selectedThumb)}
              </span>
            </Box>
            <Typography>what did you like or what did you not like?</Typography>
            <TextField
              multiline
              rows={3}
              fullWidth
              placeholder='Please write your feedback'
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
            />
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            setOpenConfirmation(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => handleNextModuleAccept()}
          autoFocus
          sx={{
            marginRight: '17px',
          }}
        >
          Yes, I'm sure
        </Button>
      </DialogActions>
    </Dialog>
  );
}
