import { useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import gsap from 'gsap';
import CallingUiBox from './CallingUiBox';
import ExpFieldBox from './ExpFieldBox';
import GraduationBox from './GraduationBox';
import WorkingSkillsBox from './WorkingSkillsBox';
import SignedUpBox from './SignedUpBox';
import DialedBox from './DialedBox';
import LeadStatusBox from './LeadStatusBox';
import CirclesComponent from './Circles';
import { useLocation } from 'react-router-dom';
import PastInteraction from './PastInteractionField';

const LeadCallDetails = () => {
  const [widthPercentage, setWidthPercentage] = useState(0);
  const [isCallingUIVisible, setCallingUIVisible] = useState(true);
  const [circlePosition, setCirclePosition] = useState(false);
  const childBoxRef = useRef(null);
  const redCircleRef = useRef(null);
  const expFieldRef = useRef(null);
  const graduationRef = useRef(null);
  const workingSkillsRef = useRef(null);
  const signedFromRef = useRef(null);
  const LeadStatusRef = useRef(null);
  const InteractionRef = useRef(null);
  const [circleShadow, setCircleShadow] = useState(false);

  const callingUIRefStyling = useRef(null);
  const ExpFieldRefStyling = useRef(null);
  const GraduationFieldRefStyling = useRef(null);
  const WorkingSkillsRefStyling = useRef(null);
  const SignedUpRefStyling = useRef(null);
  const LeadStatusRefStyling = useRef(null);
  const InteractionRefStyling = useRef(null);

  const location = useLocation();
  const currentLead = location.state.currentLead;
  const currentLeadStatusActivities =
    location.state.currentLeadStatusActivities;
  const currentLeadSignups = location.state.currentLeadSignups;
  const currentLeadInteractions = location.state.currentLeadInteractions;

  setTimeout(() => {
    callingUIRefStyling.current.style.display = 'none';
  }, 3000);
  setTimeout(() => {
    ExpFieldRefStyling.current.style.display = 'none';
  }, 7000);
  setTimeout(() => {
    GraduationFieldRefStyling.current.style.display = 'none';
  }, 12000);
  setTimeout(() => {
    WorkingSkillsRefStyling.current.style.display = 'none';
  }, 16000);
  setTimeout(() => {
    SignedUpRefStyling.current.style.display = 'none';
  }, 19000);
  setTimeout(() => {
    LeadStatusRefStyling.current.style.display = 'none';
  }, 24000);
  setTimeout(() => {
    InteractionRefStyling.current.style.display = 'none';
  }, 27000);

  useEffect(() => {
    const childBox = childBoxRef.current;
    const redCircle = redCircleRef.current;

    if (childBox && redCircle) {
      const originalWidth = childBox.offsetWidth;
      const targetWidths = [0.26, 0.38, 0.5, 0.75, 0.99];
      const delays = [1, 3, 17, 3, 4];

      const tl = gsap.timeline({
        onUpdate: () => {
          const currentWidth = redCircle.offsetWidth;
          const percentage = (currentWidth / originalWidth) * 100;
          setWidthPercentage(Math.round(percentage));

          if (widthPercentage >= 20 && widthPercentage <= 31) {
            setCircleShadow(true);
          }

          if (percentage >= 27) {
            setCallingUIVisible(false);
            setCirclePosition(true);
          } else {
            setCirclePosition(false);
          }

          if (percentage >= 27 && percentage <= 39) {
            gsap.to(expFieldRef.current, { opacity: 1, duration: 0.3 });
          } else {
            gsap.to(expFieldRef.current, { opacity: 0, duration: 0.1 });
          }

          if (percentage >= 39 && percentage <= 50) {
            gsap.to(graduationRef.current, { opacity: 1, duration: 0.3 });
            gsap.to(workingSkillsRef.current, {
              opacity: 1,
              duration: 0.3,
              delay: 4,
            });
            gsap.to(signedFromRef.current, {
              duration: 0.3,
              delay: 7,
              opacity: 1,
              ease: 'back',
            });
            gsap.to(LeadStatusRef.current, {
              duration: 0.3,
              delay: 10,
              opacity: 1,
              ease: 'back',
            });

            setTimeout(() => {
              gsap.to(graduationRef.current, { opacity: 0 });
              gsap.to(graduationRef.current, {
                opacity: 0,
                duration: 0.1,
              });
              gsap.to(workingSkillsRef.current, {
                opacity: 0,
                duration: 1,
                delay: 3,
              });
              gsap.to(signedFromRef.current, {
                opacity: 0,
                duration: 1,
                delay: 9,
              });
              gsap.to(LeadStatusRef.current, {
                opacity: 0,
                duration: 1,
                delay: 12,
              });
            }, 3000);
          }
        },
        onComplete: () => {
          setCirclePosition(true);
          setCircleShadow(false);
        },
      });

      targetWidths.forEach((targetWidth, index) => {
        tl.to(redCircle, { duration: 1, width: originalWidth * targetWidth });

        tl.to('.circle:nth-child(2)', {
          duration: 0.3,
          opacity: 0,
          stagger: 0.1,
          delay: 0.3,
        });

        tl.to(redCircle, {
          duration: 0,
          width: originalWidth * targetWidth,
          delay: delays[index],
        });
      });
    }
  }, []);

  return (
    <Paper
      component='div'
      sx={{
        backgroundColor: '#0B0A0A',
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        flexDirection: 'column',
      }}
    >
      <Box
        id='topBox'
        className='top-box'
        sx={{
          minHeight: '11rem',
          width: '100%',
          backgroundColor: '#0B0A0A',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          id='childBox'
          ref={childBoxRef}
          className='child-box'
          sx={{
            width: '60%',
            color: '#8C8686',
            boxSizing: 'content-box',
            height: '18px',
            borderRadius: '25px',
            border: '1.47px solid',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '3px',
            overflow: 'hidden',
            mt: '3rem',
          }}
        >
          {[1, 2, 3, 4, 5].map((_, index) => (
            <Box
              key={index}
              className='circle'
              sx={{
                position: 'relative',
                height: '17px',
                width: '17px',
                background: `linear-gradient(90deg, #703ef3 -13.25%, #9f6bf5 51%, #e6caf0 115.25%)`,
                borderRadius: '50%',
                transformOrigin: '50% 50%',
                zIndex: 1,
              }}
            />
          ))}
          <Box
            className='red-circle'
            ref={redCircleRef}
            sx={{
              position: 'absolute',
              height: '17px',
              width: '17px',
              background: `linear-gradient(90deg, #703ef3 -13.25%, #9f6bf5 51%, #e6caf0 115.25%)`,
              borderRadius: '25px',
              transformOrigin: '50% 50%',
              zIndex: 5,
            }}
          />
        </Box>
      </Box>

      <Box
        className='dataBox'
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          top: '1rem',
        }}
      >
        <Box ref={callingUIRefStyling}>
          <CallingUiBox
            widthPercentage={widthPercentage}
            isCallingUIVisiblePro={isCallingUIVisible}
            currentLeadData={currentLead}
          />
        </Box>
        <Box ref={ExpFieldRefStyling}>
          <ExpFieldBox
            expFieldRef={expFieldRef}
            currentLeadData={currentLead}
          />
        </Box>
        <Box ref={GraduationFieldRefStyling}>
          <GraduationBox
            graduationRef={graduationRef}
            currentLeadData={currentLead}
          />
        </Box>
        <Box ref={WorkingSkillsRefStyling}>
          <WorkingSkillsBox
            workingSkillsRef={workingSkillsRef}
            currentLeadData={currentLead}
          />
        </Box>
        <Box ref={SignedUpRefStyling}>
          <SignedUpBox
            signedFromRef={signedFromRef}
            currentLeadData={currentLead}
            currentLeadSignups={currentLeadSignups}
          />
        </Box>
        <Box ref={LeadStatusRefStyling}>
          <LeadStatusBox
            LeadStatusRef={LeadStatusRef}
            currentLeadData={currentLead}
            currentLeadStatusActivities={currentLeadStatusActivities}
          />
        </Box>
        <Box ref={InteractionRefStyling}>
          <PastInteraction
            LeadStatusRef={InteractionRef}
            currentLeadData={currentLead}
            currentLeadInteractions={currentLeadInteractions}
            widthPercentage={widthPercentage}
          />
        </Box>

        <Box className={'diealBoxUi'}>
          <DialedBox
            widthPercentage={widthPercentage}
            currentLeadData={currentLead}
          />
        </Box>
      </Box>
      <CirclesComponent
        applyShadow={circleShadow}
        newCirclePosition={circlePosition}
      />
    </Paper>
  );
};

export default LeadCallDetails;
