import React, { useState } from 'react';
import {
  Tabs,
  Tab,
  DialogContent,
  Dialog,
  Box,
  Typography,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import AdminProfileModal from './AdminProfileModal';
import CloseIcon from '@mui/icons-material/Close';
import BucketSearchSettings from './BucketSearchSettings';
import PropTypes from 'prop-types';
import {
  currentAdminIsHr,
  currentAdminIsTa,
  currentAdminIsInterviewer,
  currentAdminIsAdmin,
  getCurrentAdminId,
  currentAdminIsSeniorManager,
} from '../utils/common';
import SalsaLimitSettings from './SalsaLimitSettings';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const AdminSettingsModal = ({ openSettingModal, setOpenSettingModal }) => {
  const [value, setValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={openSettingModal}
      onClose={(e) => setOpenSettingModal(false)}
      maxWidth='md'
      fullWidth
      scroll='paper'
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant='h5'
          component='p'
        >
          Settings
        </Typography>

        <IconButton onClick={(e) => setOpenSettingModal(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          padding: 0,
          flexGrow: 2,
          bgcolor: 'background.paper',
          display: 'flex',
          width: '100%',
        }}
      >
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={value}
          onChange={handleTabChange}
          sx={{
            borderRight: 1,
            borderColor: 'divider',
          }}
        >
          {!currentAdminIsHr() &&
            !currentAdminIsTa() &&
            !currentAdminIsInterviewer() && <Tab label='Bucket Search' />}
          {(currentAdminIsAdmin() || currentAdminIsSeniorManager()) && (
            <Tab label='Salsa Limit' />
          )}
        </Tabs>

        <Stack
          sx={{
            height: 'inherit',
            overflow: 'auto',
            width: 'inherit',
          }}
        >
          <TabPanel
            value={value}
            index={0}
          >
            <BucketSearchSettings />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
          >
            <SalsaLimitSettings />
          </TabPanel>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AdminSettingsModal;
