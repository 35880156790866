import React from 'react';
import Box from '@mui/material/Box';
import { Line } from 'react-chartjs-2';

const ChartBox = ({ chartDatalist, isChartOpen, date }) => {
  const todayDate = new Date(date) || new Date();

  const formattedDate = `${todayDate.getUTCDate()} ${new Intl.DateTimeFormat(
    'en-US',
    {
      month: 'short',
    }
  ).format(todayDate)}`;

  const { driveDates, yAxisRankArray, yAxisCapsArray } = chartDatalist;

  if (!Array.isArray(yAxisRankArray)) {
    return null;
  }

  const maxRank = Math.max(...yAxisRankArray);

  let yAxisData = Array.from(
    {
      length: maxRank < 10 ? 11 : Math.ceil(maxRank / 10) * 10 + 1,
    },
    (_, index) => index
  );

  const currentDateIndex = driveDates?.findIndex(
    (date) => date === formattedDate
  );

  const data = {
    labels: driveDates,
    datasets: [
      {
        label: 'caps',
        data: yAxisRankArray,
        fill: false,
        borderColor: '#1976D2',
        tension: 0,
        borderWidth: 2,
        pointRadius: (context) => {
          const dataIndex = context.dataIndex;
          const capValue = yAxisCapsArray[dataIndex];
          return capValue !== 0 ? 4 : 0;
        },
        pointBackgroundColor: '#1976D2',
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'category',
        labels: driveDates,
        grid: {
          display: true,
          color: (context) =>
            context.tick.value === currentDateIndex ? '#424242' : '',
          lineWidth: (context) =>
            context.tick.value === currentDateIndex ? 2 : 0,
          borderDash: (context) =>
            context.tick.value === currentDateIndex ? [5, 5] : [],
        },
      },
      y: {
        type: 'category',
        labels: yAxisData,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataPointIndex = context.dataIndex;
            const additionalInfo = yAxisCapsArray[dataPointIndex];
            return `Caps: ${additionalInfo}`;
          },
        },
      },
    },
  };

  options.scales.y.axis = 'y';
  options.scales.y.title = {
    display: true,
    text: 'Rank',
    font: {
      size: 14,
    },
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: isChartOpen ? '230px' : '0',
        overflow: 'hidden',
        zIndex: 1,
        padding: '0 10px',
      }}
    >
      <Line
        data={data}
        options={options}
      />
    </Box>
  );
};

export default ChartBox;
