import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const LeaderboardSkeleton = () => {
  const skeletonRows = Array.from({ length: 7 }).map((_, index) => (
    <Box
      key={index}
      sx={{
        borderRadius: '8px',
        background: 'transparent',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        color: '#000000',
        fontSize: '16px',
        margin: '10px 0',
      }}
    >
      <Skeleton
        variant='circular'
        animation='wave'
        width={15}
        height={15}
        sx={{}}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '15px',
          marginLeft: '-2rem',
        }}
      >
        <Skeleton
          variant='circular'
          animation='wave'
          width={20}
          height={20}
        />
        <Skeleton
          variant='caption'
          animation='wave'
          width={90}
          height={10}
          sx={{
            borderRadius: '8px',
          }}
        />
      </Box>
      <Skeleton
        variant='circular'
        width={15}
        height={15}
        animation='wave'
        sx={{
          marginLeft: '-3em',
          marginRight: '3rem',
        }}
      />
      <Skeleton
        variant='caption'
        width={90}
        height={10}
        animation='wave'
        sx={{
          marginLeft: '-4em',
          borderRadius: '8px',
        }}
      />
      <Skeleton
        variant='caption'
        width={90}
        height={10}
        animation='wave'
        sx={{
          borderRadius: '8px',
        }}
      />
    </Box>
  ));

  return <>{skeletonRows}</>;
};

export default LeaderboardSkeleton;
