import React from 'react';
import { GoogleLogout } from 'react-google-login';
import { useNavigate } from 'react-router-dom';
import { useAuthStateValue } from '../auth/AuthContext';
import { Box, ListItemIcon, ListItemText } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useSocket } from '../../socket/socketContext';
import { getCurrentAdminId } from '../../utils/common';

function Logout() {
  const [socket, disconnectSocket] = useSocket();
  const [user, setUser] = useAuthStateValue();
  const history = useNavigate();
  function logout() {
    if (socket) {
      disconnectSocket();
    }
    const userId = getCurrentAdminId();
    setUser(null);
    localStorage.clear();
    history(`/?${userId}`);
  }

  return (
    <GoogleLogout
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      render={(renderProps) => (
        <Box
          px={2}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            cursor: 'pointer',
          }}
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <ListItemIcon>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          <ListItemText
            primary={'Logout'}
            sx={{
              cursor: 'pointer',
              marginLeft: '-1rem',
            }}
          />
        </Box>
      )}
      buttonText='Logout'
      onLogoutSuccess={logout}
    />
  );
}

export default Logout;
