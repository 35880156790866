import EditIcon from '@mui/icons-material/Edit';

const PencilIcon = ({ style }) => {
  const IconStyle = {
    position: 'absolute',
    right: 0,
    cursor: 'pointer',
    textTransform: 'none',
    backgroundColor: 'black',
    borderRadius: '50%',
    color: 'white',
    bottom: 5,
    padding: '3px 0 0 0',
    margin: '5px 20px 0 0',
    zIndex: 1,
    ...style,
  };
  return (
    <>
      <EditIcon
        variant='outlined'
        sx={IconStyle}
      ></EditIcon>
    </>
  );
};
export default PencilIcon;
