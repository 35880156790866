import React, { useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { splitByUnderscoresAndCapitalize } from '../../../utils/common';
import { styled } from '@mui/material/styles';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme-past-date': {
    backgroundColor: '#f0f0f0', // Gray background for past dates
  },
}));

export default function HolidaysTable({ allHolidays, operator, value }) {
  const [perPage, setPerPage] = useState(50);

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      type: 'date',
      valueGetter: ({ value }) => value,
    },
    {
      field: 'type',
      headerName: 'Holiday Type',
      width: 180,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) =>
        params.row?.type
          ? splitByUnderscoresAndCapitalize(params.row.type)
          : '',
    },
  ];

  return (
    <Box
      width='fit-content'
      height='100%'
      sx={{ mt: 5 }}
    >
      <StyledDataGrid
        initialState={{
          filter: {
            filterModel: {
              items: [
                {
                  columnField: 'date',
                  operatorValue: 'onOrAfter',
                  value: new Date().toISOString(),
                },
              ],
            },
          },
          sorting: {
            sortModel: [{ field: 'date', sort: 'asc' }],
          },
        }}
        rows={allHolidays}
        getRowId={(row) => row.id}
        columns={columns}
        pageSize={perPage}
        rowsPerPageOptions={[50, 100]}
        onPageSizeChange={(page) => setPerPage(page)}
        pagination
        autoHeight
        disableSelectionOnClick
        getRowClassName={(params) => {
          const holidayDate = new Date(params.row.date);
          const isPastDate = holidayDate < new Date();
          return isPastDate ? 'super-app-theme-past-date' : '';
        }}
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#e3f2fd',
          },
          '& .MuiDataGrid-cell': {
            paddingLeft: '25px',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            paddingLeft: '20px',
          },
        }}
      />
    </Box>
  );
}
