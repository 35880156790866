import {
  Button,
  Slide,
  FormControl,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { base_url } from '../../components/Mode';
import CloseIcon from '@mui/icons-material/Close';
import {
  splitByCamelCaseAndCapitalize,
  splitByUnderscoresAndCapitalize,
} from '../../utils/common';
import { useStyles } from './ManagerTeamOverview';
import ImagesList from './ImagesList';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction='up'
      ref={ref}
      {...props}
    />
  );
});

export default function InsightsVerificationModal({
  selectedAdminForVerification,
  setSelectedAdminForVerification,
  setOpenDetailsVerificationModal,
  openDetailsVerificationModal,
  date,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
  fetchOverview,
}) {
  const [insights, setInsights] = useState({});
  const [reportData, setReportData] = useState({});
  const [reportImages, setReportImages] = useState([]);

  useEffect(() => {
    getAdminInsights();
    getAdminReport();
    return () => {};
  }, []);

  function getAdminInsights() {
    let url = `${base_url()}/api/manager`;
    url += `?team_reports=0&of=${selectedAdminForVerification}&from=${date}&to=${date}`;

    axios
      .get(url)
      .then((res) => {
        delete res.data.rows.verification;
        setInsights(res.data.rows);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch admin insights"
        );
      });
  }

  function getAdminReport() {
    let url = `${base_url()}/api/admins/reports`;
    url += `?admin_id=${selectedAdminForVerification}&date=${date}`;

    axios
      .get(url)
      .then((res) => {
        const { data } = res;
        const { report, images } = data;

        delete report.admin_id;
        delete report.created_at;
        delete report.updated_at;
        delete report.date;
        setReportData(report);
        setReportImages(images);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            "Couldn't fetch the fields for verification"
        );
      });
  }

  function customVerificationDropdown(key) {
    return (
      <FormControl fullWidth>
        <InputLabel>{splitByUnderscoresAndCapitalize(key ?? '')}</InputLabel>
        <Select
          value={reportData[key]}
          label={splitByUnderscoresAndCapitalize(key ?? '')}
          onChange={(e) =>
            setReportData({ ...reportData, [key]: e.target.value })
          }
        >
          <MenuItem value={1}>Verified</MenuItem>
          <MenuItem value={0}>Not verified</MenuItem>
        </Select>
      </FormControl>
    );
  }

  const handleClose = () => {
    setOpenDetailsVerificationModal(false);
    setSelectedAdminForVerification('');
  };

  const handleSave = () => {
    if (!reportData.id) {
      setHasError(true);
      setErrorMessage(`Report id = ${reportData.id} not found.`);
      return;
    }

    const body = { ...reportData };
    delete body.id;

    axios
      .patch(`${base_url()}/api/reports/${reportData.id}`, body)
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Report successfully verified');
        handleClose();
        fetchOverview();
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't add report verification"
        );
      });
  };

  return (
    <Dialog
      fullScreen
      open={openDetailsVerificationModal}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant='h6'
            component='div'
          >
            Details verfication
          </Typography>
          <Button
            autoFocus
            color='inherit'
            onClick={handleSave}
          >
            save
          </Button>
        </Toolbar>
      </AppBar>

      <Stack
        m={4}
        spacing={2}
      >
        <Typography
          variant='h5'
          component='h5'
        >
          Uploaded images
        </Typography>

        <Stack alignItems='center'>
          {reportImages.length > 0 ? (
            <ImagesList images={reportImages} />
          ) : (
            <Typography
              variant='body1'
              component='p'
            >
              No images found
            </Typography>
          )}
        </Stack>

        {Object.keys(insights)?.length > 0 ? (
          <SelectedAdminOverviewTable data={insights} />
        ) : (
          <Typography
            variant='body1'
            component='p'
            textAlign='center'
          >
            No report data found
          </Typography>
        )}
      </Stack>

      {Object.keys(reportData).length > 0 && (
        <Stack
          m={4}
          spacing={3}
        >
          <Grid
            container
            spacing={2}
          >
            {Object.keys(reportData).map((key, index) => {
              if (key !== 'verification_notes' && key !== 'id') {
                return (
                  <Grid
                    item
                    xs={1.5}
                  >
                    {customVerificationDropdown(key)}
                  </Grid>
                );
              }
              return <></>;
            })}
          </Grid>
          <TextField
            type='text'
            label='Verification note'
            multiline
            minRows={4}
            maxRows={5}
            placeholder='Add note here'
            onChange={(e) =>
              setReportData({
                ...reportData,
                verification_notes: e.target.value,
              })
            }
            value={reportData.verification_notes ?? ''}
          />
        </Stack>
      )}
    </Dialog>
  );
}

export function SelectedAdminOverviewTable({ data }) {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            size='medium'
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {Object.keys(data).map((metric, index) => (
                  <TableCell
                    align='center'
                    sx={{ borderRight: 1, borderBottom: 1, padding: 2 }}
                  >
                    {/* if a string contains special characters too, then those points also naturally become split points */}
                    {splitByCamelCaseAndCapitalize(metric, ['_'])}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                {Object.keys(data)?.map((key) => {
                  return (
                    <TableCell
                      className={useStyles.sticky}
                      sx={{ borderRight: 1, borderBottom: 1 }}
                    >
                      {data[key][0]?.value ?? '-'}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
