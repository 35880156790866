import { Chip } from '@mui/material';
import { splitByUnderscoresAndCapitalize } from '../common';

export function getCandidateStatusColor(status) {
  switch (status) {
    case 'created':
      return '#e3f2fd';
    case 'assigned':
      return '#e3f2fd';
    case 'profile_reviewed':
      return '#ede7f6';
    case 'screening_done':
      return '#ede7f6';
    case 'cultural_questions_sent':
      return '#ede7f6';
    case 'cultural_questions_received':
      return '#fff176';
    case 'round_1_scheduled':
      return 'rgba(0, 0, 0, 0.08)';
    case 'round_1_done':
      return '#fff176';
    case 'round_2_scheduled':
      return '#fff176';
    case 'round_2_done':
      return '#fff176';
    case 'manager_round_scheduled':
      return '#fff176';
    case 'manager_round_done':
      return '#ffd54f';
    case 'salary_finalized':
      return '#ffd54f';
    case 'offer_letter_sent':
      return '#ffb74d';
    case 'offer_letter_signed':
      return '#dce775';
    case 'documents_collected':
      return '#d7ccc8';
    case 'joined':
      return '#81c784';
    default:
      return 'primary';
  }
}

export function getCandidateStatusLable(label, sx = {}) {
  function renderLabel(label, bgColor) {
    return (
      <Chip
        label={label.toUpperCase()}
        size='small'
        sx={{
          backgroundColor: bgColor,
          fontSize: '12px',
          fontWeight: 500,
          '& .MuiChip-icon': {
            color: 'red',
          },
          ...sx,
        }}
      />
    );
  }

  return renderLabel(
    splitByUnderscoresAndCapitalize(label),
    getCandidateStatusColor(label)
  );
}

export const feedbackPendingIcon = () => {
  return (
    <svg
      width='23'
      height='23'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_2758_2681)'>
        <path
          d='M33.3335 3.33301H6.66683C4.8335 3.33301 3.35016 4.83301 3.35016 6.66634L3.3335 36.6663L10.0002 29.9997H33.3335C35.1668 29.9997 36.6668 28.4997 36.6668 26.6663V6.66634C36.6668 4.83301 35.1668 3.33301 33.3335 3.33301ZM33.3335 26.6663H8.61683L7.6335 27.6497L6.66683 28.6163V6.66634H33.3335V26.6663ZM21 21.5L21.6668 19.9997V23.333L20.5 23.5L21 21.5Z'
          fill='#424242'
        />
        <circle
          cx='28'
          cy='24.1875'
          r='10.5'
          fill='#FF0000'
          stroke='white'
        />
        <path
          d='M29.5029 25.7812H26.9248L26.5264 18.3906H29.9014L29.5029 25.7812ZM26.5576 28.5156C26.5576 27.9635 26.7191 27.5755 27.042 27.3516C27.3701 27.1276 27.7581 27.0156 28.2061 27.0156C28.6488 27.0156 29.029 27.1276 29.3467 27.3516C29.6644 27.5755 29.8232 27.9635 29.8232 28.5156C29.8232 29.0417 29.6644 29.4219 29.3467 29.6562C29.029 29.8906 28.6488 30.0078 28.2061 30.0078C27.7581 30.0078 27.3701 29.8906 27.042 29.6562C26.7191 29.4219 26.5576 29.0417 26.5576 28.5156Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_2758_2681'>
          <rect
            width='40'
            height='40'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const feedbackDoneIcon = () => {
  return (
    <svg
      width='23'
      height='23'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_2758_2688)'>
        <path
          d='M33.3335 3.33301H6.66683C4.8335 3.33301 3.35016 4.83301 3.35016 6.66634L3.3335 36.6663L10.0002 29.9997H33.3335C35.1668 29.9997 36.6668 28.4997 36.6668 26.6663V6.66634C36.6668 4.83301 35.1668 3.33301 33.3335 3.33301ZM33.3335 26.6663H8.61683L7.6335 27.6497L6.66683 28.6163V6.66634H33.3335V26.6663ZM21 21.5L21.6668 19.9997V23.333L20.5 23.5L21 21.5Z'
          fill='#424242'
        />
        <rect
          x='14'
          y='11'
          width='28'
          height='28'
          rx='14'
          fill='white'
        />
        <path
          d='M40.1241 24.8599C39.7601 24.1879 39.7041 23.4319 39.9001 22.7039C40.2361 21.5559 39.8161 20.3239 38.8641 19.5959C38.2481 19.1479 37.8561 18.5039 37.7441 17.7479C37.5481 16.5719 36.6521 15.6199 35.4761 15.3959C34.7481 15.2559 34.0761 14.8359 33.6561 14.2199C32.9841 13.2399 31.7521 12.7919 30.5761 13.0999C29.8481 13.2959 29.0921 13.2119 28.4481 12.8199C27.4121 12.2319 26.0961 12.3719 25.2001 13.1559C24.6401 13.6599 23.9121 13.8839 23.1561 13.8559C21.9521 13.7719 20.8321 14.4719 20.3561 15.5639C20.0481 16.2639 19.5161 16.7959 18.8161 17.0759C17.6961 17.5239 16.9961 18.6159 17.0521 19.8199C17.0801 20.5759 16.8281 21.3039 16.3241 21.8359C15.4841 22.7599 15.3161 24.0759 15.8761 25.1399C16.2401 25.8119 16.2961 26.5679 16.1001 27.2959C15.7641 28.4439 16.1841 29.6759 17.1361 30.4039C17.7521 30.8519 18.1441 31.4959 18.2561 32.2519C18.4521 33.4279 19.3481 34.3799 20.5241 34.6039C21.2521 34.7439 21.9241 35.1639 22.3441 35.7799C23.0161 36.7599 24.2481 37.2079 25.4241 36.8999C26.1521 36.7039 26.9081 36.7879 27.5521 37.1799C28.5881 37.7679 29.9041 37.6279 30.8001 36.8439C31.3601 36.3399 32.0881 36.1159 32.8441 36.1439C34.0481 36.2279 35.1681 35.5279 35.6441 34.4359C35.9521 33.7359 36.4841 33.2039 37.1841 32.9239C38.3041 32.4759 39.0041 31.3839 38.9481 30.1799C38.9201 29.4239 39.1721 28.6959 39.6761 28.1639C40.5161 27.2399 40.6841 25.9239 40.1241 24.8599ZM25.7601 30.0119L21.3081 25.5599L22.4281 24.4399L25.7601 27.7999L33.5721 19.9879L34.6921 21.1079L25.7601 30.0119Z'
          fill='#0EB848'
        />
      </g>
      <defs>
        <clipPath id='clip0_2758_2688'>
          <rect
            width='40'
            height='40'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const handleKeyDown = (event) => {
  if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
    event.preventDefault(); // Prevent the default arrow key behavior
  }
};
