import { Box, Typography, Chip } from '@mui/material';
import Search10minConnect from '../../components/users/QuickSearch/Search10minConnect';
const LeadConnect10min = () => {
  return (
    <Box>
      <Typography
        variant='h6'
        component='p'
      >
        10 Minute Lead Connect
      </Typography>
      <Typography
        component='p'
        marginTop='20px'
        marginBottom='10px'
      >
        Search hot lead, and send with all IC's one by one
      </Typography>
      <Search10minConnect />
    </Box>
  );
};

export default LeadConnect10min;
