import React from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import './HeatMap.css';
import { Box } from '@mui/material';
import Text from '../../pages/Profile/Atoms/Text';

const HeatMap = ({ statusData }) => {
  const today = new Date();
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(today.getFullYear() - 1);
  return (
    <>
      {statusData && (
        <Box
          p={2}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Text
            textStyle1={{
              fontWeight: 'bold',
              fontSize: '16px',
              margin: '10px 0px 25px 8px',
              color: '#2196f3',
            }}
            text={'CONTRIBUTIONS'}
          />

          <CalendarHeatmap
            startDate={oneYearAgo}
            endDate={today}
            values={statusData[1]}
            classForValue={(value) => {
              if (!value || !value['COUNT']) {
                return 'color-Empty';
              }
              if (value['COUNT'] === 0) {
                return 'color-Empty';
              }
              if (value['CAP'] >= 1) {
                return 'color-high';
              } else if (value['TAP'] === 1) {
                return 'light-green-60';
              } else if (value['TAP'] > 1) {
                return 'light-green-80';
              } else if (value['APP'] === 1) {
                return 'light-green-20';
              } else if (value['APP'] > 1) {
                return 'light-green-40';
              }
            }}
            titleForValue={(value) => {
              if (!value['COUNT']) {
                return `No app, tap, or cap received on ${new Date(
                  value['date']
                )
                  .toString()
                  .slice(0, 15)}`;
              }

              const { CAP, TAP, APP, date } = value;

              if (CAP === 0 && TAP === 0 && APP === 0) {
                return `No app, tap, or cap received on ${date}`;
              }

              const messages = [];
              if (CAP > 0) {
                messages.push(`${CAP} CAP`);
              }
              if (TAP > 0) {
                messages.push(`${TAP} TAP`);
              }
              if (APP > 0) {
                messages.push(`${APP} APP`);
              }

              return `${messages.join(', ')} received on ${new Date(date)
                .toString()
                .slice(0, 15)}`;
            }}
            showMonthLabels={true}
            showWeekdayLabels={true}
          />
        </Box>
      )}
    </>
  );
};

export default HeatMap;
