import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import LeadFunnel from './LeadFunnel';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import useCheckSize from './../../hooks/useCheckSize';

export default function LeadFunnelModal({ isOpen, onClose, copyIds }) {
  const screenSize = useCheckSize();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw !important',
    height: screenSize >= 1400 ? '1000px' : '750px',
    bgcolor: '#fff',
    border: '2px solid #000',
    boxShadow: 24,
    maxWidth: 'none !important',
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: style }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          textAlign: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <DialogTitle sx={{ width: '95%' }}>Generate Lead Funnel</DialogTitle>
        <CloseIcon
          sx={{ margin: '0 17px 0 0', cursor: 'pointer' }}
          onClick={onClose}
        />
      </Box>
      <DialogContent sx={{ width: '100%' }}>
        <LeadFunnel defaultLeadIds={copyIds} />
      </DialogContent>
    </Dialog>
  );
}
