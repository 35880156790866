import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@mui/material';

const Timer = ({ closeModal }) => {
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    let timer;

    if (seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      closeModal();
    }

    return () => clearInterval(timer);
  }, [seconds]);

  const circleStyle = {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    border: '2px solid #009af0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  return (
    <div style={circleStyle}>
      <Typography variant='h4'>{seconds}</Typography>
    </div>
  );
};

export default Timer;
