import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { TabPanel } from '../AdminDashboard';
import IndividualActivity from './UserActivities/IndividualActivity';
import TeamActivity from './UserActivities/TeamActivity';
import { currentAdminIsAdmin } from '../../../utils/common';

export default function Monitor({ setHasError, setErrorMessage }) {
  const [currentTab, setCurrentTab] = useState(0);
  const [activityData, setActivityData] = useState(null);

  const handleTabChange = (e, newTabValue, data = null) => {
    setActivityData(data);
    setCurrentTab(newTabValue);
  };

  return (
    <Box sx={{ mr: 5, mt: 4 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
        >
          <Tab label='Team Activity' />
          <Tab label='Individual Activity' />
        </Tabs>
      </Box>
      <TabPanel
        value={currentTab}
        index={0}
      >
        <TeamActivity tabChange={handleTabChange} />
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={1}
      >
        <IndividualActivity data={activityData} />
      </TabPanel>
    </Box>
  );
}
