import { Box } from '@mui/material';
import Text from './Text';
const ProfileEditingBanner = ({ edit, onhandleEdit }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Text
          text={
            'Your profile is incomplete. Please update your profile details.    '
          }
          textStyle1={{
            fontSize: '15px',
            marginLeft: '270px',
          }}
        />
        <Text
          text={'Get Started'}
          textStyle1={{
            fontSize: '15px',
            marginLeft: '10px',
            borderBottom: '2px solid black',
            cursor: 'pointer',
          }}
          onClick={onhandleEdit}
        />
      </Box>
    </>
  );
};

export default ProfileEditingBanner;
