import React, { useState, useEffect } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { currentAdminIsManagerOrAdmin } from '../../utils/common';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import { useCurrentLead } from '../CurrentLeadContext';

const LeadDropRequestDetails = ({
  reasons,
  selectedReasons,
  setSelectedReasons,
  raisedDropRequestDetails,
  leadDropRequest,
}) => {
  const { setUpdatedDropReasons } = useCurrentLead();
  const [secondaryOptions, setSecondaryOptions] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const metadata = JSON.parse(raisedDropRequestDetails[0]?.metadata);
  const priviouseSelectedReasons = metadata?.selectedReasons;

  const handleSecondaryReasonSelect = (index, reason) => {
    const secondary = selectedReasons.secondary;
    if (index in selectedReasons.secondary) {
      delete secondary[index];
    } else {
      secondary[index] = reason;
    }
    setSelectedReasons({
      ...selectedReasons,
      secondary,
    });
  };

  useEffect(() => {
    let selectedReasonObject = {
      primary: priviouseSelectedReasons?.primary,
      secondary: priviouseSelectedReasons?.secondary,
    };
    setSelectedReasons(selectedReasonObject);

    setSecondaryOptions(priviouseSelectedReasons.secondary);
    reasons.map((reason) => {
      if (reason.primary === selectedReasonObject.primary) {
        setSecondaryOptions(reason.secondary);
      }
    });
  }, [raisedDropRequestDetails]);

  setUpdatedDropReasons(selectedReasons);
  return (
    <Box
      width={'98%'}
      style={{
        borderRadius: '15px',
        border: '1px solid black',
      }}
      p={2}
    >
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      {!currentAdminIsManagerOrAdmin() && (
        <Typography color={'red'}>
          You can't edit the lead details because the drop is pending
        </Typography>
      )}
      <Typography
        p={0}
        variant='h5'
        component='h5'
      >
        Lead drop
      </Typography>

      <Stack
        m={0}
        spacing={2}
      >
        <FormControl>
          {reasons.length > 0 &&
            reasons.map((reason) => {
              return (
                <FormControlLabel
                  value={reason.primary}
                  control={
                    <Radio
                      checked={reason.primary === selectedReasons.primary}
                      disabled={!currentAdminIsManagerOrAdmin()}
                      onChange={(e) => {
                        setSelectedReasons({
                          primary: reason.primary,
                          secondary: {},
                        });
                        setSecondaryOptions(reason.secondary);
                      }}
                    />
                  }
                  label={reason.primary}
                />
              );
            })}
        </FormControl>

        {secondaryOptions.length > 0 && (
          <>
            <Typography
              variant='body2'
              component='p'
            >
              Please select the relevant reason to drop this lead.
            </Typography>

            <FormGroup>
              {secondaryOptions.map((reason, index) => {
                return (
                  <FormControlLabel
                    value={reason.secondary}
                    disabled={!currentAdminIsManagerOrAdmin()}
                    control={
                      <Checkbox
                        checked={selectedReasons.secondary[index] ?? false}
                        onChange={(e) =>
                          handleSecondaryReasonSelect(index, reason)
                        }
                        color='success'
                      />
                    }
                    label={reason.value}
                  />
                );
              })}
            </FormGroup>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default LeadDropRequestDetails;
