import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { base_url } from '../Mode';
import {
  getFormattedDate,
  splitByUnderscoresAndCapitalize,
} from '../../utils/common';
import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CAPTrackingFilters from './CAPTrackingFilters';

const CAPTrackingTable = ({
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
  setTriggerRefresh,
  triggerRefresh,
  setEditCAP,
  setEditCAPData,
  handleOpen,
  currentDriveName,
  currentDriveDates,
  drivesDropdown,
  IndependentContributorDropdown,
  managerDropdown,
  initialFilterState,
  filters,
  setFilters,
}) => {
  const [capDetails, setCapDetails] = useState([]);
  const [perPage, setPerPage] = useState(50);
  const [capCount, setCapCount] = useState(0);
  const [fetchingData, setFetchingData] = useState(false);

  useEffect(() => {
    getCapDetails();
  }, [triggerRefresh, filters]);

  const getCapDetails = () => {
    const url = buildQueryString(filters);

    if (!url) {
      setHasError(true);
      setErrorMessage('Error in fetching CAP details url not found.');
    }

    setFetchingData(true);
    axios
      .get(`${base_url()}${url}`)
      .then((res) => {
        let data = [...res?.data.capsDetails];
        res?.data?.capsDetails?.forEach((element, index) => {
          element.srNo = index + 1;
          element.status = splitByUnderscoresAndCapitalize(element.status);
        });
        setCapDetails(data);
        setCapCount(res?.data?.totalCount);
        setFetchingData(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage('Error in fetching CAP details.');
        setFetchingData(false);
      });
  };

  const buildQueryString = (filters) => {
    let queryString = '/api/cap_tracking?';

    if (filters) {
      const { assignee, drive, from, managers, to, lead_id } = filters;

      if (assignee) {
        queryString += `&assignee=${assignee}`;
      }

      if (drive) {
        queryString += `&drive=${drive}`;
      }

      if (from) {
        queryString += `&from=${from}`;
      }

      if (managers) {
        queryString += `&managers=${managers}`;
      }

      if (to) {
        queryString += `&to=${to}`;
      }

      if (lead_id) {
        queryString += `&lead_id=${lead_id}`;
      }
    }

    return queryString;
  };

  const handleEdit = async (leadData) => {
    setEditCAP(true);
    setEditCAPData(leadData);
    handleOpen();
  };

  const handleDelete = async (leadId) => {
    try {
      const response = await axios.delete(
        `${base_url()}/api/cap_tracking/${leadId}`
      );
      setIsSuccess(true);
      setSuccessMessage('CAP removed successfully');
      setTriggerRefresh(!triggerRefresh);
    } catch (error) {
      console.error('Error in removing cap', error);
      setHasError(true);
      setErrorMessage('Could not delete the cap');
    }
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Sr No',
      width: 80,
      hide: true,
    },
    {
      field: 'srNo',
      headerName: 'Sr No',
      width: 80,
    },
    {
      field: 'lead_id',
      headerName: 'Lead Id',
      width: 120,
    },
    {
      field: 'lead_name',
      headerName: 'Lead Name',
      width: 250,
    },
    {
      field: 'lead_email',
      headerName: 'Email',
      width: 250,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 220,
    },
    {
      field: 'assignee_name',
      headerName: 'Assignee',
      width: 230,
    },
    {
      field: 'manager_name',
      headerName: 'Manager',
      width: 230,
    },
    {
      field: 'drive',
      headerName: 'Drive',
      width: 120,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
      valueGetter: (params) =>
        getFormattedDate(new Date(Date.parse(params?.row?.date)), true),
    },
    {
      field: 'edit',
      headerName: '',
      width: 70,
      renderCell: (params) =>
        params?.row?.is_deleted === 'no' ? (
          <IconButton onClick={() => handleEdit(params?.row)}>
            <EditOutlinedIcon />
          </IconButton>
        ) : null,
    },
    {
      field: 'delete',
      headerName: '',
      width: 70,
      renderCell: (params) =>
        params?.row?.is_deleted === 'no' ? (
          <IconButton
            sx={{ color: 'red' }}
            onClick={() => handleDelete(params?.row?.id)}
          >
            <DeleteIcon />
          </IconButton>
        ) : null,
    },
  ];

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme': {
      backgroundColor: '#bdbdbd',
    },
  }));
  return (
    <>
      <CAPTrackingFilters
        currentDriveName={currentDriveName}
        currentDriveDates={currentDriveDates}
        getCapDetails={getCapDetails}
        fetchingData={fetchingData}
        setTriggerRefresh={setTriggerRefresh}
        triggerRefresh={triggerRefresh}
        filters={filters}
        setFilters={setFilters}
        initialFilterState={initialFilterState}
        managerDropdown={managerDropdown}
        drivesDropdown={drivesDropdown}
        IndependentContributorDropdown={IndependentContributorDropdown}
      />
      {capDetails?.length != 0 ? (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              border: '2px solid #e0e0e0',
              borderRadius: '10px',
              width: '150px',
              marginBottom: '20px',
              marginTop: '10px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                textAlign: 'center',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                width: '100%',
                background: 'lightgray',
                padding: '10px',
              }}
            >
              Total CAPs
            </Typography>
            <Typography
              sx={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                padding: '10px',
                flexGrow: 1,
                fontSize: 'large',
              }}
            >
              {capCount}
            </Typography>
          </Box>

          <StyledDataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
              },
            }}
            rows={capDetails}
            columns={columns}
            pageSize={perPage}
            rowsPerPageOptions={[50, 100]}
            onPageSizeChange={(page) => setPerPage(page)}
            pagination
            autoHeight
            getRowClassName={(params) => {
              return params?.row?.is_deleted === 'yes' ? 'super-app-theme' : '';
            }}
          />
        </>
      ) : (
        <>
          <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
              No CAP Details to show
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default CAPTrackingTable;
