import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import { useCSVReader } from 'react-papaparse';
import {
  ImportSalariesColumnFormat,
  ImportBaseSalariesColumnFormat,
  ImportIncentiveColumnFormat,
} from '../../utils/importAdminsColumsFormat';
import EntriesToUploadModal from './EntriesToUploadModal';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';

const BulkImportSalary = ({ open, onChange, isBaseSalary = null }) => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { CSVReader } = useCSVReader();
  const [columnsInCSVInput, setColumnsInCSVInput] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [unRecognizedColumns, setUnRecognizedColumns] = useState('');
  const [serverResponse, setServerResponse] = useState(null);
  const [hasImported, setHasImported] = useState(false);
  const [fileType, setFileType] = useState('');
  const [openEntriesToUploadModal, setOpenEntriesToUploadModal] =
    useState(false);

  const handleVerifyInputColumns = () => {
    let validColumn;

    if (isBaseSalary) {
      validColumn = ImportBaseSalariesColumnFormat;
    }
    if (fileType === 'salary') {
      validColumn = ImportSalariesColumnFormat;
    }

    if (fileType === 'incentive') {
      validColumn = ImportIncentiveColumnFormat;
    }

    const knownColumns = new Set(validColumn);
    const unknownColumns = columnsInCSVInput?.filter((column) => {
      if (!knownColumns?.has(column)) return column;
    });
    setUnRecognizedColumns(unknownColumns?.join(','));
    if (unknownColumns?.length > 0) {
      setHasError(true);
      setErrorMessage(
        `There are unrecognized colums: ${unknownColumns?.join(
          ','
        )} please remove them, Accepted columns are ${validColumn?.join(',')} `
      );

      return;
    }

    handleEntriesToUploadModal();
  };

  const downloadTestCSV = () => {
    let data;
    let fileName = 'Sample.csv';
    if (isBaseSalary) {
      data = testcsvfileDataBaseSalary;
      fileName = 'Base Salary Sample.csv';
    }
    if (fileType === 'salary') {
      data = testcsvfileDataSalary;
      fileName = 'Salary Sample.csv';
    }

    if (fileType === 'incentive') {
      data = testcsvfileDataIncentive;
      fileName = 'Incentive Sample.csv';
    }

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      data?.map((row) => row?.join(','))?.join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');

    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);

    document.body.appendChild(link);
    link.click();
  };

  const testcsvfileDataSalary = [
    ['email', 'amount', 'base_salary_cleared_till'],
    ['demo@gmail.com', '10000', '2024-04-10'],
    ['nitesh@gmail.com', '5000', '2024-04-10'],
    ['aman@gmail.com', '25000', '2024-04-10'],
  ];
  const testcsvfileDataIncentive = [
    ['email', 'amount', 'incentive_cleared_till'],
    ['demo@gmail.com', '10000', '2024-04-10'],
    ['nitesh@gmail.com', '5000', '2024-04-10'],
    ['aman@gmail.com', '25000', '2024-04-10'],
  ];
  const testcsvfileDataBaseSalary = [
    ['name', 'email', 'base_salary'],
    ['demoname', 'demo@gmail.com', '10000'],
    ['Nitesh', 'nitesh@gmail.com', '5000'],
    ['aman', 'aman@gmail.com', '25000'],
  ];

  const handleEntriesToUploadModal = () => {
    setOpenEntriesToUploadModal((s) => !s);
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={onChange}
      maxWidth
    >
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <EntriesToUploadModal
        fileType={fileType}
        isBaseSalary={isBaseSalary}
        serverResponse={serverResponse}
        setServerResponse={setServerResponse}
        open={openEntriesToUploadModal}
        csvData={csvData}
        onChange={handleEntriesToUploadModal}
        columnsInCSVInput={columnsInCSVInput}
      />

      <DialogTitle
        sx={{
          minWidth: '36rem',
          position: 'relative',
        }}
      >
        {'Import Salary'}
        <IconButton
          onClick={onChange}
          sx={{ position: 'absolute', right: 0, top: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {!isBaseSalary && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2,
          }}
        >
          <FormControl
            sx={{ width: '200px' }}
            variant='outlined'
            margin='normal'
          >
            <InputLabel id='file-type-label'>Select File Type</InputLabel>
            <Select
              labelId='file-type-label'
              value={fileType}
              onChange={handleFileTypeChange}
              label='File Type'
            >
              <MenuItem value='salary'>Salary</MenuItem>
              <MenuItem value='incentive'>Incentive</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}

      <DialogContent>
        {(fileType || isBaseSalary) && (
          <DialogContentText>Please choose a CSV file</DialogContentText>
        )}
        <Box>
          <CSVReader
            onUploadAccepted={(result) => {
              setColumnsInCSVInput(result?.data?.shift());
              setCsvData(result?.data);
              setHasImported(true);
              setServerResponse({});
            }}
          >
            {({ getRootProps, acceptedFile, ProgressBar }) => (
              <Stack
                gap={3}
                mt={2}
              >
                {acceptedFile && hasImported && (
                  <Typography>
                    <strong>Selected file name:</strong> {acceptedFile?.name}
                  </Typography>
                )}
                <ProgressBar />
                <Stack
                  direction={'row-reverse'}
                  gap={2}
                >
                  {(fileType || isBaseSalary) && (
                    <>
                      {' '}
                      <Button
                        variant='contained'
                        startIcon={<FileUploadIcon size={15} />}
                        {...getRootProps()}
                      >
                        Select file
                      </Button>
                      {!acceptedFile && !hasImported && (
                        <>
                          <Button
                            variant='contained'
                            startIcon={<DownloadIcon size={15} />}
                            onClick={() => downloadTestCSV()}
                          >
                            sample file
                          </Button>
                        </>
                      )}
                    </>
                  )}

                  {acceptedFile && hasImported && (
                    <>
                      <Button
                        variant='contained'
                        onClick={handleVerifyInputColumns}
                      >
                        upload
                      </Button>
                    </>
                  )}
                </Stack>
              </Stack>
            )}
          </CSVReader>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default BulkImportSalary;
