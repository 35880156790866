import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { base_url } from '../../components/Mode';

export const getLeadDropRequest = createAsyncThunk(
  'leadDropRequest/fetchData',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${base_url()}/api/manager_actions/drop_request?action=pending`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || 'An error occurred.'
      );
    }
  }
);
