import { Box, Typography } from '@mui/material';
import React from 'react';

const GraduationBox = ({ graduationRef, currentLeadData }) => {
  const { fname, lname, college, graduation_year, branch } = currentLeadData;
  const fullname = fname + (lname !== null ? ` ${lname}` : '');

  return (
    <>
      {(branch || graduation_year) && (
        <Box
          ref={graduationRef}
          className='graduationRef'
          sx={{
            zIndex: 4,
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'opacity 0.1s ease-in',
            opacity: 0,
          }}
        >
          <Typography
            sx={{
              zIndex: 6,
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: 'Inter',
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            {fullname} is a
          </Typography>

          <Typography
            sx={{
              zIndex: 6,
              color: '#FFF',
              textAlign: 'center',
              fontSize: '50px',
              fontWeight: 700,
              width: '820px',
            }}
          >
            {branch} {graduation_year}{' '}
            <span
              style={{
                color: '#B998EF',
                fontFamily: 'Montserrat',
              }}
            >
              graduate
              {college && <span> from</span>}
            </span>{' '}
            {college}
          </Typography>
        </Box>
      )}
      {!branch && !graduation_year && (
        <Box
          ref={graduationRef}
          className='graduationRef'
          sx={{
            zIndex: 4,
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'opacity 0.1s ease-in',
            opacity: 0,
          }}
        >
          <Typography
            sx={{
              zIndex: 6,
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: 'Inter',
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            You don't have
          </Typography>

          <Typography
            sx={{
              zIndex: 6,
              color: '#FFF',
              textAlign: 'center',
              fontSize: '50px',
              fontWeight: 700,
              width: '820px',
            }}
          >
            {fullname}'s{' '}
          </Typography>
          <Typography
            sx={{
              zIndex: 6,
              color: '#FFF',
              textAlign: 'center',
              fontSize: '50px',
              fontWeight: 700,
              width: '820px',
            }}
          >
            <span
              style={{
                color: '#B998EF',
                fontFamily: 'Montserrat',
              }}
            >
              graduation
            </span>{' '}
            details.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default GraduationBox;
