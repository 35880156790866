import React, { useEffect, useRef, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthStateValue } from '../components/auth/AuthContext';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import AdminsComponent from '../components/AdminsContext';
import { useState, useCallback } from 'react';
import CurrentLeadComponent from '../components/CurrentLeadContext';
import Toolbar from '@mui/material/Toolbar';
import { Grid, IconButton, Stack, Typography, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Logo } from '../components/Logo';
// import { Stack } from "@chakra-ui/react";
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import FocusModeDialog from '../components/users/FocusMode/FocusModeDialog';
import AcceptNotificationModel from '../components/AcceptNotificationModel';
import { currentAdminIsIC, getCurrentAdminId } from '../utils/common';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// import io from 'socket.io-client';
import axios from 'axios';
import { base_url } from '../components/Mode';
import { Notification10MinConnectModel } from '../components/Notification10MinConnectModel';
import { useLocation } from 'react-router-dom';
import { useSocket } from '../socket/socketContext';
import { NotificationTapCapAchievement } from '../components/NotificationTapCapAchievement';
import Missed10MinConnectNotifications from '../components/users/Missed10MinConnectNotifications';

const drawerWidth = 290;
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: useLocation().pathname == '/ic-home' ? '0' : theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: open ? `${drawerWidth}px` : 0,
    ...(open && {
      transition: theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: 'white',
  boxShadow: 'none',
  ...(open && {
    // width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: 'white',
    boxShadow: 'none',
  }),
}));

export let handleNotification;

export let handleDrawerClose;

const Dashboard = () => {
  const [open, setOpen] = useState(true);
  const [notification, setNotification] = useState('');
  const [seconds, setSeconds] = useState(10);
  const [isRejectConnect, setIsRejectConnect] = useState(false);
  const [openNotificationModel, setOpenNotificationModel] = useState(false);
  const [openAcceptModel, setOpenAcceptModel] = useState(false);
  const [icUserId, setIcUserId] = useState('');
  const notificationAudio = new Audio('/10MinNotification.mp3');
  const [leadUserId, setLeadUserId] = useState('');
  const [leadClaimId, setLeadClaimId] = useState('');
  const [leadClaimAttemptId, setLeadClaimAttemptId] = useState({});
  const [socket] = useSocket();
  notificationAudio.preload = 'auto';
  const [newAchievement, setNewAchievement] = useState(false);
  const [achieverDetail, setAchieverDetails] = useState({
    achieverName: '',
    achievement: '',
    achieverId: 0,
    achieverDetail: '',
    manager: '',
    noOfCap: '',
    noOfTap: '',
  });

  const [achieverDetailList, setachieverDetailList] = useState([]);
  const [focusModeIcData, setFocusModeIcData] = useState({});
  const [fullBucketIcData, setFullBucketIcData] = useState({});
  const [offlineIcData, setOfflineIcData] = useState({});
  const [showNoICAlert, setShowNoICAlert] = useState('');

  let intervalId;
  // handleNotification function triggering the 10 min connect notification

  // handleNotification = (body, leadClaimedId) => {
  //   socket.emit('10MinConnectTrigger', body.lead_id, body, leadClaimedId);
  // };

  const handelNotificationCounter = () => {
    setSeconds(0);
  };

  const handleAccept = () => {
    // socket.emit('10MinConnectAcceptLead');

    // after accepting lead, assigning lead to perticuler IC
    const body = {
      assignee: getCurrentAdminId(),
      status: 'assigned',
      action_by: '10MinutConnect',
    };
    axios
      .put(`${base_url()}/api/users/${leadUserId}`, body)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    // changing the status=accept in lead_claim_attempts table after accepting
    const leadAttepmtBody = {
      status: 'accepted',
      lead_claim_id: leadClaimId,
    };
    axios
      .patch(
        `${base_url()}/api/lead_claim_attempts/${leadClaimAttemptId}`,
        leadAttepmtBody
      )
      .then((res) => {
        console.log(res);
        socket.emit('10MinConnectAcceptLead');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReject = () => {
    // changing the status=reject in quick_lead_claims table after rejecting
    const leadAttepmtBody = {
      status: 'rejected',
      lead_claim_id: leadClaimId,
    };
    axios
      .patch(
        `${base_url()}/api/lead_claim_attempts/${leadClaimAttemptId}`,
        leadAttepmtBody
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenNotificationModel(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [openNotificationModel]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNoICAlert('');
    }, 5000);

    return () => clearTimeout(timer);
  }, [showNoICAlert]);

  // useEffect(() => {
  //   if (socket && socket.on) {
  //     socket.on('notification', (data) => {
  //       const [
  //         message,
  //         userId,
  //         leadId,
  //         quickLeadbody,
  //         newLeadClaimAttepts,
  //         leadClaimedId,
  //       ] = data;
  //       setLeadUserId(leadId);
  //       setLeadClaimAttemptId(newLeadClaimAttepts.id);
  //       setIcUserId(userId);
  //       setSeconds(10);
  //       setLeadClaimId(leadClaimedId);
  //       setNotification(message);
  //       setIsRejectConnect(false);
  //       if (userId === getCurrentAdminId()) {
  //         notificationAudio.play();
  //       }
  //     });

  //     socket.on('NoICOnlineNotifyToAdmin', (data) => {
  //       console.log('NoICOnlineNotifyToAdmin', data);
  //       setShowNoICAlert(data);
  //     });

  //     socket.on('achievement', (data) => {
  //       setNewAchievement(true);
  //       setAchieverDetails({
  //         achieverName: data.name,
  //         achievement: data.status,
  //         achieverId: data.userId,
  //         achieverDetail: data.achieverDetail,
  //         manager: data.manager,
  //         noOfCap: data.noOfCap,
  //         noOfTap: data.noOfTap,
  //       });

  //       const new_array = [...achieverDetailList]; // Assuming achieverDetailList is an array
  //       new_array.push({
  //         achieverName: data.name,
  //         achievement: data.status,
  //         achieverId: data.userId,
  //         achieverDetail: data.achieverDetail,
  //         manager: data.manager,
  //         noOfCap: data.noOfCap,
  //         noOfTap: data.noOfTap,
  //       });

  //       setachieverDetailList(new_array);
  //     });

  //     socket.on('missed10MinConnectLead', (data) => {
  //       const [focusModeICsNotification, fullBucketICsNotification] = data;
  //       setFocusModeIcData(focusModeICsNotification);
  //       setFullBucketIcData(fullBucketICsNotification);
  //     });

  //     socket.on('offlinemissed10MinConnectLead', (data) => {
  //       const [offlineICsNotification] = data;
  //       setOfflineIcData(offlineICsNotification);
  //     });
  //     return () => {
  //       socket.off('achievement');
  //       socket.off('missed10MinConnectLead');
  //       socket.off('offlinemissed10MinConnectLead');
  //     };
  //   }
  // }, [socket]);

  const location = useLocation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const theme = createTheme({
    breakpoints: {
      values: {
        xxs: 0, // small phone
        xs: 300, // phone
        sm: 600, // tablets
        md: 900, // small laptop
        lg: 1200, // desktop
        xl: 1536, // large screens
      },
    },
  });

  const shouldDisplayAchievement = () => {
    return (
      achieverDetail?.achieverName &&
      achieverDetail?.achievement &&
      newAchievement &&
      achieverDetail?.achieverId !== getCurrentAdminId()
    );
  };

  const shouldShowMissedNotifications = (state) => {
    if (state?.ids?.length === 0) return false;
    return state?.ids?.includes(getCurrentAdminId());
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AdminsComponent>
        <CurrentLeadComponent>
          <AppBar
            position='fixed'
            open={open}
          >
            <Toolbar
              theme={theme}
              sx={{
                ml: {
                  md: '0px',
                },
              }}
            >
              <Grid
                container
                display='flex'
                justifyContent='flex-end'
              >
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: open ? 'none' : 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: { xs: 'flex-start' },
                      gap: '30px',
                    }}
                  >
                    <Stack
                      direction='row'
                      sx={{
                        display: { xs: 'none', sm: 'inline-block' },
                      }}
                    >
                      <Logo
                        width={200}
                        padding={5}
                      />
                    </Stack>
                    <IconButton
                      aria-label='open drawer'
                      onClick={handleDrawerOpen}
                      edge='start'
                    >
                      <MenuIcon
                        style={{
                          fontSize: '36px',
                          fontWeight: 'bolder',
                          color: 'black',
                        }}
                      />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={10}
                >
                  <div>
                    <Navbar />
                  </div>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {notification && icUserId === getCurrentAdminId() && (
            <Notification10MinConnectModel
              notification={notification}
              socket={socket}
              intervalId={intervalId}
              setOpenAcceptModel={setOpenAcceptModel}
              setOpenNotificationModel={setOpenNotificationModel}
              setNotification={setNotification}
              handleAccept={handleAccept}
              handleReject={handleReject}
              setIsRejectConnect={setIsRejectConnect}
              handelNotificationCounter={handelNotificationCounter}
            />
          )}

          {shouldShowMissedNotifications(focusModeIcData) &&
            currentAdminIsIC() && (
              <Missed10MinConnectNotifications data={focusModeIcData} />
            )}
          {shouldShowMissedNotifications(fullBucketIcData) &&
            currentAdminIsIC() && (
              <Missed10MinConnectNotifications data={fullBucketIcData} />
            )}
          {currentAdminIsIC() &&
            getCurrentAdminId() === offlineIcData.userId && (
              <Missed10MinConnectNotifications data={offlineIcData} />
            )}
          <Box>
            {shouldDisplayAchievement() && (
              <NotificationTapCapAchievement
                setNewAchievement={setNewAchievement}
                achieverDetailList={achieverDetailList}
              />
            )}
          </Box>

          <Sidebar
            open={open}
            handleDrawerClose={handleDrawerClose}
          />
          <Main
            open={open}
            style={{
              paddingTop: ` ${location.pathname == '/ic-home' ? '0' : '40px'} `,
              overflow: 'auto',
            }}
          >
            <DrawerHeader />
            <Outlet />
          </Main>

          <FocusModeDialog />
        </CurrentLeadComponent>
      </AdminsComponent>
      {openNotificationModel && icUserId === getCurrentAdminId() && (
        <Alert
          severity='error'
          variant='filled'
          sx={{
            zIndex: '30000',
            position: 'fixed',
            left: '10px',
            bottom: '10px',
            backgroundColor: '#ff9800',
          }}
        >
          <AlertTitle>
            {isRejectConnect ? 'Lead Connect Rejected!' : 'Time Over!'}
          </AlertTitle>
          {isRejectConnect
            ? 'Your 10 min lead connect is rejected, better luck next time'
            : 'Your 10 min lead connect acceptance time is over, better luck next time!'}
        </Alert>
      )}
      {showNoICAlert && (
        <Alert
          severity='error'
          variant='filled'
          sx={{
            zIndex: '30000',
            position: 'fixed',
            left: '10px',
            bottom: '10px',
            backgroundColor: '#ff9800',
          }}
        >
          <AlertTitle>{showNoICAlert}</AlertTitle>
        </Alert>
      )}

      {openAcceptModel && (
        <AcceptNotificationModel
          openAcceptModel={openAcceptModel}
          setOpenAcceptModel={setOpenAcceptModel}
          leadUserId={leadUserId}
        />
      )}
    </Box>
  );
};

export default React.memo(Dashboard);
