import React, { useState, useEffect } from 'react';
import { FormControl, IconButton, InputLabel, TextField } from '@mui/material';
import { SwatchesPicker, CompactPicker } from 'react-color';
import CircleIcon from '@mui/icons-material/Circle';
import InputAdornment from '@mui/material/InputAdornment';

export function LabelTextFieldWithColorPicker(props) {
  const showColorPallete = () => {
    if (!props.isColorPalleteVisible) return;
    return (
      <SwatchesPicker
        onChange={props.setLabelColor}
        value={props.labelColor}
      />
    );
  };

  const showFontColorPallete = () => {
    if (!props.isFontColorPalleteVisible) return;
    return (
      <CompactPicker
        onChange={props.setLabelFontColor}
        value={props.labelFontColor}
        colors={[
          '#FFFFFF',
          '#CCCCCC',
          '#B3B3B3',
          '#999999',
          '#808080',
          '#666666',
          '#4D4D4D',
          '#333333',
          '#000000',
        ]}
      />
    );
  };

  return (
    <FormControl fullWidth>
      <IconTextField
        label='Label'
        type='label'
        name='label'
        onChange={props.updateLeadDetails}
        value={props.label}
        iconEnd={
          <>
            <CircleIcon
              sx={{ color: props.labelColor, cursor: 'pointer' }}
              onClick={() => {
                props.setIsColorPalleteVisible(!props.isColorPalleteVisible);
              }}
            />
            <CircleIcon
              sx={{ color: props.labelFontColor, cursor: 'pointer' }}
              onClick={() => {
                props.setIsFontColorPalleteVisible(
                  !props.isFontColorPalleteVisible
                );
              }}
            />
          </>
        }
      />
      {showColorPallete()}
      {showFontColorPallete()}
    </FormControl>
  );
}

const IconTextField = ({ iconStart, iconEnd, InputProps, ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: iconStart ? (
          <InputAdornment position='start'>{iconStart}</InputAdornment>
        ) : null,
        endAdornment: iconEnd ? (
          <InputAdornment position='end'>{iconEnd}</InputAdornment>
        ) : null,
      }}
    />
  );
};
