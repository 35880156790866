import Text from '../Atoms/Text';
import { Box } from '@mui/material';
import CustomButton from '../Atoms/CustomButton';
import LinkedinIcon from '../Atoms/SocialMediaIcons/LinkedinIcon';
import InstagramIcon from '../Atoms/SocialMediaIcons/InstagramIcon';
import SnapchatIcon from '../Atoms/SocialMediaIcons/SnapchatIcon';
import DiscordIcon from '../Atoms/SocialMediaIcons/DiscordIcon';
import TwitterIcon from '../Atoms/SocialMediaIcons/TwitterIcon';
import PencilIcon from '../Atoms/PencilIcon';

const SocialMediaIcon = ({
  linksToEmbed,
  edit,
  openAddSocialMediaModal,
  openAddSocialMediaModalHandler,
  closeAddSocialMediaModalHandler,
}) => {
  const textStyle = {
    fontWeight: 'normal',
    fontSize: '16px',
    fontFamily: 'Arial, sans-serif',
  };
  const text = 'Connect with me:';
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ marginRight: '20px' }}>
            <Text
              textStyle1={textStyle}
              text={text}
            />
          </Box>
          {linksToEmbed?.linkedin && (
            <LinkedinIcon
              link={linksToEmbed?.linkedin}
              style={{ height: '25px', width: '25px', marginRight: '10px' }}
            />
          )}

          {linksToEmbed?.instagram && (
            <InstagramIcon
              link={linksToEmbed?.instagram}
              style={{ height: '25px', width: '25px', marginRight: '10px' }}
            />
          )}
          {linksToEmbed?.snapchat && (
            <SnapchatIcon
              link={linksToEmbed?.snapchat}
              style={{ height: '25px', width: '25px', marginRight: '10px' }}
            />
          )}
          {linksToEmbed?.discord && (
            <DiscordIcon
              link={linksToEmbed?.discord}
              style={{ height: '25px', width: '25px', marginRight: '10px' }}
            />
          )}

          {linksToEmbed?.twitter && (
            <TwitterIcon
              link={linksToEmbed?.twitter}
              style={{
                height: '25px',
                width: '25px',
              }}
            />
          )}

          {edit && (
            <>
              <Box sx={{ marginTop: '15px', marginLeft: '-5px' }}>
                <CustomButton
                  text={
                    <PencilIcon
                      style={{
                        backgroundColor: '#bdbdbd',
                        fontSize: 28,
                      }}
                    />
                  }
                  clickHandler={openAddSocialMediaModalHandler}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SocialMediaIcon;
