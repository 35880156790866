import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import SelectionPanel from './SelectionPanel';
import Recording from './Recording';
import VoiceAssignment from './VoiceAssignment';
import MockCalls from '../trainee/mockcalls/trainee/Mockcalls';
import PdfViewer from './chartFlow';
import Explain from './explain';

const ModuleContainer = ({
  selectedModule,
  videoLink,
  description,
  sendClickDataForTrainee,
  flowChartLink,
  hasAssignment,
  videoTranscript,
  hasModuleQuestionsCompleted,
  isMockcallMandatory,
  setRefreshPage,
  trnModuleAttemptId,
  videoDriveLink,
}) => {
  const [selectedTask, setSelectedTask] = useState('recording');
  const handleSelectedTask = (selectedValue) => {
    setSelectedTask(selectedValue);
  };

  useEffect(() => {
    setSelectedTask('recording');
  }, [selectedModule]);

  return (
    <Box>
      <SelectionPanel
        selectedModule={selectedModule}
        handleSelectedTask={handleSelectedTask}
        selectedTask={selectedTask}
        sendClickDataForTrainee={sendClickDataForTrainee}
        hasAssignment={hasAssignment}
        hasModuleQuestionsCompleted={hasModuleQuestionsCompleted}
        flowChartLink={flowChartLink}
        isMockcallMandatory={isMockcallMandatory}
      />
      {(() => {
        switch (selectedTask) {
          case 'recording':
            return (
              <Recording
                selectedModule={selectedModule}
                videoLink={videoLink}
                sendClickDataForTrainee={sendClickDataForTrainee}
                videoDriveLink={videoDriveLink}
              />
            );
          case 'flow chart':
            return (
              <PdfViewer
                selectedModule={selectedModule}
                sendClickDataForTrainee={sendClickDataForTrainee}
                url={flowChartLink}
              />
            );
          case 'voice assignment':
            return (
              <VoiceAssignment
                selectedModule={selectedModule}
                sendClickDataForTrainee={sendClickDataForTrainee}
              />
            );
          case 'explain':
            return (
              <Explain
                selectedModule={selectedModule}
                sendClickDataForTrainee={sendClickDataForTrainee}
                url={flowChartLink}
                videoTranscript={videoTranscript}
                setRefreshPage={setRefreshPage}
                trnModuleAttemptId={trnModuleAttemptId}
              />
            );
          case 'mockcall':
            return (
              <MockCalls
                selectedModule={selectedModule}
                description={description}
              />
            );
        }
      })()}
    </Box>
  );
};

export default ModuleContainer;
