import { TextField, Typography } from '@mui/material';

export default function CampaignPerformance({
  islocked,
  isApproved,
  isPaid,
  campaignDetails,
  createCampaignDetails,
  textFieldStyle,
}) {
  const styleObjHeader = {
    border: '1px solid #C4C4C4',
    textAlign: 'center',
    padding: '2%',
    backgroundColor: '#dddddd',
  };
  const styleObj = {
    border: '1px solid #C4C4C4',
    textAlign: 'center',
    padding: '2%',
  };
  const textFieldStyleForFullWidth = {
    ...textFieldStyle,
    width: '85%',
    '& .MuiInputBase-root': {
      height: '2.5rem',
    },
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '3%',
        }}
      >
        <Typography style={{ width: '20%', marginTop: '1%' }}>
          No of Clicks:
        </Typography>
        <TextField
          disabled={
            islocked(campaignDetails) ||
            isApproved(campaignDetails) ||
            isPaid(campaignDetails)
          }
          name='total_click'
          type='text'
          value={campaignDetails.total_click}
          onChange={createCampaignDetails}
          id='total_clicks'
          fullWidth
          sx={textFieldStyleForFullWidth}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '3%',
        }}
      >
        <Typography style={{ width: '20%', marginTop: '1%' }}>
          Link of Post:
        </Typography>
        <TextField
          disabled={
            islocked(campaignDetails) ||
            isApproved(campaignDetails) ||
            isPaid(campaignDetails)
          }
          name='post_link'
          type='text'
          value={campaignDetails.post_link}
          onChange={createCampaignDetails}
          id='post_link'
          fullWidth
          required={campaignDetails.utm_source === 'linkedin'}
          sx={textFieldStyleForFullWidth}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '3%',
        }}
      >
        <Typography style={{ width: '20%', marginTop: '1%' }}>
          Lead Details:
        </Typography>

        <table
          style={{
            fontFamily: 'arial sansSerif',
            borderCollapse: 'collapse',
            width: '87%',
            borderRadius: '20px',
          }}
        >
          <tr style={styleObjHeader}>
            <th style={styleObjHeader}></th>
            <th style={styleObjHeader}>Student</th>
            <th style={styleObjHeader}>Graduate</th>
            <th style={styleObjHeader}>Working Proffessional</th>
          </tr>
          <tr style={styleObj}>
            <td style={styleObj}>PQL</td>
            <td style={styleObj}>
              {campaignDetails.leadData &&
                campaignDetails.leadData.pqlStudentLeadsCountForCampaign}
            </td>
            <td style={styleObj}>
              {campaignDetails.leadData &&
                campaignDetails.leadData.pqlGraduatedLeadsCountForCampaign}
            </td>
            <td style={styleObj}>
              {campaignDetails.leadData &&
                campaignDetails.leadData
                  ?.pqlWorkingProfessionalLeadsCountForCampaign}
            </td>
          </tr>
          <tr style={styleObj}>
            <td style={styleObj}>Non PQL</td>
            <td style={styleObj}>
              {campaignDetails.leadData &&
                campaignDetails.leadData.nonPqlStudentLeadsCountForCampaign}
            </td>
            <td style={styleObj}>
              {campaignDetails.leadData &&
                campaignDetails.leadData.nonPqlGraduatedLeadsCountForCampaign}
            </td>
            <td style={styleObj}>
              {campaignDetails.leadData &&
                campaignDetails.leadData
                  .nonPqlWorkingProfessionalLeadsCountForCampaign}
            </td>
          </tr>
          <tr style={styleObj}>
            <td style={styleObj}>Total</td>
            <td style={styleObj}>
              {campaignDetails.leadData &&
                campaignDetails.leadData.nonPqlStudentLeadsCountForCampaign +
                  campaignDetails.leadData.pqlStudentLeadsCountForCampaign}
            </td>
            <td style={styleObj}>
              {campaignDetails.leadData &&
                campaignDetails.leadData.nonPqlGraduatedLeadsCountForCampaign +
                  campaignDetails.leadData.pqlGraduatedLeadsCountForCampaign}
            </td>
            <td style={styleObj}>
              {campaignDetails.leadData &&
                campaignDetails.leadData
                  .nonPqlWorkingProfessionalLeadsCountForCampaign +
                  campaignDetails.leadData
                    .pqlWorkingProfessionalLeadsCountForCampaign}
            </td>
          </tr>
        </table>
      </div>
    </>
  );
}
