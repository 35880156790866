import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PlusIcon from './PlusIcon';
import { Box } from '@mui/material';
import ImagePreview from '../Molecules/ImagePreview';
import CustomDeleteIcon from './CustomDeleteIcon';
import { getCurrentAdminId } from '../../../utils/common';
import axios from 'axios';
import { base_url } from '../../../components/Mode';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
import CustomTooltip from './CustomTooltip';

const CustomImageList = ({
  edit,
  handleImagePreview,
  closeImagePreview,
  admin,
  previewImage,
  imageUrls,
  style,
  setUpdate,
  update,
  onChange,
}) => {
  const ImageUrls = imageUrls ? JSON.parse(imageUrls) : [];
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const defaultStyles = {
    width: '100%',
    height: '200px',
    image: {
      width: '150px',
      height: 'auto',
    },
  };

  const styles = style ? style : defaultStyles;

  const imagesWithPlusIcon = [...ImageUrls, 'plusIcon'];

  const handleDeleteImage = (index) => {
    try {
      const imageURL = JSON.parse(imageUrls);
      const imageURLToDelete = imageURL[index];

      if (!imageURLToDelete) {
        setHasError(true);
        setErrorMessage('Could not remove the image');
      }
      axios
        .delete(
          `${base_url()}/api/admins/${getCurrentAdminId()}/profile-gallery-delete`,
          {
            params: {
              url: imageURLToDelete,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            const updatedImageUrls = JSON.parse(imageUrls).filter(
              (_, i) => i !== index
            );
            setUpdate(!update);
            setIsSuccess(true);
            setSuccessMessage('Image removed successfully');
          }
        })
        .catch((error) => {
          console.error('Error removing image', error);
          setHasError(true);
          setErrorMessage('Could not remove the image');
        });
    } catch (error) {
      console.error('Error parsing image URLs', error);
      setHasError(true);
      setErrorMessage('Could not parse image URLs');
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <div
        style={{
          ...styles,
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {imagesWithPlusIcon?.map((url, index) => {
          if (url === 'plusIcon') {
            return (
              <Box key={index}>
                <input
                  type='file'
                  id='other-image'
                  accept='image/*'
                  onChange={onChange}
                  style={{ display: 'none' }}
                />
                <label htmlFor='other-image'>
                  {admin && (
                    <PlusIcon
                      style={{
                        fontSize: 48,
                        width: 180,
                        height: 140,
                        color: 'white',
                        backgroundColor: '#bdbdbd',
                        marginTop: '10px',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                </label>
              </Box>
            );
          } else {
            return (
              <>
                <div
                  key={index}
                  style={{ position: 'relative', marginRight: '10px' }}
                >
                  <img
                    key={index}
                    src={url}
                    alt={`Image-${index}`}
                    style={{
                      display: 'inline',
                      marginRight: '10px',
                      ...styles.image,
                      marginTop: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleImagePreview(url)}
                  />
                  {admin && (
                    <CustomTooltip
                      text={'Delete Image'}
                      popperProps={{
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [-50, -180],
                            },
                          },
                        ],
                      }}
                    >
                      <CustomDeleteIcon
                        style={{
                          position: 'absolute',
                          top: '10px',
                          right: '10px',
                          color: '#ef5350',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleDeleteImage(index)}
                      />
                    </CustomTooltip>
                  )}
                </div>
              </>
            );
          }
        })}
      </div>
      <ImagePreview
        imageUrl={previewImage}
        open={!!previewImage}
        onClose={closeImagePreview}
      />
    </>
  );
};

CustomImageList.propTypes = {
  imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  styles: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    image: PropTypes.object,
  }),
};

export default CustomImageList;
