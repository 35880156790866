import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ModuleQuestions from './ModuleQuestions';
import AttendedQuestionResult from './AttendedQuestionResult';

const VoiceAssignment = ({ selectedModule }) => {
  const [selectedQuestionId, setSelectedQuestionId] = useState();
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState();
  const [questionUrl, setQuestionUrl] = useState();
  const [expectedAnswers, setExpectedAnswers] = useState();
  const [attempts, setAttempts] = useState([]);
  const [newStatus, setNewStatus] = useState();

  useEffect(() => {
    setSelectedQuestionId('');
  }, [selectedModule]);

  const handleSelectedQuestion = (
    selectedQuestionId,
    questionIndex,
    questionUrl,
    expectedAnswers
  ) => {
    setSelectedQuestionId(selectedQuestionId);
    setSelectedQuestionIndex(questionIndex);
    setQuestionUrl(questionUrl);
    setExpectedAnswers(expectedAnswers);
  };

  return (
    <Box
      style={{
        display: 'flex',
        height: '75vh',
        borderRadius: '7px',
        border: '1px solid',
        borderColor: 'lightgray',
        overflow: 'hidden',
        marginTop: '15px',
        boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.1)',
      }}
    >
      {selectedModule ? (
        <Grid
          container
          spacing={0}
          style={{ height: '100%' }}
        >
          <Grid
            item
            xs={4}
            sm={4}
            style={{
              borderRight: '2px solid #ccc',
              mb: 2,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflow: 'hidden',
              overflowY: 'auto',
              paddingLeft: '0px',
            }}
          >
            <Paper
              style={{
                height: '100%',
                background: '000000',
              }}
            >
              <ModuleQuestions
                id={selectedModule}
                handleSelectedQuestion={handleSelectedQuestion}
                attempts={attempts}
                newStatus={newStatus}
              />
            </Paper>
          </Grid>

          <Grid
            item
            xs={8}
            sm={8}
            style={{}}
          >
            <Paper style={{ height: '100%' }}>
              <AttendedQuestionResult
                selectedQuestionId={selectedQuestionId}
                selectedQuestionIndex={selectedQuestionIndex}
                selectedModule={selectedModule}
                questionUrl={questionUrl}
                expectedAnswers={expectedAnswers}
                attempts={attempts}
                setAttempts={setAttempts}
                setNewStatus={setNewStatus}
              />
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Box
          style={{
            display: 'flex',
            marginTop: '20vh',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Typography style={{ color: 'gray' }}>
            Select any module you would like to attend.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default VoiceAssignment;
