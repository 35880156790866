import Box from '@mui/material/Box';

const CirclesComponent = ({ applyShadow, newCirclePosition }) => {
  const outerCircleSize = 1380;
  const innerCircleSizes = newCirclePosition
    ? [1200, 1180, 1160, 1140, 1120, 860, 780, 700, 620, 540, 460, 400]
    : [1260, 1180, 1100, 1020, 940, 860, 780, 700, 620, 540, 460, 400];

  const createCircle = (size, index) => (
    <Box
      key={index}
      className='circleInsideDataBox'
      sx={{
        borderRadius: '50%',
        border: newCirclePosition
          ? '1px solid #1e1d1d3e'
          : '1.28px solid #7a77773f',
        backgroundColor: index === 11 && applyShadow ? '' : 'transparent',
        boxSizing: 'border-box',
        position: 'absolute',
        top: '135%',
        left: newCirclePosition ? `calc(50% + ${index * 50}px)` : '50%',
        transform: 'translate(-50%, -50%)',
        width: `${size}px`,
        height: `${size}px`,
        transition: 'left 0.5s ease-in-out',
      }}
    />
  );

  const outerBoxStyles = {
    position: 'relative',
    width: `${outerCircleSize}px`,
    height: `${outerCircleSize}px`,
    backgroundColor: 'transparent',
    left: newCirclePosition ? '60%' : '',
    top: newCirclePosition ? '-0%' : '',
    transform: newCirclePosition ? 'translate(0%, -50%)' : '',
    transition: 'left 3s cubic-bezier(0.68, -0.55, 0.27, 1.55)',
  };
  const leftCirclesBox = {
    position: 'relative',
    width: `${outerCircleSize}px`,
    height: `${outerCircleSize}px`,
    backgroundColor: 'transparent',
    right: newCirclePosition ? '60%' : '',
    top: newCirclePosition ? '0%' : '',
    transform: newCirclePosition
      ? 'rotate(180deg) translate(0%, -50%)'
      : 'translate(0%, -50%)',
    transition: 'left 3s cubic-bezier(0.68, -0.55, 0.27, 1.55)',
  };

  const lighterBox = {
    position: 'absolute',
    height: '500px',
    width: '500px',
    backgroundColor: 'rgba(159, 107, 245, 0.5)',
    filter: 'blur(200px)',
    boxSizing: 'border-box',
    top: '135%',
    left: '50%',
    transform: 'translate(-50%, -90%)',
    boxShadow: applyShadow
      ? '0px 0px 92.04px 0px rgba(159, 107, 245, 0.5), 0px 0px 184.08px 0px rgba(159, 107, 245, 0.50), 0px 0px 644.28px 0px rgba(159, 107, 245, 0.50), 0px 0px 1288.56px 0px rgba(159, 107, 245, 0.50), 0px 0px 2208.96px 0px rgba(159, 107, 245, 0.50), 0px 0px 3865.68px 0px rgba(159, 107, 245, 0.50)'
      : 'none',
    transition: 'box-shadow 0.5s ease-in-out',
  };

  return (
    <>
      <Box
        id='outer-box'
        sx={outerBoxStyles}
      >
        {innerCircleSizes.map((size, index) => createCircle(size, index))}
        <Box sx={lighterBox}></Box>
      </Box>
      {newCirclePosition && (
        <Box
          id='left-box'
          sx={leftCirclesBox}
        >
          {innerCircleSizes.map((size, index) => createCircle(size, index))}
          <Box sx={lighterBox}></Box>
        </Box>
      )}
    </>
  );
};

export default CirclesComponent;
