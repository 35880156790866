import React from 'react';
import { Stack, Typography, Paper, IconButton, Button } from '@mui/material';
import Box from '@mui/material/Box';
import { background, border } from '@chakra-ui/react';
import Dialog from '@mui/material/Dialog';
import Timer from './Timer';

const MockcallRequestPopup = ({
  isMockCallPopupOpen,
  handleMockcallRequest,
  setIsAccepted,
  setIsMockCallPopupOpen,
  traineeDetails,
  setClick,
}) => {
  return (
    <Dialog open={isMockCallPopupOpen}>
      <Box p={3}>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant='h5'>Mockcall Request</Typography>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant='body1'>
            Trainee: {traineeDetails?.traineeDetails?.traineeName}
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography
            variant='body1'
            style={{ fontSize: '16px' }}
          >
            A new mockcall request has been raised.
          </Typography>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
            pt={2}
            ml={5}
          >
            <Timer closeModal={() => setIsMockCallPopupOpen(false)} />
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            alignItems='end'
            mr={2}
          >
            <Box>
              <Button
                style={{ backgroundColor: 'green', width: '150px' }}
                variant='contained'
                onClick={() => {
                  handleMockcallRequest('accept');
                  setIsAccepted(true);
                  setIsMockCallPopupOpen(false);
                  setClick(true);
                }}
              >
                Accept
              </Button>
            </Box>
            <Box mt={2}>
              <Button
                style={{ backgroundColor: 'red', width: '150px' }}
                variant='contained'
                onClick={() => {
                  handleMockcallRequest('reject');
                  setIsAccepted(false);
                  setIsMockCallPopupOpen(false);
                }}
              >
                Reject
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MockcallRequestPopup;
