import HotIcon from '../images/icons8-hot.gif';

export function HotButton({ style = {} }) {
  const keyframes = `
      @keyframes slide {
        0% { left: -100%; }
        50% { left: 100%; }
        100% { left: -100%; }
      }
    `;

  const containerStyle = {
    position: 'relative',
    display: 'inline-block',
    padding: '5px 20px',
    background:
      'linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(255, 0, 0, 1) 90%, rgba(251, 73, 38, 1) 100%)',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '3px',
    overflow: 'hidden',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1em',
    verticalAlign: 'middle',
    marginLeft: '0.5rem',
    fontSize: '12px',
    height: 'calc(100% - 10px)',
  };

  const overlayStyle = {
    position: 'absolute',
    top: '-20%',
    left: '-100%',
    width: '50%',
    height: '140%',
    background:
      'linear-gradient(90deg, rgba(255, 0, 0, 0) 0%, rgba(247, 156, 82, 1) 50%, rgba(251, 73, 38, 0) 100%)',
    zIndex: 1,
    transition: 'left 0.5s',
    transform: 'rotate(15deg)',
    animation: 'slide 3s infinite',
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '30px',
      }}
    >
      <img
        src={HotIcon}
        display={'inline-block'}
        style={{
          position: 'relative',
          mixBlendMode: 'darken',
          width: 'auto',
          height: '100%',
        }}
        alt='Hot Lead'
      />
      <div style={{ ...containerStyle, ...style }}>
        <style>{keyframes}</style>
        HOT
        <div style={overlayStyle}></div>
      </div>
    </div>
  );
}
