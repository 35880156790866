const constants = {
  INDEPENDENT_CONTRIBUTOR: 'independent_contributor',
  MANAGER: 'lead',
  SENIOR_MANAGER: 'senior_manager',
  ADMIN: 'admin',
  PROGRAM_MANAGER: 'program_manager',
  MARKETING: 'marketing',
  FINANCE: 'finance',
  HR: 'hr',
  TA: 'talent_acquisition',
  INTERVIEWER: 'interviewer',
  TRAINEE: 'trainee',
  TRAINER: 'trainer',
  FULL: 'full',
  RESTRICTED: 'restricted',
  BASIC: 'basic',
  ACCOUNTANT: 'accountant',
  SENIOR_MARKETING_MANAGER: 'senior_marketing_manager',
  ALLOWED: 'allowed',
  DISABLED: 'disabled',
  SALES1_HOST_NAME: 'sales1.heycoach.in',
  SALES_HOST_NAME: 'sales.heycoach.in',
  SALES2_HOST_NAME: 'sales2.heycoach.in',
  SALES3_HOST_NAME: 'sales3.heycoach.in',

  NEW_INTERACTION: 'interaction_update',

  CREATED: 'created',
  ASSIGNED: 'assigned',
  CONNECT_ATTEMPTED: 'connect_attempted',
  LEAD_QUALIFICATION_DONE: 'lead_qualification_done',
  COMPLETE_PROGRAM_EXPLAINED: 'complete_program_explained',
  ONE_STEP_TO_PAYMENT: 'one_step_to_payment',
  TOKEN_AMOUNT_PAID: 'token_amount_paid',
  COMPLETE_AMOUNT_PAID: 'complete_amount_paid',
  REACTIVATED: 'reactivated',
  DROPPED: 'dropped',
  DROPPED_POST_SALES_REFUND_INITIATED: 'dropped_post_sales_refund_initiated',
  DROPPED_POST_SALES_REFUND_DENIED: 'dropped_post_sales_refund_denied',
  PAYMENT_LINK_SENT: 'payment_link_sent',
  NEED_GENERATION_DONE: 'need_generation_done',
  DREAM_SELLING_DONE: 'dream_selling_done',
  QUESTIONING_IN_TECH_DONE: 'questioning_in_tech_done',
  ELIGIBILITY_FORM_SENT: 'eligibility_form_sent',
  ELIGIBILITY_FORM_FILLED: 'eligibility_form_filled',
  INTENT_CLOSURE_CALL_DONE: 'intent_closure_call_done',
  SCREENING_CALL_SCHEDULED: 'screening_call_scheduled',
  SCREENING_CALL_ATTENDED: 'screening_call_attended',
  OFFER_LETTER_PAYMENT_LINK_SENT: 'offer_letter_payment_link_sent',
  THIRTY_MINUTES: 30,
  WEEKDAY_OFF: 'weekday_off',
  COMPANY_DECLARED_HOLIDAY: 'company_declared_holiday',
  PERSONAL_LEAVE: 'personal_leave',
  PERSONAL_LEAVE_HALF_DAY: 'personal_leave_half_day',

  MY_LEADS_DUE_NOW: 'my_leads_due_now',
  LEADS_NOT_TOUCHED_SINCE_ASSIGNMENT: 'leads_not_touched_since_assignment',
  LEADS_HAVING_SNOOZED_UNTIL_IN_PAST: 'leads_having_snoozed_until_in_past',
  TOTAL_ASSIGNED_LEADS: 'total_assigned_leads',
  TOTAL_INTERACTIONS_IN_PAST_3_HOURS: 'total_interactions_in_past_3_hours',
  FREQUENT_LEADS_DROP: 'frequent_leads_drop',
  SCHEDULED_FOLLOW_UP_IN_10M: 'scheduled_follow_up_in_10m',
  SCHEDULED_FOLLOW_UP_RIGHT_NOW: 'scheduled_follow_up_right_now',
  LEAD_SEARCH_LIMIT_EXCEEDED: 'lead_search_limit_exceeded',
  LEAD_SOURCE_TYPE: 'lead_source_type_update',
  LEAD_CREATED_BY: 'lead_created',
  TRAINING: 'training',

  COMPLETED: 'completed',
  CONVERTED: 'converted',
  IN_PROGRESS: 'in_progress',
  FINANCE_HANDOVER_INITIATED: 'finance_handover_initiated',
  FINANCE_HANDOVER_REJECTED: 'finance_handover_rejected',
  PGM_HANDOVER_INITIATED: 'pgm_handover_initiated',
  PGM_HANDOVER_APPROVED: 'pgm_handover_approved',
  PGM_HANDOVER_REJECTED: 'pgm_handover_rejected',

  EXPECTED_UNIQUE_LEADS: 60,
  EXPECTED_CPE: 4,
  EXPECTED_AAP: 1,
  EXPECTED_OLS: 0,
  EXPECTED_TAP: 0,
  EXPECTED_CAP: 0,

  MISSED_LEAD_NOTIFICATION: 'Oops! A lead slipped by.',

  POOL_LEAD_STATUS_CREATED: 'created',
  POOL_LEAD_STATUS_POOLED: 'pooled',
  POOL_LEAD_STATUS_ASSIGNED: 'assigned',

  // Scheduling automation constants

  HEYCOACH_TEAM_EMAIL_ID: 'team@heycoach.in',
  ENTER: 'Enter',
  DID_NOT_PICK: 'dnp',
  REQUESTED_CALLBACK: 'rcb',
  EF_NOT_SENT: 'ef_not_sent',
  BUCKET_SEARCH_RESTRICTED_USERS: [
    276, 386, 678, 681, 758, 769, 829, 866, 896, 870, 890, 892, 905, 913,
  ],
};

export default constants;
