import React, { useEffect } from 'react';
import Badge from '@mui/material/Badge';
import { useDispatch, useSelector } from 'react-redux';
import { getLeadDropRequest } from '../features/leadDropManagerAction/leadDropManagerActionThunk';

const NotificationDots = ({ count }) => {
  const { data } = useSelector((state) => state.leadDropRequestData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLeadDropRequest());
  }, [dispatch]);
  return (
    <Badge
      color='error'
      badgeContent={data.length >= 20 ? '9+' : 0}
      max={9}
      invisible={data.length < 20}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      style={{ position: 'absolute', top: 16, right: 20 }}
    />
  );
};

export default NotificationDots;
