import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { base_url } from '../../../components/Mode';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
import AdminsAutocompleteDropdown from '../../../components/admins/AdminAutoComplete';

export default function LeadAccessPoliciesTable({
  allLeadAccessPolicies,
  setAllLeadAccessPolicies,
  setErrorMessage,
  setHasError,
}) {
  const [perPage, setPerPage] = useState(50);
  const [lapBeingUpdated, setLAPBeingUpdated] = useState({});
  const [lapIdToIndexMap, setLAPIdToIndexMap] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const tmpMap = {};
    allLeadAccessPolicies.forEach((policy, index) => {
      tmpMap[policy.id] = index;
    });
    setLAPIdToIndexMap(tmpMap);
    return () => {};
  }, [allLeadAccessPolicies]);

  const columns = [
    {
      field: 'id',
      headerName: 'S.No',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      type: 'number',
    },
    {
      field: 'lead_owner_name',
      headerName: 'Reportee',
      width: 300,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      renderCell: (params) => {
        if (params.row.lead_owner_name) {
          return (
            <Typography variant='body2'>
              {params.row.lead_owner_name}
            </Typography>
          );
        }

        return <span style={{ color: '#cecece' }}>Unavailable</span>;
      },
      renderEditCell: (params) => {
        return (
          <AdminsAutocompleteDropdown
            label='Lead owner name'
            value={params.row.lead_owner_id}
            onChangeHandler={(e, newValue) => {
              setLAPBeingUpdated((prev) => {
                return {
                  ...prev,
                  lead_owner_id: newValue.value,
                };
              });
            }}
            sx={{
              width: '100%',
              padding: '1rem',
            }}
          />
        );
      },
    },
    {
      field: 'lead_accessor_name',
      headerName: 'Manager',
      width: 300,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      renderCell: (params) => {
        if (params.row.lead_accessor_name) {
          return (
            <Typography variant='body2'>
              {params.row.lead_accessor_name}
            </Typography>
          );
        }

        return <span style={{ color: '#cecece' }}>Unavailable</span>;
      },
      renderEditCell: (params) => {
        return (
          <AdminsAutocompleteDropdown
            label='Lead accessor name'
            value={params.row.lead_accessor_id}
            onChangeHandler={(e, newValue) => {
              setLAPBeingUpdated((prev) => {
                return {
                  ...prev,
                  lead_accessor_id: newValue.value,
                };
              });
            }}
            sx={{
              width: '100%',
              padding: '2rem',
            }}
          />
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = lapBeingUpdated[id]?.mode === 'edit';
        if (isInEditMode) {
          return [
            <CheckIcon
              fontSize='medium'
              color='success'
              sx={{ cursor: 'pointer' }}
              onClick={(e) => handleLAPUpdate('updated', id)}
            />,
            <ClearIcon
              fontSize='medium'
              color='error'
              onClick={(e) => handleLAPUpdate('clear', id)}
              sx={{ cursor: 'pointer' }}
            />,
          ];
        }
        return [
          <EditIcon
            onClick={(e) => {
              setLAPBeingUpdated({
                ...lapBeingUpdated,
                [id]: {
                  mode: 'edit',
                },
              });
            }}
            sx={{ cursor: 'pointer' }}
          />,
          <DeleteIcon
            onClick={(e) => handleDeleteLAP(id)}
            color='error'
            sx={{ cursor: 'pointer' }}
          />,
        ];
      },
    },
  ];

  const handleLAPUpdate = (clickType, id) => {
    if (clickType === 'clear') {
      setLAPBeingUpdated({
        ...lapBeingUpdated,
        [id]: {
          mode: 'view',
          ignoreModifications: true,
        },
      });
      return;
    }

    setLAPBeingUpdated({
      ...lapBeingUpdated,
      [id]: {
        mode: 'view',
      },
    });
  };

  const processPolicyUpdate = (updatedPolicy) => {
    const body = {};

    if (lapBeingUpdated.lead_accessor_id) {
      body.new_lead_accessor_id = lapBeingUpdated.lead_accessor_id;
    }
    if (lapBeingUpdated.lead_owner_id) {
      body.new_lead_owner_id = lapBeingUpdated.lead_owner_id;
    }

    axios
      .patch(`${base_url()}/api/lead-access-policies/${updatedPolicy.id}`, body)
      .then((res) => {
        const index = lapIdToIndexMap[updatedPolicy.id];
        const tmp = [...allLeadAccessPolicies];
        tmp[index] = res.data;
        setAllLeadAccessPolicies(tmp);
        updatedPolicy = {
          ...res.data,
        };
        setIsSuccess(true);
        setSuccessMessage('Access policy updated');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't update the policy"
        );
      });

    return updatedPolicy;
  };

  const handleDeleteLAP = (id) => {
    axios
      .delete(`${base_url()}/api/lead-access-policies/${id}`)
      .then(() => {
        setAllLeadAccessPolicies((prev) => {
          return prev.filter((policy) => policy.id != id);
        });
        setIsSuccess(true);
        setSuccessMessage('Successfully deleted the access policy.');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't delete the policy"
        );
      });
  };

  return (
    <Box
      width='70vw'
      height='100%'
      sx={{ mt: 5 }}
    >
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <DataGrid
        rows={allLeadAccessPolicies}
        getRowId={(row) => row.id}
        columns={columns}
        pageSize={perPage}
        rowsPerPageOptions={[50, 100]} // 100 is the max page size in non-pro version
        onPageSizeChange={(page) => setPerPage(page)}
        pagination
        autoHeight
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#e3f2fd',
          },
          '& .MuiDataGrid-cell': {
            paddingLeft: '25px',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            paddingLeft: '20px',
          },
        }}
        editMode='row'
        rowModesModel={lapBeingUpdated}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processPolicyUpdate}
        onRowEditStart={(params, event) => {
          event.defaultMuiPrevented = true;
        }}
        onRowEditStop={(params, event) => {
          event.defaultMuiPrevented = true;
        }}
      />
    </Box>
  );
}
