import TwitterLogo from '../../../../images/devicon_twitter.svg';

const TwitterIcon = ({ style, link }) => {
  return (
    <>
      <a
        href={link}
        target='_blank'
        rel='noopener noreferrer'
      >
        <img
          style={style}
          name={'icons'}
          alt={'icons'}
          src={TwitterLogo}
        />
      </a>
    </>
  );
};
export default TwitterIcon;
