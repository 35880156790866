/* eslint-disable no-unused-vars */
import { Box, TextField, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import Text from '../../pages/Profile/Atoms/Text';
import CustomTextField from '../../pages/Profile/Atoms/CustomTextFiled';

const BioComp = ({ edit, bioData, SetBio }) => {
  const [data, setData] = useState({
    about: bioData?.description,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({ [name]: value });
    SetBio(data);
  };

  return (
    <Box
      p={2}
      sx={{
        maxWidth: '100%',
        marginTop: '-50px',
        marginLeft: '10px',
      }}
    >
      <Text
        textStyle1={{
          fontWeight: 'bold',
          fontSize: '16px',
          color: '#2196f3',
        }}
        text={' ABOUT ME'}
      />
      {!edit ? (
        <>
          <Box sx={{ marginLeft: '-25px' }}>
            <Text
              textStyle1={{
                fontWeight: 'bold',
                fontSize: '16px',
                margin: '10px 0px 7px 25px',
              }}
              text={data?.about ? data?.about : ''}
            />
          </Box>
        </>
      ) : (
        <>
          <Box>
            <CustomTextField
              changeHandler={handleInputChange}
              value={data?.about}
              customAttributes={{
                name: 'about',
                type: 'text',
                id: 'about',
                label: 'About',
                placeholder: 'About me',
                variant: 'standard',
              }}
              customStyle={{
                color: 'blue',
                width: '100%',
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default BioComp;
