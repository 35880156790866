import React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import { Text, Center } from '@chakra-ui/react';
import { getFormattedTimestampWithoutYear } from '../../utils/common';
import Interactions from '../../pages/hrms/Interactions';
import { getCandidateStatusLable } from '../../utils/hrms/common';

export default function HrmsCandidatesDataTable({ candidateData }) {
  const [open, setOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});

  const handleClose = () => {
    setOpen(false);
  };

  const handleRowClick = (params) => {
    setSelectedRowData(params.row);
    setOpen(true);
  };
  const renderLabel = (row) => {
    if (!row.label) {
      return;
    }
    if (!row.label_bg_color && !row.label_font_color) {
      return (
        <Chip
          label={row.label}
          sx={{ backgroundColor: '#3f51b5', color: '#fff', margin: 'auto' }}
        />
      );
    }
    return (
      <Chip
        label={row.label}
        sx={{
          backgroundColor: row.label_bg_color,
          color: row.label_font_color,
          margin: 'auto',
        }}
      />
    );
  };

  const renderAssignee = (row) => {
    if (!row.assignee) {
      return;
    }
    return (
      <Chip
        label={row.assignee}
        sx={{ backgroundColor: '#D1C4E9', fontWeight: 500, margin: 'auto' }}
      />
    );
  };

  const getSnoozedText = (e) => {
    if (!e.value) {
      return;
    }
    return (
      <Center
        display={'inline-block'}
        margin={'auto'}
      >
        <Text
          fontSize='sm'
          display='block'
          m={1}
          background={'#FFF9C4'}
          fontWeight={500}
          p={'2px 4px'}
        >
          {getFormattedTimestampWithoutYear(new Date(e.value))}
        </Text>
      </Center>
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      renderCell: (params) => (
        <div
          style={{ margin: 'auto' }}
        >{`#${params.row.id} ${params.row.name}`}</div>
      ),
      width: 220,
      reorder: true,
      center: true,
      headerAlign: 'center',
    },
    {
      field: 'label',
      headerName: 'Label',
      renderCell: (params) => renderLabel(params.row),
      width: 190,
      reorder: true,
      center: true,
      headerAlign: 'center',
    },
    {
      field: 'assignee',
      headerName: 'Assignee',
      renderCell: (params) => renderAssignee(params.row),
      width: 220,
      reorder: true,
      center: true,
      headerAlign: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (e) => (
        <div style={{ margin: 'auto' }}>{getCandidateStatusLable(e.value)}</div>
      ),
      width: 220,
      reorder: true,
      center: true,
      headerAlign: 'center',
    },
    {
      field: 'mobile1',
      headerName: 'Mobile',
      renderCell: (params) => (
        <div style={{ margin: 'auto' }}>{params.value}</div>
      ),
      width: 190,
      reorder: true,
      center: true,
      headerAlign: 'center',
    },
    {
      field: 'email1',
      headerName: 'Email',
      renderCell: (params) => (
        <div style={{ margin: 'auto' }}>{params.value}</div>
      ),
      width: 190,
      reorder: true,
      center: true,
      headerAlign: 'center',
    },
    {
      field: 'snoozed_until',
      headerName: 'Snoozed Until',
      renderCell: (e) => getSnoozedText(e),
      width: 190,
      reorder: true,
      center: true,
      headerAlign: 'center',
    },
  ];

  const rows = candidateData?.map((e) => {
    return {
      id: e.id,
      name: e.name,
      assignee: e?.admin?.fname
        ? `${e?.admin?.fname} ${e?.admin?.lname} `
        : 'Not Assigned',
      label: e.label,
      label_bg_color: e.label_bg_color,
      label_font_color: e.label_font_color,
      mobile1: e.mobile1,
      email1: e.email1,
      status: e.status,
      snoozed_until: e.snoozed_until,
    };
  });

  return (
    <div>
      {open && (
        <Interactions
          handleClose={handleClose}
          open={open}
          selectedRowData={selectedRowData}
        />
      )}

      <Box sx={{ height: '100vh', width: '100%', marginTop: '30px' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          onCellClick={(params) => handleRowClick(params)}
          sx={{ border: 'none', cursor: 'pointer' }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </Box>
    </div>
  );
}
