import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const initialUserState = {
  lead_source_type: 'inbound',
  utm_source: 'none',
  utm_medium: 'none',
  utm_campaign: '',
};

const initialFormsErrors = {
  lead_source_type: 'inbound',
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
};

const SignupsModal = ({ open, handleClose, setFormSubmitted }) => {
  const [formValues, setFormValues] = useState(initialUserState);
  const [formErrors, setFormErrors] = useState(initialFormsErrors);
  const [showUtmOther, setShowUtmOther] = useState('');
  const [isFieldEmpty, setIsFieldEmpty] = useState({
    lead_source_type: false,
    utm_source: false,
    utm_medium: false,
    utm_campaign: false,
  });

  const handleChange = (event) => {
    const isUtmSourceOther =
      event.target.name === 'utm_source' && event.target.value === 'other';
    if (isUtmSourceOther) {
      setShowUtmOther('other');
    } else if (
      event.target.name === 'utm_source' &&
      ['instagram', 'whatsapp', 'youtube', 'phone_call', 'facebook'].includes(
        event.target.value
      )
    ) {
      setShowUtmOther('');
    }

    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleModalClose = () => {
    handleClose(false);
  };

  const handleSubmit = () => {
    const isError = checkErrors(formValues);
    if (isError) {
      return;
    }
    setFormSubmitted(formValues);
    handleClose(false);
  };

  const checkErrors = (data) => {
    let errors = false;
    Object.keys(data).forEach((key) => {
      if (!data[key]) {
        setIsFieldEmpty((prev) => {
          return { ...prev, [key]: true };
        });
        setFormErrors((prev) => {
          return { ...prev, [key]: 'This field is mandatory' };
        });
        errors = true;
      }
    });
    return errors;
  };

  return (
    <Modal open={open}>
      <Box
        sx={{
          px: 4,
          py: 3,
          backgroundColor: 'white',
          margin: 'auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 450,
          borderRadius: 2,
        }}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            py={1}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant='h6'>Add Source Details</Typography>
              <IconButton onClick={handleModalClose}>
                <ClearIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <FormControl fullWidth>
              <InputLabel id='lead_source_type'>Lead source type</InputLabel>
              <Select
                type='text'
                name='lead_source_type'
                label='lead_source_type'
                labelId='lead_source_type'
                value={formValues?.lead_source_type}
                onChange={handleChange}
                size={'small'}
              >
                <MenuItem value='inbound'>Inbound</MenuItem>
                <MenuItem value='outbound'>Outbound</MenuItem>
              </Select>
            </FormControl>
            <Typography
              color={
                isFieldEmpty.lead_source_type && formErrors.lead_source_type
                  ? 'error'
                  : 'initial'
              }
              variant='body2'
            >
              {isFieldEmpty.lead_source_type && formErrors.lead_source_type}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <FormControl fullWidth>
              <InputLabel id='utm_source'>Utm source</InputLabel>
              <Select
                type='text'
                name='utm_source'
                label='utm_source'
                labelId='utm_source'
                value={showUtmOther || formValues?.utm_source}
                onChange={handleChange}
                size={'small'}
              >
                <MenuItem value=''>None</MenuItem>
                <MenuItem value='instagram'>Instagram</MenuItem>
                <MenuItem value='whatsapp'>Whatsapp</MenuItem>
                <MenuItem value='youtube'>Youtube</MenuItem>
                <MenuItem value='phone_call'>Phone call</MenuItem>
                <MenuItem value='facebook'>Facebook</MenuItem>
                <MenuItem value='other'>Other</MenuItem>
              </Select>
            </FormControl>
            <Typography
              color={
                isFieldEmpty.utm_source && formErrors.utm_source
                  ? 'error'
                  : 'initial'
              }
              variant='body2'
            >
              {isFieldEmpty.utm_source && formErrors.utm_source}
            </Typography>
          </Grid>
          {showUtmOther && (
            <Grid
              item
              xs={12}
            >
              <FormControl fullWidth>
                <TextField
                  type='text'
                  required
                  name='utm_source'
                  label='Utm source'
                  value={formValues?.utm_source}
                  onChange={handleChange}
                  size={'small'}
                />
              </FormControl>
            </Grid>
          )}
          <Grid
            item
            xs={12}
          >
            <FormControl fullWidth>
              <InputLabel id='utm_medium'>Utm Medium</InputLabel>
              <Select
                type='text'
                name='utm_medium'
                label='utm_medium'
                labelId='utm_medium'
                value={formValues?.utm_medium}
                onChange={handleChange}
                size={'small'}
              >
                <MenuItem value=''>None</MenuItem>
                <MenuItem value='social'>Social</MenuItem>
                <MenuItem value='collab'>Collab</MenuItem>
                <MenuItem value='ads'>Ads</MenuItem>
                <MenuItem value='influencer'>Influencer</MenuItem>
                <MenuItem value='other'>Other</MenuItem>
              </Select>
            </FormControl>
            <Typography
              color={
                isFieldEmpty.utm_medium && formErrors.utm_medium
                  ? 'error'
                  : 'initial'
              }
              variant='body2'
            >
              {isFieldEmpty.utm_medium && formErrors.utm_medium}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControl fullWidth>
              <TextField
                type='text'
                name='utm_campaign'
                label='Utm Campaign'
                value={formValues.utm_campaign}
                onChange={handleChange}
                size={'small'}
              />
            </FormControl>
            <Typography
              color={
                isFieldEmpty.utm_campaign && formErrors.utm_campaign
                  ? 'error'
                  : 'initial'
              }
              variant='body2'
            >
              {isFieldEmpty.utm_campaign && formErrors.utm_campaign}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            onClick={handleModalClose}
            sx={{ mr: 1, border: '1px solid lightBlue' }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SignupsModal;
