import React from 'react';
import { useCurrentLead } from '../../CurrentLeadContext';
import LeadSearchBar from '../QuickSearch/LeadSearchBar';

function FocusModeSearchBar({ currentLeadIndex, setLeadList }) {
  const { leadFocusMode } = useCurrentLead();

  const getLeadDetails = (id) => {
    setLeadList((prev) => [
      ...prev.slice(0, currentLeadIndex),
      Number(id),
      ...prev.slice(currentLeadIndex),
    ]);
  };

  return <LeadSearchBar {...{ getLeadDetails, leadFocusMode }} />;
}

export default FocusModeSearchBar;
