import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CallRingingBox from './CallRingingBox';
import DnpConnect from './DnpConnect';
import CirclesComponentTwo from './CirclesComponents';

const LeadCallDialingProfile = () => {
  const scheduledTimeForRinging = 30;
  const [ringingTime, setRingingTime] = useState(0);
  const [isProgressRunning, setIsProgressRunning] = useState(true);
  const [isYesClicked, setIsYesClicked] = useState(false);

  const location = useLocation();
  const currentLead = location.state.currentLeadData;
  useEffect(() => {}, [currentLead]);

  const {
    fname,
    lname,
    branch,
    college,
    company,
    email,
    experience,
    graduation_year,
    mobile,
    role,
    tech_stack,
    past_interaction,
  } = currentLead;
  const fullname = fname + (lname !== null ? ` ${lname}` : '');

  let yearOfExp = 'Fresher';
  if (experience === 'working_professional_0_2_exp') {
    yearOfExp = '0-2 years';
  } else if (experience === 'student' || experience === 'graduated') {
    yearOfExp = 'Fresher';
  } else if (experience === 'working_professional_2_5_exp') {
    yearOfExp = '2-5 years';
  } else if (experience === 'working_professional_5_plus_exp') {
    yearOfExp = '5+ years';
  } else {
    yearOfExp = 'Fresher';
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (ringingTime < scheduledTimeForRinging) {
        setRingingTime((prevSec) => prevSec + 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [ringingTime]);

  return (
    <Grid
      container
      sx={{
        background: '#0B0A0A;',
        overflow: 'hidden',
        height: '100vh',
      }}
    >
      <Grid
        item
        sx={{
          color: '#fff',
          borderRadius: '0px 16px 16px 0px',
          background: 'rgba(35, 34, 34, 0.9)',

          padding: '22px 0 0 18px ',
          overflow: 'hidden',
        }}
        md={3.2}
      >
        <Typography
          sx={{
            color: '#FFF',
            fontFamily: "'Montserrat' 'arial'",
            fontSize: '28px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
            position: 'relative',
          }}
        >
          {fullname}’s Profile
        </Typography>
        {(email || mobile) && (
          <Box>
            <Typography
              sx={{
                color: '#C6AEEC',
                fontFamily: "'Inter','sens'",
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: 'normal',
                mt: '17px',
              }}
            >
              Contact Details
            </Typography>
            <Grid
              sx={{
                borderBottom: '1px solid gray',
                paddingBottom: '16px',
                mt: '9px',
              }}
            >
              {email && (
                <Typography
                  sx={{
                    color: '#9F6BF5',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                  }}
                >
                  Email
                  <span
                    style={{
                      fontSize: '14px',
                      marginLeft: '6.4rem',
                      color: 'white',
                    }}
                  >
                    {email}
                  </span>
                </Typography>
              )}
              {mobile && (
                <Typography
                  sx={{
                    color: '#9F6BF5',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    mt: '5px',
                  }}
                >
                  Mobile
                  <span
                    style={{
                      fontSize: '14px',
                      marginLeft: '5.7rem',
                      color: 'white',
                    }}
                  >
                    {mobile}
                  </span>
                </Typography>
              )}
            </Grid>
          </Box>
        )}
        {(tech_stack || yearOfExp || company || role) && (
          <Box>
            <Typography
              sx={{
                color: '#C6AEEC',
                fontFamily: "'Inter','sens'",
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: 'normal',
                mt: '16px',
              }}
            >
              Professional Details
            </Typography>
            <Grid
              sx={{
                borderBottom: '1px solid gray',
                paddingBottom: '16px',
                mt: '9px',
              }}
            >
              {yearOfExp && (
                <Typography
                  sx={{
                    color: '#9F6BF5',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                  }}
                >
                  Experience
                  <span
                    style={{
                      fontSize: '14px',
                      marginLeft: '3.8rem',
                      color: 'white',
                    }}
                  >
                    {yearOfExp}
                  </span>
                </Typography>
              )}
              {company && (
                <Typography
                  sx={{
                    color: '#9F6BF5',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    mt: '5px',
                  }}
                >
                  Company
                  <span
                    style={{
                      fontSize: '14px',
                      marginLeft: '4.6rem',
                      color: 'white',
                    }}
                  >
                    {company}
                  </span>
                </Typography>
              )}
              {role && (
                <Typography
                  sx={{
                    color: '#9F6BF5',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    mt: '5px',
                  }}
                >
                  Role
                  <span
                    style={{
                      fontSize: '14px',
                      marginLeft: '7rem',
                      color: 'white',
                    }}
                  >
                    {role}
                  </span>
                </Typography>
              )}
              {tech_stack && (
                <Typography
                  sx={{
                    color: '#9F6BF5',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    mt: '5px',
                  }}
                >
                  Tech Stack
                  <span
                    style={{
                      fontSize: '14px',
                      marginLeft: '3.8rem',
                      color: 'white',
                    }}
                  >
                    {tech_stack}
                  </span>
                </Typography>
              )}
            </Grid>
          </Box>
        )}
        {(college || branch || graduation_year) && (
          <Box>
            <Typography
              sx={{
                color: '#C6AEEC',
                fontFamily: "'Inter','sens'",
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: 'normal',
                mt: '16px',
              }}
            >
              Education Details
            </Typography>
            <Grid
              sx={{
                borderBottom: '1px solid gray',
                paddingBottom: '16px',
                mt: '9px',
              }}
            >
              {college && (
                <Typography
                  sx={{
                    color: '#9F6BF5',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                  }}
                >
                  College
                  <span
                    style={{
                      fontSize: '14px',
                      marginLeft: '5.5rem',
                      color: 'white',
                    }}
                  >
                    {college}
                  </span>
                </Typography>
              )}
              {branch && (
                <Typography
                  sx={{
                    color: '#9F6BF5',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    mt: '5px',
                  }}
                >
                  Branch
                  <span
                    style={{
                      fontSize: '14px',
                      marginLeft: '5.8rem',
                      color: 'white',
                    }}
                  >
                    {branch}
                  </span>
                </Typography>
              )}
              {graduation_year && (
                <Typography
                  sx={{
                    color: '#9F6BF5',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    mt: '5px',
                  }}
                >
                  Graduation Year
                  <span
                    style={{
                      fontSize: '14px',
                      marginLeft: '1.4rem',
                      color: 'white',
                    }}
                  >
                    {graduation_year}
                  </span>
                </Typography>
              )}
            </Grid>
          </Box>
        )}

        {past_interaction && (
          <Box>
            <Typography
              sx={{
                color: '#C6AEEC',
                fontFamily: "'Inter','sens'",
                fontSize: '17px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: 'normal',
                mt: '16px',
              }}
            >
              Past Interactions
            </Typography>
            <Grid
              sx={{
                mt: '9px',
              }}
            >
              <Typography
                sx={{
                  color: '#FFF',
                  fontFamily: 'Open Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '160%',
                  letterSpacing: '0.2px',
                }}
              ></Typography>
            </Grid>
          </Box>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        md={8}
        sx={{
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {!isYesClicked && (
            <>
              <Box
                sx={{
                  display: ringingTime === 30 ? 'none' : 'flex',
                }}
              >
                <CallRingingBox
                  ringingTime={ringingTime}
                  SetIsYesClicked={setIsYesClicked}
                />
              </Box>
              <Box
                sx={{
                  display: ringingTime === 30 ? 'flex' : 'none',
                }}
              >
                <DnpConnect
                  currentLead={currentLead}
                  SetIsYesClicked={setIsYesClicked}
                  setRingingTime={setRingingTime}
                />
              </Box>
            </>
          )}
          {isYesClicked && (
            <>
              <Box
                sx={{
                  display: ringingTime === 30 ? 'none' : 'flex',
                }}
              >
                <CallRingingBox
                  ringingTime={ringingTime}
                  SetIsYesClicked={setIsYesClicked}
                />
              </Box>
              <Box
                sx={{
                  display: ringingTime === 30 ? 'flex' : 'none',
                }}
              >
                <DnpConnect
                  currentLead={currentLead}
                  SetIsYesClicked={setIsYesClicked}
                />
              </Box>
            </>
          )}
        </Box>

        <Box
          sx={{
            position: 'absolute',
          }}
        >
          <CirclesComponentTwo />
        </Box>
      </Grid>
    </Grid>
  );
};

export default LeadCallDialingProfile;
