import React, { useEffect, useState } from 'react';
import { useSocket } from '../../../../../socket/socketContext';
import FeedbackPopUp from './FeedbackPopUp';
import SuccessNotifier from '../../../../ToastNotifications/SuccessNotifier';

const ICScreen = () => {
  const [isMockCallPopupOpen, setIsMockCallPopupOpen] = useState(false);
  const [openPopUp, setopenPopUp] = useState(false);
  const [isOpenTraineeDetailsPopUp, setisOpenTraineeDetailsPopUp] =
    useState(false);
  const [isOpenFeedbackPopUp, setIsOpenFeedbackPopUp] = useState(false);
  const [traineeDetails, setTraineeDetails] = useState();
  const [submissionDetails, setSubmissionDetails] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [socket, disconnectSocket] = useSocket();

  useEffect(() => {
    if (socket) {
      socket.on('mockCallAssignmentConfirmed', (traineeDetails) => {
        setIsMockCallPopupOpen(true);
        setopenPopUp(true);
        setTraineeDetails(traineeDetails);
      });
      socket.on('submissionDetailsToIC', (details) => {
        setSubmissionDetails(details);
      });
    }

    return () => {
      if (socket) {
        socket.off('selectedICOffline');
        socket.off('mockCallAssignmentConfirmed');
      }
    };
  }, [socket]);

  return (
    <>
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      {openPopUp && (
        <FeedbackPopUp
          openPopUp={openPopUp}
          setopenPopUp={setopenPopUp}
          traineeDetails={traineeDetails}
          isOpenFeedbackPopUp={isOpenFeedbackPopUp}
          setIsOpenFeedbackPopUp={setIsOpenFeedbackPopUp}
          submissionDetails={submissionDetails}
          setSuccessMessage={setSuccessMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
    </>
  );
};

export default ICScreen;
