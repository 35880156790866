import React, { useEffect, useState } from 'react';
import MockcallRequest from './MockcallRequest';
import Paper from '@mui/material/Paper';
import MockcallDetails from './MockcallDetails';
import MockcallUpload from './MockcallUpload';
import { useSocket } from '../../../../../socket/socketContext';
import {
  getCurrentAdminId,
  getCurrentAdminName,
} from '../../../../../utils/common';
import SuccessNotifier from '../../../../ToastNotifications/SuccessNotifier';
import ErrorNotifier from '../../../../ToastNotifications/ErrorNotifier';

const MockCalls = ({ selectedModule, description }) => {
  const [socket, disconnectSocket] = useSocket();
  const [isAccepted, setIsAccepted] = useState(false);
  const [isMockcallRequested, setIsMockcallRequested] = useState(false);
  const [isMockcallCompleted, setIsMockcallCompleted] = useState(false);
  const [isMockcallSend, setIsMockcallSend] = useState(false);
  const [isICAssigned, setIsICAssigned] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [assignedIC, setAssignedIC] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [submissionDetails, setSubmissionDetails] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isMockcallUploaded, setIsMockcallUploaded] = useState(false);
  const moduleId = selectedModule;
  const traineeId = getCurrentAdminId();
  const traineeName = getCurrentAdminName();
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [requestCancelled, setRequestCancelled] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isManagersOffline, setIsManagersOffline] = useState(false);

  useEffect(() => {
    setOnlineStatus(true);
    if (socket) {
      const handleOnlineUsers = (users) => {
        setOnlineUsers(users);
      };

      socket.on('onlineUsers', handleOnlineUsers);
      if (socket) {
        socket.on('connect', () => {
          console.log('Socket connected:', socket.id);
        });
        socket.on('mockCallAssignmentConfirmedByManager', (ICData) => {
          setIsLoading(false);
          setIsICAssigned(true);
          setAssignedIC(ICData);
        });
        socket.on('submissionDetailsToTrainee', (details) => {
          setSubmissionDetails(details);
        });
        socket.on('requestCancelled', () => {
          setIsLoading(false);
          setRequestCancelled(true);
          setHasError(true);
          setErrorMessage('Your request got cancelled. Please try again!');
        });
        socket.on('managersOffline', () => {
          setHasError(true);
          setErrorMessage('Managers are offline. Please try again!');
          setIsLoading(false);
        });
        socket.on('noManagersAvailable', () => {
          setHasError(true);
          setErrorMessage('Managers are not available. Please try again!');
          setIsLoading(false);
        });
      }

      return () => {
        setOnlineStatus(false);
        socket.off('onlineUsers', handleOnlineUsers);
      };
    }
  }, [socket, isMockcallRequested]);

  const handleMockCallRequest = () => {
    setIsLoading(true);
    socket.emit('requestMockCallToManager', {
      traineeDetails: { moduleId, traineeId, traineeName },
      onlineStatus,
      description,
    });
  };

  return (
    <Paper>
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {!isICAssigned && !isMockcallCompleted && (
        <MockcallRequest
          selectedModule={selectedModule}
          setIsAccepted={setIsAccepted}
          setIsMockcallRequested={setIsMockcallRequested}
          handleMockCallRequest={handleMockCallRequest}
          isLoading={isLoading}
          description={description}
        />
      )}

      {isICAssigned && assignedIC && (
        <MockcallDetails
          setIsMockcallCompleted={setIsMockcallCompleted}
          assignedIC={assignedIC}
          setIsICAssigned={setIsICAssigned}
        />
      )}

      {isMockcallCompleted && assignedIC && (
        <MockcallUpload
          assignedIC={assignedIC}
          submissionDetails={submissionDetails}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
          setIsMockcallCompleted={setIsMockcallCompleted}
          setIsMockcallUploaded={setIsMockcallUploaded}
        />
      )}
    </Paper>
  );
};

export default MockCalls;
