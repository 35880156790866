import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

export default function DisplayLeadsModal({ leadsData }) {
  const columns = [
    {
      name: 'Utm Campaign Type',
      selector: (row) => row?.utm_campaign_type ?? '--',
      maxWidth: '300px',
    },
    {
      name: 'First name',
      selector: (row) => row?.fname ?? '--',
      maxWidth: '200px',
    },
    {
      name: 'Last name',
      selector: (row) => row?.lname ?? '--',
      width: '200px',
      maxWidth: '200px',
    },
    {
      name: 'Mobile',
      selector: (row) => row?.mobile ?? '--',
      width: '200px',
    },
    {
      name: 'Email',
      selector: (row) => row?.email ?? '--',
      width: '200px',
    },
    {
      name: 'Whatsapp',
      selector: (row) => row?.whatsapp ?? '--',
      width: '200px',
    },
    {
      name: 'Lead type source',
      selector: (row) => row?.lead_source_type ?? '--',
      width: '200px',
    },
    {
      name: 'College',
      selector: (row) => row?.college ?? '--',
      width: '200px',
    },
    {
      name: 'Branch',
      selector: (row) => row?.branch ?? '--',
      width: '200px',
    },
    {
      name: 'Graduation year',
      selector: (row) => row?.graduation_year ?? '--',
      width: '200px',
    },
    {
      name: 'Company',
      selector: (row) => row?.company ?? '--',
      width: '200px',
    },
    {
      name: 'Linkedin link',
      selector: (row) => row?.linkedin_link ?? '--',
      width: '200px',
    },
    {
      name: 'Github link',
      selector: (row) => row?.github_link ?? '--',
      width: '200px',
    },
    {
      name: 'Experience',
      selector: (row) => row?.experience ?? '--',
      width: '200px',
    },
    {
      name: 'Role',
      selector: (row) => row?.role ?? '--',
      width: '200px',
    },
    {
      name: 'Current salary',
      selector: (row) => row?.current_salary ?? '--',
      width: '200px',
    },
    {
      name: 'Desired salary',
      selector: (row) => row?.desired_salary ?? '--',
      width: '200px',
    },
    {
      name: 'Current location',
      selector: (row) => row?.current_location ?? '--',
      width: '200px',
    },
    {
      name: 'Total experience',
      selector: (row) => row?.total_experience ?? '--',
      width: '200px',
    },
    {
      name: 'Portfolio link',
      selector: (row) => row?.portfolio_link ?? '--',
      width: '200px',
    },
    {
      name: 'Internship experience',
      selector: (row) => row?.internship_experience ?? '--',
      width: '200px',
    },
    {
      name: 'Utm source',
      selector: (row) => row?.utm_source ?? '--',
      width: '200px',
    },
    {
      name: 'Utm campaign',
      selector: (row) => row?.utm_campaign ?? '--',
      width: '200px',
    },
    {
      name: 'Utm medium',
      selector: (row) => row?.utm_medium ?? '--',
      width: '200px',
    },
    {
      name: 'Utm term',
      selector: (row) => row?.utm_term ?? '--',
      width: '200px',
    },
    {
      name: 'Utm content',
      selector: (row) => row?.utm_content ?? '--',
      width: '200px',
    },
    {
      name: 'Utm region',
      selector: (row) => row?.utm_region ?? '--',
      width: '200px',
    },
    {
      name: 'Utm adset',
      selector: (row) => row?.utm_adset ?? '--',
      width: '200px',
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: 'black',
      },
    },
    cells: {
      style: {
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: 'black',
      },
    },
    pagination: {
      style: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: 'black',
      },
    },
  };

  return (
    <>
      <Box
        sx={{
          marginTop: '20px',
        }}
      >
        {leadsData.length > 0 && (
          <DataTable
            customStyles={customStyles}
            highlightOnHover={true}
            columns={columns}
            data={leadsData}
            dense
          />
        )}
      </Box>
    </>
  );
}
