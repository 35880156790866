import React, { useState, useEffect, useRef } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { base_url } from '../../../components/Mode';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from '@mui/x-data-grid';
import {
  currentAdminIsManagerOrAdmin,
  getDurationText,
  getStatusLabel,
} from '../../../utils/common';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import Tooltip from '@mui/material/Tooltip';
import { ConfirmReportDeletionModal } from './ConfirmReportDeletionModal';
import { InsightsTable } from './InsightsTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAllAdmins } from '../../../components/AdminsContext';

function CallInsightsFromReport({
  filters,
  reportId,
  setReportId,
  setHasError,
  setErrorMessage,
  endpoint,
  setIsSuccess,
  setSuccessMessage,
  setIsFetchingResults,
}) {
  const [tableData, setTableData] = useState([]);
  const [perPage, setPerPage] = useState(100);
  const [sortModel, setSortModel] = useState([{ field: 'id', sort: 'desc' }]);
  const [openDeleteReportConfirmation, setOpenDeleteReportConfirmation] =
    useState(false);
  const [reportDeletionConfirmed, setReportDeletionConfirmed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const audioRef = useRef();
  const { showRecordingColumn } = useAllAdmins();

  useEffect(() => {
    if (filters.admin_id !== -1) {
      fetchReportData();
    }
    return () => {};
  }, [filters]);

  useEffect(() => {
    if (reportDeletionConfirmed) {
      handleReportDeletion();
    }
    return () => {};
  }, [reportDeletionConfirmed]);

  function fetchReportData() {
    setIsFetchingResults(true);
    const params = [];
    Object.entries(filters).forEach(([key, val], index) => {
      if (val && val !== '') {
        params.push(`${key}=${val}`);
      }
    });

    window.history.replaceState(
      null,
      '',
      location.pathname + '?' + params.join('&')
    );

    let url = `${base_url() + endpoint}`;
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }

    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          const { report_entries = [], report_id = -1 } = res.data;
          setTableData(report_entries);
          setReportId(report_id);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch the insights"
        );
      })
      .finally(() => {
        setIsFetchingResults(false);
      });
  }

  const updateCurrentLead = (leadId) => {
    const url = '/leads/' + leadId;
    const newTab = window.open('', '_blank');
    if (newTab) {
      newTab.opener = null;
      newTab.location.href = url;
    } else {
      navigate(url, {
        state: {
          from: location.pathname + window.location.search,
        },
      });
    }
  };

  const columns = [
    {
      field: 'id',
      headerName: '#',
      width: 70,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.id,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 140,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => getLeadIdText(params.row, updateCurrentLead),
    },
    {
      field: 'mobile',
      headerName: 'Mobile',
      width: 110,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        if (params.row.type === 'Incoming') {
          return params.row.from_number;
        } else if (params.row.type === 'Outgoing') {
          return params.row.to_number;
        } else if (params.row.type === 'Missed') {
          return params.row.from_number;
        }
      },
    },
    {
      field: 'start time',
      headerName: 'Start Time',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.calledOn,
    },
    {
      field: 'duration',
      headerName: 'Duration',
      type: 'number',
      minWidth: 180,
      maxWidth: 280,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: ({ value }) => `${getDurationText(value)}`,
      sortComparator: (v1, v2) => v1 - v2,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 50,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => getCallTypeIcon(params.row.type),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 300,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => getStatus(params.row['status']),
    },
    {
      field: 'recording',
      headerName: 'Recording',
      width: 400,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <div>
          {params.row.recording ? (
            <audio
              controls
              ref={audioRef}
              src={params.row.recording}
            >
              Your browser does not support the audio element.
            </audio>
          ) : (
            'No Audio'
          )}
        </div>
      ),
    },
    {
      field: 'last_interaction',
      headerName: 'Last Interaction',
      width: 300,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Typography
          align='center'
          variant='body2'
          sx={{
            padding: '2px',
            margin: 1,
          }}
        >
          {(params.row?.lead_id ?? '') === '' ? (
            <div
              style={{
                background: 'antiquewhite',
                padding: '7px',
                paddingLeft: '5px',
                paddingRight: '5px',
                color: 'chocolate',
                borderRadius: '15px',
                fontSize: '14px',
              }}
            >
              <p>Please add an interaction for this lead.</p>
            </div>
          ) : (
            <div
              style={{
                backgroundColor: '#ebf8ff',
                padding: '1px',
                paddingLeft: '15px',
                paddingRight: '15px',
                borderRadius: '15px',
              }}
            >
              {params.row.last_interaction}
            </div>
          )}
        </Typography>
      ),
    },
  ];

  const handleReportDeletion = () => {
    if (reportId === -1) return;

    axios
      .delete(`${base_url()}/api/reports/${reportId}`)
      .then((res) => {
        setTableData([]);
        setReportId(-1);
        setIsSuccess(true);
        setSuccessMessage('Report successfully deleted');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
    setReportDeletionConfirmed(false);
  };

  return (
    <Stack
      spacing={4}
      sx={{ mt: 3, mr: 1 }}
    >
      {tableData.length > 0 && (
        <Stack
          spacing={4}
          sx={{ width: 1090 }}
        >
          <Stack
            direction='row'
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography
              variant='h4'
              textAlign='left'
            >
              Report #{reportId}
            </Typography>

            {currentAdminIsManagerOrAdmin() && reportId !== -1 && (
              <Button
                variant='contained'
                color='error'
                onClick={(e) => setOpenDeleteReportConfirmation(true)}
                size='small'
              >
                Delete report
                <DeleteIcon />
              </Button>
            )}
          </Stack>
          <Typography
            variant='h4'
            textAlign='left'
          >
            Insights
          </Typography>
          <InsightsTable
            {...{
              date: filters.date,
              admin: filters.admin_id,
              setHasError,
              setErrorMessage,
              setIsSuccess,
              setSuccessMessage,
            }}
          />
          <Typography
            variant='h4'
            textAlign='left'
          >
            Details
          </Typography>
          <DataGrid
            rows={tableData}
            columns={
              showRecordingColumn
                ? columns
                : columns.filter((x) => x.field !== 'recording')
            }
            pageSize={perPage}
            rowsPerPageOptions={[25, 50, 75, 100]} // 100 is the max page size in non-pro version
            onPageSizeChange={(page) => setPerPage(page)}
            pagination
            autoHeight
            disableSelectionOnClick
            getRowHeight={() => 'auto'}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            sx={{
              '& .MuiDataGrid-row:hover': {
                backgroundColor: '#e3f2fd',
              },
              '& .MuiDataGrid-cell': {
                paddingTop: 0.4,
                paddingBottom: 0.4,
              },
            }}
          />
        </Stack>
      )}
      {tableData.length === 0 && (
        <Typography
          variant='body1'
          textAlign='center'
        >
          No call insights found
        </Typography>
      )}
      {openDeleteReportConfirmation && (
        <ConfirmReportDeletionModal
          {...{
            openDeleteReportConfirmation,
            setOpenDeleteReportConfirmation,
            setReportDeletionConfirmed,
          }}
        />
      )}
    </Stack>
  );
}

export default CallInsightsFromReport;

function getLeadIdText(leadData, updateCurrentLead) {
  if (leadData['lead_id']) {
    const nameFields = [leadData.fname];
    if ((leadData?.lname ?? '').length > 0) {
      nameFields.push(leadData.lname);
    }
    nameFields.push(` #${leadData.lead_id}`);
    nameFields.join(' ');
    return (
      <Typography
        variant='body1'
        textAlign='center'
        flexWrap='wrap'
        onClick={(e) => updateCurrentLead(leadData['lead_id'])}
        sx={{
          cursor: 'pointer',
          color: 'blue',
        }}
      >
        {nameFields}
      </Typography>
    );
  }
  return (
    <Tooltip title='Please create a new lead with this mobile number and assign it to yourself.'>
      <Typography
        variant='body1'
        textAlign='center'
        sx={{
          p: '3px',
          color: 'chocolate',
          borderRadius: '15px',
          background: 'antiquewhite',
          padding: '5px',
          fontSize: '14px',
        }}
      >
        Lead not found
      </Typography>
    </Tooltip>
  );
}

function getStatus(status) {
  return status ? getStatusLabel(status, false) : <></>;
}

function getCallTypeIcon(type) {
  if (!type) return <>Type not found</>;
  type = type.toLowerCase();
  if (type === 'outgoing') {
    return (
      <Tooltip title='outgoing'>
        <Typography
          display={'inline-block'}
          color={'blue'}
          px={'5px'}
        >
          <PhoneForwardedIcon />
        </Typography>
      </Tooltip>
    );
  }
  if (type === 'incoming') {
    return (
      <Tooltip title='incoming'>
        <Typography
          display={'inline-block'}
          color={'green'}
          px={'5px'}
        >
          <PhoneCallbackIcon />
        </Typography>
      </Tooltip>
    );
  }
  return (
    <Tooltip title='missed'>
      <Typography
        display={'inline-block'}
        color={'red'}
        px={'5px'}
      >
        <PhoneMissedIcon />
      </Typography>
    </Tooltip>
  );
}
