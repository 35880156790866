import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Typography, IconButton, Stack, Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import axios from 'axios';
import { base_url } from '../../components/Mode';

export default function CampaignsFileUpload({
  setHasError,
  setErrorMessage,
  selectedCampaign,
  setSuccessMessage,
  setIsSuccess,
  islive,
  isRejected,
  isDraft,
  islocked,
  isApproved,
  isPaid,
  setSelectedCampaign,
  setTableData,
  uploadedFiles,
  setUploadedFiles,
  selectedFiles,
  setSelectedFiles,
}) {
  const shouldEnableFileUploadButton =
    islive(selectedCampaign) ||
    isRejected(selectedCampaign) ||
    isDraft(selectedCampaign);

  const shouldShowUploadedImage =
    islocked(selectedCampaign) ||
    isApproved(selectedCampaign) ||
    isPaid(selectedCampaign);

  const isNewCampaign = !(
    islive(selectedCampaign) ||
    isRejected(selectedCampaign) ||
    isDraft(selectedCampaign) ||
    islocked(selectedCampaign) ||
    isApproved(selectedCampaign) ||
    isPaid(selectedCampaign)
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      const MAX_ALLOWED = 5;
      const files = acceptedFiles.map((file) => ({
        name: file.name,
        data: file,
      }));
      let extraFiles = files.length + selectedFiles.length - MAX_ALLOWED;
      if (extraFiles > 0) {
        while (extraFiles > 0) {
          files.pop();
          extraFiles--;
        }
      }
      setSelectedFiles([...selectedFiles, ...files]);
    },
    [selectedFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxFiles: 5,
  });

  const fileUploadHandler = async () => {
    if (selectedFiles.length === 0) {
      return;
    }
    const formBody = new FormData();
    selectedFiles.forEach((file) => {
      formBody.append('campaigns_files', file.data);
    });

    await axios
      .post(
        base_url() +
          '/api/campaigns/' +
          selectedCampaign.id +
          '/files?context=campaigns',
        formBody,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((res) => {
        setUploadedFiles(res.data);
        setSelectedCampaign((prev) => ({
          ...prev,
          campaignFiles: res.data,
        }));

        setTableData((prev) => {
          const index = prev.findIndex((val) => val.id === selectedCampaign.id);
          const tmp = [...prev];
          tmp[index] = {
            ...selectedCampaign,
            campaignFiles: res.data,
          };
          return tmp;
        });

        setIsSuccess(true);
        setSuccessMessage('Files Upload Successfully');
        setSelectedFiles([]);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'File Can Not Be Uploaded'
        );
      });
  };

  const openFile = (data) => {
    const newTab = window.open();
    newTab.document.write(`<img src="${URL.createObjectURL(data)}" />`);
  };

  return (
    <>
      {(shouldEnableFileUploadButton || isNewCampaign) && (
        <>
          <div
            {...getRootProps()}
            style={{
              width: '100%',
              border: '1px dotted black',
              display: 'flex',
              flexDirection: 'column',
              padding: '0.5rem',
              height: '30vh',
              alignItems: 'center',
              borderRadius: '20px',
            }}
          >
            <input
              {...getInputProps()}
              style={{ display: 'none' }}
            />
            <div style={{ marginTop: '5%', alignItems: 'center' }}>
              <DescriptionOutlinedIcon sx={{ marginLeft: '40%' }} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p style={{ fontWeight: 'bold' }}>
                  Drop your files here or{' '}
                  <span style={{ color: 'blue', cursor: 'pointer' }}>
                    browse
                  </span>
                </p>
              )}
              <p style={{ fontSize: '0.7rem', marginLeft: '15%' }}>
                Max 5, Size Less Than 2MB
              </p>
            </div>
          </div>
          <p>
            {selectedFiles.length > 0 && (
              <Stack
                spacing={1}
                mt={2}
              >
                {selectedFiles.map((file, index) => (
                  <Stack
                    key={index}
                    sx={{
                      padding: '1px',
                      border: '1px dashed blue',
                    }}
                    direction='row'
                    justifyContent='space-between'
                    alignContent='center'
                  >
                    <Box
                      display='flex'
                      justifyContent='start'
                      alignItems='start'
                      style={{
                        margin: 'auto',
                        width: '100%',
                      }}
                    >
                      <DescriptionOutlinedIcon />
                      <Typography
                        variant='body2'
                        alignSelf='center'
                        onClick={() => openFile(file.data)}
                      >
                        {file.name}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={() => {
                        setSelectedFiles(
                          selectedFiles.filter((f, i) => i !== index)
                        );
                      }}
                    >
                      <ClearIcon color='error' />
                    </IconButton>
                  </Stack>
                ))}
              </Stack>
            )}
          </p>
        </>
      )}

      <br />
      {shouldEnableFileUploadButton && (
        <Button
          disabled={selectedFiles.length <= 0}
          variant='contained'
          size='small'
          onClick={fileUploadHandler}
          sx={{ marginLeft: '40%', width: '20%' }}
        >
          Upload
        </Button>
      )}
      {uploadedFiles.length > 0 &&
        (shouldEnableFileUploadButton || isNewCampaign) && (
          <Stack
            spacing={2}
            mt={2}
          >
            <h4>Uploaded Files</h4>
            {uploadedFiles.map((file, index) => (
              <Stack
                key={index}
                sx={{
                  textAlign: 'center',
                  border: '1px dashed blue',
                  padding: '1px',
                }}
              >
                <a
                  href={file.url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Typography
                    variant='body2'
                    alignSelf='center'
                  >
                    {file.file_name}
                  </Typography>
                </a>
              </Stack>
            ))}
          </Stack>
        )}
      {shouldShowUploadedImage && (
        <div
          style={{
            width: '100%',
            border: '1px dotted black',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
            padding: '0.5rem',
            minHeight: '30vh',
            alignItems: 'center',
            borderRadius: '20px',
          }}
        >
          {uploadedFiles.length > 0 &&
            uploadedFiles.map((file, index) => (
              <Stack
                key={index}
                sx={{
                  textAlign: 'center',
                  padding: '1px',
                  width: '32%',
                  height: '2%',
                }}
              >
                <a
                  href={file.url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    src={file.url}
                    alt={file.file_name}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                      marginBottom: '5px',
                    }}
                  />
                </a>
              </Stack>
            ))}
          {uploadedFiles.length === 0 && (
            <div>No images has been uploaded.</div>
          )}
        </div>
      )}
    </>
  );
}
