import { Avatar } from '@mui/material';

const CustomImage = ({ onClick, detail, url }) => {
  const commonStyles = {
    width: detail.width,
    height: detail.height,
    objectFit: 'cover',
  };

  return (
    <>
      {detail.type === 'background' && (
        <img
          style={{
            ...commonStyles,
            cursor: 'pointer',
            borderRadius: '25px',
          }}
          name={detail.type}
          alt={detail.type}
          src={url}
          onClick={onClick}
        />
      )}

      {detail.type === 'profile' && (
        <Avatar
          onClick={onClick}
          style={{
            border: '3px solid white',
            cursor: 'pointer',
            ...commonStyles,
          }}
          name={detail.type}
          alt='Remy Sharp'
          src={url}
        />
      )}
    </>
  );
};
export default CustomImage;
