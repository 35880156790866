import React from 'react';
import LeadSearchBar from './LeadSearchBar';

function QuickSearch() {
  const getLeadDetails = (id) => {
    const currentUrl = '/leads/' + id;
    window.open(currentUrl, '_blank');
  };

  return (
    <>
      <LeadSearchBar
        {...{
          getLeadDetails,
        }}
      />
    </>
  );
}

export default QuickSearch;
