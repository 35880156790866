import { useEffect, useRef, useState } from 'react';
import { Stack, Typography, Paper } from '@mui/material';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import SpeechRecognition, {
  useSpeechRecognition,
} from '../../SpeechRecognition';
import ViewRecordedAudio from './ViewRecordedAudio';
import { base_url } from '../../Mode';
import axios from 'axios';
import Box from '@mui/material/Box';

const AnswerRecorder = ({
  selectedQuestionId,
  selectedModule,
  questionText,
  expectedAnswers,
  setAttempts,
  attempts,
  isReAttempt,
  setIsReAttempt,
  setIsSuccess,
  setHasError,
  setSuccessMessage,
  setErrorMessage,
  setNewStatus,
}) => {
  const {
    transcript,
    resetTranscript,
    startListening,
    stopListening,
    listening,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition({
    transcribing: true,
    clearTranscriptOnListen: true,
  });

  const [recordingPaused, setRecordingPaused] = useState(false);
  const [isRecordingStopped, setIsRecordingStopped] = useState(false);
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [audioTranscript, setAudioTranscript] = useState(transcript);
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);

  useEffect(() => {
    setAudioTranscript(transcript);
  }, [transcript]);

  useEffect(() => {
    setAudioBlob(null);
  }, [selectedQuestionId]);

  const listenContinuously = () => {
    SpeechRecognition.startListening({
      continuous: true,
      language: 'en-GB',
    });
  };

  const stopRecording = () => {
    try {
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state === 'recording'
      ) {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
        setRecording(false);
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage(`Error stopping recording: ${error.message}`);
    }
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunks.current.push(event.data);
          }
        };

        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunks.current, {
            type: 'audio/wav',
          });
          setAudioBlob(audioBlob);
        };

        mediaRecorderRef.current.start();
        setRecording(true);
      })
      .catch((error) => {
        setHasError(true);
        setErrorMessage(`Error accessing microphone: ${error.message}`);
      });
  };

  const resetAudioRecording = () => {
    setAudioBlob(null);
    audioChunks.current = [];
  };

  const handleStartRecording = () => {
    resetAudioRecording();
    resetTranscript();
    setRecordingPaused(false);
    setIsRecordingStopped(false);
    listenContinuously();
    startRecording();
  };

  const handleStopRecording = () => {
    setIsRecordingStopped(true);
    SpeechRecognition.stopListening();
    stopRecording();
  };

  const handleRecordAgain = () => {
    resetTranscript();
    setRecordingPaused(false);
    setIsRecordingStopped(false);
    setAudioBlob(null);
  };

  const handleSaveEditedTranscript = (editedTranscript) => {
    setAudioTranscript(editedTranscript);
  };

  const handleSubmit = async () => {
    try {
      if (!audioTranscript || audioTranscript.trim() === '') {
        setHasError(true);
        setErrorMessage('Please speak to record your answer.');
        return;
      }
      setIsSubmit(true);

      let url = base_url() + '/api/module-question-attempts/evaluate';
      const requestData = {
        answer: audioTranscript,
        question: questionText,
        expectedAnswer: [expectedAnswers],
      };
      const response = await axios.post(url, requestData);
      const formData = new FormData();
      formData.append('answer_text', audioTranscript);
      formData.append('status', response.data.is_correct);
      formData.append('audioFile', audioBlob, 'audio.wav');

      const apiUrl =
        base_url() +
        `/api/training-modules/${selectedQuestionId}/question-attempts?context=module-voice`;
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const result = await axios.post(apiUrl, formData, headers);
      setAttempts([...attempts, result.data]);
      setNewStatus(result.data.result);
      setIsSubmit(false);
      setIsSuccess(true);
      setSuccessMessage('Answer Submitted Successfully');
    } catch (error) {
      console.log('error while file handling', error);
      setHasError(true);
      setErrorMessage('An error occurred during file handling.');
    } finally {
      setAudioBlob(null);
      setIsReAttempt(false);
      setIsSubmit(false);
      setAudioTranscript('');
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>No browser support</span>;
  }

  if (!isMicrophoneAvailable) {
    return <span>Please allow access to the microphone</span>;
  }

  return (
    <Stack>
      {audioBlob ? (
        <ViewRecordedAudio
          recordedText={audioTranscript}
          audioBlob={audioBlob}
          onRecordAgain={handleRecordAgain}
          handleSaveEditedTranscript={handleSaveEditedTranscript}
          onSubmit={handleSubmit}
          isSubmit={isSubmit}
        />
      ) : (
        <>
          <Box mt={3}>
            <Typography
              variant='p'
              fontSize={'14px'}
              textAlign={'center'}
              component='div'
              gutterBottom
            >
              Click the button below and start speaking:
            </Typography>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Paper
              elevation={3}
              style={{
                borderRadius: '50%',
                marginTop: '40px',
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'center',
                width: '100px',
                height: '100px',
                padding: '10px',
                borderColor: listening ? '#66AFFF' : '#ffffff',
                borderWidth: '2px',
                borderStyle: 'solid',
                transition: 'border 0.5s ease-in-out',
                transform: listening ? 'scale(1.1)' : 'scale(1)',
              }}
            >
              <button
                color='primary'
                style={{
                  width: '100%',
                  background: 'transparent',
                  border: 'none',
                  borderRadius: '50%',
                }}
                onClick={handleStartRecording}
              >
                <KeyboardVoiceIcon />
              </button>
            </Paper>
          </Box>
        </>
      )}

      {listening && (
        <Stack
          direction='row'
          spacing={2}
          justifyContent='center'
        >
          <button
            onClick={handleStopRecording}
            color='black'
            style={{
              width: '100%',
              background: 'transparent',
              border: 'none',
              borderRadius: '50%',
            }}
          >
            <StopCircleIcon fontSize='large' />
          </button>
        </Stack>
      )}
      {!isRecordingStopped && listening && !isPaused && (
        <Typography
          variant='p'
          fontSize={'14px'}
          textAlign={'center'}
          gutterBottom
        >
          Recording...
        </Typography>
      )}
      {!isRecordingStopped && listening && audioTranscript && !isPaused && (
        <Box
          style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}
        >
          <Typography
            variant='p'
            fontSize={'14px'}
            textAlign={'center'}
            gutterBottom
          >
            Your Answer: {audioTranscript}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default AnswerRecorder;
