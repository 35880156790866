import React from 'react';
import { Paper, Typography, Box } from '@mui/material';

function renderManager(manager, depth) {
  return (
    <Box key={manager.id}>
      <Paper
        align='center'
        sx={{
          padding: 2,
          mx: 1,
          my: 2,
          width: '80%',
          borderBottom: '2px solid #1976d2',
          boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Typography variant='h6'>{manager.data.fullname}</Typography>
      </Paper>
      {manager.children &&
        manager.children.map((child) => renderManager(child, depth + 1))}
    </Box>
  );
}

function AdminProfileManagerTab({ managerData, currentAdminData }) {
  return (
    <Box
      sx={{
        width: '50%',
        maxWidth: 500,
        maxHeight: 300,
        overflow: 'auto',
        margin: 'auto',
      }}
    >
      <Paper
        align='center'
        sx={{
          padding: 2,
          mx: 1,
          my: 2,
          width: '80%',
          borderBottom: '2px solid #1976d2',
          boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.2)',
        }}
      >
        {currentAdminData && (
          <Typography variant='h6'>{currentAdminData.fullname}</Typography>
        )}
      </Paper>

      {managerData &&
        managerData.children &&
        managerData.children.map((manager) => renderManager(manager, 0))}
    </Box>
  );
}

export default AdminProfileManagerTab;
