import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import {
  ImportSalariesColumnFormat,
  ImportBaseSalariesColumnFormat,
  ImportIncentiveColumnFormat,
} from '../../utils/importAdminsColumsFormat';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import axios from 'axios';
import { base_url } from '../Mode';
import { AES } from 'crypto-js';
import EncryptionKeyModal from './EncryptionKeyModal';

const EntriesToUploadModal = ({
  csvData,
  columnsInCSVInput,
  open,
  onChange,
  setServerResponse,
  serverResponse,
  isBaseSalary,
  fileType,
}) => {
  const [encryptionKey, setEncryptionKey] = useState('');
  const [encryptionKeyError, setEncryptionKeyError] = useState('');
  const [showKey, setShowKey] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    return () => {
      setEncryptionKey('');
    };
  }, []);

  const convertCsvToJson = (csvData) => {
    return csvData?.map((values) =>
      columnsInCSVInput?.reduce((obj, key, index) => {
        obj[key] = values[index];
        return obj;
      }, {})
    );
  };

  const bulkUploadSalary = () => {
    if (!encryptionKey) {
      setEncryptionKeyError('Encryption key is required');
      return;
    }
    if (encryptionKey?.includes(' ')) {
      setEncryptionKeyError('Encryption key should not contain spaces');
      return;
    }

    setServerResponse({});
    const jsonData = convertCsvToJson(csvData);

    if (jsonData?.length === 0) {
      setHasError(true);
      setErrorMessage('No data to import');
      return;
    }

    let isDataInvalid = false;

    let salaryData;
    let date;

    jsonData?.forEach((entry, index) => {
      salaryData = isBaseSalary ? entry?.base_salary : entry?.amount;
      date =
        fileType === 'salary'
          ? entry?.base_salary_cleared_till
          : entry?.incentive_cleared_till;

      if (!entry?.email || entry?.email.trim() === '') {
        isDataInvalid = true;
        setHasError(true);
        setErrorMessage(`Email data is empty or missing in row ${index + 1}.`);
        return;
      }

      if (!salaryData || salaryData.trim() === '') {
        isDataInvalid = true;
        setHasError(true);
        setErrorMessage(`Salary data is empty or missing in row ${index + 1}.`);
        return;
      }

      if (!isBaseSalary) {
        if (!date || date.trim() === '') {
          isDataInvalid = true;
          setHasError(true);
          setErrorMessage(`Date data is empty or missing in row ${index + 2}.`);
          return;
        }
      }
    });

    if (!isDataInvalid) {
      const encryptedData = jsonData?.map((entry) => {
        if (salaryData === ' ' || !salaryData) {
          setHasError(true);
          setErrorMessage('Salary Data Cannot be empty.');
          return;
        }

        if (isBaseSalary) {
          entry.base_salary = AES.encrypt(
            entry?.base_salary,
            encryptionKey
          ).toString();
        } else {
          entry.amount = AES.encrypt(entry?.amount, encryptionKey)?.toString();
        }

        return entry;
      });

      const data = encryptedData;

      const url = isBaseSalary ? 'base_salaries/import' : 'salaries/import';

      if (!url) {
        setHasError(true);
        setErrorMessage('Error in uploading salary entries missing url');
        return;
      }

      axios
        .post(`${base_url()}/api/${url}`, {
          data,
          columns: columnsInCSVInput,
        })
        .then((res) => {
          setServerResponse(res?.data);
          if (res?.data?.successSalaryEntries?.length > 0) {
            setEncryptionKeyError('');
            setIsSuccess(true);
            setSuccessMessage('Salaries entries added successfully');
          } else {
            setEncryptionKeyError('');
            setHasError(true);
            setErrorMessage('Error in uploading salary entries');
          }
        })
        .catch((err) => {
          setEncryptionKeyError('');
          console.log(err);
          setHasError(true);
          setErrorMessage(err?.error || 'Somthing went wrong');
        });
    }
  };

  const downloadFailedEntriesCSV = (failedEntries) => {
    if (failedEntries?.length === 0) {
      return;
    }

    let columnsFormat;

    if (isBaseSalary) {
      columnsFormat = ImportBaseSalariesColumnFormat;
    }
    if (fileType === 'salary') {
      columnsFormat = ImportSalariesColumnFormat;
    }

    if (fileType === 'incentive') {
      columnsFormat = ImportIncentiveColumnFormat;
    }

    const headers = ['error', ...columnsFormat];

    const csvData = failedEntries?.map((entry) => {
      const rowData = [
        entry?.err,
        ...columnsFormat?.map((header) => entry?.row[header] ?? ''),
      ];
      return rowData?.join(',');
    });

    const csvContent = [headers?.join(','), ...csvData]?.join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'failed_entries.csv';
    link.click();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onChange}
        maxWidth='md'
        fullWidth
      >
        {hasError && (
          <ErrorNotifier {...{ message: errorMessage, setHasError }} />
        )}
        {isSuccess && (
          <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
        )}
        <DialogTitle>
          Entries to Upload
          <IconButton
            onClick={onChange}
            sx={{ position: 'absolute', right: 0, top: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Please review the entries before uploading.
          </DialogContentText>
          <TableContainer sx={{ maxHeight: '400px' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columnsInCSVInput?.map((column, index) => (
                    <TableCell key={index}>
                      {column?.charAt(0)?.toUpperCase() + column?.slice(1)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {csvData.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>{cell || '-'}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          {serverResponse && Object.keys(serverResponse)?.length > 0 && (
            <Stack
              direction='row'
              gap={5}
            >
              <Typography>
                <strong>No of Success :</strong>{' '}
                {serverResponse?.successSalaryEntries?.length ?? 0}
              </Typography>{' '}
              <Typography sx={{ marginRight: '20px' }}>
                <strong>No of failed :</strong>
                {serverResponse?.failedSalaryEntries?.length ?? 0}
              </Typography>
            </Stack>
          )}
          {serverResponse?.failedSalaryEntries?.length > 0 && (
            <Button
              variant='contained'
              disabled={
                serverResponse?.failedSalaryEntries?.length <= 0 ? true : false
              }
              startIcon={<DownloadIcon size={15} />}
              onClick={() =>
                downloadFailedEntriesCSV(serverResponse?.failedSalaryEntries)
              }
            >
              download failed entries
            </Button>
          )}

          {serverResponse && Object.keys(serverResponse)?.length === 0 ? (
            <>
              <Button
                variant='contained'
                onClick={bulkUploadSalary}
              >
                Approve
              </Button>
              <Button
                variant='contained'
                onClick={onChange}
              >
                Reject
              </Button>
            </>
          ) : (
            <>
              <Button
                variant='contained'
                onClick={onChange}
              >
                Close
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <EncryptionKeyModal
        isBaseSalary={isBaseSalary}
        setEncryptionKey={setEncryptionKey}
        setEncryptionKeyError={setEncryptionKeyError}
        bulkUploadSalary={bulkUploadSalary}
        encryptionKey={encryptionKey}
        encryptionKeyError={encryptionKeyError}
        setShowKey={setShowKey}
        showKey={showKey}
      />
    </>
  );
};

export default EntriesToUploadModal;
