import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CopyIcon } from '../../utils/common';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LeadsStatusCard from './LeadStatusCard';
import { SummaryContentItem } from './Summary.styled';
import LeadsSummaryTable from './LeadSummaryTable';
import MarketingTabularData from './MarketingTabularData';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LeadFunnelModal from '../../components/lead-funnel/LeadFunnelModal';
import CircularProgress from '@mui/material/CircularProgress';

function getQualificationScore(
  qualifiedLeadsCount,
  unqualifiedLeadsCount,
  allLeadsCount = 0,
  digitsAfterDecimal = 0
) {
  const totalLeads =
    allLeadsCount || qualifiedLeadsCount + unqualifiedLeadsCount;
  if (totalLeads) {
    return ((qualifiedLeadsCount * 100) / totalLeads).toFixed(
      digitsAfterDecimal
    );
  }
  return (0).toFixed(digitsAfterDecimal);
}

const Summary = ({
  summaryData,
  expandedSummary,
  setExpandedSummary,
  marketingData,
}) => {
  const { total, working_professional, working_professional_with_gts } =
    summaryData;
  const [copyIds, setCopyIds] = useState([]);
  const [openLeadFunnel, setOpenLeadFunnel] = useState(false);
  const [loading, setLoading] = useState(false);
  const bucketSearchRedirect = `/bucket-search?id=${total.allLeadsIds}`;
  const bucketSearchRedirectwpwithGTS = `/bucket-search?id=${working_professional_with_gts.allLeadsIds}`;
  const toggleLeadFunnelModal = () => {
    setOpenLeadFunnel((p) => !p);
  };
  const copyIdsandtoggleFunnel = (name) => {
    console.log(name);
    if (name === 'total-leads') {
      setCopyIds(total.allLeadsIds);
    } else if (name === 'wpwithGTS') {
      setCopyIds(working_professional_with_gts.allLeadsIds);
    }
    toggleLeadFunnelModal();
  };
  const totalLeads = total.allLeadsCount;
  const wpwithGTS = working_professional_with_gts.allLeadsCount;

  const leadCategories = [
    {
      title: 'Qualified Leads',
      value: 'qualifiedLeads',
    },
    {
      title: 'Unqualified Leads',
      value: 'unqualifiedLeads',
    },
    {
      title: 'Leads unable to classify',
      value: 'unClassifiedLeads',
    },
    {
      title: 'Total Leads',
      value: 'allLeads',
    },
  ];

  const qualificationScore = getQualificationScore(
    total.qualifiedLeadsCount,
    total.unqualifiedLeadsCount
  );

  const qualifiedWorkingProfessionals = getQualificationScore(
    working_professional.qualifiedLeadsCount,
    0,
    total.qualifiedLeadsCount,
    2
  );

  const allWorkingProfessionals = getQualificationScore(
    working_professional.allLeadsCount,
    0,
    total.allLeadsCount,
    2
  );

  return (
    <>
      {openLeadFunnel && (
        <LeadFunnelModal
          isOpen={openLeadFunnel}
          onClose={toggleLeadFunnelModal}
          copyIds={copyIds}
        />
      )}
      <Accordion expanded={expandedSummary}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setExpandedSummary(!expandedSummary)}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '5px',
              }}
            >
              <Typography
                variant='h6'
                component='p'
                marginLeft='13px'
              >
                Total leads :<b>{totalLeads}</b>
              </Typography>
              <>
                <Tooltip title='View Leads'>
                  <IconButton
                    size='small'
                    component={Link}
                    target='_blank'
                    to={bucketSearchRedirect}
                  >
                    {totalLeads <= 1500 && <OpenInNewIcon fontSize='small' />}
                  </IconButton>
                </Tooltip>
                <IconButton
                  size='small'
                  name='total-leads'
                  onClick={(e) => copyIdsandtoggleFunnel(e.currentTarget.name)}
                >
                  {totalLeads <= 30000 && <FilterAltIcon fontSize='small' />}
                </IconButton>
              </>
            </Box>

            <Box sx={{ display: 'flex' }}>
              <Typography
                variant='h6'
                component='p'
                marginLeft='13px'
              >
                Working professional with GTS :<b>{wpwithGTS}</b>
              </Typography>
              <>
                <Tooltip title='View Leads'>
                  <IconButton
                    size='small'
                    component={Link}
                    target='_blank'
                    to={bucketSearchRedirectwpwithGTS}
                  >
                    {wpwithGTS <= 1500 && <OpenInNewIcon fontSize='small' />}
                  </IconButton>
                </Tooltip>
                <IconButton
                  size='small'
                  name='wpwithGTS'
                  onClick={(e) => copyIdsandtoggleFunnel(e.currentTarget.name)}
                >
                  {wpwithGTS <= 30000 && <FilterAltIcon fontSize='small' />}
                </IconButton>
              </>
            </Box>
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          <Grid
            container
            rowSpacing={2}
          >
            <Grid
              item
              xs={12}
            ></Grid>

            <Grid
              container
              spacing={2}
              item
              xs={12}
            >
              <Grid
                item
                xs={12}
                sx={{ marginLeft: '14px' }}
              >
                <SummaryContentItem>
                  <Typography
                    variant='h6'
                    component='p'
                    sx={{ marginBottom: '16px', marginLeft: '2px' }}
                  >
                    Lead Quality Overview
                  </Typography>
                </SummaryContentItem>
                <LeadsSummaryTable summaryData={summaryData} />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Typography
                variant='h6'
                component='p'
                sx={{ marginBottom: '16px', marginLeft: '13px' }}
              >
                Lead Source Overview
              </Typography>
              <MarketingTabularData marketingData={marketingData} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Summary;
