import React, { useState } from 'react';
import { Box } from '@mui/system';
import axios from 'axios';
import { base_url } from '../../../components/Mode';
import { useEffect } from 'react';
import HolidaysTable from './HolidaysTable';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';

export default function Holidays() {
  const [allHolidays, setAllHolidays] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchAllHolidays();
    return () => {};
  }, []);

  const fetchAllHolidays = () => {
    axios
      .get(`${base_url()}/api/holidays`)
      .then((res) => {
        setAllHolidays(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not fetch the holidays.'
        );
      });
  };

  return (
    <Box sx={{ mr: 5 }}>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      {allHolidays.length > 0 && <HolidaysTable allHolidays={allHolidays} />}
    </Box>
  );
}
