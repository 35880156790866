import React from 'react';
import Box from '@mui/material/Box';
import { Typography, Stack } from '@mui/material';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import MicOutlinedIcon from '@mui/icons-material/MicOutlined';
import SchemaIcon from '@mui/icons-material/Schema';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import Divider from '@mui/material/Divider';
import CallIcon from '@mui/icons-material/Call';
import constants from '../../../utils/training/constants';

const SelectionPanel = ({
  handleSelectedTask,
  selectedTask,
  selectedModule,
  sendClickDataForTrainee,
  hasAssignment,
  hasModuleQuestionsCompleted,
  flowChartLink,
  isMockcallMandatory,
}) => {
  return (
    <Box>
      <Box style={{ display: 'flex', columnGap: '30px' }}>
        <Box
          style={{
            display: 'flex',
            columnGap: '4px',
            cursor: 'pointer',
            position: 'relative',
            color: selectedTask === 'recording' && '#009af0',
          }}
          onClick={() => handleSelectedTask('recording')}
        >
          <Box>
            <PlayCircleOutlinedIcon />
          </Box>
          <Typography>Recording</Typography>
          {selectedTask === 'recording' && (
            <Divider
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                borderTop: '2px solid rgb(76,156,248)',
                transition: 'border-top 0.2s ease-in-out',
              }}
            />
          )}
        </Box>
        {flowChartLink && (
          <Box
            style={{
              display: 'flex',
              cursor: 'pointer',
              position: 'relative',
              color: selectedTask === 'flow chart' && '#009af0',
            }}
            onClick={() => {
              handleSelectedTask('flow chart');
              sendClickDataForTrainee(constants.FLOW_CHART, selectedModule);
            }}
          >
            <Box>
              <SchemaIcon />
            </Box>
            <Typography>Flow Chart</Typography>
            {selectedTask === 'flow chart' && (
              <Divider
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  borderTop: '2px solid rgb(76,156,248)',
                  transition: 'border-top 0.2s ease-in-out',
                }}
              />
            )}
          </Box>
        )}
        {hasAssignment && (
          <Box
            style={{
              display: 'flex',
              cursor: 'pointer',
              position: 'relative',
              color: selectedTask === 'voice assignment' && '#009af0',
            }}
            onClick={() => {
              handleSelectedTask('voice assignment');
              sendClickDataForTrainee(
                constants.VOICE_ASSIGNMENT_CLICKED,
                selectedModule
              );
            }}
          >
            <Box>
              <MicOutlinedIcon />
            </Box>
            <Typography>Voice Assignment</Typography>
            {selectedTask === 'voice assignment' && (
              <Divider
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  borderTop: '2px solid rgb(76,156,248)',
                  transition: 'border-top 0.2s ease-in-out',
                }}
              />
            )}
          </Box>
        )}
        {
          <Box
            style={{
              display: 'flex',
              cursor: hasModuleQuestionsCompleted ? 'pointer' : 'not-allowed',
              position: 'relative',
              color: selectedTask === 'explain' && '#009af0',
            }}
            onClick={() => {
              if (hasModuleQuestionsCompleted) {
                handleSelectedTask('explain');
                sendClickDataForTrainee(constants.EXPLAIN, selectedModule);
              }
            }}
          >
            <Box sx={{ color: !hasModuleQuestionsCompleted && '#C4C4C4' }}>
              <InterpreterModeIcon />
            </Box>
            <Typography
              sx={{ color: !hasModuleQuestionsCompleted && '#C4C4C4' }}
            >
              Explain
            </Typography>
            {selectedTask === 'explain' && (
              <Divider
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  borderTop: '2px solid rgb(76,156,248)',
                  transition: 'border-top 0.2s ease-in-out',
                }}
              />
            )}
          </Box>
        }
        {isMockcallMandatory && (
          <Box
            style={{
              display: 'flex',
              cursor: 'pointer',
              position: 'relative',
              color: selectedTask === 'mockcall' && '#009af0',
            }}
            onClick={() => {
              handleSelectedTask('mockcall');
              sendClickDataForTrainee(
                constants.MOCKCALL_CLICKED,
                selectedModule
              );
            }}
          >
            <Box style={{ marginRight: '2px' }}>
              <CallIcon style={{ fontSize: '22px' }} />
            </Box>
            <Typography>Mockcall</Typography>
            {selectedTask === 'mockcall' && (
              <Divider
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  borderTop: '2px solid rgb(76,156,248)',
                  transition: 'border-top 0.2s ease-in-out',
                }}
              />
            )}
          </Box>
        )}
      </Box>
      <Divider
        sx={{
          width: '100%',
          borderTop: '1px solid',
          color: 'gray',
        }}
      />
    </Box>
  );
};

export default SelectionPanel;
