import PersonalInfo from './PersonalInfo';
import Education from './Education';
import Professional from './Professional';
import Source from './Source';
import Assignment from './Assignment';

export const leadForms = [
  {
    step: 0,
    step_name: 'Personal Info',
    component: PersonalInfo,
  },
  {
    step: 1,
    step_name: 'Education',
    component: Education,
  },
  {
    step: 2,
    step_name: 'Professional',
    component: Professional,
  },
  {
    step: 3,
    step_name: 'Source',
    component: Source,
  },
  {
    step: 4,
    step_name: 'Assignment',
    component: Assignment,
  },
];
