import React from 'react';
import {
  Button,
  OutlinedInput,
  Checkbox,
  Chip,
  Box,
  ListItemText,
  MenuProps,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import AppliedRoleAutocompleteDropdown from './AppliedRolesDropdown';
import CandidatesSourceAutocompleteDropdown from './CandidateSourcesDropdown';
import AdminsAutocompleteDropdown from '../admins/AdminAutoComplete';

const applicationStatus = [
  { value: 'created', label: 'Created' },
  { value: 'assigned', label: 'Assigned' },
  { value: 'profile_reviewed', label: 'Profile Reviewed' },
  { value: 'screening_done', label: 'Screening Done' },
  { value: 'cultural_questions_sent', label: 'Cultural Questions Sent' },
  {
    value: 'cultural_questions_received',
    label: 'Cultural Questions Received',
  },
  {
    value: 'round_1_scheduled',
    label: 'Round 1 Scheduled',
  },
  { value: 'round_1_done', label: 'Round 1 Done' },
  { value: 'round_2_scheduled', label: 'Round 2 Scheduled' },
  { value: 'round_2_done', label: 'Round 2 Done' },
  { value: 'manager_round_scheduled', label: 'Manager Round Scheduled' },
  { value: 'manager_round_done', label: 'Manager Round Done' },
  { value: 'salary_finalized', label: 'Salary Finalized' },
  { value: 'offer_letter_sent', label: 'Offer Letter Sent' },
  { value: 'offer_letter_signed', label: 'Offer Letter Signed' },
  { value: 'documents_collected', label: 'Documents Collected' },
  { value: 'joined', label: 'Joined' },
];

function CandidateApplicationDetails({ filters, handleChange, handleReset }) {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          variant='h6'
          component='p'
          style={{
            fontSize: '14px',
            marginLeft: '17px',
          }}
        >
          Application Details
        </Typography>
        <Divider
          style={{
            margin: '0 16px',
            flexGrow: 1,
          }}
        />
      </div>
      <AccordionDetails>
        <Grid
          container
          spacing={0.8}
          direction={{
            xs: 'column',
            sm: 'row',
          }}
        >
          <Grid
            item
            xs={3}
            sm={3}
            md={2}
          >
            <FormControl
              id='applied_role'
              size='small'
              fullWidth
            >
              <AppliedRoleAutocompleteDropdown
                value={filters?.applied_role}
                onChangeHandler={(e, newValue) => {
                  const updatedEvent = {
                    target: {
                      name: 'applied_role',
                      value: newValue.value,
                    },
                  };
                  handleChange(updatedEvent);
                }}
                sx={{
                  width: '100%',
                }}
                label='Applied Role'
                size='small'
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
          >
            <FormControl
              id='status'
              size='small'
              fullWidth
            >
              <InputLabel id='application_status_label'>
                Application status
              </InputLabel>
              <Select
                name='status'
                label='Application status'
                labelId='status'
                onChange={(e) => handleChange(e)}
                value={filters?.status}
              >
                {applicationStatus.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
          >
            <FormControl
              id='candidate_source'
              size='small'
              sx={{
                height: '100%',
              }}
              fullWidth
            >
              <CandidatesSourceAutocompleteDropdown
                value={filters?.source_id}
                onChangeHandler={(e, newValue) => {
                  const updatedEvent = {
                    target: {
                      name: 'source_id',
                      value: newValue.value,
                    },
                  };
                  handleChange(updatedEvent);
                }}
                sx={{
                  width: '100%',
                }}
                label='Candidate Source'
                size='small'
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={2}
          >
            <FormControl
              id='assignee'
              fullWidth
            >
              <AdminsAutocompleteDropdown
                value={filters?.assignee}
                onChangeHandler={(e, newValue) => {
                  e.target.name = 'assignee';
                  e.target.value = newValue.value;
                  handleChange(e);
                }}
                sx={{
                  width: '100%',
                }}
                label='Assignee'
                size='small'
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={2}
          >
            <FormControl
              id='snoozed_until'
              fullWidth
            >
              <TextField
                size='small'
                type='text'
                placeholder='Snoozed until'
                name='snoozed_until'
                onFocus={(e) => {
                  e.target.type = 'date';
                }}
                value={filters?.snoozed_until}
                onChange={(e) => handleChange(e)}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={2}
          >
            <FormControl
              id='label'
              fullWidth
            >
              <TextField
                size='small'
                type='text'
                name='label'
                placeholder='Label'
                onChange={(e) => handleChange(e)}
                value={filters?.label}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={2}
          >
            <Button
              variant='outlined'
              px={10}
              onClick={(e) => handleReset(e)}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </div>
  );
}

export default CandidateApplicationDetails;
