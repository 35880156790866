export const allBucketSearchSettingColumns = {
  id: false,
  name: false,
  mobile: false,
  experience: false,
  tech_stack: false,
  product: false,
  label: false,
  status: false,
  snoozed_until: false,
  last_interaction: false,
  last_interaction_on: false,
  last_interaction_by: false,
  graduation_year: false,
  company: false,
  created_at: false,
  assignee_name: false,
  lead_source_type: false,
  dropped: false,
};
