import React, { useState, createContext, useContext, useEffect } from 'react';
import axios from 'axios';
import { base_url } from './Mode';
import {
  currentAdminIsAdmin,
  currentAdminIsMarketing,
  currentAdminIsHr,
  currentAdminIsManagerOrAdmin,
  currentAdminIsTa,
} from '../utils/common';

const AdminsContext = createContext();

function AdminsComponent({ children }) {
  const [allAdmins, setAllAdmins] = useState([]);
  const [updateAdminsContext, setUpdateAdminsContext] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showRecordingColumn, setShowRecordingColumn] = useState(false);
  const [showImportBtn, setShowImportBtn] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${base_url()}/api/admins`)
      .then((res) => {
        const currAdmin = JSON.parse(localStorage.getItem('admin'));
        const subordinateAdminIds = new Set(
          currAdmin.subordinate_admin_ids ?? [currAdmin.id]
        );
        if (currentAdminIsManagerOrAdmin()) {
          setShowRecordingColumn(true);
          setShowImportBtn(true);
        }
        const subordinateAdmins = [];
        const enabledAccessAdmins = [];
        const disabledAccessAdmins = [];

        res.data.forEach((admin) => {
          admin.access_type === 'allowed'
            ? enabledAccessAdmins.push(admin)
            : disabledAccessAdmins.push(admin);

          if (
            currentAdminIsAdmin() ||
            currentAdminIsMarketing() ||
            currentAdminIsHr() ||
            currentAdminIsTa() ||
            subordinateAdminIds.has(admin.id)
          ) {
            subordinateAdmins.push(admin);
            if (
              currentAdminIsAdmin() ||
              currentAdminIsHr() ||
              currentAdminIsTa()
            ) {
              subordinateAdminIds.add(admin.id);
            }
          }
        });

        setAllAdmins(enabledAccessAdmins.concat(disabledAccessAdmins));
        localStorage.setItem('admins', JSON.stringify(subordinateAdmins));

        currAdmin.subordinate_admin_ids = Array.from(subordinateAdminIds);
        localStorage.setItem('admin', JSON.stringify(currAdmin));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {};
  }, [updateAdminsContext]);

  return (
    <AdminsContext.Provider
      value={{
        allAdmins,
        setAllAdmins,
        updateAdminsContext,
        setUpdateAdminsContext,
        showRecordingColumn,
        showImportBtn,
        loading,
      }}
    >
      {children}
    </AdminsContext.Provider>
  );
}

export default AdminsComponent;
export const useAllAdmins = () => useContext(AdminsContext);
