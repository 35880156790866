import React, { useState } from 'react';
import {
  Modal,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import { base_url } from '../../components/Mode';

export default function AddTechStackModal({
  isOpen,
  onClose,
  setIsSuccess,
  setSuccessMessage,
  setHasError,
  setErrorMessage,
}) {
  const [newTechStackName, setNewTechStackName] = useState('');
  const [selectedOption, setSelectedOption] = useState('tech_background');

  const handleTechStackNameChange = (event) => {
    setNewTechStackName(event.target.value);
  };

  const handleAddTechStackSubmit = () => {
    const trimmedName = newTechStackName.trim();

    if (trimmedName === '') {
      setHasError(true);
      setErrorMessage('Tech Stack name cannot be empty');
      return;
    }

    if (!/^[^,\s]+$/.test(trimmedName)) {
      setHasError(true);
      setErrorMessage('Tech Stack name cannot contain spaces.');
      return;
    }

    const body = {
      name: trimmedName,
      type: selectedOption,
    };

    axios
      .post(`${base_url()}/api/tech-stacks`, body)
      .then((res) => {
        setNewTechStackName('');
        setSelectedOption('tech_background');
        setIsSuccess(true);
        setSuccessMessage('Tech Stack successfully added');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not add the tech stack.'
        );
      });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TextField
          label='Enter tech stack name'
          variant='outlined'
          value={newTechStackName}
          onChange={handleTechStackNameChange}
          sx={{ marginBottom: '1rem', width: '100%' }}
        />

        <FormControl
          variant='outlined'
          sx={{ marginBottom: '1rem', width: '100%' }}
        >
          <InputLabel htmlFor='tech-stack-select'>
            Select tech stack type
          </InputLabel>
          <Select
            value={selectedOption}
            onChange={(event) => setSelectedOption(event.target.value)}
            label='Select tech stack type'
            inputProps={{ id: 'tech-stack-select' }}
          >
            <MenuItem value='tech_background'>Tech Background</MenuItem>
            <MenuItem value='non_tech_background'>Non-Tech Background</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant='contained'
          onClick={handleAddTechStackSubmit}
          sx={{ marginTop: '1rem' }}
        >
          Add Tech Stack
        </Button>
      </Box>
    </Modal>
  );
}
