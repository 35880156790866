// NewLeadNavigationButtons.js
import React from 'react';
import { Button } from '@mui/material';

const NewLeadNavigationButtons = ({
  backButtonText,
  nextButtonText,
  backButtonHandler,
  nextButtonHandler,
}) => {
  return (
    <>
      {backButtonText && (
        <Button
          variant='contained'
          onClick={backButtonHandler}
        >
          {backButtonText}
        </Button>
      )}
      {nextButtonText && (
        <Button
          variant='contained'
          onClick={nextButtonHandler}
        >
          {nextButtonText}
        </Button>
      )}
    </>
  );
};

export default NewLeadNavigationButtons;
