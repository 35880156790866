import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { CopyIcon } from '../../../utils/common';
import { base_url } from '../../../components/Mode';

export default function LeadsSnoozedTillToday({
  setHasError,
  setErrorMessage,
}) {
  const [overview, setOverview] = useState([]);
  const [perPage, setPerPage] = useState(50);

  useEffect(() => {
    fetchLeadsSnoozedTillToday();
    return () => {};
  }, []);

  function fetchLeadsSnoozedTillToday() {
    axios
      .get(`${base_url()}/api/admins/summary?type=snoozed_till_today`)
      .then((res) => {
        const tmpOverview = [];
        Object.keys(res.data).forEach((id) => {
          if (!('leadIds' in res.data[id])) {
            res.data[id]['leadIds'] = [];
          }
          tmpOverview.push(res.data[id]);
        });
        setOverview(tmpOverview);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            "Couldn't fetch snoozed till today end of the day."
        );
      });
  }

  const columns = [
    {
      field: 'id',
      headerName: 'S.No',
      width: 100,
      align: 'left',
      headerAlign: 'left',
      type: 'number',
      valueGetter: (params) => params.row.id,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) => params.row.name,
    },
    {
      field: 'snoozed_leads',
      headerName: 'Snoozed leads count',
      width: 170,
      align: 'left',
      headerAlign: 'left',
      type: 'number',
      valueGetter: (params) => params.row.leadIds.length,
    },
    {
      field: 'action',
      headerName: 'Copy snoozed lead ids',
      width: 190,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) =>
        CopyIcon(
          params.row.leadIds.map((id) => id.toString()).join(', '),
          true
        ),
    },
  ];

  return (
    <Box width={800}>
      {overview.length > 0 && (
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
          rows={overview}
          columns={columns}
          pageSize={perPage}
          rowsPerPageOptions={[50, 100]} // 100 is the max page size in non-pro version
          onPageSizeChange={(page) => setPerPage(page)}
          pagination
          autoHeight
          disableSelectionOnClick
          sx={{
            '& .MuiDataGrid-row:hover': {
              backgroundColor: '#e3f2fd',
            },
            '& .MuiDataGrid-cell': {
              paddingLeft: '25px',
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {
              paddingLeft: '20px',
            },
          }}
        />
      )}

      {overview.length === 0 && (
        <Typography
          variant='body1'
          alignSelf='center'
        >
          No data found
        </Typography>
      )}
    </Box>
  );
}
