import React, { useState } from 'react';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
import { Modal, TextField, Box, Button } from '@mui/material';
import axios from 'axios';
import Papa from 'papaparse';
import { base_url } from '../../../components/Mode';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import IncentiveStructure_table from './IncentiveStructure_table';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

function IncentiveModal({ open, handleClose }) {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [incentivedata, setIncentiveData] = useState({});
  const [fileuploaded, setFileUploaded] = useState(false);

  const handlesave = async () => {
    if (!name || !description || Object.keys(incentivedata).length === 0) {
      setHasError(true);
      setErrorMessage('Please fill in all required fields.');
      return;
    }
    handleClose();

    const requestData = {
      name: name,
      description: description,
      incentiveData: incentivedata,
    };
    try {
      await axios.post(`${base_url()}/api/drives/incentive`, requestData);
      setIsSuccess(true);
      setSuccessMessage('Incentive Strucuture Upload Successfully');
      setFileUploaded(false);
      setName('');
      setDescription('');

      handleClose();
    } catch (error) {
      setHasError(true);
      setErrorMessage(
        'while uploading data in incentive structure',
        error.message
      );
    }
  };

  const handleUploadCSV = async (event) => {
    try {
      const file = event.target.files[0];

      if (!file) {
        throw new Error('No file uploaded');
      }
      // Use PapaParse to parse the CSV file
      Papa.parse(file, {
        complete: async (result) => {
          const csvData = result.data;
          if (csvData.length === 0) {
            throw new Error('CSV file is empty');
          }
          if (csvData.length < 13) {
            setHasError(true);
            setErrorMessage('CSV file have less then 13 entries');
            throw new Error('CSV file have less then 13 entries');
          }
          const headers = csvData[0];
          const expectedHeaders = [
            'No. of Caps',
            'Incentive Amount',
            'Prorata',
            'Prorata Message',
            'Incentive Explanation',
          ];
          const isValidHeaders = headers.every((header, index) => {
            return header.trim() === expectedHeaders[index];
          });

          if (!isValidHeaders) {
            setErrorMessage('CSV file headers do not match expected format');
            setHasError(true);
            throw new Error('CSV file headers do not match expected format');
          }
          const filteredData = csvData
            .slice(1)
            .filter((row) =>
              row.every((cell) => cell !== null && cell.trim() !== '')
            );
          const jsonData = filteredData.map((row) => ({
            'No. of Caps': row[0],
            'Incentive Amount': row[1],
            Prorata: row[2],
            'Prorata Message': row[3],
            'Incentive Explanation': row[4],
          }));

          setIncentiveData(jsonData);
          setSuccessMessage('CSV file uploaded and parsed successfully');
          setIsSuccess(true);
          setFileUploaded(true);
        },
        error: (error) => {
          console.error('Error parsing CSV file:', error);
          setErrorMessage('Failed to parse CSV file');
          setHasError(true);
        },
      });
    } catch (error) {
      console.error('Error uploading CSV file:', error);
      setErrorMessage('Failed to upload CSV file');
      setHasError(true);
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: 400,
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Box>
            <h3 style={{ fontSize: '18px', marginBottom: '10px' }}>
              Add New Incentive Structure
            </h3>

            <TextField
              label='Incentive Structure Name'
              fullWidth
              size='small'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          <Box mt={2}>
            <TextField
              label='Description'
              fullWidth
              size='small'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              // Adjusting the size of the text field
            />
          </Box>
          <Box
            sx={{
              backgroundColor: '#E1DDDD',
              marginTop: '10px',
              height: '60px',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid',
            }}
          >
            {fileuploaded ? (
              <div style={{ fontSize: '20px' }}>File Uploaded Successfully</div>
            ) : (
              <label
                htmlFor='upload-csv'
                style={{ fontSize: '20px', cursor: 'pointer' }}
              >
                <AttachFileIcon /> Upload File
              </label>
            )}
            <input
              type='file'
              accept='.csv'
              style={{ display: 'none' }}
              onChange={handleUploadCSV}
              id='upload-csv'
            />
          </Box>
          <Box
            mt={2}
            sx={{
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={handlesave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

function IncentiveTab() {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const downloadTemplate = () => {
    const csvContent =
      'No. of Caps,Incentive Amount,Prorata,Prorata Message,Incentive Explanation\nValue1,Value2,Value3,Value4,Value5\n';
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a temporary anchor element to trigger the download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'incentive_template.csv';
    link.click();
  };

  return (
    <Box>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <Box
        display='flex'
        justifyContent='flex-end'
        gap={2}
      >
        <Box
          display='flex'
          justifyContent='flex-end'
        >
          <Button
            variant='contained'
            style={{ fontSize: '10px' }}
            onClick={downloadTemplate}
          >
            Download incentive Structure template
          </Button>
        </Box>
        <Box>
          <Button
            variant='contained'
            component='span'
            style={{ fontSize: '10px' }}
            onClick={handleOpenModal}
          >
            Add new incentive Structure
          </Button>
        </Box>
        <IncentiveModal
          open={openModal}
          handleClose={handleCloseModal}
          //   handleUploadCSV={handleUploadCSV}
          setHasError={setHasError}
          setIsSuccess={setIsSuccess}
          successMessage={successMessage}
          errorMessage={errorMessage}
        />
      </Box>
      <Box>
        <IncentiveStructure_table IncentiveModal={IncentiveModal} />
      </Box>
    </Box>
  );
}

export default IncentiveTab;
