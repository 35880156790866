import { Box, Typography } from '@mui/material';

const ExpFieldBox = ({ expFieldRef, currentLeadData }) => {
  const { fname, lname, tech_stack, company, experience } = currentLeadData;
  const fullname = fname + (lname !== null ? ` ${lname}` : '');

  let yearOfExp = '0';
  if (experience === 'working_professional_0_2_exp') {
    yearOfExp = '0-2';
  } else if (experience === 'student' || experience === 'graduated') {
    yearOfExp = '0';
  } else if (experience === 'working_professional_2_5_exp') {
    yearOfExp = '2-5';
  } else if (experience === 'working_professional_5_plus_exp') {
    yearOfExp = '5+';
  } else {
    yearOfExp = '0';
  }

  return (
    <Box
      ref={expFieldRef}
      className='expField'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        flexDirection: 'column',
        alignItems: 'center',
        transition: 'opacity 0.3s ease-in',
        opacity: 0,
      }}
    >
      <Typography
        sx={{
          zIndex: 6,
          color: '#EBE3F8',
          fontSize: '44px',
          textAlign: 'center',
          fontFamily: "'Inter', 'sans-serif'",
          fontWeight: 700,
          WebkitBackgroundClip: 'text',
          backgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundImage:
            'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
          transition: 'opacity 0.6s ease-in-out',
        }}
      >
        {fullname}
        {tech_stack && company && <span> is a</span>}
      </Typography>

      {tech_stack && company && (
        <Typography
          sx={{
            color: '#FFF',
            textAlign: 'center',
            fontSize: '60px',
            fontWeight: 700,
            zIndex: 6,
          }}
        >
          {tech_stack}{' '}
          <span
            style={{
              color: '#B998EF',
              fontFamily: '"Montserrat", "sans-serif"',
              zIndex: 6,
            }}
          >
            at
          </span>{' '}
          {company}
        </Typography>
      )}

      {yearOfExp !== '0' ? (
        <Typography
          sx={{
            color: '#B998EF',
            fontSize: '50px',
            textAlign: 'center',
            fontWeight: 700,
            zIndex: 6,
          }}
        >
          with{' '}
          <span
            style={{
              color: '#FFF',
              fontFamily: '"Montserrat", "sans-serif"',
              zIndex: 6,
            }}
          >
            {yearOfExp} years
          </span>{' '}
          of experience.
        </Typography>
      ) : (
        <Typography
          sx={{
            color: '#B998EF',
            fontSize: '50px',
            textAlign: 'center',
            fontWeight: 700,
            zIndex: 6,
          }}
        >
          has{' '}
          <span
            style={{
              color: '#FFF',
              fontFamily: '"Montserrat", "sans-serif"',
              zIndex: 6,
            }}
          >
            no experience.
          </span>
        </Typography>
      )}
    </Box>
  );
};

export default ExpFieldBox;
