import { TextField, Button, Select, MenuItem, Typography } from '@mui/material';

export default function CampaignBasic({
  islive,
  islocked,
  isApproved,
  isPaid,
  campaignDetails,
  createCampaignDetails,
  previous28Days,
  longUrl,
  generateUrlHandler,
  textFieldStyle,
}) {
  const textFieldStyleForFullWidth = {
    ...textFieldStyle,
    width: '85%',
    '& .MuiInputBase-root': {
      height: '2.5rem',
    },
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '1%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '48%',
          }}
        >
          <Typography style={{ width: '40%', marginTop: '1%' }}>
            UTM Source:
          </Typography>
          <Select
            name='utm_source'
            disabled={
              islive(campaignDetails) ||
              islocked(campaignDetails) ||
              isApproved(campaignDetails) ||
              isPaid(campaignDetails)
            }
            value={campaignDetails.utm_source}
            onChange={createCampaignDetails}
            style={{ width: '60%', height: '70%' }}
          >
            <MenuItem value='instagram'>Instagram</MenuItem>
            <MenuItem value='linkedin'>LinkedIn</MenuItem>
            <MenuItem value='telegram'>Telegram</MenuItem>
            <MenuItem value='whatsapp'> WhatsApp</MenuItem>
            <MenuItem value='facebook'>Facebook</MenuItem>
            <MenuItem value='youtube'>YouTube</MenuItem>
            <MenuItem value='email'>Email</MenuItem>
            <MenuItem value='x'>X</MenuItem>
          </Select>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '48%',
          }}
        >
          <Typography style={{ width: '40%', marginTop: '1%' }}>
            UTM Campaign:
          </Typography>
          <TextField
            disabled={
              islive(campaignDetails) ||
              islocked(campaignDetails) ||
              isApproved(campaignDetails) ||
              isPaid(campaignDetails)
            }
            value={campaignDetails.utm_campaign}
            name='utm_campaign'
            type='text'
            id='utm_campaign'
            onChange={createCampaignDetails}
            sx={textFieldStyle}
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '1%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '48%',
          }}
        >
          <Typography style={{ width: '40%', marginTop: '1%' }}>
            UTM Medium:
          </Typography>
          <Select
            disabled={
              islive(campaignDetails) ||
              islocked(campaignDetails) ||
              isApproved(campaignDetails) ||
              isPaid(campaignDetails)
            }
            name='utm_medium'
            value={campaignDetails.utm_medium}
            onChange={createCampaignDetails}
            style={{ width: '60%', height: '70%' }}
          >
            <MenuItem value='social'>Social</MenuItem>
            <MenuItem value='collab'>Collab</MenuItem>
            <MenuItem value='ads'> Ads</MenuItem>
            <MenuItem value='influencer'>Influencer</MenuItem>
          </Select>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '48%',
          }}
        >
          <Typography style={{ width: '40%', marginTop: '1%' }}>
            Live On:
          </Typography>
          <TextField
            disabled={
              islive(campaignDetails) ||
              islocked(campaignDetails) ||
              isApproved(campaignDetails) ||
              isPaid(campaignDetails)
            }
            name='live_on'
            inputProps={{
              min: previous28Days,
            }}
            value={campaignDetails.live_on.slice(0, 10)}
            onChange={createCampaignDetails}
            type='date'
            id='live_on'
            InputLabelProps={{ shrink: true }}
            sx={textFieldStyle}
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '1%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '48%',
          }}
        >
          <Typography style={{ width: '40%', marginTop: '1%' }}>
            Product:
          </Typography>
          <Select
            disabled={
              islive(campaignDetails) ||
              islocked(campaignDetails) ||
              isApproved(campaignDetails) ||
              isPaid(campaignDetails)
            }
            name='product'
            value={campaignDetails.product}
            onChange={createCampaignDetails}
            style={{ width: '60%', height: '70%' }}
          >
            <MenuItem value='super30'>Super 30</MenuItem>
            <MenuItem value='pc'>Personal Coaching</MenuItem>
          </Select>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '48%',
          }}
        >
          <Typography style={{ width: '40%', marginTop: '1%' }}>
            Campaign Type:
          </Typography>

          <Select
            disabled={
              islive(campaignDetails) ||
              islocked(campaignDetails) ||
              isApproved(campaignDetails) ||
              isPaid(campaignDetails)
            }
            name='campaign_type'
            value={campaignDetails.campaign_type}
            onChange={createCampaignDetails}
            style={{ width: '60%', height: '40px' }}
          >
            <MenuItem value='lead_generation'>Lead Generation</MenuItem>
            <MenuItem value='brand_awareness'>Brand Awareness</MenuItem>
          </Select>
        </div>
      </div>

      {(islocked(campaignDetails) ||
        islive(campaignDetails) ||
        isApproved(campaignDetails) ||
        isPaid(campaignDetails)) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '3%',
          }}
        >
          <Typography style={{ width: '20%', marginTop: '1%' }}>
            Long URL:
          </Typography>
          <TextField
            disabled={
              islive(campaignDetails) ||
              islocked(campaignDetails) ||
              isApproved(campaignDetails) ||
              isPaid(campaignDetails)
            }
            name='long_url'
            type='text'
            value={campaignDetails.long_url}
            id='long_url'
            fullWidth
            sx={textFieldStyleForFullWidth}
          />
        </div>
      )}

      {(islocked(campaignDetails) ||
        islive(campaignDetails) ||
        isApproved(campaignDetails) ||
        isPaid(campaignDetails) ||
        longUrl) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '3%',
          }}
        >
          <Typography style={{ width: '20%', marginTop: '1%' }}>
            Short URL:
          </Typography>
          <TextField
            disabled={
              islocked(campaignDetails) ||
              isApproved(campaignDetails) ||
              isPaid(campaignDetails)
            }
            name='short_url'
            type='text'
            value={campaignDetails.short_url}
            onChange={createCampaignDetails}
            id='short_url'
            fullWidth
            sx={textFieldStyleForFullWidth}
          />
        </div>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '3%',
        }}
      >
        <Typography style={{ width: '20%', marginTop: '1%' }}>
          Notes (if any):
        </Typography>
        <TextField
          disabled={
            islocked(campaignDetails) ||
            isApproved(campaignDetails) ||
            isPaid(campaignDetails)
          }
          name='notes'
          type='text'
          value={campaignDetails.notes}
          onChange={createCampaignDetails}
          placeholder='Notes '
          id='notes'
          fullWidth
          sx={textFieldStyleForFullWidth}
        />
      </div>

      {longUrl && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '3%',
          }}
        >
          <Typography style={{ width: '20%', marginTop: '1%' }}>
            URL:
          </Typography>
          <TextField
            disabled={islive(campaignDetails)}
            type='text'
            value={longUrl}
            id='url'
            fullWidth
            sx={textFieldStyleForFullWidth}
          />
        </div>
      )}

      {!islive(campaignDetails) &&
        !islocked(campaignDetails) &&
        !isApproved(campaignDetails) &&
        !isPaid(campaignDetails) && (
          <Button
            disabled={longUrl}
            variant='contained'
            onClick={generateUrlHandler}
            size='large'
            sx={{ width: '40%', alignItems: 'center', marginLeft: '30%' }}
          >
            Get URL
          </Button>
        )}
    </>
  );
}
