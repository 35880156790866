import React from 'react';
import useTitle from './components/UseTitle';
import { useAuthStateValue } from './components/auth/AuthContext';
import Routing from './routes/routes';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SocketProvider } from './socket/socketContext';
import ManagerScreen from './components/training/trainee/mockcalls/manager/ManagerScreen';
import ICScreen from './components/training/trainee/mockcalls/IC/ICScreen';
import { getCurrentAdminId } from './utils/common';

function NewApp() {
  const [user, setUser, isUserLoggedIn] = useAuthStateValue();
  const history = useNavigate();
  useTitle('Sales | HeyCoach');
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      return response;
    },
    function (error) {
      if (
        error?.response?.status === 401 ||
        (error?.response?.status === 403 &&
          error?.response?.data ===
            'Access denied. The required cookies are missing in the request.')
      ) {
        try {
          const userId = getCurrentAdminId();
          setUser(null);
          localStorage.clear();
          history(`/?${userId}`);
        } catch (error) {
          console.error('Error occured in auto logout', error);
        }
      }
      return Promise.reject(error);
    }
  );

  return (
    <main
      style={{
        height: '98vh',
        overflow: 'auto',
      }}
    >
      <SocketProvider user={user}>
        <Routing />
        <ManagerScreen />
        <ICScreen />
      </SocketProvider>
    </main>
  );
}

export default NewApp;
