const constants = {
  VIDEO_PLAYED: 'video_played',
  VIDEO_PAUSED: 'video_paused',
  MOCKCALL_CLICKED: 'mockcall_clicked',
  VOICE_ASSIGNMENT_CLICKED: 'voice_assignment_clicked',
  FLOW_CHART: 'flow_chart_clicked',
  EXPLAIN: 'explain_clicked',
  MODULE_CLICKED: 'module_clicked',
};

export default constants;
