import React, { useState } from 'react';
import ProfileDetails from './ProfileDetails';
import SalesHandoverTab from './SalesHandoverTab';
import { Stack } from '@mui/material';

const PostSalesProfileTab = () => {
  const [handleProfileEditClose, setHandleProfileEditClose] = useState(false);
  return (
    <>
      {handleProfileEditClose ? (
        <Stack
          sx={{
            width: '50%',
            margin: '0 auto',
          }}
        >
          <SalesHandoverTab
            setHandleProfileEditClose={setHandleProfileEditClose}
          />
        </Stack>
      ) : (
        <ProfileDetails setHandleProfileEditClose={setHandleProfileEditClose} />
      )}
    </>
  );
};

export default PostSalesProfileTab;
