import React, { useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function CustomBackdrop({ open }) {
  const getLoadingState = () => {
    if (typeof open === 'boolean') {
      return open;
    } else if (open && typeof open === 'object') {
      return Object.values(open).filter((e) => e).length > 0;
    } else {
      return false;
    }
  };
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={getLoadingState()}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  );
}
