export const newImportAdminsColumnFormat = [
  'fname',
  'lname',
  'email',
  'role',
  'manager_email',
];

export const ImportSalariesColumnFormat = [
  'email',
  'amount',
  'incentive',
  'base_salary_cleared_till',
];

export const ImportIncentiveColumnFormat = [
  'email',
  'amount',
  'incentive',
  'incentive_cleared_till',
];

export const ImportBaseSalariesColumnFormat = ['name', 'email', 'base_salary'];
