import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
const EncryptionKeyModal = ({
  encryptionKey,
  encryptionKeyError,
  setEncryptionKeyError,
  setEncryptionKey,
  setShowKey,
  showKey,
  bulkUploadSalary,
  decrypt,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      bulkUploadSalary();
    }
  };

  return (
    <>
      <Dialog
        open={!!encryptionKeyError}
        onClose={() => setEncryptionKeyError('')}
      >
        <DialogTitle>Enter Secret Key</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            id='encryption-key'
            label={decrypt ? 'Decryption Key' : 'Encryption Key'}
            type={showKey ? 'text' : 'password'}
            fullWidth
            value={encryptionKey}
            onChange={(e) => setEncryptionKey(e.target.value)}
            error={!!encryptionKeyError}
            helperText={
              decrypt ? 'Decryption key is required' : encryptionKeyError
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowKey((prev) => !prev)}
                    edge='end'
                  >
                    {showKey ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onKeyDown={handleKeyDown}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEncryptionKeyError('');
              setEncryptionKey('');
            }}
            color='primary'
          >
            Cancel
          </Button>
          <Button
            onClick={bulkUploadSalary}
            color='primary'
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EncryptionKeyModal;
