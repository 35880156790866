import React from 'react';
import { Stack } from '@mui/material';

export default function ImageUploads({ uploadedImages, setUploadedImages }) {
  const handleUpload = (e) => {
    const files = Array.from(e.target.files).map((file) => ({
      name: file.name,
      data: file,
    }));
    setUploadedImages(files);
  };

  return (
    <Stack spacing={2}>
      <input
        type='file'
        multiple
        name='images'
        onChange={handleUpload}
      />
    </Stack>
  );
}
