import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  Typography,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { useCurrentPostSalesLead } from '../../../CurrentPostSalesLeadContext';
import axios from 'axios';
import { base_url } from '../../../Mode';
import { currentAdminIsIC } from '../../../../utils/common';
import { fileUploadHandler } from '../../../SpeechRecognition/utils';

const PostSalesPaymentNewRecord = ({ setOpen, open, setPaymentDetails }) => {
  const [editedFields, setEditedFields] = useState({});
  const [paymentScreenshot, setPaymentScreenshot] = useState({});
  const [uploadedFile, setUploadedFile] = useState({});
  const { leadData, setToastNotificationMessage } = useCurrentPostSalesLead();

  const getFieldValue = (key) => {
    if (key in editedFields) {
      return editedFields[key];
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedFields({
      ...editedFields,
      [name]: value,
    });
    return;
  };

  const handleUpload = (e) => {
    console.log('file -', Array.from(e.target.files));
    const file = Array.from(e.target.files).map((file) => ({
      name: file.name,
      data: file,
    }));

    setPaymentScreenshot(...file);
  };

  const updateDetails = async () => {
    // const data = await fileUploadHandler(null, paymentScreenshot);
    // if (data.success) {
    //   setUploadedFile(data.response);
    //   setToastNotificationMessage('success', data.message);
    //   setPaymentScreenshot({});
    // } else {
    //   setToastNotificationMessage('error', data.message);
    // }
    // if (Object.keys(uploadedFile).length === 0) {
    //   setOpen(false);
    //   setToastNotificationMessage(
    //     'error',
    //     'Please attach the payment screenshot!'
    //   );
    //   return;
    // }
    const body = {
      ...editedFields,
      post_sales_lead_id: leadData.id,
      payment_screenshot: uploadedFile,
    };

    if (Object.keys(body).length === 1) {
      return;
    }

    axios
      .post(`${base_url()}/api/post-sales-payments`, body)
      .then((res) => {
        setPaymentDetails((prev) => {
          return [...prev, res.data];
        });
        setEditedFields({});
        setToastNotificationMessage(
          'success',
          'Payment Record Successfully Saved'
        );
      })
      .catch((err) => {
        console.log(err);
        setToastNotificationMessage(
          'error',
          err.response?.data?.message ||
            'An error occurred while creating the payment. Please try again later.'
        );
      })
      .finally(() => {
        setOpen(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={(e) => setOpen(false)}
      fullWidth
      maxWidth='sm'
    >
      <Box
        display='flex'
        justifyContent='flex-end'
      >
        <Button onClick={(e) => setOpen(false)}>Close</Button>
      </Box>
      <DialogContent>
        <DialogContentText>
          <Stack spacing={2}>
            <TextField
              name='title'
              label='Title'
              variant='outlined'
              fullWidth
              margin='normal'
              value={getFieldValue('title')}
              onChange={handleChange}
            />
            {/* {!currentAdminIsIC() && ( */}
            <TextField
              name='transaction_id'
              label='Transaction id'
              variant='outlined'
              fullWidth
              margin='normal'
              value={getFieldValue('transaction_id')}
              onChange={handleChange}
            />
            {/* )} */}
            <TextField
              name='tenure'
              label='Tenure'
              variant='outlined'
              fullWidth
              margin='normal'
              value={getFieldValue('tenure')}
              onChange={handleChange}
              disabled={getFieldValue('source_type') !== 'emi'}
            />
            <TextField
              name='txn_amount'
              label='Txn amount'
              variant='outlined'
              fullWidth
              margin='normal'
              type='number'
              value={getFieldValue('txn_amount')}
              onChange={handleChange}
            />
            <TextField
              name='disbursed_amount'
              label='Disbursed amount'
              variant='outlined'
              fullWidth
              margin='normal'
              type='number'
              value={getFieldValue('disbursed_amount')}
              onChange={handleChange}
            />
            <TextField
              name='disbursed_date'
              label='Disbursed date'
              variant='outlined'
              fullWidth
              margin='normal'
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              value={getFieldValue('disbursed_date')}
              onChange={handleChange}
            />
            <FormControl fullWidth>
              <InputLabel id='source_type'>Source type</InputLabel>
              <Select
                type='text'
                name='source_type'
                label='Source type'
                labelId='source_type'
                value={getFieldValue('source_type')}
                onChange={handleChange}
              >
                <MenuItem value='bank_transfer'>Bank transfer</MenuItem>
                <MenuItem value='online_payment'>Online payment</MenuItem>
                <MenuItem value='emi'>Emi</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id='type'>Type</InputLabel>
              <Select
                type='text'
                name='type'
                label='EMI provider'
                labelId='type'
                value={getFieldValue('type')}
                onChange={handleChange}
              >
                <MenuItem value='expected'>Expected</MenuItem>
                <MenuItem value='received'>Received</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name='txn_email'
              label='Txn email'
              variant='outlined'
              fullWidth
              margin='normal'
              value={getFieldValue('txn_email')}
              onChange={handleChange}
            />
            {/* <input
              type='file'
              id='resume-sales-handover'
              name='resume-sales-handover'
              onChange={handleUpload}
            /> */}
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={updateDetails}
          disabled={Object.keys(editedFields).length === 0}
        >
          Record a new payment
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PostSalesPaymentNewRecord;
