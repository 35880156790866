import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Modal, TextField, Box, Button } from '@mui/material';
import axios from 'axios';
import { base_url } from '../../../components/Mode';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import Papa from 'papaparse';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

function IncentiveModal({ open, handleClose, selectedIdIncentiveStructure }) {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [incentivedata, setIncentiveData] = useState({});
  const [fileuploaded, setFileUploaded] = useState(false);

  const handlesave = async () => {
    if (!name || !description || Object.keys(incentivedata).length === 0) {
      setHasError(true);
      setErrorMessage('Please fill in all required fields.');
      return;
    }

    const requestData = {
      name: name,
      description: description,
      incentiveData: incentivedata,
      selectedIdIncentiveStructure: selectedIdIncentiveStructure,
    };

    try {
      await axios.put(`${base_url()}/api/drives/incentive/update`, requestData);
      setIsSuccess(true);
      handleClose();
      setSuccessMessage('Incentive Structure Updated Successfully');
      setFileUploaded(false);
      setName('');
      setDescription('');
    } catch (error) {
      setHasError(true);
      setErrorMessage(
        'while uploading data in incentive structure',
        error.message
      );
    }
  };

  const handleUploadCSV = async (event) => {
    try {
      const file = event.target.files[0];

      if (!file) {
        throw new Error('No file uploaded');
      }

      // Use PapaParse to parse the CSV file
      Papa.parse(file, {
        complete: async (result) => {
          const csvData = result.data;
          if (csvData.length === 0) {
            throw new Error('CSV file is empty');
          }
          if (csvData.length < 13) {
            setHasError(true);
            setErrorMessage('CSV file have less then 13 entries');
            throw new Error('CSV file have less then 13 entries');
          }

          const headers = csvData[0];
          const expectedHeaders = [
            'No. of Caps',
            'Incentive Amount',
            'Prorata',
            'Prorata Message',
            'Incentive Explanation',
          ];

          const isValidHeaders = headers.every((header, index) => {
            return header.trim() === expectedHeaders[index];
          });

          if (!isValidHeaders) {
            setErrorMessage('CSV file headers do not match expected format');
            setHasError(true);
            throw new Error('CSV file headers do not match expected format');
          }

          const filteredData = csvData
            .slice(1)
            .filter((row) =>
              row.every((cell) => cell !== null && cell.trim() !== '')
            );

          const jsonData = filteredData.map((row) => ({
            'No. of Caps': row[0],
            'Incentive Amount': row[1],
            Prorata: row[2],
            'Prorata Message': row[3],
            'Incentive Explanation': row[4],
          }));

          setIncentiveData(jsonData);
          setSuccessMessage('CSV file uploaded and parsed successfully');
          setIsSuccess(true);
          setFileUploaded(true);
        },
        error: (error) => {
          console.error('Error parsing CSV file:', error);
          setErrorMessage('Failed to parse CSV file');
          setHasError(true);
        },
      });
    } catch (error) {
      console.error('Error uploading CSV file:', error);
      setErrorMessage('Failed to upload CSV file');
      setHasError(true);
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: 400,
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Box>
            <h3 style={{ fontSize: '18px', marginBottom: '10px' }}>
              Add New Incentive Structure
            </h3>

            <TextField
              label='Incentive Structure Name'
              fullWidth
              size='small'
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          <Box mt={2}>
            <TextField
              label='Description'
              fullWidth
              size='small'
              required
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: '#E1DDDD',
              marginTop: '10px',
              height: '60px',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid',
            }}
          >
            {fileuploaded ? (
              <div style={{ fontSize: '20px' }}>File Uploaded Successfully</div>
            ) : (
              <label
                htmlFor='upload-csv'
                style={{ fontSize: '20px', cursor: 'pointer' }}
              >
                <AttachFileIcon /> Upload File
              </label>
            )}
            <input
              type='file'
              accept='.csv'
              required
              style={{ display: 'none' }}
              onChange={handleUploadCSV}
              id='upload-csv'
            />
          </Box>
          <Box
            mt={2}
            sx={{
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={handlesave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

function IncentiveStructure_table() {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [data, setData] = useState([]);
  const [selectedIncentive, setSelectedIncentive] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [editopenModal, setEditOpenModal] = useState(false);
  const [selectedIdIncentiveStructure, setSelectedIdIncentiveStructure] =
    useState();

  useEffect(() => {
    fetchincentiveStructure();
  }, []);

  const fetchincentiveStructure = async () => {
    try {
      const response = await axios.get(
        `${base_url()}/api/drives/incentive/structure`
      );
      setIsSuccess(true);
      setData(response?.data);
    } catch (error) {
      setHasError(true);
      setErrorMessage(error);
    }
  };

  const columns = [
    { field: 'id', headerName: 'No.', width: 70 },
    { field: 'name', headerName: 'Internal Name', width: 200 },
    { field: 'description', headerName: 'Description', width: 250 },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          <VisibilityIcon
            sx={{ marginRight: '10px' }}
            onClick={() => handleView(params.row.id)}
          />
          <EditIcon onClick={() => handleEdit(params.row.id)} />
        </div>
      ),
    },
  ];

  const handleView = (id) => {
    console.log(`Viewing incentive with ID: ${id}`);
    const selectedIncentive = data.find((incentive) => incentive.id === id);
    const incentivestructure = JSON.parse(selectedIncentive?.metadata);
    setSelectedIncentive(incentivestructure);
    setOpenModal(true);
  };

  const handleEdit = (id) => {
    console.log(`Editing incentive with ID: ${id}`);
    setSelectedIdIncentiveStructure(id);
    handleEditOpenModal();
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleEditCloseModal = () => {
    setEditOpenModal(false);
  };

  const handleEditOpenModal = () => {
    setEditOpenModal(true);
  };

  return (
    <>
      <IncentiveModal
        open={editopenModal}
        handleClose={handleEditCloseModal}
        selectedIdIncentiveStructure={selectedIdIncentiveStructure}
      />
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            width: '80%',
            maxWidth: '800px',
          }}
        >
          {selectedIncentive && (
            <DataGrid
              rows={selectedIncentive?.map((row, index) => ({
                id: index,
                ...row,
              }))}
              columns={[
                { field: 'No. of Caps', headerName: 'No. of Caps', width: 100 },
                {
                  field: 'Incentive Amount',
                  headerName: 'Incentive Amount',
                  width: 150,
                },
                { field: 'Prorata', headerName: 'Prorata' },
                {
                  field: 'Prorata Message',
                  headerName: 'Prorata Message',
                  width: 200,
                },
                {
                  field: 'Incentive Explanation',
                  headerName: 'Incentive Explanation',
                  width: 200,
                },
              ]}
              pageSize={5}
              autoHeight
            />
          )}
        </div>
      </Modal>

      <div style={{ height: '100%', width: '100%', marginTop: '30px' }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          autoHeight
        />
      </div>
    </>
  );
}

export default IncentiveStructure_table;
