import { Box, FormControl, Grid, TextField, Typography } from '@mui/material';
import React from 'react';

const Education = ({ formValues, formErrors, setFormValues }) => {
  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <Grid
      container
      spacing={2}
      py={2}
      px={2}
      sx={{ background: '#E2EEFE' }}
    >
      <Grid
        item
        xs={12}
        py={2}
      >
        <Typography variant='h6'>Education</Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <FormControl fullWidth>
          <TextField
            type='text'
            name='college'
            label='College'
            value={formValues.college}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <FormControl fullWidth>
          <TextField
            type='text'
            name='branch'
            label='Branch'
            value={formValues.branch}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
        >
          <FormControl fullWidth>
            <TextField
              type='text'
              name='graduation_year'
              label='Graduation Year'
              value={formValues.graduation_year}
              onChange={handleChange}
            />
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Education;
