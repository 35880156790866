export const offerLetterForm = {
  1: {
    id: 1,
    title: 'Payment Link and Amount',
    type: 'radio',
    options: [
      {
        amount: '500',
        CashFree: 'https://payments.cashfree.com/forms/klds',
        RazorPay: 'https://rzp.io/l/g9yHVzA7',
      },
      {
        amount: '1000',
        CashFree: 'https://payments.cashfree.com/forms/jsdt',
        RazorPay: 'https://rzp.io/l/4yRQ2dWG',
      },
      {
        amount: '2000',
        CashFree: 'https://payments.cashfree.com/forms/jsrf',
        RazorPay: 'https://rzp.io/l/u2qt9asg',
      },
      {
        amount: '3000',
        CashFree: 'https://payments.cashfree.com/forms/htsa',
        RazorPay: 'https://rzp.io/l/h3z9Mp2',
      },
      {
        amount: '4000',
        CashFree: 'https://payments.cashfree.com/forms/jass',
        RazorPay: 'https://rzp.io/l/ygdkdHQ0As',
      },
      {
        amount: '5000',
        CashFree: 'https://payments.cashfree.com/forms/jrly',
        RazorPay: 'https://rzp.io/l/YPyAi5cU',
      },
      {
        amount: '6000',
        CashFree: 'https://payments.cashfree.com/forms/sdga',
        RazorPay: 'https://rzp.io/l/AMX9Z1JKJ',
      },
      {
        amount: '7000',
        CashFree: 'https://payments.cashfree.com/forms/kfgy',
        RazorPay: 'https://rzp.io/l/2T0Du17u',
      },
      {
        amount: '8000',
        CashFree: 'https://payments.cashfree.com/forms/olpy',
        RazorPay: 'https://rzp.io/l/U1z5JVCJT',
      },
      {
        amount: '9000',
        CashFree: 'https://payments.cashfree.com/forms/mqnp',
        RazorPay: 'https://rzp.io/l/fc7coJZViJ',
      },
      {
        amount: '10000',
        CashFree: 'https://payments.cashfree.com/forms/dhdh',
        RazorPay: 'https://rzp.io/l/S2YZH9QXCi',
      },
      {
        amount: '11000',
        CashFree: 'https://payments.cashfree.com/forms/elom',
        RazorPay: 'https://rzp.io/l/t3sUoLhjxN',
      },
      {
        amount: '12000',
        CashFree: 'https://payments.cashfree.com/forms/wtlo',
        RazorPay: 'https://rzp.io/l/98ddkVScw',
      },
      {
        amount: '13000',
        CashFree: 'https://payments.cashfree.com/forms/hjfs',
        RazorPay: 'https://rzp.io/l/YoT1TpaKN',
      },
      {
        amount: '14000',
        CashFree: 'https://payments.cashfree.com/forms/frtn',
        RazorPay: 'https://rzp.io/l/iVW0tHj85D',
      },
      {
        amount: '15000',
        CashFree: 'https://payments.cashfree.com/forms/nfas',
        RazorPay: 'https://rzp.io/l/ngLmVuFjP',
      },
      {
        amount: '20000',
        CashFree: 'https://payments.cashfree.com/forms/tnty',
        RazorPay: 'https://rzp.io/l/LIpGcz0ERN',
      },
      {
        amount: '21000',
        CashFree: 'https://payments.cashfree.com/forms/gffd',
        RazorPay: 'https://rzp.io/l/PCj0iNS',
      },
      {
        amount: '30000',
        CashFree: 'https://payments.cashfree.com/forms/ssdc',
        RazorPay: 'https://rzp.io/l/X5ldynElt',
      },
      {
        amount: '34000',
        CashFree: 'https://payments.cashfree.com/forms/ffdr',
        RazorPay: 'https://rzp.io/l/fqBQuBRYgo',
      },
      {
        amount: '40000',
        CashFree: 'https://payments.cashfree.com/forms/ukrf',
        RazorPay: 'https://rzp.io/l/KmZpHjj2',
      },
      {
        amount: '50000',
        CashFree: 'https://payments.cashfree.com/forms/hfoi',
        RazorPay: 'https://rzp.io/l/HHmEKbn',
      },
      {
        amount: '52000',
        CashFree: 'https://payments.cashfree.com/forms/ghsd',
        RazorPay: 'https://rzp.io/l/apDlOv7qE',
      },
      {
        amount: '54000',
        CashFree: 'https://payments.cashfree.com/forms/ggfr',
        RazorPay: 'https://rzp.io/l/xxGDHgoY0Q',
      },
      {
        amount: '60000',
        CashFree: 'https://payments.cashfree.com/forms/cvsd',
        RazorPay: 'https://rzp.io/l/RxHrGqT',
      },
      {
        amount: '65000',
        CashFree: 'https://payments.cashfree.com/forms/bpjn',
        RazorPay: 'https://rzp.io/l/nrF9oFI',
      },
      {
        amount: '70000',
        CashFree: 'https://payments.cashfree.com/forms/ghjf',
        RazorPay: 'https://rzp.io/l/jd0xsTKKNu',
      },
      {
        amount: '80000',
        CashFree: 'https://payments.cashfree.com/forms/uire',
        RazorPay: 'https://rzp.io/l/ZUqfgIKBp',
      },
      {
        amount: '102360',
        CashFree: 'https://payments.cashfree.com/forms/ghjs',
        RazorPay: 'https://rzp.io/l/RSKyEqh',
      },
      {
        amount: '100000',
        CashFree: 'https://payments.cashfree.com/forms/ghsa',
        RazorPay: 'https://rzp.io/l/4yA5U4sN3h',
      },
      {
        amount: '119000',
        CashFree: 'https://payments.cashfree.com/forms/fsda',
        RazorPay: 'https://rzp.io/l/BYpLILMM6M',
      },
      {
        amount: '130000',
        CashFree: 'https://payments.cashfree.com/forms/hrpw',
        RazorPay: 'https://rzp.io/l/gfdPdZLU9j',
      },
      {
        amount: '132000',
        CashFree: 'https://payments.cashfree.com/forms/bbhg',
        RazorPay: 'https://rzp.io/l/ojNArAKBvt',
      },
      {
        amount: '136000',
        CashFree: 'https://payments.cashfree.com/forms/jjku',
        RazorPay: 'https://rzp.io/l/Q3mBOzLhDd',
      },
      {
        amount: '189000',
        CashFree: 'https://payments.cashfree.com/forms/sdfr',
        RazorPay: 'https://rzp.io/l/EG8PiGij',
      },
    ],
  },
  2: {
    id: 2,
    title: 'Payment Methods',
    type: 'radio',
    options: ['CashFree', 'RazorPay', 'Phonepe'],
  },
};
