import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';

const Professional = ({ formValues, formErrors, setFormValues }) => {
  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <Grid
      container
      spacing={2}
      py={2}
      px={2}
      sx={{ background: '#E2EEFE' }}
    >
      <Grid
        item
        xs={12}
        py={2}
      >
        <Typography variant='h6'>Professional Background</Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <FormControl fullWidth>
          <TextField
            type='text'
            name='company'
            label='Company'
            value={formValues.company}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <FormControl fullWidth>
          <TextField
            type='text'
            name='role'
            label='Role'
            value={formValues.role}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <FormControl fullWidth>
          <InputLabel id='experience_label'>Experience</InputLabel>
          <Select
            type='text'
            name='experience'
            label='Experience'
            labelId='experience_label'
            value={formValues.experience}
            onChange={handleChange}
          >
            <MenuItem value=''>Select Option</MenuItem>
            <MenuItem value='student'>Student</MenuItem>
            <MenuItem value='graduated'>Graduated but not working</MenuItem>
            <MenuItem value='working_professional_0_2_exp'>
              Working professional with 0-2 years exp
            </MenuItem>
            <MenuItem value='working_professional_2_5_exp'>
              Working professional with 2-5 years exp
            </MenuItem>
            <MenuItem value='working_professional_5_plus_exp'>
              Working professional with 5+ years exp
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <FormControl fullWidth>
          <TextField
            type='text'
            name='tech_stack'
            label='Tech Stack'
            value={formValues.techStack}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default Professional;
