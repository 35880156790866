import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

const CustomTooltip = ({ text, children, popperProps }) => {
  return (
    <Tooltip
      title={text}
      PopperProps={popperProps}
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export default CustomTooltip;
