/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
  Grid,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {
  getCurrentAdminId,
  splitByUnderscoresAndCapitalize,
} from '../../utils/common';
import { base_url } from '../Mode';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import ImageCropper from './ImageCropper';

const MIN_Dimension = 25;
const PersonalDetailArea = ({
  edit,
  onhandleEdit,
  onsaveEdit,
  personalData,
  about,
}) => {
  const [imagePreview, setImagePreview] = useState('');
  const [imageUpdate, setImageupdate] = useState();
  const [admin, setAdmin] = useState(false);
  const [imgsrc, setimgsrc] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userData, setUserData] = useState({
    username: personalData?.username,
    bio: personalData?.bio,
    pic: personalData?.profile_picture,
  });
  const [otherDetails, setOtherDetails] = useState({
    fname: personalData?.fname,
    lname: personalData?.lname,
    designation: personalData?.designation,
  });
  const [open, setOpen] = React.useState(false);
  const [cropData, setCropData] = useState(null);

  const onCropImage = (crop, imgsrc) => {
    setCropData({ crop, imgsrc });
  };

  const updateDataHandler = async () => {
    setImagePreview();
    onsaveEdit(userData);
  };

  useEffect(() => {
    if (cropData) {
      setImageupdate(cropData.imgsrc);
    }
  }, [cropData]);

  useEffect(() => {
    if (personalData) {
      setAdmin(getCurrentAdminId() === personalData?.id);
    }
  }, [edit, personalData]);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const fileName = file.name.toLowerCase();
    const isImage =
      fileName.endsWith('.jpg') ||
      fileName.endsWith('.jpeg') ||
      fileName.endsWith('.png');

    if (!isImage) {
      return;
    }

    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        console.log(file);
        uploadImage(file);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error during file handling:', error);
    }
  };
  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append('profile', file, file.name);

      const response = await axios.patch(
        `${base_url()}/api/admins/${getCurrentAdminId()}/profile?context=admin-profile`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      setImageupdate(response.data.profile_picture);
    } catch (error) {
      console.log(error);
    }
  };
  const onSelectFile = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const imageElement = new Image();
      const imageurl = reader.result?.toString() || '';
      imageElement.src = imageurl;
      imageElement.addEventListener('load', (event) => {
        const { naturalWidth, naturalHeight } = event.currentTarget;
        if (naturalWidth < MIN_Dimension || naturalHeight < MIN_Dimension) {
          setHasError(true);
          setErrorMessage('Image must be Greater than 150 pixel');
          return setimgsrc('');
        }
      });
      setimgsrc(imageurl);
      setOpen(true);
    });
    reader.readAsDataURL(file);
  };

  const handleOpen = () => {
    onSelectFile();
  };
  const handleClose = () => {
    setOpen(false);
    updateDataHandler();
  };
  const Modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '550px',
    height: '490px',
    padding: '24px',
    bgcolor: 'background.paper',
    border: '3px dotted white',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
    overflow: 'hidden',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const profileimageUrl = () => {
    if (imageUpdate) return imageUpdate;
    else if (imagePreview) return imagePreview;
    else if (userData?.pic) return userData?.pic;
    else
      return 'https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp';
  };

  return (
    <>
      {userData && (
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          // sx={{ width: '100%' }}
        >
          {edit ? (
            <>
              <input
                type='file'
                accept='image/*'
                name='profile'
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id='avatar-upload'
              />
              <label htmlFor='avatar-upload'>
                <Avatar
                  sx={{
                    width: 200,
                    height: 200,
                    cursor: 'pointer',
                  }}
                  name='profile'
                  src={profileimageUrl()}
                />
              </label>
              <Typography
                variant='h5'
                fontWeight={800}
              >
                {otherDetails?.fname + ' ' + otherDetails?.lname}
              </Typography>
              <TextField
                label='username'
                style={{ marginTop: '10px' }}
                placeholder='Enter your username'
                variant='standard'
                required='true'
                value={userData.username}
                onChange={(e) =>
                  setUserData({ ...userData, username: e.target.value })
                }
              ></TextField>
              <Typography
                mt={2}
                variant='subtitle3'
                fontWeight={800}
              >
                {splitByUnderscoresAndCapitalize(otherDetails?.designation)}
              </Typography>
              <Button
                style={{
                  marginTop: '8px',
                  textTransform: 'none',
                }}
                variant='outlined'
                onClick={updateDataHandler}
              >
                Save Changes
              </Button>
            </>
          ) : (
            <>
              <Grid
                container
                justifyContent='flex-end'
              >
                {/* Edit Profile button */}
                {admin && (
                  <Grid item>
                    <EditIcon
                      sx={{
                        textTransform: 'none',
                        // marginTop: '8px',
                        padding: '3px 3px 0 0',
                        margin: '5px 5px 0 0',
                      }}
                      variant='outlined'
                      onClick={onhandleEdit}
                    >
                      {admin && 'Edit Profile'}
                    </EditIcon>
                  </Grid>
                )}
              </Grid>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
              >
                <Box sx={Modalstyle}>
                  <ImageCropper
                    onCropImage={onCropImage}
                    onClose={handleClose}
                    imgSource={imgsrc}
                    uploadImage={uploadImage}
                  />
                </Box>
              </Modal>
              <Box style={{ position: 'relative' }}>
                <Avatar
                  style={{
                    width: 150,
                    height: 150,
                  }}
                  name='profile'
                  alt='Remy Sharp'
                  src={profileimageUrl()}
                />

                {admin && (
                  <>
                    {' '}
                    <input
                      type='file'
                      id='file-input'
                      accept='image/*'
                      onChange={onSelectFile}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor='file-input'>
                      <EditIcon
                        style={{
                          position: 'absolute',
                          right: 0,
                          cursor: 'pointer',
                          textTransform: 'none',
                          backgroundColor: 'black',
                          borderRadius: '50%',
                          color: 'white',
                          bottom: 5,
                          padding: '3px 0 0 0',
                          margin: '5px 20px 0 0',
                          zIndex: 1,
                        }}
                      />
                    </label>
                  </>
                )}
              </Box>
              <Typography
                mt={2}
                variant='h5'
                fontWeight={800}
                sx={{ fontFamily: 'Roboto' }}
              >
                {otherDetails?.fname + ' ' + otherDetails?.lname}
              </Typography>

              <Typography
                variant='subtitle3'
                fontWeight={800}
              >
                {userData.username}
              </Typography>
              <Typography
                mt={2}
                variant='subtitle3'
                fontWeight={800}
                sx={{ fontFamily: 'Roboto' }}
              >
                {splitByUnderscoresAndCapitalize(otherDetails?.designation)}
              </Typography>
              {userData?.designation && (
                <Typography
                  variant='p'
                  fontStyle={'italic'}
                  textAlign={'center'}
                  sx={{ fontFamily: 'Roboto' }}
                >
                  {userData.designation}
                </Typography>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default PersonalDetailArea;
