import React, { useState, useEffect } from 'react';
import {
  currentAdminIsAdmin,
  currentAdminIsFinance,
} from '../../../../utils/common';
import { Button, Stack } from '@mui/material';
import { useCurrentPostSalesLead } from '../../../CurrentPostSalesLeadContext';
import axios from 'axios';
import { base_url } from '../../../Mode';
import SalesClosureForm from './SalesClosureForm';

const hasAllMandatoryFields = (editedForm) => {
  const fields = ['gold_enabled', 'sales_drive', 'sales_owner', 'manager'];

  const hasEmptyField = fields.some(
    (field) => (editedForm[field] ?? '') === ''
  );
  return hasEmptyField;
};

const SalesClosure = ({
  setSalesClosureOpen,
  setSalesClosureData,
  editedForm,
  setEditedForm,
}) => {
  const { leadData, setToastNotificationMessage } = useCurrentPostSalesLead();
  const [hasUpdate, setHasUpdate] = useState(false);

  const handleSave = () => {
    if (Object.keys(editedForm).length === 0) {
      return;
    }

    const form = {
      post_sales_lead_id: leadData.id,
      ...editedForm,
    };
    const ok = hasAllMandatoryFields(form);
    if (ok) {
      setToastNotificationMessage('error', 'All the fields are mandatory.');
      return;
    }

    axios
      .post(`${base_url()}/api/sales-closures`, form)
      .then((res) => {
        setEditedForm(res.data ?? {});
        setSalesClosureData([res.data]); //==============
        setSalesClosureOpen(true);
        setToastNotificationMessage('success', 'Form successfully saved');
      })
      .catch((err) => {
        console.log(err);
        setToastNotificationMessage(
          'error',
          err.response?.data?.message ||
            'An error occured while saving the form updates'
        );
      });
  };

  const handleUpdate = () => {
    if (Object.keys(editedForm).length === 0) {
      return;
    }

    const form = {
      post_sales_lead_id: leadData.id,
      ...editedForm,
    };

    axios
      .patch(`${base_url()}/api/sales-closures/${editedForm.id}`, form)
      .then((res) => {
        setSalesClosureData([res.data]); // ================
        setSalesClosureOpen(true);

        setToastNotificationMessage(
          'success',
          'Form updates successfully saved'
        );
      })
      .catch((err) => {
        console.log(err);
        setToastNotificationMessage(
          'error',
          'An error occured while saving the form updates'
        );
      });
  };

  return (
    <Stack spacing={2}>
      <Stack
        direction='row'
        spacing={1}
      >
        {!editedForm.id && (
          <Button
            variant='contained'
            size='small'
            onClick={handleSave}
            disabled={
              !hasUpdate ||
              Object.keys(editedForm).length === 0 ||
              (!currentAdminIsFinance() && !currentAdminIsAdmin())
            }
          >
            Save
          </Button>
        )}

        {editedForm.id && (
          <Button
            variant='contained'
            size='small'
            onClick={handleUpdate}
            disabled={
              !hasUpdate ||
              Object.keys(editedForm).length === 0 ||
              (!currentAdminIsFinance() && !currentAdminIsAdmin())
            }
          >
            Update
          </Button>
        )}

        <Button
          variant='outlined'
          size='small'
          onClick={(e) => setSalesClosureOpen(true)}
          color='error'
        >
          Cancel
        </Button>
      </Stack>

      <SalesClosureForm
        editedForm={editedForm}
        setEditedForm={setEditedForm}
        setHasUpdate={setHasUpdate}
      />
    </Stack>
  );
};

export default SalesClosure;
