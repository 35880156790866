import { Box, Button, Typography } from '@mui/material';
import BulkImportSalary from './BulkImportSalaries';
import { useState } from 'react';
import SalaryTable from './SalaryTable';

const Salary = ({
  setIsSuccess,
  setHasError,
  setSuccessMessage,
  setErrorMessage,
  isSuccess,
}) => {
  const [openSalaryBulkImportModal, setOpenSalaryBulkImportModal] =
    useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [isAccess, setIsAccess] = useState(false);

  const handleChange = () => {
    setOpenSalaryBulkImportModal((s) => !s);
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <Typography
          sx={{ marginTop: '8px', fontSize: '1.1rem', fontWeight: 'bold' }}
        >
          Employee Salary
        </Typography>
        {isAccess && (
          <Button
            variant='contained'
            onClick={handleChange}
          >
            Import Salary
          </Button>
        )}
      </Box>

      <>
        <BulkImportSalary
          isAccess={isAccess}
          setIsAccess={setIsAccess}
          open={openSalaryBulkImportModal}
          onChange={handleChange}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          setIsSuccess={setIsSuccess}
          setHasError={setHasError}
        />
        <SalaryTable
          isAccess={isAccess}
          setIsAccess={setIsAccess}
          triggerRefresh={triggerRefresh}
          setTriggerRefresh={setTriggerRefresh}
          isSuccess={isSuccess}
        />
      </>
    </>
  );
};
export default Salary;
