import React, { useState } from 'react';
import {
  Typography,
  Stack,
  Divider,
  Box,
  Button,
  IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
  CopyIcon,
  getLeadStatusColorForLeadFunnel,
  splitByUnderscoresAndCapitalize,
} from '../../utils/common';
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
const LeadFunnelTable = ({
  showDropReason,
  showSecondaryDropReason,
  tableData,
}) => {
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleNavigateButtonClick = (value) => {
    const droppedLeadsValue = value.join(',');
    const bucketSearchRedirectwpwithGTS = `/bucket-search?id=${droppedLeadsValue}`;
    return bucketSearchRedirectwpwithGTS;
  };
  const copyLeadIds = (leadIds) => {
    const idsArray = Array.isArray(leadIds) ? leadIds : [leadIds];
    const textToCopy = idsArray.join(', ');
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        setIsSuccess(true);
        setSuccessMessage('Lead IDs copied successfully');
      },
      (err) => {
        setHasError(true);
        setErrorMessage('Failed to copy lead IDs');
      }
    );
  };
  const columns = [
    {
      field: 'funnel',
      headerName: 'Funnel',
      width: 500,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.status === 'dream_selling_need_generation_questioning') {
          params.row.status =
            'dream_selling_done / need_generation_done / questioning_in_tech_done';
        }
        let backgroundColor = getLeadStatusColorForLeadFunnel(
          params.row.status
        );
        if (backgroundColor == 'primary') {
          backgroundColor = '#fff176';
        }
        if (backgroundColor == '#e3f2fd') {
          backgroundColor = '#96d2ff';
        }
        if (backgroundColor == '#ede7f6') {
          backgroundColor = '#dec8ff';
        }
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'left',
              width: '100%',
              height: '100%',
            }}
          >
            <Tooltip
              title={
                <Typography fontSize={15}>
                  {`${
                    params.row.count -
                    params.row.unMovedLeadCount -
                    params.row.droppedCount
                  }
                  leads moved from here to next status`}
                </Typography>
              }
              arrow
              enterTouchDelay={0}
            >
              <div
                style={{
                  width: `${params.row.conversionRate}%`,
                  height: '100%',
                  backgroundColor: backgroundColor,
                  fontWeight: 'bold',
                }}
              />
            </Tooltip>
            {params.row.unMovedLeadPercent && (
              <Tooltip
                title={
                  <Typography fontSize={15}>
                    {`${params.row.unMovedLeadCount} leads have not transitioned
                    from this status.`}
                  </Typography>
                }
                arrow
                enterTouchDelay={0}
                sx={{ fontWeight: 'bold', fontSize: '35px' }}
              >
                <div
                  style={{
                    width: `${params.row.unMovedLeadPercent}%`,
                    height: '100%',
                    backgroundColor: backgroundColor,
                    backgroundImage: [
                      'linear-gradient(90deg, transparent 25%, white 25%, white 50%, transparent 50%, transparent 75%, white 75%, white 100%)',
                      'linear-gradient(180deg, transparent 25%, white 25%, white 50%, transparent 50%, transparent 75%, white 75%, white 100%)',
                    ],
                    backgroundSize: '2px 2px',
                  }}
                />
              </Tooltip>
            )}
            <Tooltip
              title={
                <Typography
                  fontSize={15}
                >{`${params.row.droppedCount} leads have been marked as dropped`}</Typography>
              }
              arrow
              enterTouchDelay={0}
              sx={{ fontSize: '16px' }}
            >
              <div
                style={{
                  width: `${params.row.percentDropped}%`,
                  height: '100%',
                  backgroundColor: '#757575',
                  backgroundImage: [
                    'linear-gradient(90deg, transparent 25%, white 25%, white 50%, transparent 50%, transparent 75%, white 75%, white 100%)',
                    'linear-gradient(180deg, transparent 25%, white 25%, white 50%, transparent 50%, transparent 75%, white 75%, white 100%)',
                  ],
                  backgroundSize: '2px 2px',
                  fontWeight: 'bold',
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      align: 'left',
      headerAlign: 'center',
      valueGetter: (params) =>
        `${splitByUnderscoresAndCapitalize(params.row.status)}`,
    },
    {
      field: 'count',
      headerName: '# Leads',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (e) => (
        <div
          style={{
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {e.row.count}
          <IconButton
            size='small'
            component={Link}
            target='_blank'
            to={handleNavigateButtonClick(e.row.leadIds)}
          >
            <OpenInNewIcon fontSize='small' />
          </IconButton>
          <ContentCopyIcon
            style={{
              marginLeft: '10px',
              color: '#9e9e9e',
              cursor: 'pointer',
              alignSelf: 'center',
              width: '20px',
            }}
            onClick={() => {
              copyLeadIds(e.row.leadIds);
            }}
          ></ContentCopyIcon>
        </div>
      ),
    },
    {
      field: 'conversion_funnel',
      headerName: 'Conversion Funnel',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        if (params.id === tableData[tableData.length - 1].status) {
          return '';
        } else {
          return `${params.row.conversionRate}%`;
        }
      },
    },

    {
      field: 'droppedCount',
      headerName: '# leads dropped',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const isLastRow = params.id === tableData[tableData.length - 1].status;

        if (isLastRow) {
          return '';
        } else {
          return (
            <div
              style={{
                margin: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {params.row.droppedCount}

              <IconButton
                size='small'
                component={Link}
                target='_blank'
                to={handleNavigateButtonClick(params.row.droppedLeadsList)}
              >
                <OpenInNewIcon fontSize='small' />
              </IconButton>

              <ContentCopyIcon
                style={{
                  marginLeft: '20px',
                  color: '#9e9e9e',
                  cursor: 'pointer',
                  alignSelf: 'center',
                  width: '20px',
                }}
                onClick={() => {
                  copyLeadIds(params.row.droppedLeadsList);
                }}
              />
            </div>
          );
        }
      },
    },

    {
      field: 'leads_dropped',
      headerName: '% of leads dropped',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        if (params.id === tableData[tableData.length - 1].status) {
          return '';
        } else {
          return `${params.row.percentDropped}%`;
        }
      },
    },
    {
      field: 'unMovedLeadCount',
      headerName: 'unmoved leads',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        // Check if the current row is the last in the tableData
        const isLastRow = params.id === tableData[tableData.length - 1].status;

        if (isLastRow) {
          return ''; // Return empty content for the last row
        } else {
          return (
            <div
              style={{
                margin: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {params.row.unMovedLeadCount}

              <IconButton
                size='small'
                component={Link}
                target='_blank'
                to={handleNavigateButtonClick(params.row.unMovedLeadList)}
              >
                <OpenInNewIcon fontSize='small' />
              </IconButton>

              <ContentCopyIcon
                style={{
                  marginLeft: '20px',
                  color: '#9e9e9e',
                  cursor: 'pointer',
                  alignSelf: 'center',
                  width: '20px',
                }}
                onClick={() => {
                  copyLeadIds(params.row.unMovedLeadList);
                }}
              />
            </div>
          );
        }
      },
    },

    {
      field: 'unMovedLead_Percent',
      headerName: '% of unmoved leads',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        if (params.id === tableData[tableData.length - 1].status) {
          return '';
        } else {
          return `${params.row.unMovedLeadPercent ?? 0}%`;
        }
      },
    },
    {
      field: 'drop_reasons',
      headerName: 'Drop reasons',
      align: 'center',
      width: 500,
      headerAlign: 'center',
      renderCell: (params) => {
        const { primary, secondary } = params.row;
        return (
          <Stack>
            {Object.keys(primary)
              .map((reason) => ({ reason, count: primary[reason] }))
              .sort((a, b) => b.count - a.count)
              .map(({ reason, count }) => (
                <Stack
                  spacing={1}
                  key={reason}
                >
                  <Typography
                    variant='subtitle2'
                    align='center'
                  >
                    {`${reason} [${count}]`}
                  </Typography>
                </Stack>
              ))}
            {showSecondaryDropReason && (
              <>
                <Divider />
                {Object.keys(secondary).map((reason) => {
                  return (
                    <Typography
                      variant='caption'
                      align='center'
                      sx={{
                        color: '#bdbdbd',
                      }}
                    >
                      {`${reason} [${secondary[reason]}]`}
                    </Typography>
                  );
                })}
              </>
            )}
          </Stack>
        );
      },
    },
  ];
  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      {tableData.length > 0 && (
        <DataGrid
          columnVisibilityModel={{
            drop_reasons: showDropReason,
          }}
          rows={tableData}
          columns={columns}
          getRowId={(row) => row.status}
          autoHeight
          width={'100%'}
          getRowHeight={() => 'auto'}
          sx={{
            '& .MuiDataGrid-cell': {
              paddingTop: 0.8,
              paddingBottom: 0.8,
            },
            "& .MuiDataGrid-cell[data-field='funnel']": {
              padding: 0,
            },
          }}
          disableSelectionOnClick
          hideFooter={true}
        />
      )}
    </>
  );
};
export default LeadFunnelTable;
