import React, { forwardRef, useState, useEffect } from 'react';
import {
  AppBar,
  Tooltip,
  Box,
  Button,
  Dialog,
  IconButton,
  Chip,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  LeadDetailsModal,
  getTechStackProperties,
} from '../users/LeadDetailsModal';
import { LeadDetailsModalV1 } from '../users/LeadDetailsModalV1';
import {
  getLeadStatusColor,
  CopyIcon,
  getStatusLabel,
  currentAdminIsSeniorManager,
  currentAdminIsMarketing,
  currentAdminIsAdmin,
  getCurrentAdminId,
} from '../../utils/common';
import { HotButton } from '../../utils/HotButton';
import { useCurrentLead } from '../CurrentLeadContext';
import ShieldIcon from '@mui/icons-material/Shield';
import CurrentPostSalesLeadComponent from '../CurrentPostSalesLeadContext';

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction='up'
      ref={ref}
      {...props}
    />
  );
});

export function CurrentLeadModalContent({
  handleLeadDetailsDialogClose = null,
}) {
  const { currentLead } = useCurrentLead();
  const [appBarColor, setAppBarColor] = useState('primary');

  useEffect(() => {
    if (currentLead) {
      setAppBarColor(getLeadStatusColor(currentLead.status));
    } else {
      setAppBarColor(getLeadStatusColor(''));
    }
  }, [currentLead]);

  const getLeadIdText = () => {
    return (
      <Typography
        color={'#757575'}
        display={'inline-block'}
        fontSize={'20px'}
      >
        #{currentLead?.lead_id}
      </Typography>
    );
  };

  const getLabelText = () => {
    if (!currentLead.label) return;
    return (
      <Chip
        label={currentLead.label}
        sx={{
          backgroundColor: currentLead.label_color,
          color: currentLead.label_font_color,
          fontSize: '16px',
        }}
      />
    );
  };

  const type = currentLead.techStack ? currentLead.techStack.type : null;
  const { tooltipMessage, color } = getTechStackProperties(type);

  let copyText = [];
  if (currentLead.fname) {
    copyText.push(currentLead.fname);
  }
  if (currentLead.lname) {
    copyText.push(currentLead.lname);
  }

  copyText.push('#' + currentLead.lead_id);
  copyText = copyText.join(' ');
  let LeadIdCopyIcon = CopyIcon(copyText);

  const hotButtonStyle = {
    height: 'auto',
    fontSize: '16px',
  };
  return (
    <>
      <AppBar
        sx={{
          position: 'relative',
          backgroundColor: appBarColor,
          color: '#000',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            display='flex'
            alignItems='center'
            sx={{ borderBottom: '1px solid #e0e0e0', paddingTop: '10px' }}
            gap='20px'
          >
            <Typography
              variant='h4'
              fontWeight={500}
              mb={2}
              display='flex'
              alignItems='center'
              gap='10px'
            >
              {(currentAdminIsMarketing() || currentAdminIsAdmin()) && (
                <Box
                  display='flex'
                  alignItems='center'
                >
                  <Tooltip title={tooltipMessage}>
                    <ShieldIcon
                      sx={{
                        color,
                        marginRight: '1px',
                      }}
                    />
                  </Tooltip>
                </Box>
              )}
              {
                /* Temporary change to don't allow Market Roles to see lead personal details */
                !currentAdminIsMarketing() &&
                  `${currentLead.fname || ''} ${currentLead.lname || ''} `
              }
              {getLeadIdText()}
              {LeadIdCopyIcon}{' '}
              {currentLead?.is_hot === 1 && (
                <HotButton style={hotButtonStyle} />
              )}
            </Typography>
            <Typography
              variant='h5'
              fontWeight={500}
              mb={2}
            >
              {getLabelText()}
            </Typography>
            <Typography
              variant='h5'
              fontWeight={950}
              mb={2}
            >
              {currentLead.status ? (
                getStatusLabel(currentLead.status, false, { fontSize: '16px' })
              ) : (
                <></>
              )}
            </Typography>
          </Box>

          <Box
            display='flex'
            width='fit-content'
            gap='4em'
          >
            {handleLeadDetailsDialogClose && (
              <IconButton
                edge='start'
                color='inherit'
                onClick={() => handleLeadDetailsDialogClose()}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
export function CurrentLeadModal({
  handleLeadDetailsDialogClose,
  openLeadDetailsDialog,
}) {
  return (
    <Dialog
      fullScreen
      open={openLeadDetailsDialog}
      onClose={() => handleLeadDetailsDialogClose()}
      TransitionComponent={Transition}
    >
      <CurrentLeadModalContent
        handleLeadDetailsDialogClose={handleLeadDetailsDialogClose}
      />

      {/* <CurrentPostSalesLeadComponent> */}
      <LeadDetailsModal />
      {/* </CurrentPostSalesLeadComponent> */}
    </Dialog>
  );
}
