import LinkedinLogo from '../../../../images/devicon_linkedin.svg';
const LinkedinIcon = ({ style, link }) => {
  return (
    <>
      <a
        href={link}
        target='_blank'
        rel='noopener noreferrer'
      >
        <img
          style={style}
          name={'icons'}
          alt={'icons'}
          src={LinkedinLogo}
        />
      </a>
    </>
  );
};
export default LinkedinIcon;
