import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LeadFunnelModal from '../../components/lead-funnel/LeadFunnelModal';
import {
  getStatusLabel,
  splitByUnderscoresAndCapitalize,
} from '../../utils/common';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';

function MarketingTabularData({ marketingData }) {
  const [perPage, setPerPage] = useState(50);
  const [copyIds, setCopyIds] = useState([]);
  const [openLeadFunnel, setOpenLeadFunnel] = useState(false);

  const [copiedleadid, setCopiedLeadId] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const copyLeadIds = (leadIds) => {
    const textToCopy = leadIds.join(', ');
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        setIsSuccess(true);
        setSuccessMessage('Lead IDs copied successfully');
      },
      (err) => {
        setHasError(true);
        setErrorMessage('Failed to copy lead IDs');
      }
    );
  };

  const toggleLeadFunnelModal = () => {
    setOpenLeadFunnel((p) => !p);
  };
  const leadmodifier = (lead) => {
    const modifiedLead = lead.replace(/^,|,$/g, '');
    return modifiedLead;
  };

  const columns = [
    {
      field: 'source',
      headerName: 'UTM_Source',
      width: 220,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) =>
        splitByUnderscoresAndCapitalize(params.row.utm_source),
    },
    {
      field: 'medium',
      headerName: 'UTM_Medium',
      width: 230,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) =>
        splitByUnderscoresAndCapitalize(params.row.utm_medium),
    },
    {
      field: 'UTM_campaign',
      headerName: 'Campaign',
      width: 230,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) =>
        splitByUnderscoresAndCapitalize(params.row.utm_campaign),
    },
    {
      field: 'count',
      headerName: 'Count',
      width: 230,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <span style={{ fontSize: '16px' }}>{params.row.count}</span>
          <IconButton
            size='small'
            onClick={(e) => {
              window.open(
                `/bucket-search?id=${params.row.lead_ids.join(',')}`,
                '_blank',
                'noopener'
              );
            }}
            disabled={params.row.count > 1500}
          >
            {params.row.count <= 1500 && <OpenInNewIcon fontSize='small' />}
          </IconButton>

          <IconButton
            size='small'
            onClick={() => copyLeadIds(params.row.lead_ids)}
          >
            <ContentCopyIcon fontSize='small' />
          </IconButton>
          <IconButton
            size='small'
            onClick={() => {
              const leadIds = params.row.lead_ids.join(', ');
              setCopyIds(leadIds);
              toggleLeadFunnelModal();
            }}
          >
            {params.row.count <= 30000 && <FilterAltIcon fontSize='small' />}
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      {openLeadFunnel && (
        <LeadFunnelModal
          isOpen={openLeadFunnel}
          onClose={toggleLeadFunnelModal}
          copyIds={copyIds}
        />
      )}
      <Box
        width={{ xs: '100%', md: '90%', lg: '99%' }}
        paddingTop='10px'
        marginLeft='13px'
        marginRight='13px'
        sx={{ maxHeight: '400px', overflowY: 'auto' }}
      >
        {marketingData.length > 0 && (
          <DataGrid
            rows={marketingData}
            columns={columns}
            pageSize={perPage}
            rowsPerPageOptions={[50, 100]}
            onPageSizeChange={(page) => setPerPage(page)}
            pagination
            autoHeight
            disableSelectionOnClick
            cellClassName={() => 'custom-cell'} // Apply custom class to cells
            sx={{
              border: '1px solid #ddd',
              '& .MuiDataGrid-colCell': {
                borderRight: '1px solid #ddd',
              },
              '& .MuiDataGrid-cell': {
                borderRight: '1px solid #ddd',
              },
              '& .custom-cell': {
                fontSize: '16px', // Customize font size for cells
              },
            }}
          />
        )}

        {marketingData.length === 0 && (
          <Typography
            variant='body1'
            textAlign='center'
          >
            No data found
          </Typography>
        )}
      </Box>
    </>
  );
}

export default MarketingTabularData;
