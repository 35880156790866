import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';

export const Notification10MinConnectModel = React.memo(
  ({
    // socket,
    intervalId,
    notification,
    setOpenAcceptModel,
    setOpenNotificationModel,
    handleAccept,
    handleReject,
    setNotification,
    setIsRejectConnect,
    handelNotificationCounter,
  }) => {
    const [seconds, setSeconds] = useState(10);

    useEffect(() => {
      if (!intervalId && notification) {
        intervalId = setInterval(() => {
          setSeconds((prevSeconds) => {
            if (prevSeconds !== 0) {
              return prevSeconds - 1;
            } else {
              setOpenNotificationModel(true);
              setNotification('');
              clearInterval(intervalId);
              return prevSeconds;
            }
          });
        }, 1000);
      }
      seconds == 0 && handelNotificationCounter();
    }, [notification]);

    return (
      <Box
        sx={{
          backgroundColor: '#F2FBFF',
          padding: '20px',
          width: '380px',
          position: 'fixed',
          left: '20px',
          bottom: '20px',
          borderRadius: '10px',
          border: '1.5px solid #1976D2',
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
          zIndex: '2000',
        }}
      >
        <Typography
          sx={{ fontSize: '26px', fontWeight: '500', textAlign: 'center' }}
        >
          10 min connect
        </Typography>
        <Typography sx={{ fontSize: '16px', textAlign: 'center' }}>
          You have a 10 min connect lead!
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 'auto',
            marginTop: '10px',
            width: '86%',
          }}
        >
          <Box
            sx={{
              width: '80px',
              height: '80px',
              border: '6px solid #1976D2',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '50%',
            }}
          >
            <Typography sx={{ fontSize: '22px', margin: 'auto' }}>
              {seconds <= 9 ? `0${seconds}` : seconds}
            </Typography>
          </Box>
          <Box>
            <Button
              variant='contained'
              sx={{ display: 'block', width: '140px', marginTop: '10px' }}
              onClick={() => {
                setOpenAcceptModel(true);
                setNotification('');
                handleAccept();
                // socket.emit('acceptLead');
              }}
            >
              Accept
            </Button>
            <Button
              color='primary'
              variant='outlined'
              sx={{
                display: 'block',
                width: '140px',
                marginTop: '10px',
                color: 'black',
              }}
              onClick={() => {
                setOpenNotificationModel(true);
                setNotification('');
                setIsRejectConnect(true);
                handleReject();
              }}
            >
              Reject
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
);
