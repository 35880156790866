export const newLeadsColumnsOrder = [
  'fname',
  'lname',
  'email',
  'mobile',
  'whatsapp',
  'lead_source_type',
  'college',
  'branch',
  'graduation_year',
  'company',
  'linkedin_link',
  'github_link',
  'experience',
  'tech_stack',
  'role',
  'current_salary',
  'desired_salary',
  'current_location',
  'total_experience',
  'portfolio_link',
  'internship_experience',
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_term',
  'utm_content',
  'utm_region',
  'utm_adset',
  'utm_campaign_type',
];
