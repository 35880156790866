import React, { useEffect, useState } from 'react';
import { useCSVReader } from 'react-papaparse';
import { Button, Stack } from '@mui/material';
import ImageUploads from './ImageUploads';

export function ReportUploads({
  csvData,
  setCsvData,
  uploadedImages,
  setUploadedImages,
}) {
  const [hasImported, setHasImported] = useState(false);
  const { CSVReader } = useCSVReader();

  useEffect(() => {
    if (csvData.length === 0) {
      setHasImported(false);
    }
    return () => {};
  }, [csvData]);

  return (
    <CSVReader
      onUploadAccepted={(results) => {
        console.log(results);
        if (results.data.length > 0) {
          setCsvData(results.data);
          setHasImported(true);
        }
      }}
    >
      {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
        <Stack
          sx={{ mt: 2 }}
          spacing={5}
        >
          <Stack
            direction='row-reverse'
            spacing={2}
          >
            <Button
              variant='contained'
              {...getRootProps()}
            >
              Import Report
            </Button>
            {acceptedFile && hasImported && (
              <>
                <Button
                  variant='contained'
                  {...getRemoveFileProps()}
                >
                  Remove
                </Button>
              </>
            )}
          </Stack>
          {acceptedFile && hasImported && (
            <>
              <div
                style={{
                  width: 'inherit',
                  border: '1px solid #ccc',
                  height: 45,
                  lineHeight: 2.5,
                  paddingLeft: 10,
                }}
              >
                {acceptedFile.name}
              </div>
              <ProgressBar
                style={{ width: 'inherit', backgroundColor: 'red' }}
              />
              <ImageUploads {...{ uploadedImages, setUploadedImages }} />
            </>
          )}
        </Stack>
      )}
    </CSVReader>
  );
}
