import React from 'react';
import {
  hasFullAccessMode,
  hasBasicAccessMode,
  hasRestrictedAccessMode,
} from '../../../utils/common';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Button } from '@mui/material';

const HelperModal = ({ openHelpModal, setOpenHelpModal }) => {
  return (
    <Dialog
      open={openHelpModal}
      onClose={(e) => setOpenHelpModal(false)}
      fullWidth
      maxWidth='md'
    >
      <Box
        display='flex'
        justifyContent='flex-end'
      >
        <Button onClick={(e) => setOpenHelpModal(false)}>Close</Button>
      </Box>
      <DialogContent>
        <DialogContentText>{getHelpModalDescription()}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const getHelpModalDescription = () => {
  let text = [];
  if (hasFullAccessMode()) {
    text = fullAccessModeDescription;
  } else if (hasRestrictedAccessMode()) {
    text = restrictedAccessModeDescription;
  } else {
    text = basicAccessModeDescription;
  }

  text = [
    ...text,
    ...getLinkedinHelpText(),
    ...getEmailHelpText(),
    ...getPhoneNumberHelpText(),
  ];

  if (hasFullAccessMode() || hasRestrictedAccessMode()) {
    text.push(...getLeadIdsHelpText());
  }
  if (hasFullAccessMode()) {
    text.push(...getLeadNameHelpText());
  }

  return <Stack spacing={2}>{text.map((t) => t)}</Stack>;
};

const getLinkedinHelpText = () => {
  return [
    <Box>
      <Typography
        sx={{ fontWeight: 'bold' }}
        variant='body1'
        component='p'
      >
        Linkedin search
      </Typography>
      <Typography
        variant='body2'
        component='p'
      >
        1. Valid search text: https://www.linkedin.com/in/lbihani9
      </Typography>
      <Typography
        variant='body2'
        component='p'
      >
        2. Any other search text pattern may not give correct results.
      </Typography>
    </Box>,
  ];
};

const getPhoneNumberHelpText = () => {
  return [
    <Box>
      <Typography
        sx={{ fontWeight: 'bold' }}
        variant='body1'
        component='p'
      >
        Phone numbers
      </Typography>
      <Typography
        variant='body2'
        component='p'
      >
        1. Prefer typing phone numbers without country codes to get best
        results.
      </Typography>
    </Box>,
  ];
};

const getEmailHelpText = () => {
  return [
    <Box>
      <Typography
        sx={{ fontWeight: 'bold' }}
        variant='body1'
        component='p'
      >
        Email search
      </Typography>
      <Typography
        variant='body2'
        component='p'
      >
        1. Valid search text: lokeshbihani99@gmail.com
      </Typography>
      <Typography
        variant='body2'
        component='p'
      >
        2. Any other search text pattern may not give correct results.
      </Typography>
    </Box>,
  ];
};

const getLeadIdsHelpText = () => {
  return [
    <Box>
      <Typography
        sx={{ fontWeight: 'bold' }}
        variant='body1'
        component='p'
      >
        Lead ids
      </Typography>
      <Typography
        variant='body2'
        component='p'
      >
        1. Prefix lead ids with "#".
      </Typography>
      <Typography
        variant='body2'
        component='p'
      >
        2. Valid search texts: "#123", "#124"
      </Typography>
      <Typography
        variant='body2'
        component='p'
      >
        3. All other search text pattern will not give correct results.
      </Typography>
    </Box>,
  ];
};

const getLeadNameHelpText = () => {
  return [
    <Box>
      <Typography
        sx={{ fontWeight: 'bold' }}
        variant='body1'
        component='p'
      >
        Lead name
      </Typography>
      <Typography
        variant='body2'
        component='p'
      >
        1. Valid search text: "Pratik", "Aditya", "Adi"
      </Typography>
    </Box>,
  ];
};

const basicAccessModeDescription = [
  <Box>
    <Typography
      variant='body1'
      component='p'
    >
      Leads can be searched using email, linkedin, mobile and whatsapp number.
    </Typography>
    ,
    <Typography
      variant='body1'
      component='p'
    >
      <b>Note:</b> Partial searches are not supported, so please type full
      linkedin url, email id, and phone numbers.
    </Typography>
  </Box>,
  <Typography
    variant='body1'
    component='p'
  >
    Examples:
  </Typography>,
];

const fullAccessModeDescription = [
  <Box>
    <Typography
      variant='body1'
      component='p'
    >
      Leads can be searched using email, linkedin, mobile, whatsapp number, lead
      id, lead name.
    </Typography>
    <Typography
      variant='body1'
      component='p'
    >
      <b>Note:</b> Partial and exact searches are supported.
    </Typography>
  </Box>,
  <Typography
    variant='body1'
    component='p'
  >
    Examples:
  </Typography>,
];

const restrictedAccessModeDescription = [
  <Box>
    <Typography
      variant='body1'
      component='p'
    >
      Leads can be searched using email, linkedin, mobile, whatsapp number and
      lead id.
    </Typography>
    <Typography
      variant='body1'
      component='p'
    >
      <b>Note:</b> Partial searches are not supported, so please type full
      linkedin url, email id, phone numbers and lead id.
    </Typography>
  </Box>,
  <Typography
    variant='body1'
    component='p'
  >
    Examples:
  </Typography>,
];

export default HelperModal;
