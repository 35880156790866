import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
// import AddInteraction from './AddInteraction';
import { useNavigate, useParams } from 'react-router-dom';

const DnpConnect = ({ currentLead, SetIsYesClicked, setRingingTime }) => {
  const [connectedButtonLabel, setConnectedButtonLabel] = useState('Connected');
  const [DNPButtonLabel, setDNPButtonLabel] = useState('DNP');
  const [textValue, setTextValue] = useState("Dial, Don't hesitate");
  const [changeFinalScreen, setChangeFinalScreen] = useState(false);
  const [ShowFinalParagraph, setShowFinalParagraph] = useState(false);
  const [buttonDisplay, setButtonDisplay] = useState('flex');
  const [isConnectBtnClicked, setIsConnectBtnClicked] = useState(false);
  const [isYesClicked, setIsYesClicked] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (changeFinalScreen) {
      setTimeout(() => {
        setButtonDisplay('none');
        setShowFinalParagraph(true);
      }, 10);
    }
  }, [changeFinalScreen, isYesClicked]);

  const changeUiDataHandler = () => {
    if (DNPButtonLabel === 'DNP') {
      setConnectedButtonLabel('Yes');
      setDNPButtonLabel('No');
      setTextValue('dial again');
    }

    if (DNPButtonLabel === 'No') {
      navigate('/bucket-search');
      setChangeFinalScreen(true);
    }
  };

  const connectbtnClickHandler = () => {
    if (connectedButtonLabel === 'Connected') {
      navigate(`/leads/${id}`);
      return;
    }
    if (connectedButtonLabel === 'Yes') {
      SetIsYesClicked(true);
      setRingingTime(0);
      const currentLeadData = currentLead;
      navigate(`/leads/${id}/call-dialing`, {
        state: { currentLeadData },
      });
      setIsYesClicked(true);
      return;
    }
    setIsConnectBtnClicked(true);
  };

  return (
    <>
      {!isConnectBtnClicked && (
        <>
          <Box
            sx={{
              zIndex: '10',
              display: buttonDisplay,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '13px',
              position: 'relative',
              transition: 'all .5s ease',
            }}
          >
            <Box>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='140'
                height='89'
                viewBox='0 0 223 89'
                fill='none'
              >
                <path
                  d='M8.63503 88.3377L53 83.6172C54.7451 83.4315 56.3708 82.6432 57.5972 81.388C58.8237 80.1327 59.5741 78.4892 59.7192 76.7403L61.6479 53.5087C61.8202 51.3836 62.7204 49.383 64.1967 47.8446C65.673 46.3063 67.6349 45.3244 69.7512 45.0649C83.5288 43.5142 97.3856 42.7747 111.25 42.8503C125.114 42.7747 138.971 43.5142 152.749 45.0649C154.865 45.3244 156.827 46.3062 158.303 47.8445C159.78 49.3829 160.68 51.3835 160.852 53.5086L162.781 76.7404C162.926 78.4893 163.676 80.1328 164.903 81.388C166.129 82.6432 167.755 83.4315 169.5 83.6172L213.865 88.3377C214.885 88.4471 215.917 88.3471 216.897 88.0437C217.878 87.7403 218.786 87.24 219.566 86.5735C220.346 85.9069 220.982 85.0881 221.434 84.1672C221.887 83.2463 222.147 82.2427 222.198 81.2178C222.929 69.6224 221.646 57.9878 218.405 46.8305C210.584 22.7034 170.43 0.906706 111.25 0.906706C52.0699 0.906706 11.9158 22.7035 4.09478 46.8305C0.853838 57.9878 -0.429196 69.6224 0.302598 81.2179C0.353697 82.2427 0.613478 83.2463 1.06604 84.1672C1.5186 85.0881 2.15437 85.9069 2.93447 86.5735C3.71457 87.24 4.62253 87.7403 5.60275 88.0437C6.58296 88.347 7.61479 88.4471 8.63503 88.3377Z'
                  fill='white'
                />
              </svg>
            </Box>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontSize: '44px',
                fontStyle: 'normal',
                fontWeight: '700',
                background:
                  'linear-gradient(90deg, #9F6BF5 0%, #C6AEEC 102.86%)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              {textValue}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                gap: '9px',
                mt: '15px',
              }}
            >
              <Box
                clasName={'connectButton'}
                sx={{
                  mt: '12px',
                  display: 'flex',
                  width: '30px',
                  height: '13px',
                  padding: '19.459px 105.405px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '12.331px',
                  flexShrink: 0,
                  borderRadius: '45px',
                  background:
                    'linear-gradient(180deg, rgba(112, 62, 243, 0.60) 0%, rgba(159, 107, 245, 0.60) 100%)',
                  boxShadow: '4.075px 4.075px 20.376px 0px #68499F',
                }}
              >
                <Button
                  sx={{
                    display: 'flex',
                    width: '20px',
                    height: '5px',
                    padding: '19.459px 105.405px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '12.331px',
                    flexShrink: 0,
                    borderRadius: '45px',
                    border: '1.233px solid #FFF',
                    background:
                      'linear-gradient(180deg, #703EF3 0%, #9F6BF5 100%)',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#FFF',
                      fontFamily: 'Inter',
                      fontSize: '22px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      cursor: 'pointer',
                    }}
                    onClick={connectbtnClickHandler}
                  >
                    {connectedButtonLabel}
                  </Typography>
                </Button>
              </Box>
              <Box
                clasName={'dnpButton'}
                sx={{
                  mt: '12px',
                  display: 'flex',
                  width: '30px',
                  height: '13px',
                  padding: '19.459px 105.405px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '12.331px',
                  flexShrink: 0,
                  borderRadius: '45px',
                  background:
                    'linear-gradient(180deg, rgba(112, 62, 243, 0.60) 0%, rgba(159, 107, 245, 0.60) 100%)',
                  boxShadow: '4.075px 4.075px 20.376px 0px #68499F',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '20px',
                    height: '5px',
                    padding: '19.459px 105.405px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '12.331px',
                    flexShrink: 0,
                    borderRadius: '45px',
                    border: '1.233px solid #FFF',
                    background:
                      'linear-gradient(180deg, #703EF3 0%, #9F6BF5 100%)',
                    cursor: 'pointer',
                  }}
                  onClick={changeUiDataHandler}
                >
                  <Typography
                    sx={{
                      color: '#FFF',
                      fontFamily: 'Inter',
                      fontSize: '22px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                    }}
                  >
                    {DNPButtonLabel}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: ShowFinalParagraph ? 'flex' : 'none',
              justifyContent: 'center',
              alignContent: 'center',
              flexDirection: 'column',
              gap: '2rem',
            }}
          >
            <Typography
              sx={{
                color: '#FFF',
                fontFamily: "'Inter',sans-sarif",
                textAlign: 'center',
                fontSize: '54px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: 'normal',
                zIndex: 4,
              }}
            >
              Follow-up has been set.
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Inter', 'sans-sarif'",
                fontSize: '35px',
                fontStyle: 'normal',
                fontWeight: 700,
                textAlign: 'center',
                lineHeight: 'normal',
                background: 'linear-gradient(90deg, #9F6BF5 0%, #C6AEEC 100%)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                zIndex: 4,
              }}
            >
              You’ll be reminded to follow-up.
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default DnpConnect;
