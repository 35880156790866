import React, { useRef } from 'react';
import Confetti from 'react-confetti';
import confettiMusic from '../../assets/confetti-celebration.mp3';

const ConfettiEffect = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const audioRef = useRef(null);

  return (
    <>
      <audio
        ref={audioRef}
        src={confettiMusic}
        autoPlay
      />
      <Confetti
        width={width}
        height={height}
        numberOfPieces={1000}
        recycle={false}
      />
    </>
  );
};

export default ConfettiEffect;
