import React, { useState, createContext, useContext, useCallback } from 'react';
import axios from 'axios';
import { base_url } from './Mode';
import { shouldEnforceFocusMode } from '../utils/common';
import CustomBackdrop from '../pages/CustomBackdrop';
import ErrorNotifier from './ToastNotifications/ErrorNotifier';

const CurrentLeadContext = createContext();

function CurrentLeadComponent({ children }) {
  const [version, setVersion] = useState(0);
  const [currentLead, setCurrentLead] = useState(null);
  const [currentLeadActivities, setCurrentLeadActivities] = useState([]);
  const [currentLeadStatusActivities, setCurrentLeadStatusActivities] =
    useState([]);
  const [currentLeadInteractions, setCurrentLeadInteractions] = useState([]);
  const [currentLeadSignups, setCurrentLeadSignups] = useState([]);
  const [currentLeadEligibilityForms, setCurrentLeadEligibilityForms] =
    useState([]);
  const [leadFocusMode, setLeadFocusMode] = useState(shouldEnforceFocusMode());
  const [leadFocusModeByManual, setLeadFocusModeByManual] = useState(false);
  const [updatedDropReasons, setUpdatedDropReasons] = useState(null);
  const [loading, setLoading] = useState({
    ElgibilityForm: false,
    Signups: false,
    Activities: false,
    Interactions: false,
    Lead: false,
    statusActivity: false,
  });
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getCurrentLeadData = (leadId) => {
    getCurrentLeadAndUpdateContext(leadId);
    getCurrentLeadInteractionsAndUpdateContext(leadId);
    getCurrentLeadActivitiesAndUpdateContext(leadId);
    getCurrentLeadStatusActivitiesAndUpdateContext(leadId);
    getCurrentLeadSignupsAndUpdateContext(leadId);
    getCurrentLeadEligibilityFormsAndUpdateContext(leadId);

    // setTimeout(() => {
    //   setLoading(false);
    // }, 1000);
  };

  const resetCurrentLeadContext = () => {
    setCurrentLead(null);
    setCurrentLeadActivities([]);
    setCurrentLeadStatusActivities([]);
    setCurrentLeadInteractions([]);
    setCurrentLeadSignups([]);
    setCurrentLeadEligibilityForms([]);
  };

  const getCurrentLeadEligibilityFormsAndUpdateContext = useCallback(
    (leadId) => {
      loading.ElgibilityForm = true;

      setLoading(loading);
      const url = base_url() + '/api/users/' + leadId + '/eligibility-forms';
      axios
        .get(url)
        .then((res) => {
          setCurrentLeadEligibilityForms(res.data ?? []);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading.ElgibilityForm = false;

          setLoading(loading);
        });
    },
    [setCurrentLeadEligibilityForms]
  );

  const getCurrentLeadAndUpdateContext = useCallback(
    (leadId) => {
      const url =
        base_url() +
        (version === 0 ? '/api/users/' : '/api/users/V1/') +
        leadId;
      loading.Lead = true;

      setLoading(loading);
      axios
        .get(url)
        .then((res) => {
          const data = res.data;
          data['lead_id'] = data['id'];
          setCurrentLead(data);
          loading.Lead = false;
          setLoading(loading);
        })
        .catch((err) => {
          console.log(err);
          setHasError(true);
          if (err) {
            setErrorMessage(err.response.data.message);
          }
        })
        .finally(() => {
          loading.Lead = false;

          setLoading(loading);
        });
    },
    [setCurrentLead]
  );

  const getCurrentLeadInteractionsAndUpdateContext = useCallback(
    (leadId) => {
      const url = base_url() + '/api/users/' + leadId + '/interactions';
      loading.Interactions = true;
      setLoading(loading);
      axios
        .get(url)
        .then((res) => {
          setCurrentLeadInteractions(res.data ?? []);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading.Interactions = false;
          setLoading(loading);
        });
    },
    [setCurrentLeadInteractions]
  );

  const getCurrentLeadActivitiesAndUpdateContext = useCallback(
    (leadId) => {
      loading.Activities = true;
      setLoading(loading);
      const url = base_url() + '/api/users/' + leadId + '/activities';
      axios
        .get(url)
        .then((res) => {
          setCurrentLeadActivities(res.data ?? []);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading.Activities = false;
          setLoading(loading);
        });
    },
    [setCurrentLeadActivities]
  );

  const getCurrentLeadStatusActivitiesAndUpdateContext = useCallback(
    (leadId) => {
      loading.statusActivity = true;
      setLoading(loading);
      const url = base_url() + '/api/users/' + leadId + '/status-activities';
      axios
        .get(url)
        .then((res) => {
          setCurrentLeadStatusActivities(res.data ?? []);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading.statusActivity = false;
          setLoading(loading);
        });
    },
    [setCurrentLeadStatusActivities]
  );

  const getCurrentLeadSignupsAndUpdateContext = useCallback(
    (leadId) => {
      loading.Signups = true;
      setLoading(loading);
      const url = base_url() + '/api/users/' + leadId + '/signups';
      axios
        .get(url)
        .then((res) => {
          setCurrentLeadSignups(res.data ?? []);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading.Signups = false;
          setLoading(loading);
        });
    },
    [setCurrentLeadSignups]
  );

  return (
    <CurrentLeadContext.Provider
      value={{
        loading,
        setVersion,
        currentLead,
        setCurrentLead,
        leadFocusMode,
        setLeadFocusMode,
        leadFocusModeByManual,
        setLeadFocusModeByManual,
        currentLeadActivities,
        currentLeadStatusActivities,
        currentLeadInteractions,
        currentLeadSignups,
        setCurrentLeadSignups,
        currentLeadEligibilityForms,
        getCurrentLeadAndUpdateContext,
        getCurrentLeadInteractionsAndUpdateContext,
        getCurrentLeadActivitiesAndUpdateContext,
        getCurrentLeadStatusActivitiesAndUpdateContext,
        getCurrentLeadSignupsAndUpdateContext,
        getCurrentLeadEligibilityFormsAndUpdateContext,
        getCurrentLeadData,
        resetCurrentLeadContext,
        updatedDropReasons,
        setUpdatedDropReasons,
      }}
    >
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <CustomBackdrop open={loading} />

      {children}
    </CurrentLeadContext.Provider>
  );
}

export default CurrentLeadComponent;
export const useCurrentLead = () => useContext(CurrentLeadContext);
