import { Box, Button, Typography } from '@mui/material';
import BulkImportSalary from './BulkImportSalaries';
import { useState } from 'react';
import BaseSalaryTable from './BaseSalaryTable';

const BaseSalary = ({
  setIsSuccess,
  setHasError,
  setSuccessMessage,
  setErrorMessage,
  isSuccess,
}) => {
  const [openSalaryBulkImportModal, setOpenSalaryBulkImportModal] =
    useState(false);
  const [isAccess, setIsAccess] = useState(false);
  const [isBaseSalary, setIsBaseSalary] = useState(true);

  const handleChange = () => {
    setOpenSalaryBulkImportModal((s) => !s);
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <Typography
          sx={{ marginTop: '8px', fontSize: '1.1rem', fontWeight: 'bold' }}
        >
          Employee Base Salaries
        </Typography>
        {isAccess && (
          <Button
            variant='contained'
            onClick={handleChange}
          >
            Import Salary
          </Button>
        )}
      </Box>

      <>
        <BulkImportSalary
          isBaseSalary={isBaseSalary}
          isAccess={isAccess}
          setIsAccess={setIsAccess}
          open={openSalaryBulkImportModal}
          onChange={handleChange}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          setIsSuccess={setIsSuccess}
          setHasError={setHasError}
        />
        <BaseSalaryTable
          isAccess={isAccess}
          setIsAccess={setIsAccess}
          isSuccess={isSuccess}
        />
      </>
    </>
  );
};
export default BaseSalary;
