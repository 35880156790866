import CloseIcon from '@mui/icons-material/Close';
const CustomCloseIcon = ({ style, onClick }) => {
  return (
    <>
      <CloseIcon
        onClick={onClick}
        sx={style}
      />
    </>
  );
};
export default CustomCloseIcon;
