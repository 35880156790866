import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import React from 'react';
import constants from '../../../utils/constants';
const {
  EXPECTED_UNIQUE_LEADS,
  EXPECTED_CPE,
  EXPECTED_AAP,
  EXPECTED_OLS,
  EXPECTED_TAP,
  EXPECTED_CAP,
} = constants;

const ProductivityInsightsSummaryTable = ({ currentCount }) => {
  function createDataForSummary(name, expected, current, action) {
    return { name, expected, current, action };
  }

  const rowsForSummary = [
    createDataForSummary(
      'Unique Leads',
      EXPECTED_UNIQUE_LEADS,
      currentCount.unique_leads,
      currentCount.ul_action
    ),
    createDataForSummary(
      'Complete Program Explained',
      EXPECTED_CPE,
      currentCount.complete_program_explained,
      currentCount.cpe_action
    ),
    createDataForSummary(
      'Eligibility Form Filled',
      EXPECTED_AAP,
      currentCount.eligibility_form_filled,
      currentCount.aap_action
    ),
    createDataForSummary(
      'Offer Letter Sent',
      EXPECTED_OLS || 0,
      currentCount.offer_letter_sent,
      '-'
    ),
    createDataForSummary(
      'Token Amount Paid',
      EXPECTED_TAP,
      currentCount.token_amount_paid,
      '-'
    ),
    createDataForSummary(
      'Complete Amount Paid',
      EXPECTED_CAP || 0,
      currentCount.complete_amount_paid,
      '-'
    ),
  ];
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Metric</TableCell>
              <TableCell align='left'>Expected</TableCell>
              <TableCell align='left'>Current</TableCell>
              <TableCell align='left'>Suggested Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsForSummary.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  sx={{ width: 100, align: 'left' }}
                  component='th'
                  scope='row'
                >
                  {row.name}
                </TableCell>
                <TableCell sx={{ width: 100, align: 'left' }}>
                  {row.expected}
                </TableCell>
                <TableCell sx={{ width: 100, align: 'left' }}>
                  {row.current}
                </TableCell>
                <TableCell sx={{ width: 250, align: 'left' }}>
                  {row.action}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default ProductivityInsightsSummaryTable;
