import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  IconButton,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useCurrentPostSalesLead } from '../../../CurrentPostSalesLeadContext';

const ProfileDetails = ({ setHandleProfileEditClose }) => {
  const { leadData } = useCurrentPostSalesLead();
  const { sales_handover } = leadData;

  const getEditIcon = () => {
    return (
      <IconButton
        onClick={() => setHandleProfileEditClose(true)}
        size='medium'
        sx={{
          float: 'right',
          mr: 1,
          color: 'black',
          display: 'inline-table',
        }}
      >
        <EditIcon />
      </IconButton>
    );
  };

  const detailsNotAvailable = () => {
    return (
      <span
        style={{
          color: '#cecece',
        }}
      >
        Unavailable
      </span>
    );
  };

  const getValue = (id) => {
    const index = (sales_handover ?? []).findIndex((item) => item.id === id);
    let value;
    if (index === -1) {
      value = detailsNotAvailable();
    } else if (sales_handover[index].value !== '') {
      value = sales_handover[index].value;
    } else {
      value = detailsNotAvailable();
    }
    return value;
  };

  const getDuration = (id) => {
    const programTypeId = 6; // check here: client/src/utils/salesHandoverForm.js
    const index = (sales_handover ?? []).findIndex(
      (item) => item.id === programTypeId
    );

    if (index !== -1 && sales_handover[index].value === 'Super 30') {
      return 4 + ' Months';
    }

    const value = getValue(id);
    return typeof value === 'object' ? value : value + ' Months';
  };

  const getResume = () => {
    const value = getValue(14);
    if (typeof value !== 'string') {
      return value;
    }

    return (
      <Stack
        sx={{
          padding: '1px',
        }}
        alignContent='center'
      >
        <Typography
          variant='body2'
          sx={{
            cursor: 'pointer',
            color: 'blue',
            textAlign: 'left',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={(e) => {
            window.open(value, '_blank', 'noopener');
          }}
        >
          View
        </Typography>
      </Stack>
    );
  };

  const getDiscord = () => {
    return getValue(16);
  };

  const getLeetcode = () => {
    return getValue(15);
  };

  const getNotes = () => {
    return (
      <Typography variant='body2'>
        <b>*Note:</b> Following lead details are mandatory to initiate finance
        handover:{' '}
        <span style={{ fontStyle: 'italic' }}>
          fname, email, mobile, whatsapp, graduation year, experience
        </span>
        .
      </Typography>
    );
  };

  const profileDetails = [
    {
      label: 'Programming Language',
      value: getValue(8),
    },
    {
      label: 'Work Experience',
      value: getValue(11),
    },
    {
      label: 'Job Role',
      value: getValue(12),
    },
    {
      label: 'Notice Period (Days) Mandatory if Working Professional',
      value: getValue(13),
    },
    {
      label: 'Resume link',
      value: getResume(),
    },
    {
      label: 'Leetcode',
      value: getLeetcode(),
    },
    {
      label: 'Discord',
      value: getDiscord(),
    },
    {
      label: 'Learning',
    },
    {
      label: 'Program Type',
      value: getValue(6),
    },
    {
      label: 'Duration',
      value: getDuration(7),
    },
    {
      label: 'Cohort proposed',
      value: getValue(17),
    },
    {
      label: 'Comment',
      value: getValue(18),
    },
  ];

  const getRow = (label, value) => {
    if (label === 'Learning') {
      return (
        <Typography
          sx={{
            fontSize: 25,
            mt: 1,
            mb: 1,
          }}
        >
          Learning
        </Typography>
      );
    }
    return (
      <TableRow key={label}>
        <TableCell
          sx={{
            width: '30%',
            padding: '5px 10px',
            border: '1px solid #e0e0e0',
            fontSize: '1rem',
            p: 1,
          }}
        >
          {label}
        </TableCell>
        <TableCell
          sx={{
            width: '70%',
            padding: '5px 10px',
            border: '1px solid #e0e0e0',
            fontSize: '1rem',
            p: 1,
          }}
        >
          {value}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      {getNotes()}
      <Table sx={{ width: '90%' }}>
        <TableHead>
          <TableRow sx={{ border: '1px solid #e0e0e0' }}>
            <TableCell
              colSpan={2}
              display={'flex'}
              justifyContent='space-between'
            >
              <Typography
                fontSize={'20px'}
                display={'inline-table'}
              >
                Profile
              </Typography>
              {getEditIcon()}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {profileDetails.map((row) => getRow(row.label, row.value))}
        </TableBody>
      </Table>
    </>
  );
};

export default ProfileDetails;
