import React from 'react';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';

const CustomDropdown = ({
  value,
  options,
  inputAttributes,
  inputStyle,
  onChange,
  placeholder,
}) => {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id='social'>{placeholder}</InputLabel>
        <Select
          labelId='social'
          id='social'
          label={placeholder}
          onChange={onChange}
        >
          {options.map((option) => (
            <MenuItem
              key={option?.value}
              value={option?.value}
            >
              {option?.icon} {option?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default CustomDropdown;
