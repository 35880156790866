import { Box } from '@mui/material';
const shapeStyles = { width: 30, height: 30 };
const shapeCircleStyles = { borderRadius: '50%' };

const CustomBadge = ({ badgeText }) => {
  return (
    <Box
      sx={{
        ...shapeStyles,
        ...shapeCircleStyles,
        backgroundColor: '#f44336', // Primary color
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 1,
      }}
    >
      {badgeText}
    </Box>
  );
};
export default CustomBadge;
