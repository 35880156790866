import MuiButton from '@mui/material/Button';

const CallButton = () => {
  return (
    <MuiButton
      display='inline-block'
      style={{
        marginLeft: '0px',
        height: '30px',
        backgroundColor: '#ff7f00',
        borderRadius: 5,
      }}
      sx={{
        mt: '-1rem',
        mb: '2rem',
        padding: '10px 20px',
        height: '40px',
        borderRadius: '5px',
        background:
          'linear-gradient(90deg, rgba(111, 0, 255, 0.60) 0%, #6F00FF 100%)',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        borderColor: '#4285f4',
        borderWidth: 1,
        color: 'white',
        fontSize: '15px',
        fontWeight: 600,
        transition: 'background 0.3s ease',
        '&:hover': {
          background: 'linear-gradient(to right, #6f00ff, #9a4cff)',
        },
      }}
    >
      Call now
    </MuiButton>
  );
};

export default CallButton;
