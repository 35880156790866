import InstagramLogo from '../../../../images/skill-icons_instagram.svg';
const InstagramIcon = ({ style, link }) => {
  return (
    <>
      <a
        href={link}
        target='_blank'
        rel='noopener noreferrer'
      >
        <img
          style={style}
          name={'icons'}
          alt={'icons'}
          src={InstagramLogo}
        />
      </a>
    </>
  );
};
export default InstagramIcon;
