import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { base_url } from '../../components/Mode';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import PortalUsers from '../Admins/AdminDashboard/PortalUsers';
import Holidays from '../Admins/Holidays/Holidays';
import LeadAccessPolicies from '../Admins/LeadAccessPolicies/LeadAccessPolicies';
import {
  currentAdminIsHr,
  currentAdminIsTa,
  getCurrentAdminRole,
} from '../../utils/common';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function HrDashboard() {
  const [roles, setRoles] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    fetchAllRoles();
    return () => {};
  }, []);
  function fetchAllRoles() {
    axios
      .get(`${base_url()}/api/roles`)
      .then((res) => {
        setRoles(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not fetch roles.'
        );
      });
  }

  const handleTabChange = (e, newTabValue) => {
    setCurrentTab(newTabValue);
  };

  return (
    <Box sx={{ mr: 5 }}>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <Box
        sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '-2.5rem' }}
      >
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
        >
          {!currentAdminIsTa() && <Tab label='Users' />}
          <Tab label='Holidays' />
          <Tab label='Lead Access Policies' />
        </Tabs>
      </Box>
      {!currentAdminIsTa() && (
        <TabPanel
          value={currentTab}
          index={0}
        >
          <PortalUsers
            {...{
              roles,
              setRoles,
              setIsSuccess,
              setHasError,
              setSuccessMessage,
              setErrorMessage,
            }}
          />
        </TabPanel>
      )}
      <TabPanel
        value={currentTab}
        index={currentAdminIsTa() ? 0 : 1}
      >
        <Holidays />
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={currentAdminIsTa() ? 1 : 2}
      >
        <LeadAccessPolicies />
      </TabPanel>
    </Box>
  );
}
