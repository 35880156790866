import {
  Stack,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useAllAdmins } from '../../../AdminsContext';
import { base_url } from '../../../Mode';
import { useCurrentPostSalesLead } from '../../../CurrentPostSalesLeadContext';
import {
  currentAdminIsAdmin,
  currentAdminIsFinance,
} from '../../../../utils/common';
import constants from '../../../../utils/constants';
const { FINANCE_HANDOVER_INITIATED, PGM_HANDOVER_REJECTED } = constants;

const SalesClosureForm = ({ editedForm, setEditedForm, setHasUpdate }) => {
  const { allAdmins } = useAllAdmins();
  const { leadData } = useCurrentPostSalesLead();
  const [drives, setDrives] = useState([]);

  useEffect(() => {
    axios
      .get(`${base_url()}/api/drives`)
      .then((res) => {
        setDrives(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {};
  }, []);

  function getAdminUsers(users) {
    const manager = [];
    const allAdminUsers = [];
    for (const user of users) {
      if (user.roles && user.roles.length > 0) {
        if (user.access_type !== 'disabled') {
          const hasAdminRole = user.roles.some((role) => role.name === 'lead');
          allAdminUsers.push(user);
          if (hasAdminRole) {
            manager.push(user);
          }
        }
      }
    }
    return { manager, allAdminUsers };
  }

  const shouldDisable = () => {
    return (
      (!currentAdminIsFinance() && !currentAdminIsAdmin()) ||
      (leadData.status !== FINANCE_HANDOVER_INITIATED &&
        leadData.status !== PGM_HANDOVER_REJECTED)
    );
  };

  const { manager, allAdminUsers } = getAdminUsers(allAdmins);

  return (
    <>
      <Stack spacing={2}>
        <FormControl
          fullWidth
          sx={{
            width: '100%',
            padding: '10px',
          }}
          disabled={shouldDisable()}
        >
          <FormLabel id='gold_enabled'>Gold enabled</FormLabel>
          <RadioGroup
            name='radio-buttons-group'
            onChange={(e) => {
              setEditedForm((prev) => {
                return {
                  ...prev,
                  gold_enabled: e.target.value,
                };
              });
              setHasUpdate(true);
            }}
            value={editedForm.gold_enabled ?? ''}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label='Yes'
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label='No'
            />
          </RadioGroup>
        </FormControl>

        <FormControl
          fullWidth
          sx={{
            width: '100%',
            padding: '10px',
          }}
        >
          <InputLabel id='sales_drive'>Sales drive</InputLabel>
          <Select
            type='text'
            name='sales_drive'
            label='sales_drive'
            labelId='sales_drive'
            value={editedForm.sales_drive ?? ''}
            onChange={(e) => {
              setEditedForm((prev) => {
                return {
                  ...prev,
                  sales_drive: Number(e.target.value),
                };
              });
              setHasUpdate(true);
            }}
            disabled={shouldDisable()}
          >
            {drives.map((drive) => {
              return <MenuItem value={drive.id}>{drive.name}</MenuItem>;
            })}
          </Select>
        </FormControl>

        {/* 
          Todo (Ankur): This should be an autocomplete dropdown.
        */}
        <FormControl
          fullWidth
          sx={{
            width: '100%',
            padding: '10px',
          }}
        >
          <InputLabel id='sales_owner'>Sales owner</InputLabel>
          <Select
            type='text'
            name='sales_owner'
            label='sales_owner'
            labelId='sales_owner'
            value={editedForm.sales_owner ?? ''}
            onChange={(e) => {
              setEditedForm((prev) => {
                return {
                  ...prev,
                  sales_owner: Number(e.target.value),
                };
              });
              setHasUpdate(true);
            }}
            disabled={shouldDisable()}
          >
            {allAdminUsers.map((admin) => {
              return (
                <MenuItem
                  value={admin.id}
                >{`${admin.fname} ${admin.lname}`}</MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {/* 
          Todo (Ankur): This should be an autocomplete dropdown.
        */}
        <FormControl
          fullWidth
          sx={{
            width: '100%',
            padding: '10px',
          }}
        >
          <InputLabel id='manager'>Manager</InputLabel>
          <Select
            type='text'
            name='manager'
            label='manager'
            labelId='manager'
            value={editedForm.manager ?? ''}
            onChange={(e) => {
              setEditedForm((prev) => {
                return {
                  ...prev,
                  manager: e.target.value,
                };
              });
              setHasUpdate(true);
            }}
            disabled={shouldDisable()}
          >
            {manager.map((manager) => {
              return (
                <MenuItem value={manager.id}>
                  {`${manager.fname} ${manager.lname}`}{' '}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Stack>
    </>
  );
};

export default SalesClosureForm;
