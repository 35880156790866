import React from 'react';
import {
  Button,
  OutlinedInput,
  Checkbox,
  Chip,
  Box,
  ListItemText,
  MenuProps,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

function CandidateProfessionalBackground({ filters, handleChange }) {
  const workExperienceMenuItems = () => {
    const menuItems = [];

    for (let years = 0; years <= 15; years += 0.25) {
      const formattedYears = parseFloat(years.toFixed(2));
      const yearPart = Math.floor(years);
      const monthPart = ((years % 1) * 12).toFixed(0);

      const label = `${yearPart} Year${
        yearPart > 1 ? 's' : ''
      } ${`${monthPart} Month${monthPart > 1 ? 's' : ''}`}`;

      menuItems.push(
        <MenuItem
          key={formattedYears}
          value={formattedYears}
        >
          {label}
        </MenuItem>
      );
    }

    return menuItems;
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          variant='h6'
          component='p'
          style={{
            fontSize: '14px',
            marginLeft: '17px',
          }}
        >
          Professional Background
        </Typography>
        <Divider
          style={{
            margin: '0 16px',
            flexGrow: 1,
          }}
        />
      </div>
      <AccordionDetails>
        <Grid
          container
          spacing={0.8}
          direction={{
            xs: 'column',
            sm: 'row',
          }}
        >
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
          >
            <FormControl
              id='work_experience'
              size='small'
              fullWidth
            >
              <InputLabel id='work_experience_label'>
                Work Experience
              </InputLabel>
              <Select
                name='work_experience'
                label='work_experience'
                labelId='work_experience'
                onChange={(e) => handleChange(e)}
                value={filters?.work_experience}
              >
                {workExperienceMenuItems()}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={3}
            sm={3}
            md={3}
          >
            <FormControl
              id='current_company'
              fullWidth
            >
              <TextField
                size='small'
                type='text'
                name='current_company'
                placeholder='Current Company'
                onChange={(e) => handleChange(e)}
                value={filters?.current_company}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
          >
            <FormControl
              id='current_role'
              fullWidth
            >
              <TextField
                size='small'
                type='text'
                name='current_role'
                placeholder='Current Role'
                onChange={(e) => handleChange(e)}
                value={filters?.current_role}
              />
            </FormControl>
          </Grid>
        </Grid>
      </AccordionDetails>
    </div>
  );
}

export default CandidateProfessionalBackground;
