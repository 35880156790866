import {
  Stack,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ConfirmationModal } from './ConfirmationModal';
import { useState } from 'react';

const ExplainRecordedAudio = ({
  onRecordAgain,
  onSubmit,
  audioBlob,
  isSubmit,
}) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);

  return (
    <Stack marginBottom={4}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
        gap={3}
      >
        {audioBlob ? (
          <Box mt={3}>
            <audio
              name='audioFile'
              controls
            >
              <source
                src={URL.createObjectURL(audioBlob)}
                type='audio/wav'
              />
              Your browser does not support the audio format.
            </audio>
          </Box>
        ) : (
          <Typography
            variant='p'
            fontSize={'14px'}
            textAlign={'center'}
            component='div'
            gutterBottom
          >
            No recorded audio available.
          </Typography>
        )}

        <Box mt={3}>
          <Button
            variant='outlined'
            style={{
              width: '200px',
              height: '50px',
              color: 'black',
              borderColor: 'black',
            }}
            startIcon={<KeyboardVoiceIcon />}
            onClick={() => setOpenConfirmation(true)}
          >
            Record Again
          </Button>
        </Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {isSubmit ? (
            <Box mt={2}>
              <CircularProgress />
            </Box>
          ) : (
            <Button
              variant='contained'
              style={{
                width: '150px',
                height: '35px',
                marginTop: '25px',
              }}
              color='primary'
              onClick={onSubmit}
            >
              Submit
            </Button>
          )}
        </Box>
      </Box>

      {openConfirmation && (
        <ConfirmationModal
          openConfirmation={openConfirmation}
          setOpenConfirmation={setOpenConfirmation}
          handleAccept={onRecordAgain}
          massage={'Do you want to record again?'}
        />
      )}
    </Stack>
  );
};

export default ExplainRecordedAudio;
