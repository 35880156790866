import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { base_url } from '../../Mode';
import { Box } from '@mui/system';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import { DataGrid } from '@mui/x-data-grid';
import {
  currentAdminIsSeniorManager,
  getCurrentAdminId,
  getStatusLabel,
} from '../../../utils/common';
import { Button, Stack, TextField, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { handleNotification } from '../../../pages/Dashboard';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@chakra-ui/react';
import { useSocket } from '../../../socket/socketContext';
import constants from '../../../utils/constants';
import TenMinConnectTable from './10MinConnectTable';

export let getAllQuickLead;

export default function Search10minConnect() {
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [quickLeadData, setQuickLeadData] = useState([]);
  const [leadId, setLeadId] = useState('');
  const [claimedAt, setClaimedAt] = useState('');
  const [loading, setLoading] = useState(false);
  const [shouldDisableButton, setShouldDisableButton] = useState();
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [triggerRefreshFor10MinConnect, setTriggerRefreshFor10MinConnect] =
    useState(false);
  const [socket] = useSocket();
  const [isIcOnlineMessage, setIsIcOnlineMessage] = useState('');
  const [isIcOnline, setIsIcOnline] = useState(true);

  const triggerRefreshing = () => {
    setTriggerRefreshFor10MinConnect((prevValue) => !prevValue);
  };

  useEffect(() => {
    if (socket) {
      socket.on('10MinConnectAccepted', () => {
        triggerRefreshing();
      });
    }
    if (socket) {
      socket.on('isIcOnline10Minute', (message) => {
        console.log(message);
      });
    }
    return () => {
      if (socket) {
        socket.off('10MinConnectAccepted');
      }
    };
  }, [socket]);

  getAllQuickLead = () => {
    axios(
      `${base_url()}/api/lead_claims?lead_id=${leadId}&claimed_at=${claimedAt}`
    )
      .then((res) => {
        setQuickLeadData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const canSendLead = () => {
    axios
      .get(`${base_url()}/api/is_lead_pending`)
      .then((res) => {
        setShouldDisableButton(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleAddLeadClaims = (body) => {
  //   setLoading(true);
  //   axios
  //     .post(`${base_url()}/api/lead_claims`, body)
  //     .then((res) => {
  //       setLoading(false);
  //       if (res.data.id) {
  //         console.log(res.data.id);
  //         handleNotification(body, res.data.id);
  //       }
  //       triggerRefreshing();
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    getAllQuickLead();
    canSendLead();
  }, [claimedAt, leadId, triggerRefreshFor10MinConnect]);

  useEffect(() => {
    searchLead();
    return () => {};
  }, [searchText]);

  const searchLead = () => {
    axios
      .get(
        `${base_url()}/api/users/search?search_text=${encodeURIComponent(
          searchText.includes('#') ? searchText : `#${searchText}`
        )}`
      )
      .then((res) => {
        if (res.data[0]) {
          setSearchResults(res.data[0]);
        } else {
          setSearchResults({});
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't search for the user"
        );
      });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    socket.emit('searchField10MinuteEvent', { searchText });
  };

  useEffect(() => {
    if (!socket) return;

    socket.on('searchFieldResponse', ({ isEmpty, loopRunning }) => {
      setShouldDisableButton(loopRunning);
      if (isEmpty) {
        setIsIcOnline(true);
      } else {
        setIsIcOnlineMessage(
          `No IC's are currently available. Please try again later.`
        );
        setIsIcOnline(false);
      }
    });

    return () => {
      socket.off('searchFieldResponse');
    };
  }, [socket]);

  const checkAssigneeAndStatus = () => {
    if (
      searchResults.assignee === 1 ||
      searchResults.assignee === 109 ||
      searchResults.assignee === 482 ||
      searchResults.assignee === 206 ||
      searchResults.assignee === 101
    ) {
      return false;
    } else if (searchResults.status !== 'created') {
      return true;
    }
  };

  return (
    <Box>
      <Stack>
        {hasError && (
          <ErrorNotifier {...{ message: errorMessage, setHasError }} />
        )}
        <TextField
          placeholder='Search Lead by Id'
          variant='standard'
          value={searchText}
          onChange={(e) => handleSearch(e)}
          InputProps={{
            startAdornment: (
              <SearchIcon sx={{ color: 'gray', marginRight: '10px' }} />
            ),
          }}
          sx={{
            width: '100%',
            '& .MuiInput-underline:after': {
              borderBottom: 'none',
            },
            '& .MuiInput-underline:before': {
              borderBottom: 'none',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottom: 'none',
            },
            border: '1px solid gray',
            padding: '3px 10px 3px 10px',
            borderRadius: '6px',
          }}
        />

        {searchResults.id ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px',
              padding: '16px',
              borderRadius: '4px',
              border: '1px solid gray',
              alignItems: 'center',
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '10px' }}>
                Lead Name
              </Typography>
              <Typography>{searchResults.name}</Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '10px' }}>
                Lead ID
              </Typography>
              <Typography>{searchResults.id}</Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '10px' }}>
                Mobile No.
              </Typography>
              <Typography>{searchResults.mobile}</Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '10px' }}>
                Status
              </Typography>
              <Typography>{getStatusLabel(searchResults.status)}</Typography>
            </Box>
            <Box>
              <Tooltip
                title={
                  window.location.host !== constants.SALES2_HOST_NAME
                    ? 'You can only send 10 Min Connect through sales only'
                    : checkAssigneeAndStatus()
                    ? '10 minutes only allowed for leads with status "created"'
                    : isIcOnline
                    ? shouldDisableButton
                      ? 'Another 10 minute connect is already active.'
                      : 'A 10 min connect claim is pending already. Please wait for some time.'
                    : isIcOnlineMessage
                }
                open={
                  (window.location.host !== constants.SALES2_HOST_NAME ||
                    shouldDisableButton ||
                    !isIcOnline ||
                    searchResults.status !== 'created') &&
                  isTooltipOpen
                }
                placement='top'
              >
                <div
                  onMouseEnter={() => setTooltipOpen(true)}
                  onMouseLeave={() => setTooltipOpen(false)}
                >
                  <Button
                    disabled={
                      window.location.host !== constants.SALES2_HOST_NAME ||
                      !isIcOnline ||
                      shouldDisableButton ||
                      checkAssigneeAndStatus()
                    }
                    variant='contained'
                    onClick={() => {
                      const body = {
                        lead_id: searchResults.id,
                        sent_by: getCurrentAdminId(),
                      };
                      // handleAddLeadClaims(body);
                    }}
                  >
                    Send 10 min Connect
                  </Button>
                </div>
              </Tooltip>
            </Box>
          </Box>
        ) : (
          !searchResults.id &&
          searchText && (
            <Typography
              sx={{
                marginTop: '20px',
                borderRadius: '50px',
              }}
            >
              Lead not found!
            </Typography>
          )
        )}
      </Stack>

      <Box
        sx={{
          marginTop: '30px',
          padding: '16px',
          borderRadius: '4px',
          border: '1px solid gray',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography
            sx={{
              marginBottom: '16px',
              fontWeight: '500',
              fontSize: '18px',
            }}
          >
            Filters
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
              marginRight={10}
              sx={{ width: '50%' }}
            >
              <TextField
                size='small'
                type='text'
                name='note'
                placeholder='Lead Id'
                value={leadId}
                onChange={(e) => setLeadId(e.target.value)}
              />
            </Grid>
            <Grid sx={{ width: '50%' }}>
              <TextField
                size='small'
                type='datetime-local'
                name='claimed_at'
                label='Claimed at'
                value={claimedAt}
                onChange={(e) => setClaimedAt(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                }}
              />
            </Grid>
          </Box>
        </Box>
      </Box>

      <div style={{ height: 600, width: '100%', marginTop: '20px' }}>
        <Typography
          sx={{
            marginBottom: '10px',
            fontWeight: '500',
            fontSize: '18px',
          }}
        >
          History
        </Typography>
        <TenMinConnectTable
          triggerRefreshFor10MinConnect={triggerRefreshFor10MinConnect}
        />
      </div>
    </Box>
  );
}
