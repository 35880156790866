import {
  Box,
  Chip,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useCurrentCandidateContext } from '../../components/hrms/CurrentCandidateContext';
import { CopyIcon, getFormattedTimestamp } from '../../utils/common';
import { useAllAdmins } from '../../components/AdminsContext';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useEffect } from 'react';
import {
  getCandidateStatusLable,
  feedbackDoneIcon,
  feedbackPendingIcon,
} from '../../utils/hrms/common';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { splitByUnderscoresAndCapitalize } from '../../utils/common';
import CandidateResumes from './CandidateResumes';
import axios from 'axios';
import { base_url } from '../../components/Mode';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import { hrmsJobRoles, hrmscandidateSources } from '../../utils/common';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  hrms_candidate_status,
  hrms_interview_status,
} from '../../utils/hrms/constants';

const HRMSProfileDetailsTableView = ({
  openCandidateEditFormToggle,
  selectedRowData,
  logedInDetails,
}) => {
  const {
    candidateData,
    setCandidateData,
    candidateInterviews,
    setCandidateInterviews,
    interactionData,
    setInteractionData,
    getCurrentCandidateAndUpdateContext,
    getCurrentLeadData,
    currentCandidateActivities,
    getCurrentCandidateActivities,
    getCurrentCandidateInterviewsAndUpdateContext,
  } = useCurrentCandidateContext();

  const [open, setOpen] = React.useState(false);
  const [openEditInterview, setOpenEditEnterview] = useState(false);
  const [candidateResume, setCandidateResume] = useState([]);
  const [newInterview, setNewInterview] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    getCurrentLeadData(selectedRowData.id);
    getCandidateResumes(selectedRowData.id);
  }, []);

  const { allAdmins } = useAllAdmins();
  const adminMap = {};
  allAdmins.forEach((admin) => (adminMap[admin['id']] = admin));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleEditInterview = (isEditTrue) => {
    if (isEditTrue) {
      setNewInterview({
        assigned_interviewer:
          candidateInterviews[candidateInterviews.length - 1][
            'assigned_interviewer'
          ],
        status: candidateInterviews[candidateInterviews.length - 1]['status'],
      });
    } else {
      setNewInterview({
        assigned_interviewer: '',
        status: '',
      });
    }
  };

  const candidateHistroystyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    width: '80%',
    maxHeight: '80vh',
    overflowY: 'auto',
  };

  const Activity = (props) => {
    return (
      <Grid
        container
        textAlign={'left'}
        pl={'0px'}
        my={'5px'}
        {...(props.onClick && {
          onClick: props.onClick,
        })}
        {...(props.sx && { sx: props.sx })}
      >
        <Grid
          item
          xs={6}
        >
          <Typography
            fontWeight={'normal'}
            width={'fit-content'}
            display={'inline-block'}
            px={'10px'}
            borderRadius={'10px'}
            fontSize={'smaller'}
            color={'#757575'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              backgroundColor: props.backgroundColor || 'unset',
              padding: props.padding || '0',
            }}
          >
            {props.children}
          </Typography>
        </Grid>

        <Grid
          item
          xs={6}
          textAlgin={'left'}
        >
          <Typography
            fontSize={'12px'}
            display={'inline-block'}
            pr={'20px'}
            style={{ float: 'right' }}
            color={'#9e9e9e'}
          >
            {props.timestamp}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const statusActivities = currentCandidateActivities.map((activity) => {
    const timestamp = new Date(Date.parse(activity.created_at));
    const { fname, lname } = adminMap[activity.admin_id];
    const doer =
      adminMap[activity.admin_id].access_type === 'disabled'
        ? 'System user'
        : [fname, lname || ''].join(' ');
    let activityIcon;
    let activityText;

    switch (activity.type) {
      case 'update_candidate':
        const parsedFields = activity.metadata ?? {};
        const updatedFields = Object.keys(parsedFields)
          .filter((key) => key !== 'old_data')
          .join(', ');

        activityText =
          doer + ' has updated the ' + updatedFields + ' of this lead';
        activityIcon = <EditIcon fontSize={'small'} />;
        break;
      case 'status_change':
        activityText = `${doer} changed the status to ${splitByUnderscoresAndCapitalize(
          activity.metadata.status
        )}`;
        activityIcon = <PublishedWithChangesIcon fontSize={'small'} />;
        break;
      default:
    }

    return {
      timestamp: timestamp,
      data: (
        <Activity timestamp={getFormattedTimestamp(timestamp)}>
          {activityIcon}
          {activityText}
        </Activity>
      ),
    };
  });

  const showHistory = () => {
    const details = [];
    details.push(...statusActivities);
    details.sort(function (a, b) {
      const keyA = new Date(a.timestamp),
        keyB = new Date(b.timestamp);
      // Compare the 2 dates
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });

    return details.map((a) => a.data);
  };

  const detailsNotAvailable = () => {
    return (
      <span
        style={{
          color: '#cecece',
        }}
      >
        Unavailable
      </span>
    );
  };

  const getCandidateIdText = () => {
    if (!candidateData.id) return detailsNotAvailable();
    return <div>{candidateData.id}</div>;
  };

  const getNameText = () => {
    if (!candidateData.name) return detailsNotAvailable();
    return <div>{candidateData.name}</div>;
  };

  let MobileCopyBtn = CopyIcon(candidateData.mobile1);
  const getMobileText = () => {
    if (!candidateData.mobile1) return detailsNotAvailable();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {candidateData.mobile1}
        {MobileCopyBtn}
      </div>
    );
  };

  let Mobile2CopyBtn = CopyIcon(candidateData.mobile2);
  const getMobile2Text = () => {
    if (!candidateData.mobile2) return detailsNotAvailable();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {candidateData.mobile2} {Mobile2CopyBtn}
      </div>
    );
  };

  let EmailCopyBtn = CopyIcon(candidateData.email1);
  const getEmailText = () => {
    if (!candidateData.email1) return detailsNotAvailable();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {candidateData.email1} {EmailCopyBtn}
      </div>
    );
  };

  let Email2CopyBtn = CopyIcon(candidateData.email2);
  const getEmail2Text = () => {
    if (!candidateData.email2) return detailsNotAvailable();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {candidateData.email2} {Email2CopyBtn}
      </div>
    );
  };

  const getCollegeText = () => {
    if (!candidateData.college) return detailsNotAvailable();
    return <div>{candidateData.college}</div>;
  };
  const getBranchText = () => {
    if (!candidateData.branch) return detailsNotAvailable();
    return <div>{candidateData.branch}</div>;
  };

  const getGraduationsYearText = () => {
    if (!candidateData.graduation_year) return detailsNotAvailable();
    return <div>{candidateData.graduation_year}</div>;
  };
  const getWorkExperienceText = () => {
    if (!candidateData.work_experience) return detailsNotAvailable();

    const years = Math.floor(candidateData.work_experience);
    const monthsFraction = candidateData.work_experience - years;
    let months = 0;

    switch (monthsFraction) {
      case 0.25:
        months = 3;
        break;
      case 0.5:
        months = 6;
        break;
      case 0.75:
        months = 9;
        break;
      default:
        break;
    }

    const yearsText = years > 0 ? `${years} Year${years > 1 ? 's' : ''}` : '';
    const monthsText =
      months > 0 ? `${months} Month${months > 1 ? 's' : ''}` : '';

    return (
      <div>{`${yearsText} ${
        yearsText && monthsText ? 'and ' : ''
      }${monthsText}`}</div>
    );
  };

  const getCurrentCompanyText = () => {
    if (!candidateData.current_company) return detailsNotAvailable();
    return <div>{candidateData.current_company}</div>;
  };
  const getCurrentRoleText = () => {
    if (!candidateData.current_role) return detailsNotAvailable();
    return <div>{candidateData.current_role}</div>;
  };
  const getApplicationRoleText = () => {
    if (!candidateData?.hrms_job_role?.name) return detailsNotAvailable();
    return <div>{hrmsJobRoles[candidateData?.hrms_job_role?.name]}</div>;
  };
  const getApplicationStatusIdText = () => {
    if (!candidateData.status) return detailsNotAvailable();
    return <div>{getCandidateStatusLable(candidateData.status)}</div>;
  };
  const checkCandidateSourceFromCandidateData = (candidateData) => {
    if (
      candidateData.candidate_sources[0].source &&
      candidateData.candidate_sources[0].source.platform
    ) {
      let source = candidateData.candidate_sources[0].source?.platform;
      return source;
    }
  };
  const getCandidateSourceText = () => {
    return (
      <div>
        {candidateData?.candidate_sources?.length > 0
          ? hrmscandidateSources[
              checkCandidateSourceFromCandidateData(candidateData)
            ]
          : detailsNotAvailable()}
      </div>
    );
  };

  const getAssigneeText = () => {
    if (!candidateData?.assignee) {
      return (
        <Chip
          label={'No assignee'}
          sx={{
            backgroundColor: '#9575cd',
            color: '#fff',
          }}
        />
      );
    }

    const { fname, lname } = adminMap[candidateData?.assignee];

    return (
      <>
        <Chip
          label={[fname, lname || ''].join(' ')}
          sx={{
            backgroundColor: '#9575cd',
            color: '#fff',
          }}
        />
      </>
    );
  };

  const getSnoozedUntilText = function () {
    if (!candidateData.snoozed_until) return detailsNotAvailable();

    return (
      <Stack
        direction={'row'}
        spacing={2}
        alignItems='center'
      >
        <Typography
          p={1}
          sx={{
            backgroundColor: '#fab005',
            width: 'fit-content',
            borderRadius: '10px',
            color: '#fff',
            lineHeight: '1rem',
          }}
          display='flex'
          alignItems='center'
          gap='7px'
          fontSize='1rem'
        >
          {getFormattedTimestamp(new Date(candidateData.snoozed_until))}
        </Typography>
        {candidateData.has_scheduled_follow_up && <NotificationsActiveIcon />}
      </Stack>
    );
  };

  const getLabelText = () => {
    return <div>{candidateData.label}</div>;
  };

  const interviewRound = (round, feedback) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span style={{ width: '80%' }}>{round}</span>
        <span style={{ width: '20%' }}>
          {logedInDetails.roles.includes('interviewer') ? (
            feedback === hrms_interview_status.SCHEDULED ? (
              feedbackPendingIcon()
            ) : feedback === hrms_interview_status.FEEDBACK_DONE ? (
              feedbackDoneIcon()
            ) : (
              <CloseIcon fontSize={'small'} />
            )
          ) : feedback === hrms_interview_status.FEEDBACK_PENDING ? (
            feedbackPendingIcon()
          ) : feedback === hrms_interview_status.FEEDBACK_DONE ? (
            feedbackDoneIcon()
          ) : (
            <span>
              <span>
                <EditIcon
                  fontSize={'small'}
                  cursor='pointer'
                  onClick={() => {
                    setOpenEditEnterview(!openEditInterview);
                    handleEditInterview(!openEditInterview);
                  }}
                />
              </span>
              <span style={{ marginLeft: '10%' }}>
                <CloseIcon
                  onClick={() => {
                    deleteInterviews();
                  }}
                  cursor='pointer'
                  fontSize={'small'}
                />
              </span>
            </span>
          )}
        </span>
      </div>
    );
  };

  const showNextInterviewRound = () => {
    const len = candidateInterviews.length;
    const allRound = [
      { value: 'round_1_done', label: 'Round 1' },
      { value: 'round_2_done', label: 'Round 2' },
      { value: 'manager_round_done', label: 'Manager Round' },
    ];
    let scheduledStatus, feedbackStatus;
    if (len) {
      scheduledStatus =
        candidateInterviews[candidateInterviews.length - 1]['status'];
      feedbackStatus =
        candidateInterviews[candidateInterviews.length - 1]['feedback_status'];
    }

    if (
      len === 0 ||
      (scheduledStatus === hrms_candidate_status.ROUND_1_DONE &&
        feedbackStatus === hrms_interview_status.SCHEDULED)
    ) {
      return [allRound[0]];
    } else if (
      candidateData.status === hrms_candidate_status.ROUND_2_SCHEDULED
    ) {
      return [allRound[1]];
    } else if (
      candidateData.status === hrms_candidate_status.ROUND_2_DONE ||
      candidateData.status === hrms_candidate_status.MANAGER_ROUND_SCHEDULED
    ) {
      return [allRound[2]];
    } else if (candidateData.status === hrms_candidate_status.ROUND_1_DONE) {
      return [allRound[1], allRound[2]];
    } else {
      return [allRound[0]];
    }
  };

  const profileDetails = [
    {
      label: 'Personal Information',
      value: '',
    },
    {
      label: 'Candidate ID',
      value: getCandidateIdText(),
    },
    {
      label: 'Name',
      value: getNameText(),
    },
    {
      label: 'mobile',
      value: getMobileText(),
    },
    {
      label: 'alternate mobile',
      value: getMobile2Text(),
    },
    {
      label: 'email',
      value: getEmailText(),
    },
    {
      label: 'alternate email',
      value: getEmail2Text(),
    },
    {
      label: 'Education',
      value: '',
    },

    {
      label: 'College',
      value: getCollegeText(),
    },
    {
      label: 'Branch',
      value: getBranchText(),
    },
    {
      label: 'Graduation Year',
      value: getGraduationsYearText(),
    },
    {
      label: 'Professional Background',
      value: '',
    },
    {
      label: 'Work Experience',
      value: getWorkExperienceText(),
    },
    {
      label: 'Current Company',
      value: getCurrentCompanyText(),
    },
    {
      label: 'Current Role',
      value: getCurrentRoleText(),
    },
    {
      label: 'Application Details',
      value: '',
    },
    {
      label: 'Applied Role',
      value: getApplicationRoleText(),
    },
    {
      label: 'Application Status',
      value: getApplicationStatusIdText(),
    },
    {
      label: 'Candidate Source',
      value: getCandidateSourceText(),
    },
    {
      label: 'Assignee',
      value: getAssigneeText(),
    },
    {
      label: 'Next follow up',
      value: getSnoozedUntilText(),
    },
    {
      label: 'Label',
      value: getLabelText(),
    },

    {
      label: 'Interviewers',
      value: '',
    },
  ];

  const sectionTitleRows = [
    'Personal Information',
    'Education',
    'Professional Background',
    'Application Details',
    'Interviewers',
  ];

  const interviewRoundObj = {
    round_1_done: 'Round 1',
    round_2_done: 'Round 2',
    manager_round_done: 'Manager Round',
  };

  const checkArrayForAllInterviewRoundDoneOrNot = [
    'manager_round_done',
    'salary_finalized',
    'offer_letter_sent',
    'offer_letter_signed',
    'documents_collected',
    'joined',
    'dropped',
  ];

  function getRow(label, value) {
    if (sectionTitleRows.includes(label)) {
      return (
        <TableRow sx={{ border: '1px solid #e0e0e0' }}>
          <TableCell
            colSpan={2}
            sx={{ fontWeight: 'bold', fontSize: '1rem' }}
          >
            {label}
          </TableCell>
        </TableRow>
      );
    }

    if (
      (label === 'alternate mobile' || label === 'alternate email') &&
      value?.props.children === 'Unavailable'
    ) {
      return null;
    }

    if (
      label === 'Add Interviewers' &&
      logedInDetails.roles.includes('interviewer') &&
      !logedInDetails.roles.includes('hr')
    ) {
      return null;
    }

    if (
      label === 'Add Interviewers' &&
      checkArrayForAllInterviewRoundDoneOrNot.includes(candidateData.status)
    ) {
      return null;
    }

    if (label === 'Add Interviewers') {
      return (
        <TableRow sx={{ border: '1px solid #e0e0e0' }}>
          <TableCell
            colSpan={2}
            sx={{ fontWeight: 'bold', fontSize: '1rem' }}
          >
            <select
              value={newInterview.status}
              onChange={(e) =>
                setNewInterview({ ...newInterview, status: e.target.value })
              }
              style={{
                width: '32%',
                height: '35px',
                marginLeft: '-10px',
              }}
            >
              <option
                key={0}
                value=''
              >
                Select Round
              </option>
              {showNextInterviewRound().length > 0 &&
                showNextInterviewRound().map((e, i) => {
                  return (
                    <option
                      key={i + 1}
                      value={e.value}
                    >
                      {e.label}
                    </option>
                  );
                })}
            </select>
            <select
              value={newInterview.assigned_interviewer}
              onChange={(e) =>
                setNewInterview({
                  ...newInterview,
                  assigned_interviewer: e.target.value,
                })
              }
              style={{
                width: '40%',
                height: '35px',
                marginLeft: '17px',
                marginRight: '15px',
              }}
            >
              <option value=''>Select Interviewer</option>
              {allAdmins
                .filter((e) => {
                  return (
                    e.roles.filter((role) => role.name === 'interviewer')
                      .length > 0
                  );
                })
                .map((interviewer) => {
                  return (
                    <option value={interviewer.id}>
                      {interviewer.fname + ' ' + interviewer.lname}
                    </option>
                  );
                })}
            </select>
            <Button
              onClick={() => {
                openEditInterview ? editInterviews() : createInterviews();
              }}
              variant='contained'
              size='small'
            >
              {openEditInterview ? 'Edit' : 'Add'}
            </Button>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <TableRow key={label}>
        <TableCell
          sx={{
            width: '35%',
            padding: '5px 10px',
            border: '1px solid #e0e0e0',
            fontSize: '1rem',
            p: 1,
          }}
        >
          {label}
        </TableCell>
        <TableCell
          sx={{
            width: '65%',
            padding: '5px 10px',
            border: '1px solid #e0e0e0',
            fontSize: '1rem',
            p: 1,
          }}
        >
          {value}
        </TableCell>
      </TableRow>
    );
  }

  function getEditIcon(openCandidateEditFormToggle) {
    return (
      <IconButton
        onClick={() => {
          if (logedInDetails.roles.includes('interviewer')) {
            setHasError(true);
            setErrorMessage('You have not permission for edit details.');
            return;
          }
          openCandidateEditFormToggle();
        }}
        size='medium'
        sx={{
          float: 'right',
          mr: 1,
          color: 'black',
          display: 'inline-table',
        }}
      >
        <EditIcon />
      </IconButton>
    );
  }

  const getCandidateResumes = async (candidate_id) => {
    const resumeUrl =
      base_url() +
      '/api/hrms/hrms_candidates/' +
      candidate_id +
      '/hrms_candidate_resumes';

    await axios
      .get(resumeUrl)
      .then((res) => {
        setCandidateResume(res.data ?? []);
      })
      .catch((err) => {
        console.log('err', err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message);
      });
  };

  const updateCandidateWithAssignee = async (candidateId, assigneeId) => {
    try {
      axios.patch(base_url() + '/api/hrms/hrms_candidates/' + candidateId, {
        assignee: assigneeId,
      });
    } catch (err) {
      setHasError(true);
      setErrorMessage(err.response?.data?.message ?? 'Something went wrong');
    }
  };

  const checkForInputBox = () => {
    return (
      !newInterview.assigned_interviewer ||
      newInterview.assigned_interviewer === '' ||
      !newInterview.status ||
      newInterview.status === ''
    );
  };

  const createInterviews = async () => {
    const statusArray = [
      'created',
      'assigned',
      'profile_reviewed',
      'screening_done',
      'cultural_questions_sent',
    ];
    if (
      statusArray.includes(candidateData.status) &&
      newInterview.status === hrms_candidate_status.ROUND_1_DONE
    ) {
      setHasError(true);
      setErrorMessage('Please schedule first round interview.');
      return;
    }
    const url = base_url() + '/api/hrms/hrms_interviews/';
    const payload = {
      candidate_id: selectedRowData.id,
      assigned_interviewer: newInterview.assigned_interviewer,
      status: newInterview.status,
      feedback_status: hrms_interview_status.SCHEDULED,
    };
    if (checkForInputBox()) {
      setHasError(true);
      setErrorMessage('Please select all requred fields');
      return;
    }
    await axios
      .post(url, payload)
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Interview scheduled sucessfully');
        setNewInterview({ assigned_interviewer: '', status: '' });
        getCurrentCandidateInterviewsAndUpdateContext(selectedRowData.id);
        updateCandidateWithAssignee(
          candidateData.id,
          newInterview.assigned_interviewer
        );
      })
      .catch((err) => {
        console.log('err', err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message);
      });
  };

  const editInterviews = async () => {
    if (checkForInputBox()) {
      setHasError(true);
      setErrorMessage('Please select all requred fields');
      return;
    }
    const url = base_url() + '/api/hrms/hrms_interviews/' + selectedRowData.id;
    const payload = {
      assigned_interviewer: newInterview.assigned_interviewer,
      status: newInterview.status,
      feedback_status: hrms_interview_status.SCHEDULED,
    };
    await axios
      .patch(url, payload)
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Interview updated sucessfully');
        updateCandidateWithAssignee(
          candidateData.id,
          newInterview.assigned_interviewer
        );
        getCurrentCandidateInterviewsAndUpdateContext(selectedRowData.id);
        setOpenEditEnterview(false);
        handleEditInterview(false);
      })
      .catch((err) => {
        console.log('err', err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message);
      });
  };

  const deleteInterviews = async () => {
    if (
      candidateData.status === hrms_candidate_status.ROUND_1_SCHEDULED ||
      candidateData.status === hrms_candidate_status.ROUND_2_SCHEDULED ||
      candidateData.status === hrms_candidate_status.MANAGER_ROUND_SCHEDULED
    ) {
      setHasError(true);
      setErrorMessage(
        'You can not delete the scheduled interview at this status'
      );
      return;
    }
    const url = base_url() + '/api/hrms/hrms_interviews/' + selectedRowData.id;
    await axios
      .delete(url)
      .then((res) => {
        updateCandidateWithAssignee(
          candidateData.id,
          candidateInterviews[candidateInterviews.length - 1]['updated_by']
        );
        getCurrentCandidateInterviewsAndUpdateContext(selectedRowData.id);
        setOpenEditEnterview(false);
        handleEditInterview(false);
        setIsSuccess(true);
        setSuccessMessage('Interview deleted sucessfully');
      })
      .catch((err) => {
        console.log('err', err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message);
      });
  };

  return (
    <Box>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}{' '}
      <Table sx={{ width: '90%' }}>
        <TableHead>
          <TableRow sx={{ border: '1px solid #e0e0e0' }}>
            <TableCell
              colSpan={2}
              display={'flex'}
              justifyContent='space-between'
            >
              <Typography
                fontSize={'20px'}
                display={'inline-table'}
              >
                Profile
              </Typography>
              {getEditIcon(openCandidateEditFormToggle)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {profileDetails.map((row) => getRow(row.label, row.value))}
          {candidateInterviews.length > 0 &&
            candidateInterviews.map((e) => {
              return getRow(
                interviewRoundObj[e.status],
                interviewRound(
                  e.interviewerDetails.fname + ' ' + e.interviewerDetails.lname,
                  e.feedback_status
                )
              );
            })}
          {getRow('Add Interviewers', '')}
        </TableBody>
      </Table>
      <Typography
        marginTop={2}
        fontWeight={'bold'}
        fontSize={'17px'}
        display={'inline-table'}
      >
        Candidate Resume
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginLeft: '-20px',
          marginRight: '20px',
        }}
      >
        {candidateResume.length > 0 &&
          candidateResume.map((e) => {
            return <CandidateResumes resumeDetails={e} />;
          })}
      </div>
      <Box
        display='flex'
        justifyContent='flex-end'
        padding={2}
        marginLeft={2}
      >
        <Typography
          sx={{
            fontSize: 'small',
            textDecoration: 'underline',
            marginLeft: '10px',
            cursor: 'pointer',
          }}
          onClick={handleOpen}
        >
          View History
        </Typography>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={candidateHistroystyle}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
          >
            Candidate History
          </Typography>
          <Typography
            id='modal-modal-description'
            sx={{ mt: 2, cursor: 'pointer' }}
          >
            {showHistory()}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default HRMSProfileDetailsTableView;
