import React, { useState } from 'react';
import { TabPanel } from '../Admins/AdminDashboard';
import {
  currentAdminIsManagerOrAdmin,
  currentAdminIsHr,
  currentAdminIsTa,
  currentAdminIsAdmin,
  currentAdminIsManager,
} from '../../utils/common';
import ReportView from './ReportInsights/ReportView';
import { Box, Tabs, Tab } from '@mui/material';
import WorkLogCalendarView from './WorkLog/WorkLogCalendarView';
import TeamWorkLog from './WorkLog/TeamWorkLog';
import AppReportsInsights from './ReportInsights/AppReportsInsights';

function Reports() {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (e, newTabValue) => {
    setCurrentTab(newTabValue);
  };

  return (
    <>
      <Box sx={{ mr: 5, mt: 4 }}>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '-3rem' }}
        >
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
          >
            {!currentAdminIsHr() && !currentAdminIsTa() && (
              <Tab label='Reports' />
            )}
            {!currentAdminIsHr() &&
              !currentAdminIsTa() &&
              !currentAdminIsManager() && <Tab label='Work log' />}

            {(currentAdminIsManagerOrAdmin() ||
              currentAdminIsHr() ||
              currentAdminIsTa()) && <Tab label='Team work logs' />}
            {currentAdminIsAdmin() && <Tab label='App reports' />}
          </Tabs>
        </Box>
        {!currentAdminIsHr() && !currentAdminIsTa() && (
          <TabPanel
            value={currentTab}
            index={0}
          >
            <ReportView />
          </TabPanel>
        )}
        {!currentAdminIsHr() && !currentAdminIsTa() && (
          <TabPanel
            value={currentTab}
            index={1}
          >
            <WorkLogCalendarView />
          </TabPanel>
        )}

        {(currentAdminIsManagerOrAdmin() ||
          currentAdminIsHr() ||
          currentAdminIsTa()) && (
          <TabPanel
            value={currentTab}
            index={currentAdminIsHr() || currentAdminIsTa() ? 0 : 2}
          >
            <TeamWorkLog />
          </TabPanel>
        )}
        {!currentAdminIsHr() && !currentAdminIsTa() && (
          <TabPanel
            value={currentTab}
            index={3}
          >
            <AppReportsInsights />
          </TabPanel>
        )}
      </Box>
    </>
  );
}

export default Reports;
