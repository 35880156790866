import axios from 'axios';
import React, { useState, useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Calendar from 'react-calendar';
import { base_url } from '../Mode';
import './react-calendar.css';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { getTimeZoneFormattedTimestamp } from '../../utils/common';

export default function CalendarView({ queryParams }) {
  // Note: This calendar view currently shows snoozed leads of the month. So, we will ignore every preset filter related to snoozed.
  // Note: JS .getMonth() returns value from 0 to 11 instead of 1 to 12.
  const [date, setDate] = useState(new Date()); // by default fetch data of current month.
  const [presetFilters, setPresetFilters] = useState('');
  const [monthlyData, setMonthlyData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getMonthlySummary();
    return () => {};
  }, [date]);

  function getPresetFilters() {
    const avoidFilters = [
      'snoozed_from',
      'snoozed_till',
      'label',
      'snoozed',
      'due_now',
      'due_in_3h',
    ];

    const params = [];
    for (let param in queryParams) {
      if (!avoidFilters.find((filter) => filter === param)) {
        // returns undefined if entry is not found.
        params.push(`${param}=${queryParams[param]}`);
      }
    }
    if (params.length > 0) {
      setPresetFilters(params.join('&'));
      return params.join('&');
    }
    return '';
  }

  function getMonthlySummary() {
    let filter = presetFilters;
    if (Object.keys(queryParams).length > 0) {
      filter = getPresetFilters();
    }

    let url = `${base_url()}/api/users?view=calendar&month=${
      date.getMonth() + 1
    }&year=${date.getFullYear()}`;
    if (filter.length > 0) {
      url += `&${filter}`;
    }

    axios
      .get(url)
      .then((res) => {
        if (res['data']) {
          setMonthlyData(res['data']);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const TileContent = ({ activeStartDate, date, view }) => {
    let totalLabelsOnAday = 0;
    if (view === 'month' && date.getDate() in monthlyData) {
      totalLabelsOnAday = monthlyData[date.getDate()].length;
    }
    if (view === 'month' && date.getDate() in monthlyData) {
      let val = [];
      Object.keys(monthlyData[date.getDate()]).forEach((label, index) => {
        val.push(
          <Typography
            variant='body1'
            align='left'
            sx={{
              color: '#1976D2',
              whiteSpace: 'nowrap',
              display: 'inline',
            }}
          >
            {monthlyData[date.getDate()][label]} - {label}
          </Typography>
        );
      });
      return (
        <>
          {date.getDate()}
          <Box
            display='flex'
            flexDirection='column'
            sx={{
              mt: '40px',
              ml: '-10px',
            }}
          >
            {val.slice(0, 5)}
            {totalLabelsOnAday > 5 && (
              <Typography
                variant='body2'
                align='center'
              >
                {`+${totalLabelsOnAday - 5} more labels`}
              </Typography>
            )}
          </Box>
        </>
      );
    }
    return <>{date.getDate()}</>;
  };

  const handleDayClick = (value, e) => {
    const date = getTimeZoneFormattedTimestamp(new Date(value)).split('T')[0];
    let url = '/bucket-search';
    url += `?snoozed_from=${date}T00:00:00`;
    url += `&snoozed_till=${date}T23:59:59`;
    if (presetFilters.length > 0) {
      url += `&${presetFilters}`;
    }
    navigate(url);
  };

  // fires when month is changed using prev/next buttons.
  const handleActiveStartDateChange = ({
    action,
    activeStartDate,
    view,
    value,
  }) => {
    setDate(new Date(activeStartDate));
  };

  return (
    <div>
      <Calendar
        defaultView='month'
        value={date}
        className='react-calendar'
        tileContent={TileContent}
        prev2Label={null}
        next2Label={null}
        prevLabel={<ArrowBackIosIcon />}
        nextLabel={<ArrowForwardIosIcon />}
        showNeighboringMonth={false}
        onClickDay={handleDayClick}
        onActiveStartDateChange={handleActiveStartDateChange}
      />
    </div>
  );
}
