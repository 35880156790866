import { Box, Typography } from '@mui/material';
import { getTimeDifference } from '../../utils/getTimeDifference';

const SignedUpBox = ({
  signedFromRef,
  currentLeadData,
  currentLeadSignups,
}) => {
  const { fname, lname } = currentLeadData;
  const fullname = fname + (lname !== null ? ` ${lname}` : '');
  const { utm_source, utm_campaign, updated_at } = currentLeadSignups[0];
  return (
    <>
      <Box
        ref={signedFromRef}
        className='signedFromRef'
        sx={{
          zIndex: 4,
          display: 'flex',
          justifyContent: 'center',
          height: '100vh',
          width: '100vw',
          flexDirection: 'column',
          alignItems: 'center',
          transition: 'opacity 0.1s ease-in',
          opacity: 0,
        }}
      >
        <Typography
          sx={{
            zIndex: 4,
            color: '#EBE3F8',
            fontSize: '44px',
            textAlign: 'center',
            fontFamily: "'Inter', sans-serif",
            fontWeight: 700,
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundImage:
              'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
          }}
        >
          {fullname} has signed up from an
        </Typography>

        <Typography
          sx={{
            zIndex: 4,
            color: '#FFF',
            textAlign: 'center',
            fontSize: '40px',
            fontWeight: 700,
            width: '820px',
          }}
        >
          {utm_source}{' '}
          {utm_campaign && (
            <span
              style={{
                zIndex: 4,
                color: '#B998EF',
                fontFamily: "'Montserrat', sans-serif",
              }}
            >
              campaign {utm_campaign || ''}
            </span>
          )}
        </Typography>
        <Typography
          sx={{
            zIndex: 4,
            color: '#FFF',
            textAlign: 'center',
            fontSize: '40px',
            fontWeight: 700,
            width: '820px',
          }}
        >
          {getTimeDifference(updated_at)}{' '}
          <span
            style={{
              color: '#B998EF',
              fontFamily: "'Montserrat', sans-serif",
            }}
          >
            ago
          </span>
        </Typography>
      </Box>
    </>
  );
};

export default SignedUpBox;
