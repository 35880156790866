import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

const Source = ({
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  formSubmitted,
}) => {
  const [showUtmOther, setShowUtmOther] = useState(false);

  useEffect(() => {
    let errors = {};
    if (
      formValues.lead_source_type === 'inbound' &&
      formValues.utm_medium.length === 0 &&
      formValues.utm_source.length === 0 &&
      formValues.utm_campaign.length === 0
    ) {
      errors.lead_source_type = 'inbound requries atleast one utm field';
    }
    setFormErrors(errors);
  }, [formValues, setFormErrors]);
  const handleChange = (event) => {
    const isUtmSourceOther =
      event.target.name === 'utm_source' && event.target.value === 'other';

    if (isUtmSourceOther) {
      setShowUtmOther(true);
    } else if (isUtmSourceOther || document.activeElement !== event.target) {
      setShowUtmOther(false);
    }

    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <Grid
      container
      spacing={2}
      py={2}
      px={2}
      sx={{ background: '#E2EEFE' }}
    >
      <Grid
        item
        xs={12}
        py={2}
      >
        <Typography variant='h6'>Source</Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <FormControl fullWidth>
          <InputLabel id='lead_source_type'>Lead source type</InputLabel>
          <Select
            type='text'
            name='lead_source_type'
            label='lead_source_type'
            labelId='lead_source_type'
            value={formValues?.lead_source_type}
            onChange={handleChange}
          >
            <MenuItem value='inbound'>Inbound</MenuItem>
            <MenuItem value='outbound'>Outbound</MenuItem>
          </Select>
        </FormControl>
        <Typography
          color={
            formSubmitted && formErrors.lead_source_type ? 'error' : 'initial'
          }
          variant='body2'
        >
          {formSubmitted && formErrors.lead_source_type}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <FormControl fullWidth>
          <InputLabel id='utm_source'>Utm source</InputLabel>
          <Select
            type='text'
            name='utm_source'
            label='utm_source'
            labelId='utm_source'
            value={formValues?.utm_source}
            onChange={handleChange}
          >
            <MenuItem value=''>None</MenuItem>
            <MenuItem value='instagram'>Instagram</MenuItem>
            <MenuItem value='whatsapp'>Whatsapp</MenuItem>
            <MenuItem value='youtube'>Youtube</MenuItem>
            <MenuItem value='phone_call'>Phone call</MenuItem>
            <MenuItem value='facebook'>Facebook</MenuItem>
            <MenuItem value='other'>Other</MenuItem>
          </Select>
          <br />
          {showUtmOther && (
            <TextField
              type='text'
              required
              name='utm_source'
              label='Utm source'
              value={formValues?.utm_source}
              onChange={handleChange}
            />
          )}
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <FormControl fullWidth>
          <InputLabel id='utm_medium'>Utm medium</InputLabel>
          <Select
            type='text'
            name='utm_medium'
            label='utm_medium'
            labelId='utm_medium'
            value={formValues.utm_medium}
            onChange={handleChange}
          >
            <MenuItem value=''>None</MenuItem>
            <MenuItem value='social'>Social</MenuItem>
            <MenuItem value='collab'>Collab</MenuItem>
            <MenuItem value='ads'>Ads</MenuItem>
            <MenuItem value='influencer'>Influencer</MenuItem>
            <MenuItem value='other'>Other</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <FormControl fullWidth>
          <TextField
            type='text'
            name='utm_campaign'
            label='Utm Campaign'
            value={formValues.utm_campaign}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default Source;
