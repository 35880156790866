import { Box, Typography } from '@mui/material';

const CallingUiBox = ({
  widthPercentage,
  isCallingUIVisiblePro,
  currentLeadData,
}) => {
  const { fname, lname } = currentLeadData;
  const fullname = fname + (lname !== null ? ` ${lname}` : '');
  return (
    <Box
      className='callingUI'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        opacity: isCallingUIVisiblePro ? 1 : 0,
      }}
    >
      <Box
        className='headingBox'
        sx={{
          paddingBottom: '20px',
          fontSize: '50px',
          fontFamily: '"Inter", "sans-serif"',
          fontWeight: 700,
          textAlign: 'center',
          position: 'relative',
          color: '#FFF',
          zIndex: '4',
          marginTop: '-3rem',
          marginBottom: '2rem',
        }}
      >
        You’re{' '}
        <span
          style={{
            background: 'linear-gradient(90deg, #9F6BF5 39.81%, #C6AEEC 100%)',
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          calling...
        </span>
      </Box>
      <Typography
        className='Leadname'
        sx={{
          color: '#EBE3F8',
          fontSize: '70px',
          textAlign: 'center',
          fontWeight: 700,
          zIndex: '4',
          transition: 'opacity 0.6s ease-in-out',
          opacity: widthPercentage >= 20 && widthPercentage <= 31 ? 1 : 0,
        }}
      >
        {fullname}
      </Typography>
    </Box>
  );
};

export default CallingUiBox;
