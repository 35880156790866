import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function AddCampaignConfirmationModal({
  open,
  exitConfirmation,
  onCloseExitConfirmation,
}) {
  return (
    <Dialog
      open={open}
      onClose={onCloseExitConfirmation}
      maxWidth='sm'
      PaperProps={{
        style: { maxWidth: '20%', width: '20%', borderRadius: '1rem' },
      }}
      fullWidth
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        Exit Confirmation
        <IconButton
          size='small'
          onClick={onCloseExitConfirmation}
          color='inherit'
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>{exitConfirmation.message}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={exitConfirmation.yesHandler}
          color='success'
          variant='contained'
          size='small'
        >
          {exitConfirmation.yes}
        </Button>

        <Button
          size='small'
          onClick={exitConfirmation.noHandler}
          color='error'
        >
          {exitConfirmation.no}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
