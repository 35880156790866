import React from 'react';
import Error from '../../components/error/Error';

const ErrorPage = () => {
  return (
    <>
      <Error />
    </>
  );
};

export default ErrorPage;
