import { Button } from '@mui/material';
import React, { useState } from 'react';
import UpdateEligibilityFormModal from './UpdateEligibilityFormModal';
import { useCurrentLead } from '../CurrentLeadContext';

export const EligibilityForm = () => {
  const { currentLead } = useCurrentLead();
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const closeUpdateModal = () => setOpenUpdateModal(false);

  const getStatusButtonText = () => {
    if (currentLead.status === 'complete_program_explained') {
      return 'Send Eligibility Form';
    } else if (currentLead.status === 'eligibility_form_filled') {
      return 'Resend Eligibility Form';
    } else {
      return 'Update Eligibility Form';
    }
  };

  return (
    <>
      <Button
        onClick={() => setOpenUpdateModal(true)}
        variant='contained'
        color='success'
        size='small'
        sx={{
          height: '2.5em',
        }}
      >
        {getStatusButtonText()}
      </Button>
      <UpdateEligibilityFormModal
        openUpdateModal={openUpdateModal}
        closeUpdateModal={closeUpdateModal}
      />
    </>
  );
};
