import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  TextField,
  Autocomplete,
  FormControl,
  ListSubheader,
  Divider,
  ListItemText,
  Box,
} from '@mui/material';

const GroupedSelect = ({ groups, SelectedValue, setSelectedValue, label }) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (SelectedValue) {
      setInputValue(SelectedValue.name);
    }
  }, [SelectedValue]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleSelect = (event, newValue) => {
    setSelectedValue(newValue);
  };

  return (
    <FormControl fullWidth>
      <Autocomplete
        options={groups}
        getOptionLabel={(option) => option.name}
        value={SelectedValue}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant='standard'
            size='small'
          />
        )}
        renderGroup={(params) => (
          <div key={params.key}>
            {params.key !== '0' && <Divider />}
            <MenuItem sx={{ bgcolor: '#f9f9f9' }}>
              <ListSubheader>{params.group}</ListSubheader>
            </MenuItem>
            {params.children}
          </div>
        )}
        renderOption={(props, option) => (
          <Box
            key={option.id}
            sx={{
              borderBottom: '1px solid lightgray',
            }}
          >
            <MenuItem {...props}>
              <ListItemText>
                <strong>{option.name || ' '}</strong>
              </ListItemText>
            </MenuItem>
            {option.options.map((subOption, index) => (
              <MenuItem
                key={index}
                disabled
              >
                <ListItemText>{subOption}</ListItemText>
              </MenuItem>
            ))}
          </Box>
        )}
      />
    </FormControl>
  );
};

export default GroupedSelect;
