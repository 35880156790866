import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { base_url } from '../Mode';
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useAllAdmins } from '../AdminsContext';
import moment from 'moment';

const ScreeningCallMetaDataSchedule = ({
  openScreeningScheduleModal,
  setOpenScreeningScheduleModal,
  setHasError,
  setErrorMessage,
  leadScheduledStatusActivity,
  getLeadScheduledActivitiesMetadata,
  setMetadataForScreeningCallSchedule,
  updateLeadDetailState,
  refreshLeadContext,
  setSuccessMessage,
  setIsSuccess,
}) => {
  const { allAdmins } = useAllAdmins();
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [validAdmins, setValidAdmins] = useState({});

  let gmtTimeForStartTime = moment.utc(
    leadScheduledStatusActivity?.metadata?.start_time,
    'YYYY-MM-DD HH:mm'
  );

  const startTimeISTConversion = leadScheduledStatusActivity?.metadata
    ?.start_time
    ? moment(gmtTimeForStartTime).local().format('YYYY-MM-DD HH:mm')
    : null;

  useEffect(() => {
    getAllEnabledAndRestrictedAdmins();
  }, []);

  useEffect(() => {
    setSelectedAdmin(leadScheduledStatusActivity?.metadata?.admin_id ?? null);
    setStartTime(startTimeISTConversion);
  }, [leadScheduledStatusActivity]);

  const getAllEnabledAndRestrictedAdmins = () => {
    let admins = {};
    allAdmins.map((admin) => {
      if (
        admin.access_type === 'allowed' ||
        admin.access_type === 'restricted'
      ) {
        admin.roles.map((role) => {
          if (role.name === 'independent_contributor' || role.name === 'lead') {
            admins[admin.id] = `${admin.fname + ' ' + admin.lname}`;
          }
        });
      }
    });
    setValidAdmins(admins);
  };

  const handleCloseRecordingOffModal = () => {
    setOpenScreeningScheduleModal(false);
    setSelectedAdmin(leadScheduledStatusActivity?.metadata?.admin_id ?? null);
    setStartTime(startTimeISTConversion);
  };

  const handleSetAdminId = (e) => {
    setSelectedAdmin(parseInt(e.target.value), 10);
  };

  const handleSetErrorMessage = (selectedAdmin, startTime) => {
    if (selectedAdmin === null) {
      setErrorMessage('Please select an interviewer');
    } else if (startTime == null) {
      setErrorMessage('Please select start time');
    } else {
      setErrorMessage('Error while saving screening call details.');
    }
  };

  const handleSCMetadata = () => {
    try {
      const startTimeInUTC = startTime
        ? moment(startTime).utc().format('YYYY-MM-DD HH:mm')
        : null;

      let body = {
        admin_id: selectedAdmin,
        lead_status: leadScheduledStatusActivity?.status,
        start_time: startTimeInUTC,
      };

      setMetadataForScreeningCallSchedule((prev) => ({
        ...prev,
        admin_id: selectedAdmin,
        lead_status: leadScheduledStatusActivity?.status,
        start_time: startTimeInUTC,
      }));

      if (selectedAdmin !== null || startTime !== null) {
        if (Object.keys(leadScheduledStatusActivity).length !== 0) {
          axios
            .patch(
              `${base_url()}/api/status_activities_scheduled/${
                leadScheduledStatusActivity.id
              }`,
              body
            )
            .then(() => {
              setIsSuccess(true);
              setSuccessMessage('Details updated');
              refreshLeadContext();
              getLeadScheduledActivitiesMetadata();
            })
            .catch((error) => {
              console.log(error);
              setHasError(true);
              setErrorMessage(
                'An error occurred while saving screening call details.'
              );
            });
        } else {
          updateLeadDetailState('status', 'screening_call_scheduled');
          getLeadScheduledActivitiesMetadata();
        }
        handleCloseRecordingOffModal();
      } else {
        setHasError(true);
        handleSetErrorMessage(selectedAdmin, startTime);
        handleCloseRecordingOffModal();
      }
    } catch (error) {
      console.log('Error while saving screening call details:', error);
      setHasError(true);
      setErrorMessage('An error occurred while saving screening call details.');
    }
  };

  return (
    <>
      <Dialog open={openScreeningScheduleModal}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            padding: '10px 10px',
            width: 'fir-content',
          }}
        >
          <Button
            onClick={handleCloseRecordingOffModal}
            sx={{ color: 'black' }}
          >
            <CloseRoundedIcon />
          </Button>
        </Box>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              gap: '15px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                textAlign: 'center',
                gap: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography>Interviewer</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '73%',
                }}
              >
                <FormControl size='small'>
                  <InputLabel id='select-admin'>Interviewer name</InputLabel>
                  <Select
                    labelId='select-admin'
                    variant='outlined'
                    label='Interviewer name'
                    onChange={handleSetAdminId}
                    value={selectedAdmin}
                  >
                    {Object.keys(validAdmins).map((adminId) => (
                      <MenuItem
                        key={adminId}
                        value={adminId}
                      >
                        {validAdmins[adminId]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                textAlign: 'center',
                gap: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography>Start Time</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <TextField
                  type='datetime-local'
                  variant='outlined'
                  onChange={(e) => setStartTime(e.target.value)}
                  value={startTime}
                  size='small'
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSCMetadata}
            autoFocus
            variant='contained'
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScreeningCallMetaDataSchedule;
