import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

const MockcallDetails = ({
  setIsMockcallCompleted,
  assignedIC,
  setIsICAssigned,
}) => {
  return (
    <Paper
      elevation={3}
      style={{
        padding: '15px',
        borderRadius: '7px',
        marginTop: '15px',
        height: '50vh',
        textAlign: 'center',
      }}
    >
      <Box
        flexDirection='column'
        justifyContent='center'
        mt={4}
      >
        <Box mt={1}>
          <Typography
            variant='h6'
            style={{ fontSize: '28px', color: 'green' }}
          >
            Your mockcall request has been accepted
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography
            variant='body'
            style={{ fontSize: '22px' }}
          >
            You need to record the mockcall
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography
            variant='body'
            style={{ fontSize: '22px' }}
          >
            Connect with {assignedIC.Full_Name}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography
            variant='body'
            style={{ fontSize: '22px', fontWeight: 800 }}
          >
            {assignedIC.phone}
          </Typography>
        </Box>
        <Box mt={2}>
          <Button
            style={{ backgroundColor: '#3994FF' }}
            variant='contained'
            onClick={() => {
              setIsMockcallCompleted(true);
              setIsICAssigned(false);
            }}
          >
            I have completed my mockcall
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default MockcallDetails;
