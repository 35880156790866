import DiscordLogo from '../../../../images/skill-icons_discord.svg';
const DiscordIcon = ({ style, link }) => {
  return (
    <>
      <a
        href={link}
        target='_blank'
        rel='noopener noreferrer'
      >
        <img
          style={style}
          name={'icons'}
          alt={'icons'}
          src={DiscordLogo}
        />
      </a>
    </>
  );
};
export default DiscordIcon;
