const sendMessageService = require('./services/sendMessage');
const disconnectService = require('./services/disconnectService');

export default function socketServices(socket) {
  socket.on('sendMessage', (data) => {
    sendMessageService(socket, data);
  });
  socket.on('disconnect', () => {
    disconnectService(socket);
  });
  socket.on('requestSentForBucketSearch', (data) => {
    console.log('testing from backend', data);
  });
  socket.on('backendEvent', () => {
    console.log('testing from backend ping event');
  });
}
