import React from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  TableHead,
} from '@mui/material';

function ReporteeTable({ reportees }) {
  const columns = [
    { id: 'id', label: 'Sr. No.', minWidth: 80, align: 'center' },
    { id: 'fullname', label: 'Name', minWidth: 250, align: 'center' },
    { id: 'email', label: 'Email', minWidth: 250, align: 'center' },
  ];

  return (
    <Paper sx={{ width: '100%', maxHeight: 300, overflow: 'auto' }}>
      <TableContainer>
        <Table
          stickyHeader
          aria-label='sticky table'
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {reportees?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align='center'
                >
                  <Typography
                    variant='h6'
                    color='textSecondary'
                  >
                    No data found
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              reportees?.map((row, index) => (
                <TableRow
                  key={row.id}
                  hover
                  role='checkbox'
                  tabIndex={-1}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                    >
                      {column.id === 'id' ? index + 1 : row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default ReporteeTable;
