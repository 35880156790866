import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AnimatedPhoneIconComponent from './AnimatedPhoneIcon';
import './CallRingingBox.css';
// import AddInteraction from './AddInteraction';
import { useNavigate, useParams } from 'react-router-dom';

const CallRingingBox = ({
  ringingTime,
  SetIsYesClicked,
  setIsProgressRunning,
}) => {
  const [isConnectBtnClicked, setIsConnectBtnClicked] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    SetIsYesClicked(false);
  }, []);

  const formattedTime = ringingTime.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  const continueBtnClickHandler = () => {
    console.log('hello');
    navigate(`/leads/${id}`);
    setIsProgressRunning(false);
    setIsConnectBtnClicked(true);
  };

  return (
    <>
      {!isConnectBtnClicked && (
        <Box
          sx={{
            zIndex: '10',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '13px',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              transform: 'rotateY(-180deg)',
            }}
          >
            <AnimatedPhoneIconComponent />
          </Box>
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontSize: '64px',
              fontStyle: 'normal',
              fontWeight: '700',
              background: 'linear-gradient(90deg, #9F6BF5 0%, #C6AEEC 102.86%)',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            ringing...
          </Typography>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box
              sx={{
                width: '492px',
                height: '10px',
                border: '1px solid gray',
                borderRadius: '30px',
                position: 'relative',
              }}
            >
              <Box
                className={'progressBarWidthAnimation'}
                sx={{
                  width: '0px',
                  height: '8px',
                  border: '1px solid gray',
                  borderRadius: '30px',
                  position: 'absolute',
                  background: `linear-gradient(90deg, #7544f0 -13.25%, #9f6bf5 51%, #e6caf0 115.25%)`,
                  animation: 'widthAnimation 30s linear  forwards',
                }}
              ></Box>
            </Box>
            <span
              style={{
                color: '#8C8686',
                fontFamily: 'Inter',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 400,
                marginLeft: '5px',
                marginTop: '-5px',
              }}
            >
              {formattedTime} sec
            </span>
          </Box>
          <Box
            sx={{
              mt: '12px',
              display: 'flex',
              width: '45px',
              height: '13px',
              padding: '19.459px 105.405px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '12.331px',
              flexShrink: 0,
              borderRadius: '45px',
              background:
                'linear-gradient(180deg, rgba(112, 62, 243, 0.60) 0%, rgba(159, 107, 245, 0.60) 100%)',
              boxShadow: '4.075px 4.075px 20.376px 0px #68499F',
            }}
          >
            <Button
              sx={{
                display: 'flex',
                width: '35px',
                height: '5px',
                padding: '19.459px 105.405px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '12.331px',
                flexShrink: 0,
                borderRadius: '45px',
                border: '1.233px solid #FFF',
                cursor: 'pointer',
                background: 'linear-gradient(180deg, #703EF3 0%, #9F6BF5 100%)',
              }}
              onClick={continueBtnClickHandler}
            >
              <Typography
                sx={{
                  color: '#FFF',
                  fontFamily: 'Inter',
                  fontSize: '22px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                }}
              >
                Connected
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CallRingingBox;
