import React from 'react';
import { Modal, Box } from '@mui/material';

const CustomModal = ({ open, onClose, ModalStyle, children }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={ModalStyle}>{children}</Box>
      </Modal>
    </>
  );
};

export default CustomModal;
