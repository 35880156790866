module.exports.hrms_candidate_status = {
  CREATED: 'created',
  ASSIGNED: 'assigned',
  PROFILE_REVIEWED: 'profile_reviewed',
  SCREENING_DONE: 'screening_done',
  CULTURAL_QUESTIONS_SEND: 'cultural_questions_sent',
  CULTURAL_QUESTIONS_RECEIVED: 'cultural_questions_received',
  ROUND_1_SCHEDULED: 'round_1_scheduled',
  ROUND_1_DONE: 'round_1_done',
  ROUND_2_SCHEDULED: 'round_2_scheduled',
  ROUND_2_DONE: 'round_2_done',
  MANAGER_ROUND_SCHEDULED: 'manager_round_scheduled',
  MANAGER_ROUND_DONE: 'manager_round_done',
  SALARY_FINALIZED: 'salary_finalized',
  OFFER_LETTER_SEND: 'offer_letter_sent',
  OFFER_LETTER_SINGNED: 'offer_letter_signed',
  DOCUMENTS_COLLECTED: 'documents_collected',
  JOINED: 'joined',
  DROPPED: 'dropped',
};

module.exports.hrms_interview_status = {
  SCHEDULED: 'scheduled',
  FEEDBACK_PENDING: 'feedback pending',
  FEEDBACK_DONE: 'feedback done',
};
