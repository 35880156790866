import { useState } from 'react';
import SalesPerformanceTable from './SalesPerformanceTable';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';

const SalesPerformance = () => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <SalesPerformanceTable
        setHasError={setHasError}
        setErrorMessage={setErrorMessage}
      />
    </>
  );
};

export default SalesPerformance;
