import React, { useState } from 'react';
import { Box } from '@mui/system';
import axios from 'axios';
import { base_url } from '../../../components/Mode';
import { useEffect } from 'react';
import LeadAccessPoliciesTable from './LeadAccessPoliciesTable';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import { Button, Stack } from '@mui/material';
import NewLeadAccessPolicyModal from './NewLeadAccessPolicyModal';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
import LeadAccessPoliciesGraph from './LeadAccessPoliciesGraph';

export default function LeadAccessPolicies() {
  const [allLeadAccessPolicies, setAllLeadAccessPolicies] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [openGraphModal, setOpenGraphModal] = useState(false);

  useEffect(() => {
    fetchAllLeadAccessPolicies();
    return () => {};
  }, []);

  const fetchAllLeadAccessPolicies = () => {
    axios
      .get(`${base_url()}/api/lead-access-policies`)
      .then((res) => {
        setAllLeadAccessPolicies(res.data ?? []);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            'Could not fetch the LeadAccessPolicies.'
        );
      });
  };

  return (
    <Box sx={{ mr: 5 }}>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <Stack
        direction='row-reverse'
        spacing={2}
      >
        <Button
          variant='contained'
          onClick={(e) => setOpen(true)}
        >
          Add lead access policy
        </Button>
        <Button
          variant='contained'
          onClick={(e) => setOpenGraphModal(true)}
          disabled={allLeadAccessPolicies.length === 0}
        >
          Visualize
        </Button>
      </Stack>

      {openGraphModal && (
        <LeadAccessPoliciesGraph
          openGraphModal={openGraphModal}
          setOpenGraphModal={setOpenGraphModal}
        />
      )}

      {open && (
        <NewLeadAccessPolicyModal
          {...{
            open,
            setOpen,
            allLeadAccessPolicies,
            setAllLeadAccessPolicies,
            setHasError,
            setErrorMessage,
            setIsSuccess,
            setSuccessMessage,
          }}
        />
      )}
      {allLeadAccessPolicies.length > 0 && (
        <LeadAccessPoliciesTable
          allLeadAccessPolicies={allLeadAccessPolicies}
          setAllLeadAccessPolicies={setAllLeadAccessPolicies}
          setErrorMessage={setErrorMessage}
          setHasError={setHasError}
        />
      )}
    </Box>
  );
}
