import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import { useCurrentLead } from '../CurrentLeadContext';
import { getDatabaseFormattedDateTime } from '../../utils/common';
import moment from 'moment';

export default function FormGeneratedModal({ open, onClose, form }) {
  const { currentLead, getCurrentLeadAndUpdateContext } = useCurrentLead();

  const handleClose = () => {
    onClose();
    getCurrentLeadAndUpdateContext(currentLead.lead_id);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50vw',
            maxHeight: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 6,
            overflowY: 'auto',
            borderRadius: '20px',
          }}
        >
          <Typography
            variant='subtitle1'
            gutterBottom
            align='left'
          >
            {currentLead.status === 'eligibility_form_sent' ? (
              'Eligibility form has been updated.'
            ) : (
              <>
                An eligibility form with id ={' '}
                <span style={{ color: 'green' }}>{form.form_id}</span> has been
                generated for this lead.
              </>
            )}{' '}
            The form will expire at &nbsp;
            <span
              style={{
                color: 'red',
              }}
            >
              {moment(form?.expires_at).format('MMM Do YYYY, h:mm A')}
            </span>
            <br />
            FormLink:{' '}
            <a
              href='https://learning.heycoach.in/'
              target='blank'
            >
              https://learning.heycoach.in/
            </a>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
