import PhoneCallbackRoundedIcon from '@mui/icons-material/PhoneCallbackRounded';
import PhoneMissedTwoToneIcon from '@mui/icons-material/PhoneMissedTwoTone';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import HomeIcon from '@mui/icons-material/Home';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import constants from '../utils/constants';
import { canAccessRoute } from '../routes/role_route_mapper';
import { getCurrentAdminId, getCurrentAdminRole } from '../utils/common';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import NotificationDots from './NotificationDots';

const {
  ADMIN,
  INDEPENDENT_CONTRIBUTOR: IC,
  MANAGER,
  SENIOR_MANAGER,
  MARKETING,
  PROGRAM_MANAGER: PM,
  FINANCE,
  HR,
  INTERVIEWER,
  TA,
  TRAINEE,
  SENIOR_MARKETING_MANAGER,
} = constants;

export const sidebarMenuList = [
  {
    subFields: [
      {
        name: 'Home',
        path: '/ic-home',
        icon: <HomeIcon />,
        isFor: [ADMIN],
      },
    ],
  },
  {
    heading: 'Leads',
    subFields: [
      {
        name: 'Create lead',
        icon: <AddCircleOutlineIcon />,
        path: null,
        isFor: [
          ADMIN,
          PM,
          FINANCE,
          MARKETING,
          MANAGER,
          SENIOR_MANAGER,
          SENIOR_MARKETING_MANAGER,
        ],
      },
      {
        name: 'Follow ups due',
        path: '/leads/follow-ups-due',
        icon: <PendingActionsIcon />,
        isFor: [ADMIN, TRAINEE, IC],
      },
      {
        name: 'Hot Leads',
        path: '/leads/hot-leads',
        icon: <WhatshotIcon />,
        isFor: [ADMIN, TRAINEE, IC, MANAGER, MARKETING],
      },
      {
        name: 'RCB',
        path: '/leads/RCB',
        icon: <PhoneCallbackRoundedIcon />,
      },
      {
        name: 'DNP',
        path: '/leads/DNP',
        icon: <PhoneMissedTwoToneIcon />,
      },
      // not needed for now
      // {
      //   name: "Prospects",
      //   path: "/leads/prospects",
      //   icon: <PersonIcon />,
      // },
      {
        name: 'Total',
        path: '/leads/total',
        icon: <SummarizeIcon />,
      },
      {
        name: 'Leads',
        path: '/leads/priority-list',
        icon: <SummarizeIcon />,
      },
    ],
  },
  {
    heading: 'Interactions',
    subFields: [
      {
        name: 'Recent interactions',
        path: '/recent-interactions',
        icon: <AccessTimeFilledIcon />,
      },
    ],
  },
  {
    heading: 'Search',
    subFields: [
      {
        name: 'Bucket search',
        path: '/bucket-search',
        icon: <SearchIcon />,
      },
    ],
  },
  {
    heading: 'Conversions',
    subFields: [
      {
        name: 'Generate receipt',
        icon: <ReceiptIcon />,
        path: '/conversions/generate-receipt',
        isFor: [ADMIN, FINANCE, SENIOR_MANAGER],
      },
      {
        name: 'My conversions',
        path: '/conversions/my-conversions',
        icon: <SportsScoreIcon />,
        isFor: [ADMIN],
      },
      {
        name: 'Post sales',
        path: '/conversions/post-sales',
        icon: <ManageAccountsIcon />,
      },
      {
        name: 'CAP tracking',
        path: '/conversions/cap-tracking',
        icon: <ManageAccountsIcon />,
      },
    ],
  },
  {
    heading: 'Reports',
    subFields: [
      {
        name: 'Reports',
        icon: <ReceiptIcon />,
        path: '/reports',
      },
      {
        name: 'Lead funnel',
        icon: <ReceiptIcon />,
        path: '/lead-funnel',
        isFor: [ADMIN, SENIOR_MANAGER, MARKETING],
      },
    ],
  },
  {
    heading: 'Dashboards',
    subFields: [
      {
        name: 'HR',
        path: '/dashboard/hr',
        icon: <ManageAccountsIcon />,
        isFor: [ADMIN, HR, TA],
      },
      {
        name: 'Marketing',
        path: '/dashboard/marketing',
        icon: <ManageAccountsIcon />,
      },
      {
        name: 'Manager',
        path: '/dashboard/manager',
        icon: (
          <>
            <ManageAccountsIcon />
            <NotificationDots />
          </>
        ),
      },
      {
        name: 'Admin',
        path: '/dashboard/admin',
        icon: <ManageAccountsIcon />,
      },
      {
        name: 'Revenue',
        path: '/dashboard/revenue',
        icon: <ManageAccountsIcon />,
      },
      // {
      //   name: 'Salsa',
      //   path: '/dashboard/salsa',
      //   icon: <ManageAccountsIcon />,
      // },
      {
        name: 'Accountant',
        path: '/dashboard/accountant',
        icon: <ManageAccountsIcon />,
      },

      {
        name: 'Leaderboard',
        path: '/dashboard/leaderboard',
        icon: <WorkspacePremiumOutlinedIcon />,
        isFor: [ADMIN, MANAGER, SENIOR_MANAGER],
      },
    ],
  },
  {
    heading: 'Training',
    subFields: [
      {
        name: 'Training',
        path: '/training/modules',
        icon: <ModelTrainingIcon />,
        isFor: [ADMIN],
      },
      // Other routes of training section are not written here because they are not supposed to be rendered along with a sidebar.
    ],
  },
  {
    heading: 'HRMS',
    subFields: [
      {
        name: 'Create Candidate',
        icon: <AddCircleOutlineIcon />,
        path: null,
        isFor: [ADMIN, HR, TA, INTERVIEWER],
      },
      {
        name: 'Candidate Search',
        path: '/hrms/candidate-search',
        icon: <SearchIcon />,
      },
      {
        name: 'Recent Interactions',
        path: '/hrms/recent-interactions',
        icon: <AccessTimeFilledIcon />,
      },
      {
        name: 'Follow ups due',
        path: '/hrms/follow-ups-due',
        icon: <PendingActionsIcon />,
      },
    ],
  },
];

export const getSidebarContent = () => {
  const currentRole = getCurrentAdminRole();
  let sideMenu = [];

  sidebarMenuList.forEach((parent) => {
    const { heading, name } = parent;

    const subList = {
      buttons: [],
      links: [],
    };

    parent.subFields.forEach((field) => {
      const { path } = field;
      if (path && canAccessRoute(path)) {
        subList.links.push(field);
      } else if (
        !path &&
        field.isFor.filter((role) => currentRole.includes(role)).length > 0
      ) {
        subList.buttons.push(field);
      }
    });

    if (subList.links.length > 0 || subList.buttons.length > 0) {
      sideMenu.push({
        heading,
        subFields: subList,
      });
    }
  });
  if (constants.BUCKET_SEARCH_RESTRICTED_USERS.includes(getCurrentAdminId())) {
    sideMenu = sideMenu.filter((menu) => menu.heading != 'Search');
  }
  return sideMenu;
};
