import React, { useEffect, useState } from 'react';
import { base_url } from '../../components/Mode';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { sortByFullName } from '../../utils/common';

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { getStatusLabel } from '../../utils/common';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';

const LowCallDurationManagerAction = () => {
  const [data, setData] = useState([]);
  const [action, setaction] = useState('pending');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [subordinateAdmins, setSubordinateAdmins] = useState([]);
  const [managerId, setManagerId] = useState('');

  useEffect(() => {
    getManagerActions();
    getmanagerDropdown();
  }, [action, managerId]);

  const getmanagerDropdown = async () => {
    const res = await axios.get(
      `${base_url()}/api/manager_actions/subordinate_managers`
    );
    setSubordinateAdmins(res.data);
  };

  const getManagerActions = () => {
    const url = `${base_url()}/api/manager_actions`;
    const apiUrl = action
      ? `${url}?action=${action}&manager_id=${managerId}`
      : `${url}?manager_id=${managerId}`;
    axios
      .get(apiUrl)
      .then((res) => {
        let data = [...res.data];
        let obj = {};
        res.data.forEach((element, index) => {
          element.srNo = index + 1;
          if (obj[element.reportee]) {
            obj[element.reportee].push(element);
          } else {
            obj[element.reportee] = [element];
          }
        });

        setData(data);
      })
      .catch((err) => {
        console.log('An error occurred: ' + err);
        setHasError(true);
        setErrorMessage('An error occurred: ' + err);
      });
  };

  const handlePitchCorrectedOffline = (id) => {
    const body = {
      status: 'resolved',
    };

    axios
      .patch(`${base_url()}/api/manager_actions/${id}`, body)
      .then((res) => {
        if (res.data) {
          getManagerActions();
          setIsSuccess(true);
          setSuccessMessage('Success!');
        }
      })
      .catch((err) => {
        console.log('Error in handling the manager actions offline: ', err);
        setHasError(true);
        setErrorMessage(
          err.res?.data?.message ??
            'An error occured while updating the manager actions information.'
        );
      });
  };

  const handleMouseUp = (leadId) => {
    const currentUrl = '/leads/' + leadId;
    window.open(currentUrl, '_blank');
  };

  const getFlaggedEntryCount = (number) => {
    const lastDigit = number % 10;
    let suffix = 'th';

    switch (lastDigit) {
      case 1:
        suffix = 'st';
        break;
      case 2:
        suffix = 'nd';
        break;
      case 3:
        suffix = 'rd';
        break;
      default:
        suffix = 'th';
    }

    if (number >= 11 && number <= 13) {
      suffix = 'th';
    }

    return `${number}${suffix} miss`;
  };

  const handleChange = (event) => {
    setaction(event.target.value);
  };

  const managerDropdown = () => {
    return sortByFullName(subordinateAdmins, 'Full_Name').map(
      (manager, index) => {
        return (
          <MenuItem
            value={manager?.Admin_id}
          >{`${manager?.Full_Name}`}</MenuItem>
        );
      }
    );
  };
  const columns = [
    {
      field: 'id',
      headerName: 'Sr No',
      width: 80,
      hide: true,
    },
    {
      field: 'srNo',
      headerName: 'Sr No',
      width: 80,
    },
    {
      field: 'reportee',
      headerName: 'Reportee',
      width: 220,
    },

    {
      field: 'flagged_entry_count',
      headerName: 'Miss #',
      width: 120,
      renderCell: (params) => <>{getFlaggedEntryCount(params.value)}</>,
    },
    {
      field: 'lead_id_and_name',
      headerName: 'Lead id',
      width: 250,
      renderCell: (params) => (
        <div onClick={() => handleMouseUp(params.row.lead_id)}>
          <Typography
            variant='body2'
            component='p'
            sx={{
              '&:hover': {
                textDecoration: 'underline',
                color: 'blue',
              },
            }}
          >
            {`${params.row.lead_name} #${params.row.lead_id}`}
          </Typography>
        </div>
      ),
    },

    {
      field: 'total_interaction_duration',
      headerName: 'Total call duration',
      width: 150,
      renderCell: (params) => <>{params.value} mins</>,
    },

    {
      field: 'action',
      headerName: 'Action',
      width: 230,
      renderCell: (params) => {
        return params.row.status !== 'resolved' ? (
          <Button
            variant='contained'
            size='small'
            onClick={() => handlePitchCorrectedOffline(params.row.id)}
          >
            Pitch corrected offline
          </Button>
        ) : (
          ''
        );
      },
    },
  ];
  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          minWidth: 160,
          marginBottom: 2,
          gap: 2,
        }}
      >
        {subordinateAdmins && subordinateAdmins.length > 0 ? (
          <FormControl sx={{ minWidth: 180 }}>
            <InputLabel
              id='managers-label'
              sx={{
                marginBottom: 2,
                paddingRight: 1,
                marginTop: '-8px',
              }}
            >
              Managers
            </InputLabel>
            <Select
              labelId='managers-label'
              id='manager-select'
              value={managerId}
              label='Managers'
              size='small'
              onChange={(e) => {
                setManagerId(e.target.value);
              }}
            >
              {managerDropdown()}
            </Select>
          </FormControl>
        ) : (
          ''
        )}

        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel
            id='action-label'
            sx={{
              marginBottom: 2,
              paddingRight: 1,
            }}
          >
            Action
          </InputLabel>
          <Select
            labelId='action-label'
            id='action-select'
            value={action}
            label='Action'
            size='small'
            onChange={handleChange}
          >
            <MenuItem value='all'>All</MenuItem>
            <MenuItem value='pending'>Pending</MenuItem>
            <MenuItem value='resolved'>Resolved</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <DataGrid
        rows={data}
        columns={columns}
        disableRowSelectionOnClick
        autoHeight
      />
    </>
  );
};

export default LowCallDurationManagerAction;
