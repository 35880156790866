import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const NumericFilter = ({ operators, onFilter, onClose }) => {
  const [selectedOperator, setSelectedOperator] = useState(operators[0]);
  const [numericValue, setNumericValue] = useState('');

  const handleOperatorChange = (event) => {
    setSelectedOperator(event.target.value);
  };

  const handleCloseClick = () => {
    onClose();
  };

  const handleInputChange = (event) => {
    setNumericValue(event.target.value);
  };

  const handleFilterClick = () => {
    onFilter({
      operator: selectedOperator,
      value: Number(numericValue),
    });
  };

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      padding={4}
      borderRadius={4}
      border='1px solid #ccc'
      boxShadow='0 2px 4px rgba(0,0,0,0.1)'
      marginBottom={2}
      bgcolor='white'
      height='100%'
      width='270%'
    >
      <FormControl
        variant='outlined'
        size='small'
        style={{ minWidth: '120px', marginRight: '10px' }}
      >
        <InputLabel>Operator</InputLabel>
        <Select
          value={selectedOperator}
          onChange={handleOperatorChange}
          label='Operator'
        >
          {operators.map((operator) => (
            <MenuItem
              key={operator}
              value={operator}
            >
              {operator}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        type='number'
        label='Numeric Value'
        variant='outlined'
        size='small'
        value={numericValue}
        onChange={handleInputChange}
        style={{ minWidth: '120px', marginRight: '10px' }}
      />

      <Button
        variant='contained'
        color='primary'
        onClick={handleFilterClick}
      >
        Apply
      </Button>
      <IconButton
        onClick={handleCloseClick}
        size='small'
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default NumericFilter;
