import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  Stack,
  Typography,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

function DropReasonModal({
  reasons,
  openDropReasonModal,
  selectedReasons,
  setSelectedReasons,
  handleDropReasonModalClose,
}) {
  const [secondaryOptions, setSecondaryOptions] = useState([]);
  const [tips, setTips] = useState([]);

  useEffect(() => {
    try {
      const tt = [];

      if (
        selectedReasons &&
        selectedReasons?.secondary &&
        typeof selectedReasons?.secondary === 'object'
      ) {
        const secondaryKeys = Object.keys(selectedReasons?.secondary);

        if (secondaryKeys?.length > 0) {
          secondaryKeys?.forEach((id) => {
            const index = Number(id);

            if (
              secondaryOptions &&
              Array.isArray(secondaryOptions) &&
              index >= 0 &&
              index < secondaryOptions?.length
            ) {
              const option = secondaryOptions[index];

              if (option && option?.tips && Array.isArray(option?.tips)) {
                tt.push(...option?.tips);
              }
            }
          });
        }
      }

      setTips(tt);
    } catch (error) {
      console.error('An error occurred in setting Tips:', error);
    }

    return () => {};
  }, [selectedReasons]);

  const handleSecondaryReasonSelect = (index, reason) => {
    const secondary = selectedReasons.secondary;
    if (index in selectedReasons.secondary) {
      delete secondary[index];
    } else {
      secondary[index] = reason;
    }
    setSelectedReasons({
      ...selectedReasons,
      secondary,
    });
  };

  return (
    <Dialog
      open={openDropReasonModal}
      onClose={(e) => handleDropReasonModalClose('cancel')}
      maxWidth='md'
      fullWidth
      sx={{
        maxHeight: '70vh',
        scroll: 'auto',
      }}
    >
      <DialogTitle>
        <Typography
          p={0}
          variant='h5'
          component='h5'
        >
          Lead drop
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Stack
          m={0}
          spacing={2}
        >
          <FormControl>
            {reasons.length > 0 &&
              reasons.map((reason) => {
                return (
                  <FormControlLabel
                    value={reason.primary}
                    control={
                      <Radio
                        checked={reason.primary === selectedReasons.primary}
                        onChange={(e) => {
                          setSelectedReasons({
                            primary: reason.primary,
                            secondary: {},
                          });
                          setSecondaryOptions(reason.secondary);
                        }}
                      />
                    }
                    label={reason.primary}
                  />
                );
              })}
          </FormControl>

          {secondaryOptions.length > 0 && (
            <>
              <Typography
                variant='body2'
                component='p'
              >
                Please select the relevant reason to drop this lead.
              </Typography>

              <FormGroup>
                {secondaryOptions.map((reason, index) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedReasons.secondary[index] ?? false}
                          onChange={(e) =>
                            handleSecondaryReasonSelect(index, reason)
                          }
                          color='success'
                        />
                      }
                      label={reason.value}
                    />
                  );
                })}
              </FormGroup>
            </>
          )}

          {tips.length > 0 && (
            <Stack
              spacing={1}
              sx={{
                padding: '1em',
                backgroundColor: '#fff9c4',
                color: 'black',
                borderRadius: '1em',
                width: '100%',
                height: 'fit-content',
              }}
            >
              <Stack
                direction='row'
                spacing={1}
              >
                <TipsAndUpdatesIcon />
                <Typography
                  variant='body1'
                  component='p'
                >
                  Tips
                </Typography>
              </Stack>

              <Stack spacing={1}>
                {tips.map((tip, index) => {
                  return (
                    <Typography
                      variant='body2'
                      component='p'
                    >
                      {index + 1}. {tip}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          variant='outlined'
          onClick={(e) => handleDropReasonModalClose('cancel')}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={(e) => handleDropReasonModalClose('save')}
          disabled={
            (selectedReasons.primary ?? '') === '' ||
            (secondaryOptions.length > 0 &&
              Object.keys(selectedReasons.secondary).length === 0)
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DropReasonModal;
