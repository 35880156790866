import constants from '../utils/constants';
import {
  getCurrentAdminRole,
  getCurrentAdminAllowLeadAssignment,
  getCurrentAdminAccessType,
} from '../utils/common';
const ADMIN_PROFILE_WILDCARD = '/admin-profile/*';
const ADMIN_PROFILE = 'admin-profile';
const {
  INDEPENDENT_CONTRIBUTOR: IC,
  ADMIN,
  MARKETING,
  MANAGER,
  SENIOR_MANAGER,
  FINANCE,
  PROGRAM_MANAGER: PM,
  HR,
  TA,
  INTERVIEWER,
  TRAINEE,
  TRAINER,
  ACCOUNTANT,
  SENIOR_MARKETING_MANAGER,
  RESTRICTED,
} = constants;

// Syntax: replace dynamic ids with "*".
// Ex: "/users/:id/interactions/:interaction_id" => "/users/*/interactions/*"

export const roleRouteMapper = {
  '/notifications': [
    ADMIN,
    IC,
    MARKETING,
    MANAGER,
    SENIOR_MANAGER,
    HR,
    TA,
    TRAINEE,
  ],

  '/leads/DNP': [IC, TRAINEE],
  '/leads/RCB': [IC, TRAINEE],
  '/leads/follow-ups-due': [ADMIN, IC, TRAINEE],
  '/leads/hot-leads': [ADMIN, IC, MANAGER, SENIOR_MANAGER, TRAINEE, MARKETING],

  '/leads/*': [
    ADMIN,
    IC,
    MANAGER,
    SENIOR_MANAGER,
    MARKETING,
    FINANCE,
    PM,
    TRAINEE,
  ],
  '/leads/*/call': [ADMIN],
  '/leads/*/call-dialing': [ADMIN],
  // "/leads/prospects": [ADMIN, IC], // not needed for now
  '/leads/total': [ADMIN, IC, MANAGER, SENIOR_MANAGER, TRAINEE],
  '/leads/priority-list': [ADMIN, IC, TRAINEE], // experimental
  '/leads/new': [ADMIN, IC, MANAGER, SENIOR_MANAGER, TRAINEE],
  '/payment-status': [ADMIN, FINANCE],

  '/recent-interactions': [ADMIN, MANAGER, SENIOR_MANAGER, MARKETING],
  '/bucket-search': [
    ADMIN,
    IC,
    TRAINEE,
    MANAGER,
    SENIOR_MANAGER,
    MARKETING,
    FINANCE,
    PM,
  ],
  '/reports': [ADMIN, IC, MANAGER, SENIOR_MANAGER, HR, TA, TRAINEE],
  '/restricted-mode': [IC, TRAINEE],
  '/restricted-mode/work-log': [IC, TRAINEE],
  '/error': [
    IC,
    ADMIN,
    MARKETING,
    MANAGER,
    SENIOR_MANAGER,
    FINANCE,
    PM,
    HR,
    TA,
    INTERVIEWER,
    TRAINEE,
    TRAINER,
    ACCOUNTANT,
    SENIOR_MARKETING_MANAGER,
  ],

  '/conversions/my-conversions': [ADMIN],
  '/conversions/generate-receipt': [ADMIN, FINANCE, SENIOR_MANAGER],

  '/conversions/post-sales': [
    ADMIN,
    IC,
    MANAGER,
    SENIOR_MANAGER,
    FINANCE,
    PM,
    TRAINEE,
  ],
  '/conversions/post-sales-finance': [ADMIN, FINANCE],

  '/conversions/cap-tracking': [ADMIN, MANAGER, SENIOR_MANAGER, FINANCE],

  '/dashboard/cap-tracking': [ADMIN, MANAGER, ACCOUNTANT, SENIOR_MANAGER],
  '/dashboard/marketing': [ADMIN, MARKETING, SENIOR_MARKETING_MANAGER, HR],

  '/dashboard/manager': [ADMIN, MANAGER, SENIOR_MANAGER],

  '/dashboard/admin': [ADMIN],
  '/dashboard/salsa': [ADMIN],
  '/dashboard/hr': [ADMIN, HR, TA],
  '/dashboard/accountant': [ADMIN, ACCOUNTANT],
  '/dashboard/leaderboard': [ADMIN, MANAGER, SENIOR_MANAGER],
  '/dashboard/revenue': [ADMIN, HR],
  '/ic-home': [ADMIN],

  '/lead-funnel': [ADMIN, MARKETING, SENIOR_MANAGER],

  '/training': [ADMIN],
  '/training/mockcalls': [ADMIN],
  '/training/mockcalls/*': [ADMIN],
  '/training/livecall': [ADMIN],
  '/training/modules': [ADMIN],
  '/training/testing': [ADMIN],
  '/training/manager-screen': [ADMIN],
  '/training/ic-screen': [ADMIN],
  '/training/ic-mockcalls': [ADMIN],
  '/training/recording': [ADMIN],
  '/training/trainer-dashboard': [ADMIN],

  '/hrms/interactions': [ADMIN, HR, TA, INTERVIEWER],
  '/hrms/candidates': [ADMIN, HR, TA, INTERVIEWER],
  [ADMIN_PROFILE_WILDCARD]: [
    ADMIN,
    IC,
    MANAGER,
    SENIOR_MANAGER,
    PM,
    MARKETING,
    FINANCE,
    TRAINEE,
    TRAINER,
    SENIOR_MARKETING_MANAGER,
    HR,
    TA,
    INTERVIEWER,
    ACCOUNTANT,
  ],

  '/hrms/recent-interactions': [ADMIN, HR, TA],
  '/hrms/candidate-search': [ADMIN, HR, TA],
  '/productivity-insights': [ADMIN, MANAGER, SENIOR_MANAGER, INTERVIEWER],
  '/hrms/follow-ups-due': [ADMIN, HR, TA, INTERVIEWER],
};

export const canAccessRoute = (route) => {
  route = sanitizeRequestRoute(route);
  const adminRole = getCurrentAdminRole();
  if (adminRole.includes(TRAINEE) && !getCurrentAdminAllowLeadAssignment()) {
    return false;
  }
  if (
    (adminRole.includes(TRAINEE) || adminRole.includes(IC)) &&
    getCurrentAdminAccessType() === RESTRICTED
  ) {
    return false;
  }
  return (
    (roleRouteMapper[route] ?? []).filter((role) => adminRole.includes(role))
      .length > 0
  );
};

export const sanitizeRequestRoute = (route) => {
  let path = route.split('?')[0];
  path = path.replace(/api/g, '');
  path = path.split('/');
  const finalPath = [];

  path.forEach((element, index) => {
    if (element === '') return;
    // this has been added, if user adds ../admin-profile in URL it will redirect to logged in user profile
    if (index === path.length - 1 && path[index] === ADMIN_PROFILE) {
      finalPath.push(element);
      finalPath.push('*');
      path = '/' + finalPath.join('/');
      return path;
    } else if (index === path.length - 1 && path[index - 1] === ADMIN_PROFILE) {
      finalPath.push('*');
    } else if (!isNaN(Number(element))) {
      // replaces dynamic ids in request url with "*".
      finalPath.push('*');
    } else {
      finalPath.push(element);
    }
  });
  path = '/' + finalPath.join('/');
  return path;
};
