import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';

export default function PaymnetTable({ paymentData }) {
  return (
    <Box mt={2}>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label='simple table'
        >
          <TableHead>
            <TableRow>
              <TableCell>Sl.No</TableCell>
              <TableCell align='right'>Lead ID</TableCell>
              <TableCell align='right'>UTR Number</TableCell>
              <TableCell align='right'>EMI Provider</TableCell>
              <TableCell align='right'>From</TableCell>
              <TableCell align='right'>To</TableCell>
              <TableCell align='right'>Status</TableCell>
              <TableCell align='right'>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentData.map((item, index) => (
              <TableRow
                key={item.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component='th'
                  scope='row'
                >
                  {index + 1}
                </TableCell>
                <TableCell align='right'>{item.lead_id}</TableCell>
                <TableCell align='right'>{item.payment_id}</TableCell>
                <TableCell align='right'>{item.emi_provider}</TableCell>
                <TableCell align='right'>{item.from}</TableCell>
                <TableCell align='right'>{item.to}</TableCell>
                <TableCell align='right'>{item.status}</TableCell>
                <TableCell align='right'>{item.action}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
