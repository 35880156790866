import React, { useEffect } from 'react';
import constants from '../../../utils/training/constants';
import { Typography } from '@mui/material';

const PdfViewer = ({ selectedModule, sendClickDataForTrainee, url }) => {
  useEffect(() => {
    sendClickDataForTrainee(constants.FLOW_CHART, selectedModule);
    return () => {};
  }, [selectedModule, sendClickDataForTrainee]);

  return (
    <div>
      {url ? (
        <iframe
          src={url}
          title='PDF'
          width='100%'
          height='600px'
          style={{ border: 'none' }}
        />
      ) : (
        <Typography sx={{ marginLeft: '30%', marginTop: '20%' }}>
          Pdf is not avaliable
        </Typography>
      )}
    </div>
  );
};

export default PdfViewer;
