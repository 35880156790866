import {
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  currentAdminIsAdmin,
  currentAdminIsManager,
  currentAdminIsSeniorManager,
  getTimeZoneFormattedTimestamp,
  splitByCamelCaseAndCapitalize,
} from '../../utils/common';
import constants from '../../utils/constants';
import axios from 'axios';
import { base_url } from '../../components/Mode';
import { getTimePeriod } from '../../components/Sidebar';
import InsightsVerificationModal from './InsightsVerificationModal';
import AdminsAutocompleteDropdown from '../../components/admins/AdminAutoComplete';
import CustomBackdrop from '../CustomBackdrop';

export const useStyles = {
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white',
    zIndex: 3,
  },
};

export default function ManagerTeamOverview({
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
}) {
  const admin = JSON.parse(localStorage.getItem('admin')) || {};
  const [teamData, setTeamData] = useState({});
  const [teamMembers, setTeamMembers] = useState([]);
  const [openDetailsVerificationModal, setOpenDetailsVerificationModal] =
    useState(false);
  const [selectedAdminForVerification, setSelectedAdminForVerification] =
    useState('');
  const [filters, setFilters] = useState({
    of: '',
    from: getTimeZoneFormattedTimestamp(new Date(), -24).split('T')[0], // -24 to get yesterday's date
    to: getTimeZoneFormattedTimestamp(new Date(), -24).split('T')[0],
  });
  const [isFetchingResults, setIsFetchingResults] = useState(false);

  useEffect(() => {
    if (filters.of === '') {
      setTeamData({});
      setTeamMembers([]);
    } else if (filters.from && filters.to) {
      fetchManagerOverview();
    }
    return () => {};
  }, [filters]);

  function fetchManagerOverview() {
    const params = [];
    Object.entries(filters).forEach(([key, val], index) => {
      if (val && val !== '') {
        params.push(`${key}=${val}`);
      }
    });

    let url = `${base_url()}/api/manager`;
    if (params.length > 0) {
      url += `?team_reports=1&${params.join('&')}`;
    }

    setIsFetchingResults(true); // to show a backdrop for the cases when api response is slow.

    axios
      .get(url)
      .then((res) => {
        const { data } = res;
        setTeamMembers(data.columns);
        setTeamData(data.rows);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message || 'Could not fetch manager overview'
        );
      })
      .finally(() => {
        setIsFetchingResults(false);
      });
  }

  const getAdminsDropdown = () => {
    /**
     * For admins, only show all access allowed list of managers.
     * For managers, only show their own entry in the dropdown.
     */
    return (
      <AdminsAutocompleteDropdown
        label='Insights of'
        showNoneOption
        showCurrentAdmin={
          !currentAdminIsAdmin() &&
          (currentAdminIsManager() || currentAdminIsSeniorManager())
        }
        adminOptions={{
          access_type: ['allowed', 'restricted'],
          roles: [constants.MANAGER, constants.SENIOR_MANAGER],
        }}
        value={filters.of}
        onChangeHandler={(e, newValue) => {
          setFilters({
            ...filters,
            of: newValue.value === 'none' ? '' : newValue.value,
          });
        }}
        size='small'
        sx={{
          width: 200,
        }}
      />
    );
  };

  return (
    <Stack
      m={3}
      spacing={5}
    >
      {isFetchingResults && <CustomBackdrop open={isFetchingResults} />}
      <Stack
        direction='row-reverse'
        spacing={2}
      >
        <TextField
          type='date'
          label='to'
          size='small'
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setFilters({ ...filters, to: e.target.value })}
          value={filters.to}
        />
        <TextField
          type='date'
          label='from'
          size='small'
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setFilters({ ...filters, from: e.target.value })}
          value={filters.from}
        />
        {getAdminsDropdown()}
      </Stack>

      {Object.keys(teamData).length > 0 &&
        teamMembers.length > 0 &&
        !openDetailsVerificationModal && (
          <ManagerTeamOverviewTable
            {...{
              teamData,
              teamMembers,
              shouldShowVerifyButton:
                filters.from === filters.to && filters.from !== '',
              setOpenDetailsVerificationModal,
              setSelectedAdminForVerification,
            }}
          />
        )}
      {(Object.keys(teamData).length === 0 || teamMembers.length === 0) && (
        <Typography
          alignSelf='center'
          variant='body2'
          component='p'
        >
          No data found
        </Typography>
      )}
      {openDetailsVerificationModal && selectedAdminForVerification !== '' && (
        <InsightsVerificationModal
          {...{
            selectedAdminForVerification,
            setSelectedAdminForVerification,
            setOpenDetailsVerificationModal,
            openDetailsVerificationModal,
            date: filters.from,
            setHasError,
            setErrorMessage,
            setIsSuccess,
            setSuccessMessage,
            fetchOverview: fetchManagerOverview,
          }}
        />
      )}
    </Stack>
  );
}

function ManagerTeamOverviewTable({
  teamMembers,
  teamData,
  shouldShowVerifyButton,
  setSelectedAdminForVerification,
  setOpenDetailsVerificationModal,
}) {
  const getVerificationColor = ({
    verifiedFields,
    totalVerificationFields,
  }) => {
    if (verifiedFields === 0) {
      return 'default';
    }
    if (verifiedFields !== totalVerificationFields) {
      return '#fbc02d'; // yellow
    }
    return 'green';
  };

  const getVerificationText = ({ verifiedFields, totalVerificationFields }) => {
    if (verifiedFields === 0) {
      return 'Verify';
    }
    if (verifiedFields !== totalVerificationFields) {
      return `Verified ${verifiedFields}/${totalVerificationFields}`;
    }
    return 'Verified';
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            size='medium'
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className={useStyles.sticky}
                  sx={{ borderRight: 1, borderBottom: 1 }}
                >
                  Name
                </TableCell>
                {teamMembers.map((member, index) => (
                  <TableCell
                    align='center'
                    sx={{ borderRight: 1, borderBottom: 1, padding: 2 }}
                  >
                    {member.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.keys(teamData)?.map((metric, index1) => {
                if (metric !== 'verification') {
                  return (
                    <TableRow key={index1}>
                      <TableCell
                        className={useStyles.sticky}
                        sx={{
                          borderRight: 1,
                          borderBottom: 1,
                        }}
                      >
                        {splitByCamelCaseAndCapitalize(metric)}
                      </TableCell>
                      {teamData[metric]?.map((datum, index2) => (
                        <TableCell
                          align='center'
                          sx={{
                            borderRight: 1,
                            borderBottom: 1,
                            padding: 2,
                          }}
                        >
                          {datum.value ?? '-'}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                }
              })}
              {shouldShowVerifyButton && (
                <TableRow>
                  <TableCell
                    className={useStyles.sticky}
                    sx={{
                      borderRight: 1,
                      borderBottom: 1,
                    }}
                  />
                  {teamData.verification.map((datum, idx) => {
                    return (
                      <TableCell
                        align='center'
                        sx={{
                          borderRight: 1,
                          borderBottom: 1,
                          padding: 2,
                        }}
                      >
                        <Button
                          size='small'
                          variant='contained'
                          sx={{
                            backgroundColor: getVerificationColor(datum.value),
                          }}
                          onClick={(e) => {
                            setOpenDetailsVerificationModal(true);
                            setSelectedAdminForVerification(
                              teamMembers[idx]?.admin_id
                            );
                          }}
                        >
                          {getVerificationText(datum.value)}
                        </Button>
                      </TableCell>
                    );
                  })}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
