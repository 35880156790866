import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { base_url } from '../../Mode';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import { Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const PaymentFinanceTable = ({ filters, setFilters }) => {
  const [postSalesData, setPostSalesData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [pdBeingUpdated, setPDBeingUpdated] = useState({});
  const [pdIdToIndexMap, setPDIdToIndexMap] = useState({});

  useEffect(() => {
    const tmpMap = {};
    postSalesData?.forEach((payment, index) => {
      tmpMap[payment.id] = index;
    });
    setPDIdToIndexMap(tmpMap);
    return () => {};
  }, [postSalesData]);

  useEffect(() => {
    GetPostSalesPaymentsData();
    return () => {};
  }, [filters, isSuccess, hasError]);

  const buildApiUrlWithFilters = (filters) => {
    const queryString = Object.keys(filters)
      .filter((key) => filters[key] !== undefined && filters[key] !== null)
      .map((key) => `${key}=${filters[key]}`)
      .join('&');

    return `/api/post-sales-payments/filter?${queryString}`;
  };

  const GetPostSalesPaymentsData = () => {
    const url = buildApiUrlWithFilters(filters);
    axios
      .get(url)
      .then((res) => {
        setPostSalesData(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            'An error occurred while creating the payment. Please try again later.'
        );
      });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'approved':
        return '#689f38';
      case 'rejected':
        return '#e53935';
      default:
        return '#fdd835';
    }
  };

  const processPaymentDetailsUpdate = (updatedPayment) => {
    if (updatedPayment.status === 'pending') {
      setHasError(true);
      setErrorMessage(
        "Couldn't update the status as pending once approved or rejected"
      );

      return updatedPayment;
    }
    const body = { ...updatedPayment };
    axios
      .patch(`${base_url()}/api/post-sales-payments/${updatedPayment.id}`, body)
      .then((res) => {
        const index = pdIdToIndexMap[updatedPayment.id];
        const tmp = [...postSalesData];
        tmp[index] = res.data;
        updatedPayment = {
          ...res.data,
        };
        setPostSalesData(tmp);
        setIsSuccess(true);
        setIsSuccess('Payment details updated');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't update the payment details"
        );
      });

    return updatedPayment;
  };

  const handlePDUpdate = (clickType, id) => {
    if (clickType === 'clear') {
      setPDBeingUpdated({
        ...pdBeingUpdated,
        [id]: {
          mode: 'view',
          ignoreModifications: true,
        },
      });
      return;
    }

    setPDBeingUpdated({
      ...pdBeingUpdated,
      [id]: {
        mode: 'view',
      },
    });
  };

  const columns = [
    {
      field: 's.no.',
      headerName: 'S.No.',
      minWidth: 80,
      maxWidth: 80,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        return <Typography variant='body2'>{index}</Typography>;
      },
    },
    {
      field: 'lead_name',
      headerName: 'Lead name',
      minWidth: 250,
      maxWidth: 250,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2'>
            {params.row.lead_name} #{params.row.lead_id || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 200,
      maxWidth: 200,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      renderCell: (params) => {
        if (params.row.email) {
          return <Typography variant='body2'>{params.row.email}</Typography>;
        }
        return <span style={{ color: '#cecece' }}>Unavailable</span>;
      },
    },
    {
      field: 'poc_name',
      headerName: 'POC',
      minWidth: 230,
      maxWidth: 230,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2'>{params.row.poc_name || '-'}</Typography>
        );
      },
    },
    {
      field: 'source_type',
      headerName: 'Source',
      minWidth: 230,
      maxWidth: 230,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2'>
            {params.row.source_type || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'transaction_id',
      headerName: 'Transaction Id',
      minWidth: 230,
      maxWidth: 230,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2'>
            {params.row.transaction_id || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'txn_amount',
      headerName: 'Transaction Amount',
      minWidth: 230,
      maxWidth: 230,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      valueGetter: (params) => {
        return params.row?.txn_amount || '-';
      },
    },
    {
      field: 'disbursed_amount',
      headerName: 'Disbursed Amount',
      minWidth: 230,
      maxWidth: 230,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2'>
            {params.row.disbursed_amount || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'disbursed_date',
      headerName: 'Disbursed Date ',
      minWidth: 150,
      maxWidth: 150,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      renderCell: (params) => {
        const rawDate = params.row?.disbursed_date;
        if (rawDate) {
          const dateObject = new Date(rawDate);
          return dateObject.toLocaleDateString();
        } else {
          return '-';
        }
      },
    },

    {
      field: 'emi_provider',
      headerName: 'EMI Provider ',
      minWidth: 150,
      maxWidth: 150,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2'>
            {params.row.emi_provider || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'status',
      minWidth: 200,
      maxWidth: 200,
      align: 'center',
      headerAlign: 'center',
      headerName: 'Verification Status',
      editable: true,
      type: 'singleSelect',
      valueOptions: ['approved', 'rejected'],
      renderCell: (params) => {
        const status = params.row.status || 'Pending';
        let backgroundColor = getStatusColor(params?.row?.status);
        return (
          <Typography
            sx={{ backgroundColor, padding: '8px', borderRadius: '20px' }}
            variant='body2'
          >
            {status}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = pdBeingUpdated[id]?.mode === 'edit';
        if (isInEditMode) {
          return [
            <CheckIcon
              fontSize='medium'
              color='success'
              sx={{ cursor: 'pointer' }}
              onClick={(e) => handlePDUpdate('updated', id)}
            />,
            <ClearIcon
              fontSize='medium'
              color='error'
              onClick={(e) => handlePDUpdate('clear', id)}
              sx={{ cursor: 'pointer' }}
            />,
          ];
        }
        return [
          <EditIcon
            onClick={(e) => {
              setPDBeingUpdated({
                ...pdBeingUpdated,
                [id]: {
                  mode: 'edit',
                },
              });
            }}
            sx={{ cursor: 'pointer' }}
          />,
        ];
      },
    },
  ];
  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={isSuccess}
          setIsSuccess={setSuccessMessage}
        />
      )}
      <DataGrid
        rows={postSalesData}
        columns={columns}
        editMode='row'
        autoHeight
        rowModesModel={pdBeingUpdated}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processPaymentDetailsUpdate}
        onRowEditStart={(params, event) => {
          event.defaultMuiPrevented = true;
        }}
        onRowEditStop={(params, event) => {
          event.defaultMuiPrevented = true;
        }}
        sx={{
          margin: '10px',
          padding: '10px',
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#e3f2fd',
            cursor: 'pointer',
          },
        }}
      />
    </>
  );
};
export default PaymentFinanceTable;
