import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';

const PaymentFinanceFilter = ({ filters, setFilters }) => {
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {}, [setFilters]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const resetAllFilters = (e) => {
    setFilters({
      status: '',
      fromDate: '',
      toDate: '',
      leadId: '',
      emiProvider: '',
      sourceType: '',
    });
  };

  return (
    <>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography
            variant='h6'
            component='p'
          >
            Filters
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={0.8}
            direction={{
              xs: 'column',
              sm: 'row',
            }}
          >
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <FormControl
                id='lead_id'
                fullWidth
              >
                <TextField
                  size='small'
                  type='text'
                  name='leadId'
                  placeholder='Lead Id'
                  onChange={handleFilterChange}
                  value={filters.leadId}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <FormControl
                id='source'
                size='small'
                fullWidth
              >
                <InputLabel id='source'>Source</InputLabel>
                <Select
                  name='sourceType'
                  label='source'
                  labelId='source'
                  onChange={handleFilterChange}
                  value={filters.sourceType || 'none'}
                >
                  <MenuItem value='none'>None</MenuItem>
                  <MenuItem value='bank_transfer'>Bank Transfer</MenuItem>
                  <MenuItem value='online_payment'>Online Payment</MenuItem>
                  <MenuItem value='emi'>EMI</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <TextField
                size='small'
                type='datetime-local'
                name='fromDate'
                label='From'
                value={filters.fromDate}
                onChange={handleFilterChange}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                  marginBottom: '10px',
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <TextField
                size='small'
                type='datetime-local'
                name='toDate'
                label='To'
                value={filters.toDate}
                onChange={handleFilterChange}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <FormControl
                id='status'
                size='small'
                fullWidth
              >
                <InputLabel id='status'>Status</InputLabel>
                <Select
                  name='status'
                  label='status'
                  labelId='status'
                  onChange={handleFilterChange}
                  value={filters.status || 'none'}
                >
                  <MenuItem value='none'>None</MenuItem>
                  <MenuItem value='approved'>Approved</MenuItem>
                  <MenuItem value='rejected'>Rejected</MenuItem>
                  <MenuItem value='pending'>Pending</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <FormControl
                id='emi_provider'
                size='small'
                fullWidth
              >
                <InputLabel id='emi_provider'>EMI Provider</InputLabel>
                <Select
                  name='emiProvider'
                  label='emi_provider'
                  labelId='emi_provider'
                  onChange={handleFilterChange}
                  value={filters.emiProvider || 'none'}
                >
                  <MenuItem value='none'>None</MenuItem>
                  <MenuItem value='eduvanz'>Eduvanz</MenuItem>
                  <MenuItem value='kuhoo'>Kuhoo</MenuItem>
                  <MenuItem value='propelld'>Propelld</MenuItem>
                  <MenuItem value='liquiloans'>Liquiloans</MenuItem>
                  <MenuItem value='varthana'>Varthana</MenuItem>
                  <MenuItem value='other'>other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <Button
                variant='outlined'
                px={10}
                onClick={(e) => resetAllFilters(e)}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default PaymentFinanceFilter;
