import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { base_url } from '../Mode';
import { getTimeZoneFormattedTimestamp } from '../../utils/common';

function LabelBox({ label, presetFilters, labelData, updateCurrentLead }) {
  function displayName(fname, lname) {
    let name = [];
    if (fname && fname !== '') {
      name.push(fname);
    }
    if (lname && lname !== '') {
      name.push(lname);
    }

    name = name.join(' ');
    if (name.length > 20) {
      return `${name.substring(0, 20)}...`;
    }
    return name;
  }

  function displayLabel(labelText) {
    if (labelText.length > 55) {
      return `${labelText.substring(0, 55)}...`;
    }
    return labelText;
  }

  function getColor(colorInDB, defaultColor) {
    if (!colorInDB || colorInDB === '') {
      return defaultColor;
    }
    return colorInDB;
  }

  return (
    <Stack
      width='18rem'
      sx={{
        border: 'solid 1px black',
        m: 1,
        borderRadius: '0.5rem',
      }}
      height='17rem'
      maxHeight='17rem'
      justifyContent='space-between'
      pb='0.5rem'
    >
      <Stack width='inherit'>
        <Box
          sx={{
            backgroundColor: getColor(
              labelData['leads'][0].label_color,
              'gray'
            ),
            color: getColor(labelData['leads'][0].label_font_color, 'black'),
            width: 'inherit',
            padding: '0.3rem',
            paddingLeft: 0,
            paddingRight: 0,
            borderTopLeftRadius: '0.5rem',
            borderTopRightRadius: '0.5rem',
          }}
        >
          <Typography
            variant='body1'
            align='center'
          >
            {displayLabel(label)}
          </Typography>
        </Box>

        <Box pt='0.5rem'>
          {labelData['leads']?.map((lead, index) => {
            return (
              <Box
                display='flex'
                justifyContent='space-between'
                onClick={(e) => updateCurrentLead(lead.id)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
              >
                <Typography
                  variant='body1'
                  sx={{ color: 'black', paddingInlineStart: 2 }}
                >
                  {`${displayName(lead.fname, lead.lname)}`}
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ color: 'black', paddingInlineEnd: 2 }}
                >
                  {`#${lead.id}`}
                </Typography>
              </Box>
            );
          })}

          {labelData.totalLeads > 5 && (
            <Typography
              variant='body2'
              align='center'
            >
              {`+${labelData.totalLeads - 5} more`}
            </Typography>
          )}
        </Box>
      </Stack>

      <Stack alignItems='center'>
        <Button
          variant='contained'
          size='small'
          component={Link}
          to={`/bucket-search?label=${label}${
            presetFilters.length > 0 ? `&${presetFilters}` : ''
          }`}
        >
          View details
        </Button>
      </Stack>
    </Stack>
  );
}

export default function GroupByLabelView({ queryParams }) {
  const [labelGroups, setLableGroups] = useState({});
  const [presetFilters, setPresetFilters] = useState('');

  useEffect(() => {
    fetchLeadsGroupedByLabels();
    return () => {};
  }, []);

  const updateCurrentLead = (leadId) => {
    const currentUrl = '/leads/' + leadId;
    window.open(currentUrl, '_blank');
  };

  function getPresetFilters() {
    const params = [];
    for (let param in queryParams) {
      if (param === 'label') {
        continue;
      }
      if (param === 'due_now') {
        const date = getTimeZoneFormattedTimestamp(new Date());
        params.push(`snoozed_till=${date}`);
      } else if (param === 'due_in_3h') {
        const now = getTimeZoneFormattedTimestamp(new Date());
        const dueIn3Hours = getTimeZoneFormattedTimestamp(new Date(), 3);
        params.push(`snoozed_from=${now}`);
        params.push(`snoozed_till=${dueIn3Hours}`);
      } else {
        params.push(`${param}=${queryParams[param]}`);
      }
    }
    if (params.length > 0) {
      setPresetFilters(params.join('&'));
      return params.join('&');
    }
    return '';
  }

  function fetchLeadsGroupedByLabels() {
    let filter = presetFilters;
    if (Object.keys(queryParams).length > 0) {
      filter = getPresetFilters();
    }

    let url = `${base_url()}/api/users?view=group_by_label`;
    if (filter.length > 0) {
      url += `&${filter}`;
    }

    axios
      .get(url)
      .then((res) => {
        setLableGroups(res['data']);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Stack
      direction='row'
      width='inherit'
      gap={0.1}
      flexWrap='wrap'
      sx={{
        maxHeight: '1000px',
        overflowY: 'auto',
      }}
    >
      {Object.keys(labelGroups).length > 0 &&
        Object.keys(labelGroups).map((label, index) => {
          return (
            <LabelBox
              key={index + 1}
              label={labelGroups[label].label}
              presetFilters={presetFilters}
              labelData={labelGroups[label]}
              updateCurrentLead={updateCurrentLead}
            />
          );
        })}
      {Object.keys(labelGroups).length === 0 && (
        <Typography
          variant='body1'
          align='center'
        >
          No labels have been added so far.
        </Typography>
      )}
    </Stack>
  );
}
