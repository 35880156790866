import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const TimeCircle = ({ time, currentLeadData }) => {
  const [formattedTime, setFormattedTime] = useState(time);
  const circleRef = useRef(null);
  const textRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setFormattedTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const calculateStrokeDasharray = () => {
    const percent = (formattedTime / time) * 90;
    const dash = (percent / 100) * 340;
    const gap = 490 - dash;
    return `${dash} ${gap}`;
  };

  useEffect(() => {
    const tl = gsap.timeline();

    tl.to(circleRef.current, {
      strokeDasharray: calculateStrokeDasharray(),
      strokeWidth: 10,
      stroke: '#C6AEEC',
      duration: 1,
    });

    if (formattedTime >= 0) {
      const newFormattedTime = formatTime(formattedTime - 1);

      tl.to(
        textRef.current,
        {
          y: -20,
          opacity: 0,
          onComplete: () => {
            if (!textRef.current) {
              return;
            }

            textRef.current.textContent = newFormattedTime;
            gsap.fromTo(
              textRef.current,
              { y: 20, opacity: 0 },
              { y: 0, opacity: 1, duration: 1 }
            );
          },
        },
        0
      );
      if (formattedTime === 0) {
        setTimeout(() => {
          const leadId = currentLeadData.id;
          navigate(`/leads/${leadId}/call-dialing`, {
            state: { currentLeadData },
          });
        }, 600);
      }
    }

    return () => {
      tl.kill();
    };
  }, [formattedTime, time]);

  return (
    <Box
      sx={{
        backgroundColor: 'transparent',
        height: '200px',
        width: '200px',
        mt: 2,
      }}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='130'
        height='130'
        viewBox='-2 -2 200 190'
        fill='#703EF3'
      >
        <circle
          id='fillCircle'
          cx='95'
          cy='95'
          r='90'
          fill='transparent'
          stroke='#703EF3'
          strokeWidth='10'
          style={{ vectorEffect: 'non-scaling-stroke', padding: '12px' }}
        />
        <circle
          ref={circleRef}
          cx='95'
          cy='95'
          r='90'
          fill='transparent'
          stroke='#C6AEEC'
          strokeWidth='10'
          style={{ vectorEffect: 'non-scaling-stroke' }}
        />
        <text
          ref={textRef}
          x='47%'
          y='50%'
          dominantBaseline='middle'
          textAnchor='middle'
          fill='#ffffff'
          fontSize='48'
        >
          {formatTime(formattedTime)}
        </text>
      </svg>
    </Box>
  );
};

function formatTime(time) {
  if (time > 0) {
    const seconds = time % 60;
    return `${seconds < 10 ? `0${seconds}` : seconds}`;
  }
  return `00`;
}

export default TimeCircle;
