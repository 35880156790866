import {
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
  Grid,
} from '@mui/material';
const CustomTextField = ({
  changeHandler,
  value,
  customAttributes,
  customStyle,
}) => {
  const defaultAttributes = {};

  const defaultStyle = {};

  const inputAttributes = customAttributes
    ? customAttributes
    : defaultAttributes;

  const inputStyle = customStyle ? customStyle : defaultStyle;

  return (
    <>
      <TextField
        value={value}
        required='true'
        {...inputAttributes}
        sx={inputStyle}
        onChange={changeHandler}
      ></TextField>
    </>
  );
};

export default CustomTextField;
