import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Checkbox,
  FormControl,
  Stack,
  TextField,
  Typography,
  Button,
  Grid,
  Autocomplete,
  Slider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { base_url } from '../Mode';

const initialFilterState = {
  from: '',
  to: '',
  managers: '',
  name: '',
  email: '',
};

const getFormattedDate = (date) => {
  return date.toISOString().split('T')[0];
};

const today = new Date();

const oneMonthBefore = new Date();
oneMonthBefore.setMonth(today.getMonth() - 1);

const SalaryFilters = ({
  fetchSalaryData,
  fetchingData,
  setHasError,
  setErrorMessage,
  isPerformance = null,
  setSliderValue,
  sliderValue,
}) => {
  const [filters, setFilters] = useState(initialFilterState);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subordinateAdmins, setSubordinateAdmins] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [startAt, setStartAt] = useState(getFormattedDate(oneMonthBefore));
  const [endAt, setEndAt] = useState(getFormattedDate(today));

  useEffect(() => {
    getSalaryDetails();
    getIndependentContributorDropdown();
  }, []);

  const findIdByName = (selectedData, name) => {
    const selectedManager = selectedData?.find(
      (data) => data.Full_Name === name
    );
    return selectedManager ? selectedManager?.Admin_id : null;
  };

  const getIndependentContributorDropdown = async () => {
    try {
      const res = await axios.get(`${base_url()}/api/all-reportees`, {
        params: {
          capTracking: true,
        },
      });
      const reportee = res?.data;

      const subordinateAdminsFiltered = reportee.filter(
        (ic) => ic.role === 'lead'
      );

      setSubordinateAdmins(subordinateAdminsFiltered);
    } catch (error) {
      setHasError(true);
      setErrorMessage(`Error in fetching assignee data`);
    }
  };

  const getSalaryDetails = () => {
    const urlToFetch = buildQueryString(filters);

    fetchSalaryData(urlToFetch);
  };

  const buildQueryString = () => {
    const baseUrl = isPerformance ? 'sales_performance' : 'salaries';

    let queryString = `/api/${baseUrl}?`;

    const SelectedManagersId = selectedManagers
      .map((name) => findIdByName(subordinateAdmins, name.Full_Name))
      .filter((id) => id !== null)
      .join(',');

    if (name) {
      queryString += `&name=${name}`;
    }

    if (email) {
      queryString += `&email=${email}`;
    }

    if (startAt) {
      queryString += `&from=${startAt}`;
    }

    if (SelectedManagersId) {
      queryString += `&managers=${SelectedManagersId}`;
    }

    if (endAt) {
      queryString += `&to=${endAt}`;
    }

    return queryString;
  };

  const handleReset = () => {
    setFilters(initialFilterState);
    resetStates();
  };

  const resetStates = () => {
    setSelectedManagers([]);
    setEmail('');
    setStartAt('');
    setEndAt('');
    setName('');
    setSliderValue(-20);
  };

  const sliderLabels = [
    { value: -20, label: 'None' },
    { value: 0, label: '0' },
    { value: 20, label: '20' },
    { value: 40, label: '40' },
    { value: 60, label: '60' },
    { value: 80, label: '80' },
    { value: 100, label: '100' },
    { value: 120, label: '100+' },
  ];

  return (
    <>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography
            variant='h6'
            component='p'
          >
            Filters
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControl
                id='name'
                size='small'
                sx={{ width: 200, marginRight: '15px' }}
              >
                <TextField
                  size='small'
                  type='text'
                  name='id'
                  placeholder='Name'
                  onChange={(event) => setName(event.target.value)}
                  value={name}
                />
              </FormControl>

              <FormControl
                id='email'
                size='small'
                sx={{ width: 200, marginRight: '15px' }}
              >
                <TextField
                  size='small'
                  type='text'
                  name='id'
                  placeholder='Email'
                  onChange={(event) => setEmail(event.target.value)}
                  value={email}
                />
              </FormControl>
              <Autocomplete
                size='small'
                limitTags={1}
                disablePortal
                sx={{ width: 200, marginRight: '15px' }}
                getOptionLabel={(option) => option.Full_Name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Select Manager'
                  />
                )}
                id='filtered-select'
                options={subordinateAdmins || []}
                value={selectedManagers.length > 0 ? selectedManagers[0] : null}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedManagers([newValue]);
                  } else {
                    setSelectedManagers([]);
                  }
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {option.Full_Name}
                  </li>
                )}
                isOptionEqualToValue={(option, value) => option === value}
              />

              {isPerformance && (
                <Box
                  sx={{ width: 300, marginRight: '15px', marginLeft: '15px' }}
                >
                  <Typography
                    variant='body1'
                    gutterBottom
                  >
                    Salary To Revenue Percent
                  </Typography>
                  <Slider
                    value={sliderValue}
                    onChange={(event, newValue) => setSliderValue(newValue)}
                    size='medium'
                    defaultValue={-20}
                    step={null}
                    marks={sliderLabels}
                    min={-20}
                    max={120}
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                sx={{ marginRight: '15px' }}
                size='small'
                id='start-date'
                label='From'
                type='date'
                value={startAt}
                onChange={(event) => setStartAt(event.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                size='small'
                id='end-date'
                label='To'
                type='date'
                value={endAt}
                onChange={(event) => setEndAt(event.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            <Grid
              container
              spacing={2}
              item
              xs={12}
              sm={6}
              md={4}
            >
              <Grid
                item
                xs={6}
              >
                <Button
                  variant='outlined'
                  fullWidth
                  onClick={(e) => handleReset(e)}
                >
                  Reset
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Button
                  variant='contained'
                  fullWidth
                  onClick={() => getSalaryDetails()}
                  disabled={fetchingData}
                >
                  {fetchingData ? 'Loading...!' : 'Search'}
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SalaryFilters;
