import React from 'react';

const MetabaseEmbed = () => {
  return (
    <div style={{ overflow: 'hidden' }}>
      <iframe
        src='https://kamat.heycoach.in/public/dashboard/70469d9c-1f40-4f3e-9f2a-0c2b5a511740?date_filter=past1days'
        width='102%'
        height='580'
        style={{
          border: 'none',
          borderTop: 'none',
          marginLeft: '-2px',
          overflow: 'hidden',
        }}
      />
    </div>
  );
};

export default MetabaseEmbed;
