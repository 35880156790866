import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CandidatePersonalInformation from './CandidatePersonalInformation';
import CandidateEducation from './CandidateEducation';
import CandidateProfessionalBackground from './CandidateProfessionalBackground';
import CandidateApplicationDetails from './CandidateApplicationDetails';

export function Search({ handleReset, filters, setFilters }) {
  const [expanded, setExpanded] = useState(true);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (
      (name === 'mobile1' || name === 'mobile2') &&
      (value.length > 10 || isNaN(Number(value)))
    ) {
      return;
    }

    setFilters({ ...filters, [name]: value });
  };

  return (
    <div
      style={{
        marginRight: '1px',
        marginLeft: '1px',
        marginTop: '-12px',
        marginBottom: '-12px',
      }}
    >
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography
            variant='h6'
            component='p'
          >
            Filters
          </Typography>
        </AccordionSummary>
        <CandidatePersonalInformation
          filters={filters}
          handleChange={handleChange}
        />
        <CandidateEducation
          filters={filters}
          handleChange={handleChange}
        />
        <CandidateProfessionalBackground
          filters={filters}
          handleChange={handleChange}
        />
        <CandidateApplicationDetails
          filters={filters}
          handleChange={handleChange}
          handleReset={handleReset}
        />
      </Accordion>
    </div>
  );
}
