import { Box } from '@mui/system';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import { getFormattedDate } from '../../utils/common';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export const options = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'Leads inflow',
    },
    zoom: {
      zoom: {
        pinch: {
          enabled: true,
        },
        wheel: {
          enabled: true,
        },
        mode: 'x',
      },
      pan: {
        enabled: true,
        mode: 'x',
      },
    },
  },
  scales: {
    y: {
      ticks: {
        fontSize: 10,
      },
    },
    x: {
      ticks: {
        fontSize: 10,
      },
    },
  },
};

const LeadsInflowGraph = ({ graphData }) => {
  const data = {
    labels: graphData.map((res) => getFormattedDate(new Date(res.date), true)),
    datasets: [
      {
        label: 'Dataset 1',
        data: graphData.map((res) => res.count),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        width: '70%',
      },
    ],
  };

  return (
    <Box
      sx={{
        maxHeight: '5%',
        width: '80%',
      }}
    >
      <Line
        data={data}
        options={options}
      />
    </Box>
  );
};

export default LeadsInflowGraph;
