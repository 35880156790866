import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import AssigneeOverview from './AssigneeOverview';
import DroppedLeads from './DroppedLeads';
import { TabPanel } from '../AdminDashboard';
import LeadsSnoozedTillToday from './LeadsSnoozedTillToday';
import NoInteraction from './NoInteraction';

export default function TeamOverview({ setHasError, setErrorMessage }) {
  const [currentSubTab, setCurrentSubTab] = useState(0);

  const handleTabChange = (e, newTabValue) => {
    setCurrentSubTab(newTabValue);
  };

  return (
    <Box sx={{ mr: 5, mt: 4 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={currentSubTab}
          onChange={handleTabChange}
        >
          <Tab label='Assignee overview' />
          <Tab label='Dropped leads' />
          <Tab label='Leads snoozed till today' />
          <Tab label='No interaction' />
        </Tabs>
      </Box>
      <TabPanel
        value={currentSubTab}
        index={0}
      >
        <AssigneeOverview {...{ setHasError, setErrorMessage }} />
      </TabPanel>
      <TabPanel
        value={currentSubTab}
        index={1}
      >
        <DroppedLeads {...{ setHasError, setErrorMessage }} />
      </TabPanel>
      <TabPanel
        value={currentSubTab}
        index={2}
      >
        <LeadsSnoozedTillToday {...{ setHasError, setErrorMessage }} />
      </TabPanel>
      {/* <TabPanel
        value={currentSubTab}
        index={3}
      >
        <NoInteraction {...{ setHasError, setErrorMessage }} />
      </TabPanel> */}
    </Box>
  );
}
