import { Box, Flex, Spacer } from '@chakra-ui/react';
import { useAuthStateValue } from './auth/AuthContext';
import {
  Button,
  Stack,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import BellNotifications from './Notifications/BellNotifications';
import { useCurrentLead } from './CurrentLeadContext';
import QuickSearch from './users/QuickSearch/QuickSearch';
import { useSocket } from '../socket/socketContext';
import { useEffect, useState } from 'react';
import constants from '../utils/constants';
import {
  currentAdminIsAdmin,
  currentAdminIsManager,
  currentAdminIsTraineeOrIC,
} from '../utils/common';
const { HR, TA, INTERVIEWER, ACCOUNTANT } = constants;

// const FocusModeSwitch = () => {
//   const { setLeadFocusMode, leadFocusMode, setLeadFocusModeByManual } =
//     useCurrentLead();
//   const handleChange = (event) => {
//     setLeadFocusMode(event.target.checked);
//     setLeadFocusModeByManual((leadFocusModeByManual) => !leadFocusModeByManual);
//   };

//   return (
//     <FormGroup>
//       <FormControlLabel
//         control={
//           <Switch
//             checked={leadFocusMode}
//             onChange={handleChange}
//             name='focusMode'
//             inputProps={{ 'aria-label': 'controlled' }}
//           />
//         }
//         label='Focus Mode'
//       />
//     </FormGroup>
//   );
// };

const VersionModeSwitch = () => {
  const { version, setVersion } = useCurrentLead();
  const handleChange = (event) => {
    setVersion((prev) => (prev === 0 ? 1 : 0));
  };

  return (
    <></>
    // <FormGroup>
    //   <FormControlLabel
    //     control={
    //       <Switch
    //         checked={version}
    //         onChange={handleChange}
    //         name='version'
    //         inputProps={{ 'aria-label': 'controlled' }}
    //       />
    //     }
    //     label='Version'
    //   />
    // </FormGroup>
  );
};

export default function Navbar() {
  const [isUserLoggedIn] = useAuthStateValue();
  const [socket, setSocket] = useSocket();
  const logedInDetails = JSON.parse(localStorage.getItem('admin')) ?? {};

  const checkForNavbarSearchVisible = () => {
    const len = logedInDetails.roles.length;
    if (
      len === 1 &&
      (logedInDetails.roles.includes(INTERVIEWER) ||
        logedInDetails.roles.includes(HR) ||
        logedInDetails.roles.includes(TA) ||
        logedInDetails.roles.includes(ACCOUNTANT))
    ) {
      return false;
    } else if (
      len >= 2 &&
      (logedInDetails.roles.includes(HR) || logedInDetails.roles.includes(TA))
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Box p={24}>
        <Flex
          h={16}
          align='center'
        >
          <Spacer />
          <Box>
            {isUserLoggedIn && (
              <Box>
                <Stack
                  direction={'row'}
                  alignItems='center'
                  spacing={3}
                  color='#000'
                >
                  {checkForNavbarSearchVisible() && (
                    <>
                      {currentAdminIsAdmin() && <VersionModeSwitch />}

                      {/* {!currentAdminIsManager() && <FocusModeSwitch />} */}
                      <QuickSearch />
                      {!currentAdminIsTraineeOrIC() && <BellNotifications />}
                    </>
                  )}
                </Stack>
              </Box>
            )}
          </Box>
        </Flex>
      </Box>
    </>
  );
}
