import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import HeyCoachLogo from '../../images/heycoach-logo.cadaeb82.png';
import { Button } from '@mui/material';
const ReceiptCanvas = ({
  created_at,
  invoice_number,
  full_leadname,
  transaction_id,
  payment_amount: payment_amt,
  phone,
  email,
  product,
}) => {
  const receiptRef = useRef(null);

  const date = new Date(created_at);

  const payment_amount = parseFloat(payment_amt);

  const getBaseValue = () => {
    const calculatedValue = (payment_amount / 118) * 100;
    const roundedValue = Math.ceil(calculatedValue * 100) / 100;
    return roundedValue.toFixed(2);
  };

  const getTaxValue = () => {
    const calculatedValue = getBaseValue() * 0.09;
    return calculatedValue.toFixed(2);
  };

  const generatePDF = () => {
    const input = receiptRef.current;
    html2canvas(input, { scale: 2, type: 'pdf' }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: [210, 297],
      });
      pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
      pdf.save(`receipt_${invoice_number}.pdf`);
    });
  };

  return (
    <div>
      <div ref={receiptRef}>
        <div
          style={{
            padding: '40px',
            fontFamily: 'Open Sans',
            fontSize: '12px',
            lineHeight: '1.5',
            position: 'relative',
          }}
        >
          <div style={{ position: 'absolute', right: '25px', top: '35px' }}>
            <img
              src={HeyCoachLogo}
              alt='HeyCoach Logo'
              style={{ width: '250px', height: '52px' }}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              left: '25px',
              top: '35px',
              fontWeight: '700',
              fontSize: '24px',
              color: 'black',
            }}
          >
            HeyCoach
          </div>
          <div
            style={{
              position: 'absolute',
              left: '25px',
              top: '77px',
              width: '230px',
              height: '100px',
              color: 'black',
              fontSize: '15px',
              fontWeight: '500',
              fontFamily: 'open sans',
            }}
          >
            <div>HeyCoach Education Pvt Ltd</div>
            <div>72, Service Rd, LRDE Layout, Doddanekundi, Bengaluru</div>
            <div> Karnataka 560037</div>
            <div>heycoach.in | info@heycoach.in</div>
            <div>GST NO. 29AAGCH3213D1ZY</div>
          </div>
          <div
            style={{
              textAlign: 'center',
              marginTop: '200px',
              marginBottom: '0',
            }}
          >
            <div
              style={{ fontWeight: '700', fontSize: '30px', color: '#1960f5' }}
            >
              Receipt
            </div>
          </div>
          <div style={{ textAlign: 'center', marginBottom: '120px' }}>
            <div style={{ color: 'black', fontSize: '24px' }}>
              Payment received
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              right: '25px',
              top: '310px',
              fontSize: '16px',
              color: 'black',
              fontWeight: `bold`,
            }}
          >
            <div style={{ fontWeight: '700' }}>
              Receipt no: {invoice_number}
            </div>
            <div style={{ fontWeight: '700' }}>
              Date:{' '}
              {`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              left: '25px',
              top: '310px',
              fontSize: '16px',
              color: 'black',
              marginBottom: '10px',
            }}
          >
            <div style={{ fontWeight: '700' }}>Receipt to :</div>
            <div style={{ fontWeight: '700', marginBottom: '10px' }}>
              {full_leadname}
            </div>
            {email && <div>{email}</div>}
            {phone && <div>{phone}</div>}
            <div>Transaction Id: {transaction_id}</div>
          </div>
          <div style={{ marginTop: '160px' }}>
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                border: '0',
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: '#1960f5',
                    color: '#FFFFFF',
                    textAlign: 'left',
                    height: '25px',
                    borderBottom: '1px solid black',
                    fontSize: '16px',
                    fontWeight: 'normal',
                  }}
                >
                  <th
                    style={{
                      padding: '8px',
                      width: '10%',
                      fontWeight: 'normal',
                    }}
                  >
                    #
                  </th>
                  <th
                    style={{
                      padding: '8px',
                      width: '45%',

                      fontWeight: 'normal',
                    }}
                  >
                    Program name
                  </th>
                  <th
                    style={{
                      padding: '8px',
                      width: '45%',
                      fontWeight: 'normal',
                    }}
                  >
                    Amount (INR)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: 'left', height: '60px' }}>
                  <td
                    style={{
                      padding: '5px',
                      color: 'black',
                      fontSize: '16px',
                      fontWeight: '600',
                    }}
                  >
                    1
                  </td>
                  <td
                    style={{
                      padding: '5px',
                      color: 'black',
                      fontSize: '16px',
                      fontWeight: '600',
                    }}
                  >
                    {product}
                    <div
                      style={{
                        marginTop: '5px',
                        fontSize: '12px',
                        color: 'black',
                      }}
                    >
                      SGST 9%
                    </div>
                    <div
                      style={{
                        marginTop: '5px',
                        fontSize: '12px',
                        color: 'black',
                      }}
                    >
                      CGST 9%
                    </div>
                  </td>
                  <td
                    style={{
                      padding: '8px',
                      color: 'black',
                      fontSize: '16px',
                      fontWeight: '600',
                    }}
                  >
                    <div style={{ textAlign: 'left' }}>{getBaseValue()}</div>
                    <div
                      style={{
                        marginTop: '5px',
                        fontSize: '12px',
                        textAlign: 'left',
                        color: 'black',
                      }}
                    >
                      {getTaxValue()}
                    </div>
                    <div
                      style={{
                        marginTop: '5px',
                        fontSize: '12px',
                        textAlign: 'left',
                        color: 'black',
                      }}
                    >
                      {getTaxValue()}
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot
                style={{
                  borderBottom: '1px solid black',
                  borderTop: '1px solid black',
                  fontSize: '18px',
                }}
              >
                <tr style={{ textAlign: 'left', height: '25px' }}>
                  <td style={{ padding: '8px' }}></td>
                  <td
                    style={{
                      padding: '8px',
                      fontWeight: '700',
                      color: 'black',
                    }}
                  >
                    Total (INR)
                  </td>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: '700',
                      color: 'black',
                    }}
                  >
                    {payment_amount.toFixed(2)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div
            style={{
              textAlign: 'center',
              marginTop: '50px',
              marginLeft: '425px',
            }}
          >
            <div
              style={{
                backgroundColor: '#1960f5',
                color: '#FFFFFF',
                width: '350px',
                margin: '0 auto',
                padding: '7px',
                display: 'flex',
              }}
            >
              <div
                style={{
                  fontWeight: '700',
                  width: '175px',
                  borderRigth: '1px solid #dddddd',
                  fontSize: '18px',
                }}
              >
                Total (INR)
              </div>

              <div
                style={{
                  fontWeight: '700',
                  width: '175px',
                  borderLeft: '1px solid #dddddd',
                  fontSize: '18px',
                }}
              >
                {payment_amount.toFixed(2)}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            position: 'relative',
            width: '100%',
            backgroundColor: '#1960f5',
            color: '#FFFFFF',
            textAlign: 'center',
            padding: '12px 0',
            display: 'flex',
            marginTop: '420px',
            fontSize: '16px',
            justifyContent: 'space-between',
            borderTop: '2px solid black',
            fontFamily: 'open sans',
          }}
        >
          <div style={{ marginLeft: '15px' }}>
            HEY COACH EDUCATION PRIVATE LIMITED
          </div>
          <div style={{ marginRight: '15px' }}>
            CIN NO. U80902KA2022PTC161678
          </div>
        </div>
      </div>

      <Button
        variant='contained'
        onClick={generatePDF}
        sx={{ marginTop: '10px' }}
      >
        Download PDF
      </Button>
    </div>
  );
};

export default ReceiptCanvas;
