import React from 'react';
import { Box, Typography } from '@mui/material';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import CancelIcon from '@mui/icons-material/Cancel';

const FilterBlocks = ({ filters }) => {
  const blockHeight = 100;
  const blockWidth = 300;
  const blocks = [];

  const verifyIconStyle = {
    fontSize: '20px',
    color: 'green',
    marginTop: '5px',
  };

  const cancelIconStyle = {
    fontSize: '20px',
    color: 'red',
    marginTop: '5px',
  };

  for (let i = 0; i < filters?.length; i += 3) {
    const blockFilters = filters?.slice(i, i + 3);
    const blockKey = `block_${i / 3}`;

    const block = (
      <Box
        key={blockKey}
        display='flex'
        flexDirection='row'
        height={`${blockHeight}px`}
        width={`${blockWidth}px`}
        padding='8px'
      >
        <Box>
          {blockFilters?.map((filter) => {
            const words = filter?.name.split(' ');
            const filterName = words[0]?.slice(5, -6);

            return (
              <Typography
                key={filter?.name}
                style={{
                  marginBottom: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '16px',
                }}
              >
                {filter?.status === 'applied' && (
                  <VerifiedRoundedIcon style={verifyIconStyle} />
                )}
                {filter?.status === 'skipped' && (
                  <CancelIcon style={cancelIconStyle} />
                )}
                {filterName}
              </Typography>
            );
          })}
        </Box>
      </Box>
    );

    blocks.push(block);
  }

  return <>{blocks}</>;
};

export default FilterBlocks;
