import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';

const MIN_Dimension = 100;
const aspect_ratio = 1;
function ImageCropper({
  onCropImage,
  imgSource,
  uploadImage,
  backgrounChange,
}) {
  const [imgsrc, setimgsrc] = useState(imgSource);
  const [crop, setCrop] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSelectFile = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const imageElement = new Image();
      const imageurl = reader.result?.toString() || '';
      imageElement.src = imageurl;
      imageElement.addEventListener('load', (event) => {
        const { naturalWidth, naturalHeight } = event.currentTarget;
        if (naturalWidth < MIN_Dimension || naturalHeight < MIN_Dimension) {
          setHasError(true);
          setErrorMessage('Image must be Greater than 150 pixel');
          return setimgsrc('');
        }
      });
      setimgsrc(imageurl);
    });
    reader.readAsDataURL(file);
  };

  const onImageLoad = (event) => {
    const { width, height } = event.currentTarget;
    const cropWidthPercent = (MIN_Dimension / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: '%',
        width: cropWidthPercent,
      },
      aspect_ratio,
      width,
      height
    );
    const centercrop = centerCrop(crop, width, height);
    setCrop(centercrop);
  };

  const handleCrop = () => {
    if (!crop) {
      setHasError(true);
      setErrorMessage('Please select a valid crop area.');
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const imageElement = new Image();
    imageElement.onload = () => {
      const width = (imageElement.width * crop.width) / 100;
      const height = (imageElement.height * crop.height) / 100;
      const x = (imageElement.width * crop.x) / 100;
      const y = (imageElement.height * crop.y) / 100;

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(imageElement, x, y, width, height, 0, 0, width, height);
      canvas.toBlob(async (blob) => {
        if (!blob) {
          console.error('Failed to convert canvas to blob.');
          return;
        }
        const croppedFile = new File([blob], 'cropped-image.png', {
          type: 'image/png',
        });
        try {
          if (backgrounChange) {
            await uploadImage(croppedFile, 'banner');
          } else {
            await uploadImage(croppedFile, 'profile');
          }
        } catch (error) {
          console.error('Error uploading cropped image:', error);
        }
      }, 'image/png');

      const croppedImgSrc = canvas.toDataURL('image/png');
      onCropImage(crop, croppedImgSrc);
      setCrop(null);
    };

    imageElement.src = imgsrc;
  };

  return (
    <Box
      sx={{
        backgroundColor: '#EFF6FF',
        height: '90%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        marginLeft: '10px',
        marginRight: '10px',
        padding: '20px',
        border: '4px dashed #b3b3b3',
        cursor: 'pointer',
      }}
      onClick={onSelectFile}
    >
      <label
        style={{
          color: 'black',
          marginBottom: '10px',
        }}
      >
        {!imgsrc && (
          <span
            style={{
              color: '#b3b3b3',
              fontSize: '30px',
              fontWeight: '800',
              fontFamily: 'Roboto, sans-serif',
              cursor: 'pointer',
            }}
          >
            Choose profile photo
          </span>
        )}

        <input
          type='file'
          accept='image/*'
          onChange={onSelectFile}
          style={{
            display: 'none',
            cursor: 'pointer',
            paddingBottom: '15px',
            backgroundColor: 'green',
          }}
        />
      </label>

      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      {imgsrc && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '70px',
          }}
        >
          <ReactCrop
            crop={crop}
            onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
            circularCrop={!backgrounChange}
            keepSelection
            aspect={backgrounChange ? null : aspect_ratio}
            minWidth={MIN_Dimension}
            sx={{ marginTop: '90px', marginBottom: '90px' }}
          >
            <img
              src={imgsrc}
              alt='upload'
              onLoad={onImageLoad}
              style={{ maxHeight: '40vh' }}
            />
          </ReactCrop>
        </Box>
      )}
      {imgsrc && (
        <Button
          onClick={handleCrop}
          sx={{
            marginTop: '70px',
            backgroundColor: '#40A2D8',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#1E90FF',
            },
          }}
        >
          Crop
        </Button>
      )}
    </Box>
  );
}

export default ImageCropper;
