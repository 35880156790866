import React from 'react';
import {
  Button,
  OutlinedInput,
  Checkbox,
  Chip,
  Box,
  ListItemText,
  MenuProps,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { handleKeyDown } from '../../utils/hrms/common';

function CandidatePersonalInformation({ filters, handleChange }) {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          variant='h6'
          component='p'
          style={{
            fontSize: '14px',
            marginLeft: '17px',
          }}
        >
          Personal Information
        </Typography>
        <Divider
          style={{
            margin: '0 16px',
            flexGrow: 1,
          }}
        />
      </div>
      <AccordionDetails>
        <Grid
          container
          spacing={0.8}
          direction={{
            xs: 'column',
            sm: 'row',
          }}
        >
          <Grid
            item
            xs={3}
            sm={3}
            md={2}
          >
            <FormControl
              id='id'
              fullWidth
            >
              <TextField
                size='small'
                type='text'
                name='id'
                placeholder='Id'
                onChange={(e) => handleChange(e)}
                value={filters?.id}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
          >
            <FormControl
              id='name'
              fullWidth
            >
              <TextField
                size='small'
                type='text'
                name='name'
                placeholder='Name'
                onChange={(e) => handleChange(e)}
                value={filters?.name}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
          >
            <FormControl
              id='mobile'
              fullWidth
            >
              <TextField
                size='small'
                type='text'
                name='mobile1'
                placeholder='Mobile'
                onChange={(e) => handleChange(e)}
                value={filters?.mobile1}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
          >
            <FormControl
              id='mobile'
              fullWidth
            >
              <TextField
                size='small'
                type='text'
                name='mobile2'
                placeholder='Mobile (optional)'
                onChange={(e) => handleChange(e)}
                value={filters?.mobile2}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
          >
            <FormControl
              id='email'
              fullWidth
            >
              <TextField
                size='small'
                type='text'
                name='email1'
                placeholder='Email'
                onChange={(e) => handleChange(e)}
                value={filters?.email1}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
          >
            <FormControl
              id='email'
              fullWidth
            >
              <TextField
                size='small'
                type='text'
                name='email2'
                placeholder='Email (optional)'
                onChange={(e) => handleChange(e)}
                value={filters?.email2}
              />
            </FormControl>
          </Grid>
        </Grid>
      </AccordionDetails>
    </div>
  );
}

export default CandidatePersonalInformation;
