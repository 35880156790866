import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  TextField,
  Divider,
  Dialog,
} from '@mui/material';
import axios from 'axios';
import { base_url } from './Mode';
import { useCurrentLead } from '../components/CurrentLeadContext';
import { offerLetterForm } from '../utils/offerLetterForm';
import moment from 'moment';
import {
  currentAdminIsAdmin,
  shouldShowPhonepePaymentGatewayOption,
} from '../utils/common';

const initialState = {
  program: '',
  token_amount: '10000',
  payment_link: '',
  payment_method: '',
  expires_at: '',
};

const shortcutLabels = [
  { label: '15 minutes', value: 15 * 60 * 1000 },
  { label: '30 minutes', value: 30 * 60 * 1000 },
  { label: '1 hour', value: 60 * 60 * 1000 },
  { label: '2 hours', value: 2 * 60 * 60 * 1000 },
  { label: '4 hours', value: 4 * 60 * 60 * 1000 },
  { label: '6 hours', value: 6 * 60 * 60 * 1000 },
  { label: '12 hours', value: 12 * 60 * 60 * 1000 },
  { label: '1 day', value: 24 * 60 * 60 * 1000 },
];

const OfferLetterModal = ({
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
  openOfferLetterModal,
  activeOLInfo,
  handleCloseOfferLetter,
}) => {
  const [tokenAmount] = useState(
    offerLetterForm[1].options.map((option) => option.amount)
  );
  const [filteredAmounts, setFilteredAmounts] = useState(null);
  const [isRequired, setIsRequired] = useState(true);
  const {
    currentLead,
    getCurrentLeadAndUpdateContext,
    getCurrentLeadStatusActivitiesAndUpdateContext,
  } = useCurrentLead();
  const [editedFields, setEditedFields] = useState(initialState);

  useEffect(() => {
    if (isRequired && tokenAmount) {
      const filteredAmounts = tokenAmount.filter((amount) => amount >= 10000);
      setFilteredAmounts(filteredAmounts);
      if (isRequired && filteredAmounts.length > 0) {
      }
    }
  }, [isRequired, tokenAmount]);

  useEffect(() => {
    if (Object.keys(activeOLInfo).length === 0) {
      return;
    }

    const { program, token_amount, payment_link, payment_method } =
      activeOLInfo.response;
    const { expires_at, id } = activeOLInfo;

    setEditedFields((p) => ({
      ...p,
      id,
      token_amount,
      payment_link,
      payment_method,
      program,
      expires_at: moment(expires_at),
    }));

    return () => {};
  }, []);

  const getPaymentLinkFromAmount = () => {
    const link = offerLetterForm[1].options.find(
      (opt) => opt.amount === editedFields.token_amount
    );

    return link ? link[editedFields?.payment_method] : '';
  };

  const handleShortcutSelection = (value) => {
    const now = moment();
    const expiry = now.add(value, 'milliseconds');
    setEditedFields((p) => ({
      ...p,
      expires_at: expiry,
    }));
  };

  const handleSubmit = () => {
    const isNew = Object.keys(activeOLInfo).length === 0;

    if (!editedFields.payment_method) {
      handleCloseOfferLetter();
      setHasError(true);
      setErrorMessage('Please Select a Payment Gateway');
      return;
    }

    const olData = {
      program: editedFields.program,
      token_amount: editedFields.token_amount,
      payment_method: editedFields.payment_method,
    };

    if (editedFields.payment_method !== 'Phonepe') {
      // This check is needed because phonepe links are created on demand.
      olData.payment_link = getPaymentLinkFromAmount();
    }

    const body = {
      expires_at: editedFields?.expires_at.utc(),
      response: olData,
    };

    let promise;
    if (isNew) {
      body.lead_id = currentLead.id;
      promise = axios.post(`${base_url()}/api/offer-letters`, body);
    } else {
      promise = axios.patch(
        `${base_url()}/api/offer-letters/${editedFields.id}`,
        body
      );
    }

    promise
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage(
          `Offer letter ${isNew ? 'created' : 'updated'} successfully`
        );
        handleCloseOfferLetter(true);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ??
            `An error occured while ${
              isNew ? 'creating' : 'updating'
            } the offer letter.`
        );
        handleCloseOfferLetter();
      });
  };

  const handleFieldChange = (e) => {
    let { name, value } = e.target;
    if (name === 'expires_at') {
      value = moment(value);
    }
    setEditedFields((p) => ({ ...p, [name]: value }));
  };

  const getFieldValue = (field) => {
    if ((editedFields[field] ?? '') === '') {
      return '*****';
    }
    return editedFields[field];
  };

  const isRequiredHandler = () => {
    setIsRequired(false);
  };

  const shouldShowPaymentLink = () => {
    return editedFields.payment_method !== 'Phonepe';
  };

  // const shouldEnforcePhonepeGateway = () => {
  //   return editedFields?.token_amount <= 10000 && !editedFields?.id;
  // };

  return (
    <Dialog
      open={openOfferLetterModal}
      onClose={handleCloseOfferLetter}
      sx={{
        p: 6,
        borderRadius: '1rem',
      }}
      maxWidth='sm'
      fullWidth
    >
      <Box
        sx={{
          maxHeight: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 3,
          overflowY: 'auto',
        }}
      >
        <Typography
          sx={{
            fontSize: '30px',
            position: 'relative',
            textAlign: 'center',
          }}
        >
          <b>Send offer letter</b>
        </Typography>
        <Divider sx={{ marginY: 2 }} />

        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <FormControl
            fullWidth
            sx={{
              marginBottom: '1rem',
            }}
          >
            <InputLabel id='program'>Program</InputLabel>
            <Select
              labelId='program'
              id='programs'
              label='Program'
              name='program'
              onChange={handleFieldChange}
              value={editedFields?.program}
            >
              <MenuItem value={'Super-30'}>Super 30</MenuItem>
              <MenuItem value={'Personal Coaching'}>Personal Coaching</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{
              marginBottom: '1rem',
              width: '100%',
            }}
          >
            <InputLabel id='token'>Token Amount</InputLabel>
            <Select
              labelId='token'
              id='token-amount'
              label='Token Amount'
              name='token_amount'
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 250,
                  },
                },
              }}
              onChange={handleFieldChange}
              value={editedFields?.token_amount}
            >
              {(isRequired ? filteredAmounts : tokenAmount)?.length > 0 &&
                (isRequired ? filteredAmounts : tokenAmount).map((amount) => (
                  <MenuItem
                    key={amount}
                    value={amount}
                  >
                    {`₹ ${amount}`}
                  </MenuItem>
                ))}
            </Select>
            <Typography
              sx={{
                fontWeight: 'normal',
                fontSize: '12px',
                color: '#2196f3',
                cursor: 'pointer',
              }}
              onClick={isRequiredHandler}
            >
              Not Recommended
            </Typography>
          </FormControl>
          <FormControl
            fullWidth
            sx={{
              marginBottom: '1rem',
            }}
          >
            <InputLabel id='payment'>Payment Gateway</InputLabel>
            <Select
              labelId='payment'
              id='Payment Gateway'
              label='Payment Gateway'
              name='payment_method'
              onChange={handleFieldChange}
              value={editedFields?.payment_method}
            >
              {offerLetterForm[2].options.map((option) => {
                // if (shouldEnforcePhonepeGateway() && option !== 'Phonepe') {
                //   return <></>;
                // }
                return (
                  <MenuItem
                    key={option}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Stack
            direction='row'
            spacing={1}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              rowGap: '0.8rem',
            }}
          >
            {shortcutLabels.map((type, index) => {
              return (
                <Chip
                  label={type.label}
                  variant='filled'
                  onClick={(e) => {
                    handleShortcutSelection(type.value);
                  }}
                />
              );
            })}
          </Stack>

          <TextField
            sx={{ width: '14em', marginTop: '1rem' }}
            onChange={handleFieldChange}
            value={
              editedFields?.expires_at instanceof moment
                ? editedFields?.expires_at?.format('YYYY-MM-DDTHH:mm')
                : editedFields?.expires_at
            }
            name='expires_at'
            label='Expiry'
            size='small'
            type='datetime-local'
            placeholder='Select Expiry'
            InputLabelProps={{ shrink: true }}
          />

          <Box
            sx={{
              position: 'relative',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Divider sx={{ marginY: 2 }} />
            <Typography
              sx={{
                fontSize: '1.5rem',
              }}
            >
              <b>Preview</b>
            </Typography>
            <Divider sx={{ marginY: 2 }} />
          </Box>
          <Typography>
            Hi{` `}
            <b>
              {currentLead.fname} {currentLead.lname}
            </b>
            ,
            <br />
            <br />
            {`Welcome aboard! We are delighted to inform you that
              you have been admitted to the`}{' '}
            <b>{getFieldValue('program')}</b>{' '}
            {`program at HeyCoach. On behalf of the entire team at
              HeyCoach, please accept our personal and warmest
              congratulations! You can pride yourself on your admission to
              this very selective program! As the next step, please click on
              the payment button and pay the token amount:`}
            <br />
            {`The Token Amount to be paid: `}
            <b>₹ {getFieldValue('token_amount')}</b>
            <br />
            {shouldShowPaymentLink() && (
              <>
                {`The payment link: `}
                <b>{getFieldValue('payment_method')}</b>
                <br />
              </>
            )}
            {`Welcome again to the very
              aspirational community of developers at HeyCoach. We are looking
              forward to undertaking this tech journey together. Should you
              have any queries, please do not hesitate to contact us at `}
            <a
              href='mailto:team@heycoach.in'
              target='_top'
            >
              team@heycoach.in
            </a>
            <br />
            <br />
            Best regards,
            <br />
            Aditya Kamat,
            <br />
            Co-Founder, HeyCoach
            <br />
            <br />
            {`Expiry of the Payment Link: `}
            <b>
              {editedFields?.expires_at
                ? editedFields?.expires_at?.format('dddd, MMMM Do YYYY, h:mm a')
                : 'YYYY-MM-DD HH:MM'}
            </b>
          </Typography>

          <Button
            onClick={handleSubmit}
            sx={{
              width: 200,
              backgroundColor: 'green',
              color: 'white',
              marginTop: '1rem',
              ':hover': {
                backgroundColor: 'green',
                boxShadow: '10',
              },
            }}
          >
            Send
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default OfferLetterModal;
