/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

const SalsaAssignedLeadsManagerWiseCount = () => {
  const srcURL = `https://kamat.heycoach.in/public/dashboard/9750475d-d09b-461a-99f6-9714a9f8b46a`;

  return (
    <iframe
      src={srcURL}
      frameborder='0'
      width='100%'
      height='500'
      allowtransparency
    ></iframe>
  );
};

export default SalsaAssignedLeadsManagerWiseCount;
