import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Typography,
  Box,
  DialogContentText,
} from '@mui/material';

const CallDurationMetadataDialog = ({
  updateLeadDetailState,
  openCompleteProgramExplainedModal,
  setOpenCompleteProgramExplainedModal,
  metadata,
  setMetadata,
  setHasError,
  setErrorMessage,
}) => {
  const handleCloseDurationModal = () => {
    setOpenCompleteProgramExplainedModal(false);
  };

  const handleMetadataChange = (e) => {
    const { name, value } = e.target;
    setMetadata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveDuration = () => {
    handleCloseDurationModal();
    if (
      !isNaN(metadata?.total_call_duration) &&
      metadata?.total_call_duration > 0
    ) {
      updateLeadDetailState('status', 'complete_program_explained');
    } else {
      setHasError(true);
      setErrorMessage(
        'Please enter a valid number for the total time duration.'
      );
    }
  };

  return (
    <>
      <Dialog
        open={openCompleteProgramExplainedModal}
        onClose={handleCloseDurationModal}
      >
        <DialogContent>
          <DialogContentText>
            For how long did you interact with this lead? Please enter the total
            call duration.
          </DialogContentText>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              textAlign: 'center',
              gap: '5px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <TextField
                sx={{
                  mt: '10px',
                }}
                label='Total call duration'
                variant='standard'
                name='total_call_duration'
                onChange={handleMetadataChange}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'end',
                textAlign: 'center',
              }}
            >
              <Typography>minutes</Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSaveDuration}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CallDurationMetadataDialog;
