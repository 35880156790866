import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TimeCircle from './TimeCircle';

const DialedBox = ({ widthPercentage, currentLeadData }) => {
  const { fname, lname, mobile } = currentLeadData;
  const fullname = fname + (lname !== null ? ` ${lname}` : '');
  return (
    <Box
      sx={{
        zIndex: '2',
        mt: '9rem',
        display:
          widthPercentage >= 70 && widthPercentage <= 100 ? 'flex' : 'none',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        transition: 'display 0.1s ease-in',
      }}
    >
      <Typography
        sx={{
          color: '#FFF',
          fontSize: '48px',
          fontWeight: 700,
          fontFamily: "'Inter', 'sans-serif'",
        }}
      >
        Dial
      </Typography>

      <Typography
        sx={{
          color: '#EBE3F8',
          fontSize: '54px',
          fontFamily: "'Inter', 'sans-serif'",
          fontWeight: 700,
          WebkitBackgroundClip: 'text',
          backgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundImage:
            'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
        }}
      >
        {fullname}’s number
      </Typography>

      <Typography
        sx={{
          color: '#FFF',
          fontSize: '40px',
          fontWeight: 700,
          fontFamily: "'Inter', 'sans-serif'",
          letterSpacing: '4px',
        }}
      >
        {mobile}
      </Typography>

      {widthPercentage >= 98 && (
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <TimeCircle
            currentLeadData={currentLeadData}
            time={widthPercentage >= 98 ? 8 : 0}
          />
        </Box>
      )}
    </Box>
  );
};

export default DialedBox;
