import React from 'react';
import { Modal, Backdrop, Fade, styled, Box } from '@mui/material';
import PropTypes from 'prop-types';
import CustomCloseIcon from '../Atoms/CustomCloseIcon';

const ModalContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: (theme) => theme.palette.background.paper,
  boxShadow: (theme) => theme.shadows[5],
  padding: (theme) => theme.spacing(2, 4, 3),
  outline: 'none',
  width: '70%',
  height: '70%',
  maxWidth: '70vw',
  maxHeight: '70vh',
});

const ImagePreview = ({ imageUrl, open, onClose }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalContent>
          <Box
            sx={{
              position: 'absolute',
              top: '-100px',
              right: '-220px',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            <CustomCloseIcon
              style={{
                fontSize: 20,
                width: 50,
                height: 40,
                color: 'white',
                cursor: 'pointer',
              }}
            />
          </Box>
          <img
            src={imageUrl}
            alt='Preview'
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        </ModalContent>
      </Fade>
    </Modal>
  );
};

ImagePreview.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImagePreview;
