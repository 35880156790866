import React from 'react';

export const Logo = (props) => {
  return (
    <img
      src='/logo.png'
      width='150px'
      style={{ cursor: 'pointer' }}
      alt='logo'
    />
  );
};
