import React from 'react';
import {
  TextField,
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  IconButton,
  Select,
  MenuItem,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {
  currentAdminIsFinance,
  currentAdminIsIC,
  currentAdminIsPgm,
} from '../../../../utils/common';
import constants from '../../../../utils/constants';
import { useCurrentPostSalesLead } from '../../../CurrentPostSalesLeadContext';
const { FINANCE_HANDOVER_REJECTED, IN_PROGRESS, PGM_HANDOVER_REJECTED } =
  constants;

const SalesHandoverForm = ({
  setResume,
  resume,
  rawForm,
  editedForm,
  setEditedForm,
  setHasUpdate,
}) => {
  const { leadData } = useCurrentPostSalesLead();

  const shouldDisable = () => {
    if (currentAdminIsFinance() || currentAdminIsPgm()) {
      return true;
    }

    const editableStates = [
      FINANCE_HANDOVER_REJECTED,
      PGM_HANDOVER_REJECTED,
      IN_PROGRESS,
    ];

    if (editableStates.includes(leadData.status)) {
      return false;
    }

    return true;
  };

  const handleUpload = (e) => {
    const file = Array.from(e.target.files).map((file) => ({
      name: file.name,
      data: file,
    }));

    setResume(...file);
    setHasUpdate(true);
  };

  const getFileUploadElement = () => {
    const isEmpty =
      (editedForm[14]?.value ?? '') === '' && Object.keys(resume).length === 0;

    if (!isEmpty) {
      return (
        <Stack spacing={1}>
          <Stack
            sx={{
              border: '1px dashed blue',
              padding: '1px',
            }}
            direction='row'
            justifyContent='space-between'
            alignContent='center'
          >
            <Typography
              variant='body2'
              alignSelf='center'
              sx={{
                cursor: 'pointer',
              }}
              {...(Object.keys(resume).length === 0 && {
                onClick: (e) => {
                  window.open(editedForm[14].value, '_target', 'noopener');
                },
              })}
            >
              Resume
            </Typography>

            <IconButton
              sx={{
                display: Object.keys(resume).length === 0 && 'none',
              }}
              onClick={(e) => setResume({})}
            >
              <ClearIcon color='error' />
            </IconButton>
          </Stack>

          {currentAdminIsIC() && (
            <input
              type='file'
              id='resume-sales-handover'
              name='resume-sales-handover'
              onChange={handleUpload}
            />
          )}
        </Stack>
      );
    }

    return (
      <input
        type='file'
        id='resume-sales-handover'
        name='resume-sales-handover'
        onChange={handleUpload}
      />
    );
  };

  return (
    <Stack spacing={2}>
      <TextField
        placeholder={rawForm[1].title}
        label={rawForm[1].title}
        type='text'
        value={editedForm[1]?.value ?? ''}
        onChange={(e) => {
          setHasUpdate(true);
          setEditedForm({
            ...editedForm,
            [1]: {
              id: 1,
              title: rawForm[1].title,
              value: e.target.value,
            },
          });
        }}
        fullWidth
        variant='outlined'
        disabled={shouldDisable()}
      />

      <TextField
        placeholder={rawForm[2].title}
        label={rawForm[2].title}
        type='text'
        value={editedForm[2]?.value ?? ''}
        onChange={(e) => {
          setHasUpdate(true);
          setEditedForm({
            ...editedForm,
            [2]: {
              id: 2,
              title: rawForm[2].title,
              value: e.target.value,
            },
          });
        }}
        fullWidth
        variant='outlined'
        disabled={shouldDisable()}
      />

      <TextField
        placeholder={rawForm[3].title}
        label={rawForm[3].title}
        type='text'
        value={editedForm[3]?.value ?? ''}
        onChange={(e) => {
          setHasUpdate(true);
          setEditedForm({
            ...editedForm,
            [3]: {
              id: 3,
              title: rawForm[3].title,
              value: e.target.value,
            },
          });
        }}
        fullWidth
        variant='outlined'
        disabled={shouldDisable()}
      />

      <TextField
        placeholder={rawForm[4].title}
        label={rawForm[4].title}
        type='text'
        value={editedForm[4]?.value ?? ''}
        onChange={(e) => {
          setHasUpdate(true);
          setEditedForm({
            ...editedForm,
            [4]: {
              id: 4,
              title: rawForm[4].title,
              value: e.target.value,
            },
          });
        }}
        fullWidth
        variant='outlined'
        disabled={shouldDisable()}
      />

      <TextField
        placeholder={rawForm[5].title}
        label={rawForm[5].title}
        type='text'
        value={editedForm[5]?.value ?? ''}
        onChange={(e) => {
          setHasUpdate(true);
          setEditedForm({
            ...editedForm,
            [5]: {
              id: 5,
              title: rawForm[5].title,
              value: e.target.value,
            },
          });
        }}
        fullWidth
        variant='outlined'
        disabled={shouldDisable()}
      />
      <FormControl>
        <FormLabel>{rawForm[6].title}</FormLabel>
        <RadioGroup
          value={editedForm[6]?.value ?? ''}
          onChange={(e) => {
            const { value } = e.target;
            const tmp = {
              ...editedForm,
            };

            if (value === 'Super 30') {
              delete tmp[7];
            }

            if (
              [
                'Personalised Coaching',
                'Program management personal coaching',
              ].includes(value)
            ) {
              delete tmp[17];
            }

            setHasUpdate(true);
            setEditedForm({
              ...tmp,
              [6]: {
                id: 6,
                title: rawForm[6].title,
                value: e.target.value,
              },
            });
          }}
        >
          {rawForm[6].options.map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio />}
              label={option}
              disabled={shouldDisable()}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <FormControl
        sx={{
          display: editedForm[6]?.value === 'Super 30' && 'none',
        }}
      >
        <FormLabel>{rawForm[7].title}</FormLabel>
        <RadioGroup
          value={editedForm[7]?.value ?? ''}
          onChange={(e) => {
            const tmp = {
              ...editedForm,
            };
            setHasUpdate(true);
            setEditedForm({
              ...tmp,
              [7]: {
                id: 7,
                title: rawForm[7].title,
                value: e.target.value,
              },
            });
          }}
          sx={{
            display: editedForm[6]?.value === 'Super 30' && 'none',
          }}
        >
          {rawForm[7].options.map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio />}
              label={option}
              disabled={shouldDisable()}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <TextField
        placeholder={rawForm[8].title}
        label={rawForm[8].title}
        type='text'
        value={editedForm[8]?.value ?? ''}
        onChange={(e) => {
          setHasUpdate(true);
          setEditedForm({
            ...editedForm,
            [8]: {
              id: 8,
              title: rawForm[8].title,
              value: e.target.value,
            },
          });
        }}
        fullWidth
        variant='outlined'
        disabled={shouldDisable()}
      />
      <TextField
        placeholder={rawForm[9].title}
        label={rawForm[9].title}
        type='text'
        value={editedForm[9]?.value ?? ''}
        onChange={(e) => {
          setHasUpdate(true);
          setEditedForm({
            ...editedForm,
            [9]: {
              id: 9,
              title: rawForm[9].title,
              value: e.target.value,
            },
          });
        }}
        fullWidth
        variant='outlined'
        disabled={shouldDisable()}
      />

      <TextField
        placeholder={rawForm[10].title}
        label={rawForm[10].title}
        type='text'
        value={editedForm[10]?.value ?? ''}
        onChange={(e) => {
          setHasUpdate(true);
          setEditedForm({
            ...editedForm,
            [10]: {
              id: 10,
              title: rawForm[10].title,
              value: e.target.value,
            },
          });
        }}
        fullWidth
        variant='outlined'
        disabled={shouldDisable()}
      />

      <TextField
        placeholder={rawForm[11].title}
        label={rawForm[11].title}
        type='text'
        value={editedForm[11]?.value ?? ''}
        onChange={(e) => {
          setHasUpdate(true);
          const tmp = {
            ...editedForm,
          };
          if (e.target.value === '') {
            delete tmp[12];
            delete tmp[13];
          }
          setEditedForm({
            ...tmp,
            [11]: {
              id: 11,
              title: rawForm[11].title,
              value: e.target.value,
            },
          });
        }}
        name={rawForm[11].title}
        disabled={shouldDisable()}
      />

      <TextField
        placeholder={rawForm[12].title}
        label={rawForm[12].title}
        type='text'
        value={editedForm[12]?.value ?? ''}
        onChange={(e) => {
          setHasUpdate(true);
          setEditedForm({
            ...editedForm,
            [12]: {
              id: 12,
              title: rawForm[12].title,
              value: e.target.value,
            },
          });
        }}
        sx={{
          display: !editedForm[11]?.value && 'none',
        }}
        fullWidth
        variant='outlined'
        disabled={shouldDisable()}
      />

      <FormControl
        sx={{
          display: !editedForm[11]?.value && 'none',
        }}
      >
        <FormLabel>{rawForm[13].title}</FormLabel>
        <RadioGroup
          value={editedForm[13]?.value ?? ''}
          onChange={(e) => {
            const tmp = {
              ...editedForm,
            };
            setHasUpdate(true);
            setEditedForm({
              ...tmp,
              [13]: {
                id: 13,
                title: rawForm[13].title,
                value: e.target.value,
              },
            });
          }}
        >
          {rawForm[13].options.map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio />}
              label={option}
              disabled={shouldDisable()}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <TextField
        placeholder={rawForm[15].title}
        label={rawForm[15].title}
        type='text'
        value={editedForm[15]?.value ?? ''}
        onChange={(e) => {
          setHasUpdate(true);
          setEditedForm({
            ...editedForm,
            [15]: {
              id: 15,
              title: rawForm[15].title,
              value: e.target.value,
            },
          });
        }}
        fullWidth
        variant='outlined'
        disabled={shouldDisable()}
      />
      {/* Discord not require anymore */}
      {/* <TextField
        placeholder={rawForm[16].title}
        type='text'
        value={editedForm[16]?.value ?? ''}
        onChange={(e) => {
          setHasUpdate(true);
          setEditedForm({
            ...editedForm,
            [16]: {
              id: 16,
              title: rawForm[16].title,
              value: e.target.value,
            },
          });
        }}
        fullWidth
        variant='outlined'
        disabled={shouldDisable()}
      /> */}
      <FormControl
        sx={{
          display: editedForm[6]?.value !== 'Super 30' && 'none',
        }}
      >
        <TextField
          placeholder={rawForm[17].title}
          label={rawForm[17].title}
          type='text'
          value={editedForm[17]?.value ?? ''}
          onChange={(e) => {
            setHasUpdate(true);
            setEditedForm({
              ...editedForm,
              [17]: {
                id: 17,
                title: rawForm[17].title,
                value: e.target.value,
              },
            });
          }}
          fullWidth
          variant='outlined'
          disabled={shouldDisable()}
        />
      </FormControl>

      <TextField
        placeholder={rawForm[18].title}
        label={rawForm[18].title}
        type='text'
        value={editedForm[18]?.value ?? ''}
        onChange={(e) => {
          setHasUpdate(true);
          setEditedForm({
            ...editedForm,
            [18]: {
              id: 18,
              title: rawForm[18].title,
              value: e.target.value,
            },
          });
        }}
        fullWidth
        variant='outlined'
        disabled={shouldDisable()}
      />

      {getFileUploadElement()}
    </Stack>
  );
};

export default SalesHandoverForm;
