import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { base_url } from '../Mode';
import ReceiptCanvas from './ReceiptCanvas';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import {
  currentAdminIsAdmin,
  currentAdminIsFinance,
  currentAdminIsManager,
  currentAdminIsSeniorManager,
  getCurrentAdminId,
} from '../../utils/common';
export default function NewReceiptModal({
  openReceiptModal,
  setOpenReceiptModal,
}) {
  const [modalData, setModalData] = useState({});
  const [pdfData, setPdfData] = useState({});
  const [showDownloadReceiptButton, setShowDownloadReceiptButton] =
    useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(pdfData).length > 0) {
      setShowDownloadReceiptButton(true);
    }
  }, [pdfData]);

  const handleGenerateReceipt = () => {
    if (
      !currentAdminIsAdmin() &&
      !currentAdminIsFinance() &&
      !currentAdminIsSeniorManager()
    ) {
      setErrorMessage("You don't have permission to generate reciept. ");
      setHasError(true);
      return;
    }

    const { lead_id, transaction_id, payment_amount, product } = modalData;

    // validation
    if (!lead_id || !transaction_id || !payment_amount || !product) {
      setErrorMessage('Please fill the all required fields.');
      setHasError(true);
      return;
    }

    axios
      .post(`${base_url()}/api/receipts`, modalData, { withCredentials: true })
      .then((res) => {
        const tmpDetails = {
          lead_id: res['data']['receipt'].lead_id,
          transaction_id: res['data']['receipt'].transaction_id,
          invoice_number: res['data']['receipt'].invoice_number,
          created_at: res['data']['receipt'].created_at,
          full_leadname: res['data']['leadName'],
          payment_amount: modalData.payment_amount,
          email: res['data']['email'],
          phone: res['data']['phone'],
          product: modalData.product,
        };
        setPdfData(tmpDetails);
      })
      .catch((err) => {
        setErrorMessage(
          err.response?.data?.message ||
            'An error occured while creating the reciept'
        );
        setHasError(true);
        console.log(err.message);
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {currentAdminIsAdmin() ||
      currentAdminIsFinance() ||
      currentAdminIsSeniorManager() ? (
        <Dialog
          open={openReceiptModal}
          fullWidth
          maxWidth='md'
          onClose={() => {
            navigate(-1);
            setOpenReceiptModal(false);
          }}
        >
          <Box
            display='flex'
            justifyContent='flex-end'
          >
            <Button
              onClick={() => {
                navigate(-1);
                setOpenReceiptModal(false);
              }}
            >
              Close
            </Button>
          </Box>
          <DialogContent>
            {!showDownloadReceiptButton ? (
              <DialogContentText>
                <TextField
                  required
                  fullWidth
                  sx={{ mt: 2 }}
                  label='Lead id'
                  placeholder='Add lead id'
                  value={modalData?.lead_id}
                  onChange={(e) =>
                    setModalData({ ...modalData, lead_id: e.target.value })
                  }
                />
                <TextField
                  required
                  fullWidth
                  sx={{ mt: 2 }}
                  label='Transaction id'
                  placeholder='Add transaction id'
                  value={modalData?.transaction_id}
                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      transaction_id: e.target.value,
                    })
                  }
                />
                <TextField
                  required
                  fullWidth
                  sx={{ mt: 2 }}
                  label='Payment amount'
                  placeholder='Add payment amount'
                  value={modalData?.payment_amount}
                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      payment_amount: e.target.value,
                    })
                  }
                />
                <TextField
                  required
                  fullWidth
                  sx={{ mt: 2 }}
                  label='Product'
                  placeholder='Add Product Name'
                  value={modalData?.product}
                  onChange={(e) =>
                    setModalData({ ...modalData, product: e.target.value })
                  }
                />
              </DialogContentText>
            ) : (
              <DialogContentText>
                <ReceiptCanvas {...pdfData} />
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            {!showDownloadReceiptButton && (
              <Button
                variant='contained'
                onClick={handleGenerateReceipt}
              >
                Generate receipt
              </Button>
            )}
          </DialogActions>
        </Dialog>
      ) : (
        <>
          {' '}
          <DialogContentText>
            You don't have permission to generate reciept.
          </DialogContentText>{' '}
        </>
      )}
    </>
  );
}
