const columnSets = {
  all: [
    'id',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'live_on',
    'createdby_admin',
    'spent_type',
    'cost',
    'leads',
    'wp',
    'cpl',
    'pql',
    'app',
    'tap',
    'cap',
    'cac',
    'status',
    'view',
    'utr',
    'processed_amount',
    'edit',
  ],
  funnel: [
    'id',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'leads',
    'app',
    'tap',
    'cap',
    'status',
    'view',
    'utr',
    'processed_amount',
    'edit',
  ],
  cost: [
    'id',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'spent_type',
    'cost',
    'leads',
    'cap',
    'cac',
  ],
  qualityleads: [
    'id',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'leads',
    'cpl',
    'wp',
    'pql',
    'status',
    'view',
    'utr',
    'processed_amount',
    'edit',
  ],
  frequency: [
    'id',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'live_on',
    'createdby_admin',
    'leads',
    'cap',
    'status',
    'view',
    'utr',
    'processed_amount',
    'edit',
  ],
};

module.exports = columnSets;
