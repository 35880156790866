import { Box } from '@mui/material';
import Text from '../pages/Profile/Atoms/Text';
import CustomTextField from '../pages/Profile/Atoms/CustomTextFiled';
import CustomButton from '../pages/Profile/Atoms/CustomButton';
import { base_url } from './Mode';
import axios from 'axios';
import ErrorNotifier from './ToastNotifications/ErrorNotifier';
import SuccessNotifier from './ToastNotifications/SuccessNotifier';

const { useState, useEffect } = require('react');

const SalsaLimitSettings = () => {
  const [salsaLimit, setSalsaLimit] = useState(null);
  const [salsaCoolDownLimit, setSalsaCoolDownLimit] = useState(null);
  const [edit, setIsEdit] = useState(false);
  const [coolDownLimitEdit, setCoolDownLimitEdit] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchRequestLeadLimit();
    fetchSalsaCoolDownLimit();
  }, []);

  const fetchRequestLeadLimit = () => {
    const url = base_url() + '/api/salsa/limit';
    axios
      .get(url)
      .then((res) => {
        setSalsaLimit(res?.data?.salsa_limit);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ?? 'Error while updating limit'
        );
      });
  };

  const fetchSalsaCoolDownLimit = () => {
    const url = base_url() + '/api/salsa/cool-down-limit';
    axios
      .get(url)
      .then((res) => {
        setSalsaCoolDownLimit(res?.data?.salsa_limit);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ?? 'Error while updating limit'
        );
      });
  };

  const updatehRequestLeadLimit = () => {
    if (+salsaLimit <= 0 || +salsaLimit > 20) {
      setHasError(true);
      setErrorMessage(
        'Limit can not be less than equal to zero and greater than 20.'
      );
      return;
    }

    const url = base_url() + '/api/salsa/limit-update';
    axios
      .patch(url, { salsa_limit: salsaLimit })
      .then((res) => {
        setSalsaLimit(res?.data?.updated_limit);
        setIsSuccess(true);
        setSuccessMessage(
          'Limit for requesting lead per IC updated successfully'
        );
        setIsEdit(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ?? 'Error while updating limit'
        );
      });
  };

  const updateSalsaCoolDownLimit = () => {
    if (
      parseInt(salsaCoolDownLimit) <= 0 ||
      parseInt(salsaCoolDownLimit) > 30
    ) {
      setHasError(true);
      setErrorMessage(
        'Limit can not be less than equal to zero and greater than 30.'
      );
      return;
    }

    const url = base_url() + '/api/salsa/cool-down-limit-update';
    axios
      .patch(url, { salsa_coolDown_time: salsaCoolDownLimit })
      .then((res) => {
        setSalsaCoolDownLimit(res?.data?.updated_limit);
        setIsSuccess(true);
        setSuccessMessage(
          'Cooldown Limit for requesting lead per IC updated successfully'
        );
        setCoolDownLimitEdit(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ?? 'Error while updating limit'
        );
        setCoolDownLimitEdit(false);
      });
  };
  const handleLimitChange = (e) => {
    setSalsaLimit(e.target.value);
  };

  const saveDataHandler = async () => {
    updatehRequestLeadLimit();
  };

  const cancelHandler = () => {
    setIsEdit(false);
  };

  const editHandler = async () => {
    setIsEdit(true);
  };

  const handleCoolDownLimitChange = (e) => {
    setSalsaCoolDownLimit(e.target.value);
  };

  const saveCoolDownDataHandler = async () => {
    updateSalsaCoolDownLimit();
  };

  const cancelCoolDownHandler = () => {
    setCoolDownLimitEdit(false);
  };

  const editCoolDownHandler = async () => {
    setCoolDownLimitEdit(true);
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <div>
        <Text
          text={'Request Limit Per IC'}
          textStyle1={{
            fontWeight: 'bold',
            fontSize: '17px',
            marginRight: '20px',
          }}
        />
        {!edit && (
          <>
            <Text
              text={salsaLimit}
              textStyle1={{
                fontWeight: 'medium',
                fontSize: '17px',
                marginTop: '7px',
              }}
            />
            <Box>
              <CustomButton
                variant={'contained'}
                style={{ marginTop: '15px' }}
                text={'Edit'}
                clickHandler={editHandler}
              />
            </Box>
          </>
        )}

        {edit && (
          <>
            <CustomTextField
              changeHandler={handleLimitChange}
              value={salsaLimit}
              customAttributes={{
                type: 'number',
                id: 'limit',
                label: 'Enter Request Lead Limit',
                placeholder: 'Enter Limit',
                variant: 'standard',
              }}
              customStyle={{
                color: 'blue',
                width: '50%',
                marginTop: '5px',
              }}
            />
            <Box sx={{ marginTop: '30px' }}>
              <CustomButton
                variant={'contained'}
                style={{ marginRight: '10px' }}
                text={'Save'}
                clickHandler={saveDataHandler}
              />
              <CustomButton
                variant={'contained'}
                text={'Cancel'}
                clickHandler={cancelHandler}
              />
            </Box>
          </>
        )}
      </div>
      <div style={{ marginTop: '20px' }}>
        <Text
          text={'Cool Down Limit For IC'}
          textStyle1={{
            fontWeight: 'bold',
            fontSize: '17px',
            marginRight: '20px',
          }}
        />
        {!coolDownLimitEdit && (
          <>
            <Text
              text={`${salsaCoolDownLimit} min`}
              textStyle1={{
                fontWeight: 'medium',
                fontSize: '17px',
                marginTop: '7px',
              }}
            />
            <Box>
              <CustomButton
                variant={'contained'}
                style={{ marginTop: '15px' }}
                text={'Edit'}
                clickHandler={editCoolDownHandler}
              />
            </Box>
          </>
        )}

        {coolDownLimitEdit && (
          <>
            <CustomTextField
              changeHandler={handleCoolDownLimitChange}
              value={salsaCoolDownLimit}
              customAttributes={{
                type: 'number',
                id: 'limit',
                label: 'Enter Cool Down Limit',
                placeholder: 'Enter Limit',
                variant: 'standard',
              }}
              customStyle={{
                color: 'blue',
                width: '50%',
                marginTop: '5px',
              }}
            />
            <Box sx={{ marginTop: '30px' }}>
              <CustomButton
                variant={'contained'}
                style={{ marginRight: '10px' }}
                text={'Save'}
                clickHandler={saveCoolDownDataHandler}
              />
              <CustomButton
                variant={'contained'}
                text={'Cancel'}
                clickHandler={cancelCoolDownHandler}
              />
            </Box>
          </>
        )}
      </div>
    </>
  );
};

export default SalsaLimitSettings;
