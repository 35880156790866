import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { ImageListItemBar } from '@mui/material';

/**
 * https://mui.com/material-ui/react-image-list/#standard-image-list
 *
 * props:
 *  images: List (default value is [])
 *  images[0] = {
 *    url,
 *    title
 *  }
 */

export default function ImagesList({ images = [] }) {
  return (
    <ImageList sx={{ width: 800, height: 450 }}>
      {images.map((item) => (
        <ImageListItem key={item.url}>
          <img
            src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${item.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading='lazy'
            onClick={(e) => {
              window.open(item.url, '_blank', 'noopener noreferrer');
            }}
            style={{
              cursor: 'pointer',
            }}
          />
          <ImageListItemBar title={item.title} />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
