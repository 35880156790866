import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import CAPTrackingTable from '../../components/cap-tracking/CAPTrackingTable';
import AddCAPModal from '../../components/cap-tracking/AddCAPModal';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import {
  getCurrentDriveName,
  getCurrentDriveStartAndEndDate,
  getDrives,
} from '../../utils/common';
import axios from 'axios';
import { base_url } from '../../components/Mode';
import { Box, Typography, MenuItem, Tab, Tabs } from '@mui/material';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function CapTrackingProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const initialFilterState = {
  from: '',
  to: '',
  managers: '',
  assignee: '',
  drive: '',
  lead_id: '',
};

const CAPTrackingDashboard = () => {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [editCAP, setEditCAP] = useState(false);
  const [editCAPData, setEditCAPData] = useState(null);
  const [independentContributor, setIndependentContributor] = useState([]);
  const [subordinateAdmins, setSubordinateAdmins] = useState([]);
  const [drives, setDrives] = useState([]);
  const [currentDriveName, setCurrentDriveName] = useState(null);
  const [currentDriveDates, setCurrentDriveDates] = useState(null);
  const [filters, setFilters] = useState(initialFilterState);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await getIndependentContributorDropdown();

      const drives = await getDrives();
      if (drives) {
        setDrives(drives);
        const currentDrive = getCurrentDriveName(drives || []);
        setCurrentDriveName(currentDrive);
        const currentDriveDate = getCurrentDriveStartAndEndDate(drives || []);
        setCurrentDriveDates(currentDriveDate);
      }
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorMessage(`Error in fetching data `);
    }
  };

  const getIndependentContributorDropdown = async () => {
    try {
      const res = await axios.get(`${base_url()}/api/all-reportees`, {
        params: {
          capTracking: true,
        },
      });
      const reportee = res.data;
      const independentContributorsFiltered = reportee.filter(
        (ic) => ic.role !== 'lead'
      );
      const subordinateAdminsFiltered = reportee.filter(
        (ic) => ic.role === 'lead'
      );
      setIndependentContributor(independentContributorsFiltered);

      setSubordinateAdmins(subordinateAdminsFiltered);
    } catch (error) {
      console.error('Error fetching independent contributors:', error);
      setHasError(true);
      setErrorMessage(`Error in fetching assignee data`);
    }
  };

  const IndependentContributorDropdown = () => {
    return independentContributor?.map((ic, index) => (
      <MenuItem
        key={index}
        value={ic?.Admin_id}
      >
        {ic?.Full_Name}
      </MenuItem>
    ));
  };

  const getmanagerDropdown = async () => {
    try {
      const res = await axios.get(
        `${base_url()}/api/manager_actions/subordinate_managers`,

        {
          params: {
            capTracking: true,
          },
        }
      );
      setSubordinateAdmins(res?.data);
    } catch (error) {
      console.error('Error fetching independent contributors:', error);
      setHasError(true);
      setErrorMessage(`Error in fetching manager data`);
    }
  };

  const managerDropdown = () => {
    return subordinateAdmins?.map((manager, index) => {
      return (
        <MenuItem value={manager?.Admin_id}>{`${manager?.Full_Name}`}</MenuItem>
      );
    });
  };

  const drivesDropdown = () => {
    return drives?.map((drives, index) => {
      return <MenuItem value={drives?.name}>{`${drives?.name}`}</MenuItem>;
    });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='cap tracking'
          >
            <Tab
              label='CAP tracking'
              {...CapTrackingProps(0)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              minWidth: 160,
              marginBottom: 2,
              gap: 2,
            }}
          >
            <AddCAPModal
              currentDriveName={currentDriveName}
              managerDropdown={managerDropdown}
              drivesDropdown={drivesDropdown}
              IndependentContributorDropdown={IndependentContributorDropdown}
              editCAPData={editCAPData}
              editCAP={editCAP}
              setEditCAP={setEditCAP}
              setHasError={setHasError}
              setErrorMessage={setErrorMessage}
              setIsSuccess={setIsSuccess}
              setSuccessMessage={setSuccessMessage}
              setTriggerRefresh={setTriggerRefresh}
              triggerRefresh={triggerRefresh}
              handleClose={handleClose}
              handleOpen={handleOpen}
              open={open}
            />
          </Box>
          <CAPTrackingTable
            initialFilterState={initialFilterState}
            filters={filters}
            setFilters={setFilters}
            managerDropdown={subordinateAdmins}
            drivesDropdown={drives}
            currentDriveName={currentDriveName}
            currentDriveDates={currentDriveDates}
            IndependentContributorDropdown={independentContributor}
            handleOpen={handleOpen}
            setEditCAP={setEditCAP}
            setEditCAPData={setEditCAPData}
            setHasError={setHasError}
            setErrorMessage={setErrorMessage}
            setIsSuccess={setIsSuccess}
            setSuccessMessage={setSuccessMessage}
            setTriggerRefresh={setTriggerRefresh}
            triggerRefresh={triggerRefresh}
          />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default CAPTrackingDashboard;
