// LeadsLayout.js
import React, { useEffect, useState, forwardRef } from 'react';
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CreationProgress from './CreationProgress';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import axios from 'axios';
import { base_url } from '../Mode';
import { leadForms } from './LayoutData';
import NewLeadNavigationButtons from './NewLeadNavigationButtons';

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction='up'
      ref={ref}
      {...props}
    />
  );
});

const initialUserState = {
  fname: '',
  lname: '',
  email: null,
  mobile: '',
  whatsapp: '',
  linkedin_link: '',
  college: '',
  branch: '',
  graduation_year: null,
  company: '',
  role: '',
  experience: null,
  tech_stack: '',
  lead_source_type: 'inbound',
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  assignee: null,
  product: null,
};

const initialFormsErrors = {
  fname: '',
  email: '',
  mobile: '',
  utm_source: '',
};

const LeadsLayout = ({ openModal, setOpenModal }) => {
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formValues, setFormValues] = useState(initialUserState);
  const [formErrors, setFormErrors] = useState(initialFormsErrors);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleStepChange = (newStep) => {
    setActiveStep(newStep);
  };
  const handleNext = () => {
    setFormSubmitted(true);
    if (Object.values(formErrors).some((error) => error)) {
      setHasError(true);
      setErrorMessage('Please correct the errors before proceeding.');
      return;
    }
    if (activeStep < leadForms.length - 1) {
      setActiveStep(activeStep + 1);
      setHasError(false);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
      setFormErrors(initialFormsErrors);
    }
  };

  const handleSubmit = () => {
    setFormSubmitted(true);
    if (Object.values(formErrors).some((error) => error)) {
      setHasError(true);
      setErrorMessage('Please correct the errors to proceed.');
      return;
    }

    axios
      .post(base_url() + '/api/users', formValues)
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage(`Lead successfully created`);
        setTimeout(() => {
          setOpenModal(!openModal);
        }, 1500);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || `Couldn't create the lead`
        );
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <Dialog
        fullScreen
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant='h6'
              component='div'
            >
              Create lead
            </Typography>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => {
                console.log('close');

                setOpenModal(!openModal);
              }}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box px={10}>
          <CreationProgress
            activeStep={activeStep}
            onStepChange={handleStepChange}
            errorStepper={
              Object.values(formErrors).some((error) => error !== '')
                ? activeStep
                : undefined
            }
          />
          {leadForms.map((form, index) => {
            if (index === activeStep) {
              const FormComponent = form.component;
              return (
                <FormComponent
                  key={index}
                  {...{
                    formValues,
                    setFormValues,
                    formErrors,
                    setFormErrors,
                    formSubmitted,
                  }}
                />
              );
            }
            return null;
          })}
        </Box>
        <Box
          display={'flex'}
          justifyContent={'center'}
        >
          <Box
            mt={4}
            width={'200px'}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <NewLeadNavigationButtons
              backButtonText='Back'
              nextButtonText={
                activeStep === leadForms.length - 1 ? 'Save' : 'Continue'
              }
              backButtonHandler={handleBack}
              nextButtonHandler={
                activeStep === leadForms.length - 1 ? handleSubmit : handleNext
              }
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default LeadsLayout;
