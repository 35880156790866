import { useState } from 'react';
import { base_url } from '../Mode';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import CryptoJS from 'crypto-js';
import EncryptionKeyModal from './EncryptionKeyModal';
import { AES } from 'crypto-js';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import SalaryFilters from './SalaryFilters';
import SalaryTabs from './SalaryTabs';

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

export const sortDataByDate = (data, isIncentive = null) => {
  if (isIncentive) {
    return data.sort(
      (a, b) =>
        new Date(b?.incentive_cleared_till) -
        new Date(a?.incentive_cleared_till)
    );
  } else {
    return data.sort(
      (a, b) =>
        new Date(b?.salary_cleared_till) - new Date(a?.salary_cleared_till)
    );
  }
};

const SalaryTable = ({ isAccess, setIsAccess }) => {
  const [encryptionKey, setEncryptionKey] = useState('');
  const [encryptionKeyError, setEncryptionKeyError] = useState('');
  const [showKey, setShowKey] = useState(false);
  const [salaryData, setSalaryData] = useState([]);
  const [incentiveData, setIncentiveData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [fetchingData, setFetchingData] = useState(false);
  const [isSalaryTab, setIsSalaryTab] = useState(true);

  const fetchSalaryData = (url) => {
    setFetchingData(true);
    axios
      .get(`${base_url()}${url ? url : '/api/salaries'}`)
      .then((res) => {
        const salaryData = res?.data?.salaryArray;
        const incentiveData = res?.data?.incentiveArray;

        salaryData.length === 0 && incentiveData.length === 0
          ? setIsAccess(true)
          : setIsAccess(false);
        setSalaryData(salaryData);
        setIncentiveData(incentiveData);
        handleShowSalary(salaryData, incentiveData);
        setFetchingData(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage('Error in fetching Salary');
        setFetchingData(false);
      });
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      renderCell: (params) =>
        params?.row?.name.charAt(0).toUpperCase() + params?.row?.name?.slice(1),
    },
    { field: 'email', headerName: 'Email', width: 350 },

    { field: 'salary', headerName: 'Amount', width: 250 },

    {
      field: isSalaryTab ? 'salary_cleared_till' : 'incentive_cleared_till',
      headerName: isSalaryTab
        ? 'Salary Cleared Till'
        : 'Incentive Cleared Till',
      width: 250,
      valueFormatter: (params) => formatDate(params?.value),
    },
  ];

  const decryptField = (field) => {
    try {
      if (field.startsWith('U2FsdGVkX')) {
        const decryptedValue = AES.decrypt(field, encryptionKey).toString(
          CryptoJS.enc.Utf8
        );
        setIsAccess(true);
        setEncryptionKeyError('');

        if (decryptedValue === '') {
          setEncryptionKeyError('Wrong encryption key entered');
          setEncryptionKeyError('');
          setHasError(true);
          setErrorMessage('Wrong encryption key entered');
          setIsAccess(false);
          return field;
        } else {
          return decryptedValue;
        }
      }
      return field;
    } catch (error) {
      console.error('Decryption error:', error);
      setEncryptionKeyError('Wrong encryption key entered');
      setHasError(true);
      setErrorMessage('Wrong encryption key entered');
      setIsAccess(false);
      return field;
    }
  };

  const handleShowSalary = (
    salaryDataFromFilter = null,
    incentiveDataFromFilter = null
  ) => {
    let salaryDataToDecrypt;
    let incentiveDataToDecrypt;
    if (
      Array.isArray(salaryDataFromFilter) &&
      Array.isArray(incentiveDataFromFilter)
    ) {
      salaryDataToDecrypt = salaryDataFromFilter;
      incentiveDataToDecrypt = incentiveDataFromFilter;
    } else {
      salaryDataToDecrypt = salaryData;
      incentiveDataToDecrypt = incentiveData;
    }
    if (
      Array.isArray(incentiveDataToDecrypt) &&
      Array.isArray(salaryDataToDecrypt) &&
      incentiveDataToDecrypt?.length === 0 &&
      salaryDataToDecrypt?.length === 0
    ) {
      setEncryptionKeyError('');
    }

    if (!encryptionKey) {
      setEncryptionKeyError('Encryption key is required');
      return;
    }
    if (encryptionKey.includes(' ')) {
      setEncryptionKeyError('Encryption key should not contain spaces');
      return;
    }
    const decryptedSalaryData = salaryDataToDecrypt?.map((entry) => {
      return {
        ...entry,
        salary: decryptField(entry?.salary),
      };
    });

    const decryptedIncentiveData = incentiveDataToDecrypt?.map((entry) => {
      return {
        ...entry,
        salary: decryptField(entry?.salary),
      };
    });

    const sortedSalaryData = sortDataByDate(decryptedSalaryData);

    const sortedIncentiveData = sortDataByDate(decryptedIncentiveData, true);

    setSalaryData(sortedSalaryData);
    setIncentiveData(sortedIncentiveData);
  };
  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <SalaryFilters
        setHasError={setHasError}
        setErrorMessage={setErrorMessage}
        fetchingData={fetchingData}
        fetchSalaryData={fetchSalaryData}
      />
      <Box sx={{ marginTop: '50px', marginBottom: '10px' }}>
        <SalaryTabs setIsSalaryTab={setIsSalaryTab} />
      </Box>

      <div style={{ height: 500, width: '100%' }}>
        <Box sx={{ height: 500, width: '100%' }}>
          {isAccess &&
          ((isSalaryTab && salaryData.length !== 0) ||
            (!isSalaryTab && incentiveData.length !== 0)) ? (
            <DataGrid
              rows={isSalaryTab ? salaryData : incentiveData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              components={{
                Footer: () => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      padding: '10px',
                    }}
                  ></div>
                ),
              }}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px',
              }}
            >
              No Salary Data
            </div>
          )}
        </Box>
      </div>

      <EncryptionKeyModal
        setEncryptionKey={setEncryptionKey}
        setEncryptionKeyError={setEncryptionKeyError}
        bulkUploadSalary={handleShowSalary}
        encryptionKey={encryptionKey}
        encryptionKeyError={encryptionKeyError}
        setShowKey={setShowKey}
        showKey={showKey}
        decrypt={true}
      />
    </>
  );
};

export default SalaryTable;
