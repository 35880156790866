import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import SuccessNotifier from '../../../ToastNotifications/SuccessNotifier';
import { base_url } from '../../../Mode';
import { getFormattedTimestampWithYear } from '../../../../utils/common';
import { fileUploadHandler } from '../../../SpeechRecognition/utils';

const PaymentDetails = ({
  setErrorMessage,
  setHasError,
  paymentDetails,
  setPaymentDetails,
  disableRecordActions,
}) => {
  const [perPage, setPerPage] = useState(50);
  const [pdBeingUpdated, setPDBeingUpdated] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [totalTxnAmount, setTotalTxnAmount] = useState(0);
  const [pdIdToIndexMap, setPDIdToIndexMap] = useState({});
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState('');

  useEffect(() => {
    const tmpMap = {};
    console.log('paymentDetails -', paymentDetails);
    paymentDetails.forEach((payment, index) => {
      tmpMap[payment.id] = index;
    });
    setPDIdToIndexMap(tmpMap);
    return () => {};
  }, [paymentDetails]);

  useEffect(() => {
    calculateTxnAmountData();
  }, [paymentDetails]);

  const calculateTxnAmountData = () => {
    let txnAmountSum = 0;

    paymentDetails.forEach((payment) => {
      txnAmountSum += Number(payment.txn_amount);
    });

    setTotalTxnAmount(txnAmountSum);
  };

  const handleImageUpload = async (event, id) => {
    const file = event.target.files[0];
    console.log('file -', { name: file.name, data: file });
    if (file) {
      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   const updatedRows = paymentDetails.map((row) =>
      //     row.id === id ? { ...row, payment_screenshot: e.target.result } : row
      //   );
      //   setPaymentDetails(updatedRows);
      // };
      // reader.readAsDataURL(file);
      const data = await fileUploadHandler(id, { name: file.name, data: file });
      if (data.success) {
        const updatedRows = paymentDetails.map((row) =>
          row.id === id ? { ...row, payment_screenshot: data.response } : row
        );
        setPaymentDetails(updatedRows);
        setIsSuccess(true);
        setSuccessMessage(data.message);
      } else {
        setHasError(true);
        setErrorMessage(data.message);
      }
    }
  };

  const handleClickOpen = (imageUrl) => {
    if (imageUrl) {
      setSelectedImage(imageUrl);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage('');
  };

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      type: 'singleSelect',
      valueOptions: ['received', 'expected'],
      editable: true,
    },
    {
      field: 'source_type',
      headerName: 'Source',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      type: 'singleSelect',
      valueOptions: ['bank_transfer', 'online_payment', 'emi'],
      editable: true,
    },
    {
      field: 'txn_amount',
      headerName: 'Transaction amount',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'disbursed_amount',
      headerName: 'Disbursed amount',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'transaction_id',
      headerName: 'Transaction id',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'disbursed_date',
      headerName: 'Disbursed date',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) =>
        getFormattedTimestampWithYear(new Date(params.row.disbursed_date)),
    },
    // {
    //   field: 'payment_screenshot',
    //   headerName: 'Payment Screenshot',
    //   width: 200,
    //   align: 'left',
    //   headerAlign: 'left',
    //   renderCell: (params) => (
    //     <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
    //       <img
    //         src={params.value}
    //         alt='Item'
    //         style={{ width: '100px', height: 'auto', objectFit: 'cover' }}
    //         onClick={() => handleClickOpen(params.value)}
    //       />
    //       {!disableRecordActions() && (
    //         <IconButton
    //           color='primary'
    //           component='label'
    //         >
    //           <CloudUploadIcon />
    //           <input
    //             type='file'
    //             hidden
    //             accept='image/*'
    //             onChange={(event) => handleImageUpload(event, params.id)}
    //           />
    //         </IconButton>
    //       )}
    //     </div>
    //   ),
    // },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = pdBeingUpdated[id]?.mode === 'edit';
        if (isInEditMode) {
          return [
            <CheckIcon
              fontSize='medium'
              color='success'
              sx={{ cursor: 'pointer' }}
              onClick={(e) => handlePDUpdate('updated', id)}
            />,
            <ClearIcon
              fontSize='medium'
              color='error'
              onClick={(e) => handlePDUpdate('clear', id)}
              sx={{ cursor: 'pointer' }}
            />,
          ];
        }
        return [
          <EditIcon
            onClick={(e) => {
              setPDBeingUpdated({
                ...pdBeingUpdated,
                [id]: {
                  mode: 'edit',
                },
              });
            }}
            sx={{ cursor: 'pointer' }}
          />,
          <DeleteIcon
            onClick={(e) => handlePaymentDeletion(id)}
            color='error'
            sx={{ cursor: 'pointer' }}
          />,
        ];
      },
      hide: disableRecordActions(),
    },
  ];

  const handlePDUpdate = (clickType, id) => {
    if (clickType === 'clear') {
      setPDBeingUpdated({
        ...pdBeingUpdated,
        [id]: {
          mode: 'view',
          ignoreModifications: true,
        },
      });
      return;
    }

    setPDBeingUpdated({
      ...pdBeingUpdated,
      [id]: {
        mode: 'view',
      },
    });
  };

  const processPaymentDetailsUpdate = (updatedPayment) => {
    const body = { ...updatedPayment };
    axios
      .patch(`${base_url()}/api/post-sales-payments/${updatedPayment.id}`, body)
      .then((res) => {
        const index = pdIdToIndexMap[updatedPayment.id];
        const tmp = [...paymentDetails];
        tmp[index] = res.data;
        updatedPayment = {
          ...res.data,
        };
        setPaymentDetails(tmp);
        setIsSuccess(true);
        setSuccessMessage('Payment details updated');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't update the payment details"
        );
      });

    return updatedPayment;
  };

  const handlePaymentDeletion = (id) => {
    axios
      .delete(`${base_url()}/api/post-sales-payments/${id}`)
      .then(() => {
        setPaymentDetails((prev) => {
          return prev.filter((payment) => payment.id != id);
        });
        setIsSuccess(true);
        setSuccessMessage('Successfully deleted the payment details.');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't delete the payment details"
        );
      });
  };

  return (
    <Box
      height='100%'
      sx={{ mt: 5 }}
    >
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <DataGrid
        rows={paymentDetails}
        getRowId={(row) => row.id}
        columns={columns}
        pageSize={perPage}
        rowsPerPageOptions={[50, 100]}
        onPageSizeChange={(page) => setPerPage(page)}
        pagination
        autoHeight
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#e3f2fd',
          },
          '& .MuiDataGrid-cell': {
            paddingLeft: '25px',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            paddingLeft: '20px',
          },
        }}
        editMode='row'
        rowModesModel={pdBeingUpdated}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processPaymentDetailsUpdate}
        onRowEditStart={(params, event) => {
          event.defaultMuiPrevented = true;
        }}
        onRowEditStop={(params, event) => {
          event.defaultMuiPrevented = true;
        }}
        showFooter
        footerHeight={40}
        components={{
          Footer: () => (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '8px',
              }}
            >
              <Typography
                variant='subtitle1'
                fontWeight='bold'
                marginLeft='16px'
              >
                Total Txn Amount: {totalTxnAmount}
              </Typography>
            </Box>
          ),
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
        fullWidth
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <DialogTitle>Image Preview</DialogTitle>
          <IconButton onClick={handleClose}>
            <ClearIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <DialogContentText>
            <img
              src={selectedImage}
              alt='Full Preview'
              style={{ width: '100%', height: 'auto' }}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PaymentDetails;
