import { Box, Typography } from '@mui/material';
import constants from '../../utils/constants';
import { getTimeDifference } from '../../utils/getTimeDifference';

const LeadStatusBox = ({
  LeadStatusRef,
  currentLeadData,
  currentLeadStatusActivities,
}) => {
  const { fname, lname, status } = currentLeadData;
  const fullname = fname + (lname !== null ? ` ${lname}` : '');
  const lastActivity =
    currentLeadStatusActivities[currentLeadStatusActivities.length - 1] || [];
  const { updated_at, metadata } = lastActivity;
  const {
    ASSIGNED,
    CONNECT_ATTEMPTED,
    LEAD_QUALIFICATION_DONE,
    COMPLETE_PROGRAM_EXPLAINED,
    TOKEN_AMOUNT_PAID,
    NEED_GENERATION_DONE,
    DREAM_SELLING_DONE,
    QUESTIONING_IN_TECH_DONE,
    ELIGIBILITY_FORM_SENT,
    ELIGIBILITY_FORM_FILLED,
    INTENT_CLOSURE_CALL_DONE,
    SCREENING_CALL_ATTENDED,
    OFFER_LETTER_PAYMENT_LINK_SENT,
    SCREENING_CALL_SCHEDULED,
    PAYMENT_LINK_SENT,
  } = constants;

  return (
    <>
      {status === ASSIGNED && (
        <Box
          ref={LeadStatusRef}
          className='signedFromRef'
          sx={{
            zIndex: 4,
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '80vw',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'opacity 0.1s ease-in',
            opacity: 0,
            padding: '0 40px',
          }}
        >
          <Typography
            sx={{
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            You will be interacting with {fullname}
          </Typography>

          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: '40px',
              fontWeight: 700,
              width: '820px',
            }}
          >
            <span
              style={{
                color: '#EBE3F8',
                fontSize: '44px',
                textAlign: 'center',
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: 700,
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundImage:
                  'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
              }}
            >
              {' '}
              for the{' '}
            </span>
            first time.
          </Typography>
        </Box>
      )}

      {status === CONNECT_ATTEMPTED && (
        <Box
          ref={LeadStatusRef}
          className='signedFromRef'
          sx={{
            zIndex: '2',
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '80vw',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'opacity 0.1s ease-in',
            opacity: 0,
            padding: '0 40px',
          }}
        >
          <Typography
            sx={{
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            You have tried reaching out
          </Typography>
          <Typography
            sx={{
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            to {fullname} {getTimeDifference(updated_at)} ago
          </Typography>

          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: '40px',
              fontWeight: 700,
              width: '820px',
            }}
          >
            <span
              style={{
                color: '#EBE3F8',
                fontSize: '44px',
                textAlign: 'center',
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: 700,
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundImage:
                  'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
              }}
            >
              but the call{' '}
            </span>
            didn’t connect.
          </Typography>
        </Box>
      )}

      {(status === LEAD_QUALIFICATION_DONE ||
        status === NEED_GENERATION_DONE ||
        status === DREAM_SELLING_DONE ||
        status === QUESTIONING_IN_TECH_DONE) && (
        <Box
          ref={LeadStatusRef}
          className='signedFromRef'
          sx={{
            zIndex: '2',
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '80vw',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'opacity 0.1s ease-in',
            opacity: 0,
            padding: '0 40px',
          }}
        >
          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: '40px',
              fontWeight: 700,
              width: '820px',
            }}
          >
            Lead’s background
          </Typography>
          <Typography
            sx={{
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            has been discussed.
          </Typography>

          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: '40px',
              fontWeight: 700,
              width: '820px',
            }}
          >
            Complete Program
          </Typography>
          <Typography
            sx={{
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            is yet to be explained.
          </Typography>
        </Box>
      )}

      {status === COMPLETE_PROGRAM_EXPLAINED && (
        <Box
          ref={LeadStatusRef}
          className='signedFromRef'
          sx={{
            zIndex: '2',
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '80vw',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'opacity 0.1s ease-in',
            opacity: 0,
            padding: '0 40px',
          }}
        >
          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: '40px',
              fontWeight: 700,
              width: '820px',
            }}
          >
            Complete Program
          </Typography>
          <Typography
            sx={{
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            was explained with a conversation of over
          </Typography>

          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: '40px',
              fontWeight: 700,
              width: '820px',
            }}
          >
            {metadata.total_call_duration} minutes.
          </Typography>
        </Box>
      )}

      {status === ELIGIBILITY_FORM_SENT && (
        <Box
          ref={LeadStatusRef}
          className='signedFromRef'
          sx={{
            zIndex: '2',
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '80vw',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'opacity 0.1s ease-in',
            opacity: 0,
            padding: '0 40px',
          }}
        >
          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: '40px',
              fontWeight: 700,
              width: '820px',
            }}
          >
            Eligibility Form
          </Typography>
          <Typography
            sx={{
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            was sent {getTimeDifference(updated_at)} ago but the form is
          </Typography>

          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: '40px',
              fontWeight: 700,
              width: '820px',
            }}
          >
            yet to be filled.
          </Typography>
        </Box>
      )}

      {status === ELIGIBILITY_FORM_FILLED && (
        <Box
          ref={LeadStatusRef}
          className='signedFromRef'
          sx={{
            zIndex: '2',
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '80vw',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'opacity 0.1s ease-in',
            opacity: 0,
            padding: '0 40px',
          }}
        >
          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: '40px',
              fontWeight: 700,
              width: '820px',
            }}
          >
            Eligibility Form
          </Typography>
          <Typography
            sx={{
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            was filled {getTimeDifference(updated_at)} ago..
          </Typography>

          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: '40px',
              fontWeight: 700,
              width: '820px',
            }}
          >
            IPS call is yet to be done.
          </Typography>
        </Box>
      )}

      {status === INTENT_CLOSURE_CALL_DONE && (
        <Box
          ref={LeadStatusRef}
          className='signedFromRef'
          sx={{
            zIndex: '2',
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '80vw',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'opacity 0.1s ease-in',
            opacity: 0,
            padding: '0 40px',
          }}
        >
          <Typography
            sx={{
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            IPS call is done.
          </Typography>
          <Typography
            sx={{
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            Screening call is yet to be
          </Typography>

          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: '40px',
              fontWeight: 700,
              width: '820px',
            }}
          >
            scheduled.
          </Typography>
        </Box>
      )}

      {status === SCREENING_CALL_SCHEDULED && (
        <Box
          ref={LeadStatusRef}
          className='signedFromRef'
          sx={{
            zIndex: '2',
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '80vw',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'opacity 0.1s ease-in',
            opacity: 0,
            padding: '0 40px',
          }}
        >
          <Typography
            sx={{
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            IPS call is done.
          </Typography>

          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: '40px',
              fontWeight: 700,
              width: '820px',
            }}
          >
            Screening call is scheduled.
          </Typography>
        </Box>
      )}

      {(status === SCREENING_CALL_ATTENDED ||
        status === OFFER_LETTER_PAYMENT_LINK_SENT ||
        status === PAYMENT_LINK_SENT) && (
        <Box
          ref={LeadStatusRef}
          className='signedFromRef'
          sx={{
            zIndex: '2',
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '80vw',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'opacity 0.1s ease-in',
            opacity: 0,
            padding: '0 40px',
          }}
        >
          <Typography
            sx={{
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            {fullname} has attended the
          </Typography>
          <Typography
            sx={{
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            screening call
          </Typography>
          <Typography
            sx={{
              color: '#EBE3F8',
              fontSize: '40px',
              textAlign: 'center',
              fontFamily: "'Montserrat', sans-serif",
            }}
          >
            {getTimeDifference(updated_at)} ago.
          </Typography>
          <Typography
            sx={{
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            No{' '}
            <span
              style={{
                color: '#FFF ',
                textAlign: 'center',
                fontSize: '40px',
                fontWeight: 700,
                backgroundImage: '#fff',
                maxWidth: '820px',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              token amount
            </span>{' '}
            has been paid.
          </Typography>
        </Box>
      )}

      {status === TOKEN_AMOUNT_PAID && (
        <Box
          ref={LeadStatusRef}
          className='signedFromRef'
          sx={{
            zIndex: '2',
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            // width: "80vw",
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'opacity 0.1s ease-in',
            opacity: 0,
            padding: '0 40px',
          }}
        >
          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: '45px',
              fontWeight: 700,
            }}
          >
            <span
              style={{
                color: '#EBE3F8',
                fontSize: '44px',
                textAlign: 'center',
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: 700,
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundImage:
                  'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
              }}
            >
              {fullname} has paid a{' '}
            </span>
            token amount
          </Typography>
          <Typography
            sx={{
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            {getTimeDifference(updated_at)} ago.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default LeadStatusBox;
