import React, { useState, useEffect } from 'react';
import { Box, Switch, ButtonGroup, Button } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import clsx from 'clsx';
import { DataGrid } from '@mui/x-data-grid';
import { base_url } from '../../components/Mode';
import {
  splitByUnderscoresAndCapitalize,
  getSubordinateAdminIds,
  currentAdminIsAdmin,
  currentAdminIsHr,
  currentAdminIsTa,
  getCurrentAdminRole,
} from '../../utils/common';
import { useAllAdmins } from '../../components/AdminsContext';
import { styled } from '@mui/material/styles';
import constants from '../../utils/constants';
const { DISABLED, SENIOR_MANAGER, ADMIN } = constants;

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .super-app-theme--restricted': {
    backgroundColor: '#e5e7eb',
  },
  '& .super-app-theme--disabled': {
    backgroundColor: '#e5e7eb',
  },
}));

const UsersTable = ({
  setSuccessMessage,
  setErrorMessage,
  setIsSuccess,
  setHasError,
}) => {
  const { allAdmins, setAllAdmins } = useAllAdmins();
  const [adminIdToIndexMap, setAdminIdToIndexMap] = useState({});
  const [perPage, setPerPage] = useState(50);
  const [subordinateAdmins, setSubordinateAdmins] = useState([]);
  const subordinateAdminIds = getSubordinateAdminIds();
  const adminRole = getCurrentAdminRole();
  //  if allAdmins is an empty array, find function will return undefined and hence, subordinateAdmins will have an entry as undefined. so we block empty array.
  useEffect(() => {
    if (allAdmins.length > 0) {
      const admins = subordinateAdminIds.map((id) =>
        allAdmins.find((admin) => admin.id == id)
      );
      setSubordinateAdmins(admins);
    }
  }, [allAdmins]);

  useEffect(() => {
    const tmpMap = {};
    subordinateAdmins.length > 0 &&
      subordinateAdmins.forEach((admin, index) => {
        tmpMap[admin?.id] = index;
      });
    setAdminIdToIndexMap(tmpMap);
    return () => {};
  }, [subordinateAdmins]);

  useEffect(() => {
    const admins = [];
    subordinateAdminIds.forEach((id) => {
      const filterItem = allAdmins.find((admin) => admin.id === id);
      if (filterItem !== undefined) {
        admins.push(filterItem);
      }
    });
    // Only HR and Admins can enable a disabled admin.
    //For other roles, remove all disabled admins from the list that is sent to them.
    const filteredAdmins =
      !currentAdminIsAdmin() || !currentAdminIsHr() || !currentAdminIsTa()
        ? admins.filter((admin) => admin.access_type !== DISABLED)
        : admins;

    setSubordinateAdmins(filteredAdmins);
  }, [allAdmins]);

  const statusMap = {
    red_alert: 'Red Alert',
    supervision: 'Supervision',
    green_zone: ' ',
  };
  const statusColorMap = {
    red_alert: '#ec2a2a',
    supervision: '#ffeb99',
    green_zone: ' ',
  };
  const hasIndependentContributorRole = (roles) => {
    return roles.some((role) => role.name === 'independent_contributor');
  };

  const columns = [
    {
      field: 'id',
      headerName: '#',
      width: 80,
      align: 'left',
      headerAlign: 'left',
      type: 'number',
      paddingLeft: '25px',
      valueGetter: (params) => `${adminIdToIndexMap[params.row.id] + 1}`,
    },
    {
      field: 'name',
      headerName: 'Admin name',
      width: 280,
      align: 'left',
      headerAlign: 'left',
      paddingLeft: '25px',
      valueGetter: (params) =>
        `${splitByUnderscoresAndCapitalize(
          params.row.fname
        )} ${splitByUnderscoresAndCapitalize(params.row.lname)}`,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 260,
      align: 'left',
      headerAlign: 'left',
    },
    // {
    //   field: 'enforce_focus_mode',
    //   headerName: 'Focus mode',
    //   width: 150,
    //   align: 'left',
    //   headerAlign: 'left',
    //   type: 'singleSelect',
    //   editable: true,
    //   valueOptions: ['Enforced', 'Unenforced'],
    //   valueGetter: (params) =>
    //     params.row.enforce_focus_mode ? 'Enforced' : 'Unenforced',
    //   renderCell: (params) => {
    //     return (
    //       <Switch
    //         checked={params.row.enforce_focus_mode}
    //         onChange={(e) => {
    //           const body = {
    //             enforce_focus_mode: !params.row.enforce_focus_mode,
    //           };
    //           updateAdminApiCall(body, params.row.id);
    //         }}
    //         color={params.row.enforce_focus_mode ? 'success' : 'default'}
    //       />
    //     );
    //   },
    //   cellClassName: (params) => {
    //     return clsx('access', {
    //       disabled: params.value === 'Enforced',
    //       allowed: params.value === 'Unenforced',
    //     });
    //   },
    // },
    {
      field: 'access_type',
      headerName: 'Access type',
      width: 300,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) => params.row.access_type,
      renderCell: (params) => {
        const states = ['allowed', 'restricted', 'disabled'];
        return (
          <ButtonGroup
            color='primary'
            size='small'
          >
            {states.map((state) => {
              const isRestricted = state === 'restricted';
              return (
                <Button
                  key={state}
                  variant={
                    params.row.access_type === state ? 'contained' : 'outlined'
                  }
                  onClick={() => {
                    if (!isRestricted) {
                      const body = { access_type: state };
                      updateAdminApiCall(body, params.row.id);
                    }
                  }}
                  disabled={isRestricted}
                >
                  {state}
                </Button>
              );
            })}
          </ButtonGroup>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        const hasRole = hasIndependentContributorRole(params.row.roles);
        const status = hasRole ? params.row.status : '';
        const color = hasRole && statusColorMap[status];
        const statusValue = hasRole ? statusMap[status] || '' : '';
        return (
          <Box
            sx={{
              backgroundColor: color,
              width: '130px',
              minWidth: '130px',
              padding: '5px 0',
              borderRadius: '6px',
              display: 'inline-block',
              marginRight: '8px',
              textAlign: 'center',
              color: '#fff',
            }}
          >
            {statusValue}
          </Box>
        );
      },
    },
    {
      field: 'dateSinceStatus',
      headerName: 'Date Since Status',
      width: 260,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) => {
        const hasRole = hasIndependentContributorRole(params.row.roles);
        const status = params.row.status;
        if (
          hasRole &&
          params.row.status_applicable_from &&
          status !== 'green_zone'
        ) {
          return moment(params.row.status_applicable_from).format('YYYY-MM-DD');
        }
        return '';
      },
    },
    (adminRole.includes(SENIOR_MANAGER) || adminRole.includes(ADMIN)) && {
      field: 'allow_lead_assignment',
      headerName: 'Lead Assignment',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      type: 'singleSelect',
      editable: true,
      valueOptions: ['AllowedLeadAssignment', 'UnAllowedLeadAssignment'],
      valueGetter: (params) =>
        params.row.allow_lead_assignment
          ? 'AllowedLeadAssignment'
          : 'UnAllowedLeadAssignment',
      renderCell: (params) => {
        const hasTraineeRole = params.row.roles?.some(
          (role) => role.name === 'trainee'
        );

        return (
          hasTraineeRole && (
            <Switch
              checked={params.row.allow_lead_assignment}
              onChange={(e) => {
                const body = {
                  allow_lead_assignment: !params.row.allow_lead_assignment,
                };
                updateAdminApiCall(body, params.row.id);
              }}
              color={params.row.allow_lead_assignment ? 'success' : 'default'}
            />
          )
        );
      },
      cellClassName: (params) => {
        return clsx('access', {
          disabled: params.value === 'AllowedLeadAssignment',
          allowed: params.value === 'UnAllowedLeadAssignment',
        });
      },
    },
  ];

  const updateAdminApiCall = (body, adminId) => {
    axios
      .patch(`${base_url()}/api/subordinate/${adminId}`, body)
      .then((res) => {
        const tmpAdmins = [...subordinateAdmins];
        const index = adminIdToIndexMap[adminId];
        tmpAdmins[index] = res.data;
        setSubordinateAdmins(tmpAdmins);
        setAllAdmins(tmpAdmins);
        setIsSuccess(true);
        setSuccessMessage('Admin successfully updated');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not update the admin.'
        );
      });
  };

  return (
    <Box
      width='100%'
      height='100%'
      sx={{
        mt: 5,
        '& .access.allowed': {
          color: 'green',
          fontWeight: '600',
        },
        '& .access.disabled': {
          color: 'red',
          fontWeight: '600',
        },
      }}
    >
      <StyledDataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
        rows={subordinateAdmins}
        columns={columns}
        pageSize={perPage}
        rowsPerPageOptions={[50, 100]}
        onPageSizeChange={(page) => setPerPage(page)}
        pagination
        autoHeight
        disableSelectionOnClick
        getRowClassName={(params) =>
          `super-app-theme--${params.row.access_type}`
        }
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#e3f2fd',
          },
          '& .MuiDataGrid-cell': {
            paddingLeft: '25px',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            paddingLeft: '15px',
          },
        }}
      />
    </Box>
  );
};

export default UsersTable;
