import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CandidateDetailView from './CandidateDetailView';
import { Box, Chip } from '@mui/material';
import { useCurrentCandidateContext } from '../../components/hrms/CurrentCandidateContext';
import {
  getCandidateStatusColor,
  getCandidateStatusLable,
} from '../../utils/hrms/common';
import { CopyIcon } from '../../utils/common';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction='up'
      ref={ref}
      {...props}
    />
  );
});

export default function Interactions({ open, handleClose, selectedRowData }) {
  const { candidateData } = useCurrentCandidateContext();
  const renderLabel = (row) => {
    if (!row.label) {
      return;
    }
    if (!row.label_bg_color && !row.label_font_color) {
      return (
        <Chip
          label={row.label}
          sx={{ backgroundColor: '#3f51b5', color: '#fff', margin: 'auto' }}
        />
      );
    }
    return (
      <Chip
        label={row.label}
        sx={{
          backgroundColor: row.label_bg_color,
          color: row.label_font_color,
          margin: 'auto',
        }}
      />
    );
  };
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: 'relative',
            backgroundColor: getCandidateStatusColor(selectedRowData.status),
          }}
        >
          <Toolbar>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                ml: 2,
                flex: 1,
                color: 'black',
              }}
              variant='h6'
              component='div'
            >
              <AccountCircleIcon sx={{ marginRight: '14px' }} />

              {`#${selectedRowData.id} ${candidateData.name}`}
              <Box sx={{ mt: 1 }}>
                {CopyIcon(`#${selectedRowData.id} ${candidateData.name}`)}
              </Box>
              <Box sx={{ marginLeft: '10px' }}>
                {renderLabel(selectedRowData)}
              </Box>
              <Box sx={{ marginLeft: '10px' }}>
                {getCandidateStatusLable(candidateData.status)}
              </Box>
            </Typography>

            <IconButton
              edge='start'
              color='default'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <CandidateDetailView selectedRowData={selectedRowData} />
      </Dialog>
    </React.Fragment>
  );
}
