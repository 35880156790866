import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import { getFormattedTimestamp } from '../../../../../utils/common';
import AudioPlayer from '../../AudioPlayer';

const SubmissionsTable = ({
  submissions,
  setIsOpenResultModal,
  setSelectedRequest,
  setIsUploadModalOpen,
  setSubmissionId,
}) => {
  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: '400px', overflowY: 'auto' }}
    >
      <Table
        sx={{ minWidth: 650 }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            <TableCell align='center'>Sl.No</TableCell>
            <TableCell align='center'>Status</TableCell>
            <TableCell align='center'>Recording</TableCell>
            <TableCell align='center'>Feedback by Interviewer</TableCell>
            <TableCell align='center'>Feedback by Trainer</TableCell>
            <TableCell align='center'>Interviewer</TableCell>
            <TableCell align='center'>Phone No</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {submissions.map((submission, index) => (
            <TableRow
              key={submission.submission_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align='center'>{index + 1}</TableCell>
              <TableCell
                align='center'
                style={{
                  color:
                    submission.status === 'accepted'
                      ? 'green'
                      : submission.status === 'rejected'
                      ? 'red'
                      : '#ff9900',
                }}
              >
                {submission.status ? submission.status : 'pending'}
              </TableCell>
              <TableCell align='center'>
                {submission?.file_path ? (
                  <AudioPlayer audioSrc={submission?.file_path} />
                ) : (
                  <Button
                    style={{ backgroundColor: '#3994FF' }}
                    variant='contained'
                    onClick={() => {
                      setSubmissionId(submission?.id);
                      setIsUploadModalOpen(true);
                    }}
                  >
                    Upload Now
                  </Button>
                )}
              </TableCell>
              <TableCell
                align='center'
                style={{
                  color: submission?.feedback_by_interviewer ? 'black' : 'red',
                }}
              >
                {submission?.feedback_by_interviewer || 'Not Available'}
              </TableCell>
              <TableCell
                align='center'
                style={{
                  color: submission?.feedback_by_trainer ? 'black' : 'red',
                }}
              >
                {submission?.feedback_by_trainer || 'Not Available'}
              </TableCell>
              <TableCell align='center'>
                {submission?.fname + ' ' + submission.lname}
              </TableCell>
              <TableCell align='center'>{submission?.phone}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SubmissionsTable;
