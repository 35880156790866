import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import StopCircleIcon from '@mui/icons-material/StopCircle';

const TextToSpeech = ({ questionId, text }) => {
  const [isPaused, setIsPaused] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [utterance, setUtterance] = useState(null);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);

    setUtterance(u);

    u.addEventListener('end', handleEnd);

    if (!isPaused) {
      synth.speak(u);
    }

    return () => {
      u.removeEventListener('end', handleEnd);
      synth.cancel();
    };
  }, [questionId, text, isPaused]);

  const handlePlay = () => {
    const synth = window.speechSynthesis;

    if (isPaused || isEnd) {
      synth.speak(utterance);
      setIsEnd(false);
    }

    setIsPaused(false);
  };

  const handlePause = () => {
    const synth = window.speechSynthesis;

    synth.pause();

    setIsPaused(true);
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;

    synth.cancel();

    setIsPaused(true);
  };

  const handleEnd = () => {
    setIsEnd(true);
    setIsPaused(true);
  };

  return (
    <>
      <Box
        ml={1}
        mb={1}
        display='flex'
        justifyContent='center'
        width='200px'
      >
        {isPaused ? (
          <PlayCircleOutlineIcon
            onClick={handlePlay}
            style={{ color: 'gray' }}
          />
        ) : (
          <PauseCircleOutlineIcon
            onClick={handlePause}
            style={{ color: 'gray' }}
          />
        )}
        <Box style={{ paddingTop: '10px' }}>
          <hr
            style={{
              width: '140px',
              height: '2px',
              margin: '0',
              border: 'none',
              backgroundColor: '#009af0',
            }}
          ></hr>
        </Box>
      </Box>
    </>
  );
};

export default TextToSpeech;
