import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { base_url } from '../Mode';
import { calculateIncentiveAndMilestone } from '../../utils/common';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
export const Caps = () => {
  const [totalcaps, setTotalCaps] = useState(0);
  const [maxCapDrive, setMaxCapDrive] = useState(0);
  const [totalincentive, setTotalIncentive] = useState(0);
  const [highestIncentive, setHighestIncentive] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchCaps();
  }, []);

  const fetchCaps = async () => {
    try {
      const username = getUsernameFromUrl();
      const response = await axios.get(
        `${base_url()}/api/admin/cap/?username=${username}`
      );

      const TotalCap = response.data?.count ?? 0;
      const MaxCapinDrive = response.data?.maxDrive?.maxCount ?? 0;
      setTotalCaps(TotalCap);
      setMaxCapDrive(MaxCapinDrive);
      const TotalIncentive = response.data?.Total ?? 0;
      const HighestDriveIncentiveValue = response.data?.Highest ?? 0;
      setTotalIncentive(TotalIncentive);
      setHighestIncentive(HighestDriveIncentiveValue);
    } catch (error) {
      setHasError(true);
      setErrorMessage(error.message);
    }
  };
  const getUsernameFromUrl = () => {
    const pathUrl = window.location.pathname.split('/');
    const usernameIndex = pathUrl.indexOf('admin-profile') + 1;

    return usernameIndex < pathUrl.length ? pathUrl[usernameIndex] : null;
  };
  function formatNumberWithCommas(number) {
    return number.toLocaleString('en-US');
  }

  return (
    <Grid
      item
      xs={12}
      md={8}
    >
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <Box
        width={'150%'}
        minHeight={200}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-evenly'}
        alignItems={'center'}
        borderRadius={'8px'}
        marginBottom={'16px'}
        // padding={'16px'}
        flexWrap='wrap'
        minWidth={300}
      >
        <Box
          className='number_of_cap_box'
          sx={{
            background: 'transparent',
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: '3%',
            paddingBottom: '10px',
            paddingTop: '10px',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#FFFFFF',
              width: '75%',
              padding: '10px 10px 10px',
              borderRadius: '5%',
              marginLeft: '1%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignitems: 'flex-start',
                textAlign: 'start',
                lineHeight: '10',
              }}
            >
              <Typography
                variant='h4'
                fontWeight='bold'
                color='black'
                gutterBottom
              >
                {maxCapDrive}
              </Typography>
              <Typography
                variant='body2'
                color='grey'
              >
                Higest CAP's delivered in a Drive
              </Typography>
            </div>
          </Box>
          <Box
            sx={{
              backgroundColor: '#FFFFFF',
              width: '75%',
              padding: '10px 10px 10px 10px',
              borderRadius: '5%',
              marginRight: '1%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignitems: 'flex-start',
                textalign: 'justify',
                lineHeight: '1.2',
              }}
            >
              <Typography
                variant='h4'
                fontWeight='bold'
                color='black'
                gutterBottom
              >
                {totalcaps}
              </Typography>
              <Typography
                variant='body2'
                color='grey'
              >
                Total no. of CAPs till now
              </Typography>
            </div>
          </Box>
        </Box>
        <Box
          sx={{
            background: 'transparent',
            width: '100%',
            display: 'flex',
            gap: '3%',
            paddingBottom: '10px',
            paddingTop: '10px',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#FFFFFF',
              width: '75%',
              padding: '10px 10px 10px 10px',
              borderRadius: '5%',
              marginLeft: '1%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignitems: 'flex-start',
                textAlign: 'justify',
                lineHeight: '1.2',
              }}
            >
              <Typography
                variant='h4'
                fontWeight='bold'
                color='black'
                gutterBottom
              >
                {formatNumberWithCommas(highestIncentive)}
              </Typography>
              <Typography
                variant='body2'
                color='grey'
              >
                Highest revenue generated in a drive
              </Typography>
            </div>
          </Box>
          <Box
            sx={{
              backgroundColor: '#FFFFFF',
              width: '75%',
              padding: '10px 10px 10px 10px',
              borderRadius: '5%',
              marginRight: '1%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignitems: 'flex-start',
                textAlign: 'justify',
                lineHeight: '1.2',
              }}
            >
              <Typography
                variant='h4'
                fontWeight='bold'
                color='black'
                gutterBottom
              >
                {formatNumberWithCommas(totalincentive)}
              </Typography>
              <Typography
                variant='body2'
                color='grey'
              >
                Total revenue generated till date
              </Typography>
            </div>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
