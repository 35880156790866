import { Box, Card, CardContent, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PencilIcon from '../Atoms/PencilIcon';
import SocialMediaIcon from './SocialMediaIcon';
import CustomImage from '../Atoms/CustomImage';
import ImageCropper from '../../../components/profile/ImageCropper';
import { base_url } from '../../../components/Mode';
import axios from 'axios';
import {
  capitalize,
  getCurrentAdminId,
  getCurrentAdminRole,
} from '../../../utils/common';
import CustomButton from '../Atoms/CustomButton';
import Text from '../Atoms/Text';
import CustomTextField from '../Atoms/CustomTextFiled';
import AddSocialMediaLink from './AddSocialMediaLink';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
import CustomModal from '../Atoms/CustomModal';
import ProfileEditingBanner from '../Atoms/ProfileEditingBanner';
import CustomBackdrop from '../../CustomBackdrop';
import ImagePreview from './ImagePreview';
const MIN_Dimension = 25;

const ProfileImageAndSocialMedia = ({
  handleImagePreview,
  closeImagePreview,
  previewImage,
  admin,
  requiredField,
  edit,
  onhandleEdit,
  onsaveEdit,
  personalData,
  about,
  setUpdate,
  update,
  openEditBannerHandler,
  showProfileEditingBanner,
}) => {
  const [imgsrc, setimgsrc] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState('');
  const [cropData, setCropData] = useState(null);
  const [imageUpdate, setImageupdate] = useState();
  const [backgroundImage, setbackgroundImage] = useState();
  const [backGroundUpdate, setBackGroundUpdate] = useState(false);
  const [openAddSocialMediaModal, setOpenAddSocialMediaModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    username: personalData?.username,
    currentRoles: personalData?.currentRoles,
    bio: personalData?.bio,
    pic: personalData?.profile_picture,
    banner: personalData?.banner,
    designation: personalData?.designation,
  });
  const [otherDetails, setOtherDetails] = useState({
    fullname: personalData?.fullname,
  });
  const [linksToEmbed, setLinksToEmbed] = useState({
    twitter: null,
    instagram: null,
    snapchat: null,
    linkedin: null,
    discord: null,
  });
  useEffect(() => {
    const adminRoles = getCurrentAdminRole();
    if (adminRoles) {
      setUserData((prev) => ({
        ...prev,
        currentRoles: adminRoles,
      }));
    }
  }, []);

  useEffect(() => {
    if (personalData) {
      updateLinksToEmbed(personalData?.social_media);
    }
  }, [personalData, update]);

  useEffect(() => {
    if (cropData) {
      if (backGroundUpdate) {
        setbackgroundImage(cropData.imgsrc);
      } else {
        setImageupdate(cropData.imgsrc);
      }
      setBackGroundUpdate(false);
    }
  }, [cropData]);

  const updateSocialMediaLinkData = (data) => {
    setUpdate(!update);
  };

  const updateLinksToEmbed = (socialMediaData) => {
    if (socialMediaData) {
      const parsedData = JSON.parse(socialMediaData);

      setLinksToEmbed((prevLinks) => ({
        twitter: parsedData.twitter || null,
        instagram: parsedData.instagram || null,
        snapchat: parsedData.snapchat || null,
        linkedin: parsedData.linkedin || null,
        discord: parsedData.discord || null,
      }));
    }
  };

  const onCropImage = (crop, imgsrc) => {
    setCropData({ crop, imgsrc });
  };

  const getUrl = (type) => {
    if (!type) {
      setHasError(true);
      setErrorMessage('Error in uploading image');
      return;
    }

    if (type === 'profile') {
      return `${base_url()}/api/admins/${getCurrentAdminId()}/profile?context=admin-profile`;
    }

    if (type === 'banner') {
      return `${base_url()}/api/admins/${getCurrentAdminId()}/profile-banner?context=admin-banner`;
    }
  };

  const uploadImage = async (file, type) => {
    try {
      if (!type) {
        setHasError(true);
        setErrorMessage('Error in uploading image');
        return;
      }
      setOpen(false);
      setLoading(true);
      const formData = new FormData();
      if (type === 'profile') {
        formData.append('profile', file, file.name);
      }

      if (type === 'banner') {
        formData.append('banner', file, file.name);
      }

      const url = getUrl(type);

      if (!url) {
        setHasError(true);
        setErrorMessage('Error in uploading image');
        return;
      }

      const response = await axios.patch(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setLoading(false);
      setIsSuccess(true);
      setSuccessMessage(`${capitalize(type)} image updates successfully`);
    } catch (error) {
      console.log(error);
    }
  };

  const onSelectFile = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const imageElement = new Image();
      const imageurl = reader.result?.toString() || '';
      imageElement.src = imageurl;
      imageElement.addEventListener('load', (event) => {
        const { naturalWidth, naturalHeight } = event.currentTarget;
        if (naturalWidth < MIN_Dimension || naturalHeight < MIN_Dimension) {
          setHasError(true);
          setErrorMessage('Image must be Greater than 150 pixel');
          return setimgsrc('');
        }
      });
      setimgsrc(imageurl);
      setOpen(true);
    });
    reader.readAsDataURL(file);
  };

  const updateDataHandler = async () => {
    setImagePreview();
    onsaveEdit(userData);
  };

  const handleClose = () => {
    updateDataHandler();
  };

  const backgroundUpdate = () => {
    setBackGroundUpdate(true);
  };

  const profileimageUrl = () => {
    if (imageUpdate) return imageUpdate;
    else if (userData?.pic) return userData?.pic;
    else
      return 'https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp';
  };

  const backgroundimageUrl = () => {
    if (backgroundImage) return backgroundImage;
    else if (userData?.banner) return userData?.banner;
    else
      return 'https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp';
  };

  const handleUserNameChange = (e) => {
    setUserData({ ...userData, username: e.target.value });
  };

  const handleDesignationChange = (e) => {
    setUserData({ ...userData, designation: e.target.value });
  };

  const openAddSocialMediaModalHandler = () => {
    setOpenAddSocialMediaModal(true);
  };

  const closeAddSocialMediaModalHandler = () => {
    setOpenAddSocialMediaModal(false);
  };

  const backgroundImageStyle = {
    type: 'background',
    height: '250px',
    width: '100%',
  };

  const profileImageStyle = {
    type: 'profile',
    height: '150px',
    width: '150px',
  };

  const pencilIconStyle = {
    height: '20px',
    width: '20px',
    color: 'white',
  };

  const Modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '550px',
    height: '490px',
    padding: '24px',
    bgcolor: 'background.paper',
    border: '3px dotted white',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
    overflow: 'hidden',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const EditProfileButtonStyle = {
    color: 'black',
    borderColor: 'black',
  };
  const SaveProfileButtonStyle = {
    width: '100px',
  };

  const formatRole = (role) => {
    const formattedRole = role.split('_').join(' ');
    return formattedRole.charAt(0).toUpperCase() + formattedRole.slice(1);
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <CustomBackdrop open={loading} />

      <ImagePreview
        imageUrl={previewImage}
        open={!!previewImage}
        onClose={closeImagePreview}
      />

      <CustomModal
        open={open}
        onClose={handleClose}
        ModalStyle={Modalstyle}
      >
        <ImageCropper
          onCropImage={onCropImage}
          onClose={handleClose}
          imgSource={imgsrc}
          uploadImage={uploadImage}
          backgrounChange={backGroundUpdate}
        />
      </CustomModal>

      <Box
        sx={{
          position: 'relative',
        }}
      >
        {requiredField && admin && (
          <Box
            sx={{
              backgroundColor: '#ffb300',
              width: '100%',
              height: '27px',
              zIndex: 1,
            }}
          >
            <ProfileEditingBanner onhandleEdit={onhandleEdit} />
          </Box>
        )}
        <Box
          sx={{
            position: 'absolute',
            top: '22%',
            right: '0%',
          }}
        >
          <input
            type='file'
            id='file-input-background'
            accept='image/*'
            onChange={(event) => {
              onSelectFile(event);
              backgroundUpdate();
            }}
            style={{ display: 'none' }}
          />
          <label htmlFor='file-input-background'>
            {/* edit icon  for background image  */}
            {edit && <PencilIcon style={pencilIconStyle} />}
          </label>
        </Box>
        <CustomImage
          onClick={() => handleImagePreview(backgroundimageUrl())}
          url={backgroundimageUrl()}
          detail={backgroundImageStyle}
        />
      </Box>

      <Box
        sx={{
          height: '200px',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            height: '200px',
            width: '50%',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '-80px',
              left: '15px',
              width: '500px',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '40%',
                left: '34%',
              }}
            >
              <input
                type='file'
                id='file-input'
                accept='image/*'
                onChange={onSelectFile}
                style={{ display: 'none' }}
              />
              <label htmlFor='file-input'>
                {/* edit icon for profile image  */}
                {edit && <PencilIcon style={pencilIconStyle} />}
              </label>
            </Box>

            <CustomImage
              onClick={() => handleImagePreview(profileimageUrl())}
              url={profileimageUrl()}
              detail={profileImageStyle}
            />

            <Text
              text={otherDetails?.fullname}
              textStyle1={{
                fontWeight: 'bold',
                fontSize: '20px',
                marginBottom: '7px',
                marginLeft: '10px',
              }}
            />

            {edit ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '10px',
                }}
              >
                <CustomTextField
                  changeHandler={handleUserNameChange}
                  value={userData?.username}
                  customAttributes={{
                    type: 'text',
                    id: 'username-input',
                    label: 'Username',
                    placeholder: 'Enter your username',
                    variant: 'standard',
                  }}
                  customStyle={{
                    marginTop: '10px',
                    marginBottom: '7px',
                    color: 'blue',
                    width: '50%',
                  }}
                />

                <CustomTextField
                  changeHandler={handleDesignationChange}
                  value={userData?.designation}
                  customAttributes={{
                    type: 'text',
                    id: 'designation',
                    label: 'Designation',
                    placeholder: 'Enter your designation',
                    variant: 'standard',
                  }}
                  customStyle={{
                    marginTop: '10px',
                    color: 'blue',
                    width: '50%',
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  marginLeft: '10px',
                  marginTop: '20px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '8px',
                    marginTop: '8px',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    text={'Username:'}
                    textStyle1={{
                      fontWeight: '600',
                      fontSize: '16px',
                      marginRight: '10px',
                    }}
                  />
                  <Text
                    text={userData?.username}
                    textStyle1={{
                      fontWeight: '400',
                      fontSize: '14px',
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '8px',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    text={'Designation:'}
                    textStyle1={{
                      fontWeight: '600',
                      fontSize: '16px',
                      marginRight: '10px',
                    }}
                  />
                  <Text
                    text={userData?.designation || 'None'}
                    textStyle1={{
                      fontWeight: '400',
                      fontSize: '14px',
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '8px',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    text={'Role:'}
                    textStyle1={{
                      fontWeight: '600',
                      fontSize: '16px',
                      marginRight: '10px',
                    }}
                  />
                  <Text
                    text={
                      userData?.currentRoles?.length > 0
                        ? userData?.currentRoles
                            ?.map((role) => formatRole(role))
                            .join(', ')
                        : 'None'
                    }
                    textStyle1={{
                      fontWeight: '400',
                      fontSize: '14px',
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            height: '200px',
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          {admin && (
            <Box
              sx={{
                marginRight: '30px',
                marginTop: '20px',
              }}
            >
              {edit ? (
                <CustomButton
                  variant={'contained'}
                  style={SaveProfileButtonStyle}
                  text={'Save'}
                  clickHandler={updateDataHandler}
                />
              ) : (
                <CustomButton
                  variant={'outlined'}
                  style={EditProfileButtonStyle}
                  text={'Edit profile detail'}
                  clickHandler={openEditBannerHandler}
                />
              )}
            </Box>
          )}

          <Box
            sx={{
              marginRight: '30px',
              marginBottom: '30px',
              marginTop: !edit ? '3rem ' : '0',
            }}
          >
            <SocialMediaIcon
              linksToEmbed={linksToEmbed}
              openAddSocialMediaModal={openAddSocialMediaModal}
              openAddSocialMediaModalHandler={openAddSocialMediaModalHandler}
              closeAddSocialMediaModalHandler={closeAddSocialMediaModalHandler}
              edit={edit}
            />
            <AddSocialMediaLink
              updateSocialMediaLinkData={updateSocialMediaLinkData}
              personalData={personalData}
              openAddSocialMediaModal={openAddSocialMediaModal}
              openAddSocialMediaModalHandler={openAddSocialMediaModalHandler}
              closeAddSocialMediaModalHandler={closeAddSocialMediaModalHandler}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ProfileImageAndSocialMedia;
