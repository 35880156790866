import React from 'react';
import Text from '../Atoms/Text';
import { Box } from '@mui/material';
import { formatNumberWithCommas } from '../../../utils/common';

const Analytics = ({
  totalcaps,
  maxCapDrive,
  totalincentive,
  highestIncentive,
  totalTAP,
  edit,
}) => {
  return (
    <>
      <Box sx={{ marginTop: edit ? '70px' : '30px' }}>
        <Text
          textStyle1={{
            fontWeight: 'bold',
            fontSize: '16px',
            margin: '-20px 0px 7px 25px',
            color: '#2196f3',
          }}
          text={'ANALYTICS'}
        />
        <Box
          sx={{
            marginLeft: '25px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ marginRight: '20px' }}>
            <Text
              textStyle1={{
                fontWeight: 'bold',
                fontSize: '20px',
                marginBottom: '10px',
              }}
              text={maxCapDrive ? maxCapDrive : '0'}
            />
            <Text
              textStyle1={{
                fontWeight: 'normal',
                fontSize: '15px',
              }}
              text={'Highest CAPs '}
              secondText={'in a drive'}
            />
          </Box>
          <Box sx={{ marginRight: '20px' }}>
            <Text
              textStyle1={{
                fontWeight: 'bold',
                fontSize: '20px',
                marginBottom: '10px',
              }}
              text={totalcaps ? totalcaps : '0'}
            />
            <Text
              textStyle1={{
                fontWeight: 'normal',
                fontSize: '15px',
              }}
              text={'Total CAPs'}
              secondText={'recieved till now'}
            />
          </Box>
          <Box sx={{ marginRight: '20px' }}>
            <Text
              textStyle1={{
                fontWeight: 'bold',
                fontSize: '20px',
                marginBottom: '10px',
              }}
              text={totalTAP ? totalTAP : '0'}
            />
            <Text
              textStyle1={{
                fontWeight: 'normal',
                fontSize: '15px',
              }}
              text={'Total TAPs'}
              secondText={'recieved till now'}
            />
          </Box>
          <Box sx={{ marginRight: '20px' }}>
            <Text
              textStyle1={{
                fontWeight: 'bold',
                fontSize: '20px',
                marginBottom: '10px',
              }}
              text={
                highestIncentive ? formatNumberWithCommas(highestIncentive) : 0
              }
            />
            <Text
              textStyle1={{
                fontWeight: 'normal',
                fontSize: '15px',
              }}
              text={'Highest revenue '}
              secondText={'generated in a drive'}
            />
          </Box>
          <Box sx={{ marginRight: '20px' }}>
            <Text
              textStyle1={{
                fontWeight: 'bold',
                fontSize: '20px',
                marginBottom: '10px',
              }}
              text={totalincentive ? formatNumberWithCommas(totalincentive) : 0}
            />
            <Text
              textStyle1={{
                fontWeight: 'normal',
                fontSize: '15px',
              }}
              text={'Highest revenue'}
              secondText={'generated till now'}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Analytics;
