import PaymentFinanceTable from './PaymentFinanceTable';
import PaymentFinanceFilter from './PaymentFinanceFilter';
import React, { useState } from 'react';
const PaymentFinanceDashboard = () => {
  const [filters, setFilters] = useState({});

  return (
    <>
      <PaymentFinanceFilter
        filters={filters}
        setFilters={setFilters}
      />
      <PaymentFinanceTable
        filters={filters}
        setFilters={setFilters}
      />
    </>
  );
};
export default PaymentFinanceDashboard;
