import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

export function ConfirmReportDeletionModal({
  openDeleteReportConfirmation,
  setOpenDeleteReportConfirmation,
  setReportDeletionConfirmed,
}) {
  return (
    <Dialog
      open={openDeleteReportConfirmation}
      onClose={() => setOpenDeleteReportConfirmation(false)}
    >
      <DialogContent>
        <DialogContentText>
          Report once deleted, cannot be retrieved again. Are you sure you want
          to delete this report?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => setOpenDeleteReportConfirmation(false)}>
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            setOpenDeleteReportConfirmation(false);
            setReportDeletionConfirmed(true);
          }}
          autoFocus
        >
          Yes, I'm sure
        </Button>
      </DialogActions>
    </Dialog>
  );
}
