import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import React from 'react';
import constants from '../../../utils/constants';
import { splitByUnderscoresAndCapitalize } from '../../../utils/common';

const {
  EXPECTED_UNIQUE_LEADS,
  EXPECTED_CPE,
  EXPECTED_AAP,
  EXPECTED_OLS,
  EXPECTED_TAP,
  EXPECTED_CAP,
} = constants;
const ProductivityInsightDetailedAnalysisTable = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <div
        style={{
          fontSize: '15px',
          margin: '10px',
        }}
      >
        No data available
      </div>
    );
  }

  const expectedValues = {
    unique_leads: EXPECTED_UNIQUE_LEADS,
    complete_program_explained: EXPECTED_CPE,
    eligibility_form_filled: EXPECTED_AAP,
    offer_letter_sent: EXPECTED_OLS,
    token_amount_paid: EXPECTED_TAP,
    complete_amount_paid: EXPECTED_CAP,
  };

  const metrics = Object.keys(data[0]);

  const metricRows = metrics.filter((metric) => metric !== 'date');

  //Comparing current and expected values and returning colored circle.
  const getColorCircleDetailAnalysisTable = (value, expectedValue) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {value}
      </div>
    );
  };

  return (
    <>
      <div style={{ width: '1350px', overflowX: 'auto' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Metric</TableCell>
                {data.map((item) => (
                  <TableCell key={item.date}>{item.date}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {metricRows.map((metric) => (
                <TableRow key={metric}>
                  <TableCell>
                    {splitByUnderscoresAndCapitalize(metric)}
                  </TableCell>
                  {data.map((item) => (
                    <TableCell
                      key={item.date}
                      sx={{
                        backgroundColor:
                          item[metric] >= expectedValues[metric]
                            ? '#dcedc8'
                            : '#ffcdd2',
                      }}
                    >
                      {getColorCircleDetailAnalysisTable(
                        item[metric],
                        expectedValues[metric]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default ProductivityInsightDetailedAnalysisTable;
