import {
  TextField,
  Button,
  Select,
  MenuItem,
  Typography,
  Box,
} from '@mui/material';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import {
  capitalize,
  currentAdminIsAdmin,
  currentAdminIsHr,
} from '../../utils/common';

export default function CampaignPayment({
  islive,
  islocked,
  isApproved,
  isPaid,
  campaignDetails,
  createCampaignDetails,
  lockConfirmationOpener,
  updateStatusApiCall,
  onCloseCampaignModal,
  getAmountToPaidValue,
  textFieldStyle,
}) {
  const textFieldStyleForShort = {
    width: '60%',
    '& .MuiInputBase-root': {
      height: '2.5rem',
    },
    '& .MuiInputBase-input': {
      height: '100%',
      padding: '0 14px',
    },
  };
  const verifiedComponent = (text) => {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        style={{ margin: 'auto', width: '50%', marginBottom: '3%' }}
      >
        <VerifiedRoundedIcon
          sx={{ color: 'green', fontSize: '2rem', marginLeft: '14%' }}
        />
        <Typography
          variant='h6'
          style={{ marginLeft: 8, color: 'green' }}
        >
          {text}
        </Typography>
      </Box>
    );
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '1%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '48%',
          }}
        >
          <Typography style={{ width: '40%', marginTop: '1%' }}>
            Spend Type:
          </Typography>
          <Select
            disabled={
              islocked(campaignDetails) ||
              isApproved(campaignDetails) ||
              isPaid(campaignDetails)
            }
            name='spent_type'
            value={campaignDetails.spent_type}
            onChange={createCampaignDetails}
            style={{ width: '60%', height: '70%' }}
          >
            <MenuItem value='fixed'>Fixed Cost</MenuItem>
            <MenuItem value='affiliate'>Affiliate cost</MenuItem>
          </Select>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '48%',
          }}
        >
          <Typography style={{ width: '40%', marginTop: '1%' }}>
            No. of leads accounted:
          </Typography>
          <TextField
            disabled={
              islocked(campaignDetails) ||
              isApproved(campaignDetails) ||
              isPaid(campaignDetails)
            }
            name='leads_accounted'
            type='number'
            value={campaignDetails.leads_accounted}
            onChange={createCampaignDetails}
            id='leads_accounted'
            fullWidth
            sx={textFieldStyle}
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '3%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '48%',
          }}
        >
          <Typography style={{ width: '40%', marginTop: '1%' }}>
            Campaign Cost:
          </Typography>
          <TextField
            disabled={
              islocked(campaignDetails) ||
              isApproved(campaignDetails) ||
              isPaid(campaignDetails)
            }
            name='cost'
            onChange={createCampaignDetails}
            type='number'
            id='cost'
            value={campaignDetails.cost}
            fullWidth
            placeholder='Rupees '
            sx={textFieldStyleForShort}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '48%',
          }}
        >
          {currentAdminIsHr() && isApproved(campaignDetails) && (
            <Typography style={{ width: '40%', marginTop: '1%' }}>
              UTR:
            </Typography>
          )}
          {currentAdminIsHr() && isApproved(campaignDetails) && (
            <TextField
              name='utr'
              onChange={createCampaignDetails}
              type='text'
              id='utr'
              value={campaignDetails.utr}
              fullWidth
              placeholder='Utr'
              sx={textFieldStyleForShort}
            />
          )}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          width: '30%',
          border: '1px solid #C4C4C4',
          marginLeft: '40%',
          borderRadius: '10px',
          marginBottom: '3%',
        }}
      >
        <Typography
          style={{ fontSize: '1rem', marginTop: '2%', marginLeft: '4%' }}
        >
          Amount To Be Paid:
        </Typography>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
          }}
        >
          <Typography
            style={{
              fontSize: '1.5rem',
              marginLeft: '4%',
              marginTop: '1%',
              color: 'blue',
              fontWeight: 'bolder',
            }}
          >
            RS
          </Typography>
          <TextField
            disabled={islocked(campaignDetails)}
            name='amount_to_paid'
            type='text'
            value={getAmountToPaidValue()}
            id='amount_paid'
            InputProps={{
              readOnly: true,
              style: {
                fontSize: '1.5rem',
                color: 'blue',
                fontWeight: 'bolder',
              },
            }}
            sx={{
              width: '90%',
              '& .MuiInputBase-root': {
                height: '40px',
              },
              '& .MuiInputBase-input': {
                height: '20px',
                padding: '0 14px',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { border: 'none' },
              },
            }}
          />
        </div>
      </div>

      {islive(campaignDetails) && (
        <Button
          variant='contained'
          onClick={lockConfirmationOpener}
          color='error'
          size='medium'
          sx={{ width: '250px', alignItems: 'center', marginLeft: '40%' }}
        >
          <HttpsOutlinedIcon /> Lock Campaign
        </Button>
      )}
      {isApproved(campaignDetails) && verifiedComponent('Approved !')}
      {isPaid(campaignDetails) && verifiedComponent('Payment Cleared !')}

      {currentAdminIsAdmin() && islocked(campaignDetails) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '3%',
            width: '50%',
            marginLeft: '30%',
          }}
        >
          <Button
            variant='contained'
            onClick={() => updateStatusApiCall('rejected')}
            color='error'
            size='medium'
            sx={{ width: '40%', alignItems: 'center' }}
          >
            Reject
          </Button>

          <Button
            variant='contained'
            onClick={() => updateStatusApiCall('approved')}
            color='success'
            size='medium'
            sx={{
              width: '40%',
              alignItems: 'center',
            }}
          >
            Approve
          </Button>
        </div>
      )}
      {currentAdminIsHr() && isApproved(campaignDetails) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '3%',
            width: '50%',
            marginLeft: '30%',
          }}
        >
          <Button
            variant='contained'
            onClick={() => onCloseCampaignModal()}
            color='inherit'
            size='medium'
            sx={{ width: '48%', alignItems: 'center' }}
          >
            Cancel
          </Button>

          <Button
            variant='contained'
            onClick={() => updateStatusApiCall('paid')}
            color='success'
            size='medium'
            sx={{
              width: '48%',
              alignItems: 'center',
            }}
          >
            Payment Cleared
          </Button>
        </div>
      )}
    </>
  );
}
