import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getTimeDifference } from '../../utils/getTimeDifference';

const PastInteraction = ({
  InteractionRef,
  currentLeadData,
  currentLeadInteractions,
  widthPercentage,
}) => {
  const { fname, lname } = currentLeadData;
  const [isYourPastInteraction, setIsYourPastInteracton] = useState(false);
  const [yourLastInteraction, setYourLastInteraction] = useState({});
  const fullname = fname + (lname !== null ? ` ${lname}` : '');
  const currAdmin = JSON.parse(localStorage.getItem('admin')) || {};

  useEffect(() => {
    checkAndUpdatePastInteraction();
  }, [currentLeadInteractions]);

  const checkAndUpdatePastInteraction = () => {
    const reversedInteractions = [...currentLeadInteractions].reverse();

    const interactionByCurrAdmin = reversedInteractions.some(
      (interaction) => interaction.updated_by === currAdmin.id
    );

    if (interactionByCurrAdmin) {
      setIsYourPastInteracton(true);

      const lastInteraction = reversedInteractions.find(
        (interaction) => interaction.updated_by === currAdmin.id
      );

      setYourLastInteraction(lastInteraction);
    } else {
      setIsYourPastInteracton(false);
      setYourLastInteraction({});
    }
  };

  return (
    <>
      {isYourPastInteraction && (
        <Box
          ref={InteractionRef}
          className='InteratonRef'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'opacity 0.3s ease-in',
            opacity: widthPercentage >= 50 && widthPercentage <= 75 ? 1 : 0,
          }}
        >
          <Typography
            sx={{
              zIndex: 6,
              color: '#FFF',
              fontSize: '50px',
              textAlign: 'center',
              fontFamily: "'Montserrat', 'sans-serif'",
              fontWeight: 700,
            }}
          >
            Past Interactions
          </Typography>

          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: '37px',
              fontWeight: 700,
              fontFamily: "'Montserrat', 'sans-serif'",
              zIndex: 6,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
              transition: 'opacity 0.6s ease-in-out',
            }}
          >
            Your last interaction
          </Typography>
          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: '37px',
              fontWeight: 700,
              fontFamily: "'Montserrat', 'sans-serif'",
              zIndex: 6,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
              transition: 'opacity 0.6s ease-in-out',
            }}
          >
            with {fullname} was
          </Typography>
          <Typography
            sx={{
              color: '#FFF',
              fontFamily: "'Montserrat', 'sans-serif'",
              fontSize: '45px',
              textAlign: 'center',
              fontWeight: 700,
              zIndex: 6,
            }}
          >
            {getTimeDifference(yourLastInteraction.updated_at)} ago.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default PastInteraction;
