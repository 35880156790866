import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { base_url } from '../../../components/Mode';
import { Stack, Box, TextField, Typography, Tooltip } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import AdminsAutocompleteDropdown from '../../../components/admins/AdminAutoComplete';
import {
  getDaysInMonth,
  currentAdminIsManager,
  currentAdminIsSeniorManager,
  splitByUnderscoresAndCapitalize,
  getDatabaseFormattedDate,
} from '../../../utils/common';
import constants from '../../../utils/constants';
import CustomBackdrop from '../../CustomBackdrop';
import { getWorkLogProps } from './WorkLogCalendarView';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';

const TeamWorkLog = () => {
  const initialState = {
    admin_id: null,
    date: null,
  };
  const [filters, setFilters] = useState(initialState);
  const [teamData, setTeamData] = useState({});
  const [isFetchingResults, setIsFetchingResults] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [holidays, setHolidays] = useState([]);
  const [weekDays, setWeekDays] = useState([
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
  ]);

  const getHolidays = async () => {
    try {
      const holidayApiUrl = `${base_url()}/api/holidays`;
      const response = await axios.get(holidayApiUrl);
      setHolidays(response?.data);
    } catch (error) {
      console.log('Error fetching holidays:', error);
      setHasError(true);
      setErrorMessage(error.message || `'Error fetching holidays:', ${error}`);
    }
  };

  useEffect(() => {
    getHolidays();
    if (filters.admin_id && filters.date) {
      fetchTeamReport();
    }
    return () => {};
  }, [filters]);

  const fetchTeamReport = () => {
    setIsFetchingResults(true);
    const { date, admin_id } = filters;
    const [year, month] = date.split('-'); // "2023-03"

    const queryParams = [
      `month=${Number(month)}`,
      `year=${year}`,
      `admin_id=${admin_id}`,
      `team=1`,
    ];

    const url = `${base_url()}/api/reports/work-log?${queryParams.join('&')}`;
    axios
      .get(url)
      .then((res) => {
        if (res['data']) {
          setTeamData(res['data']);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch the results."
        );
      })
      .finally(() => {
        setIsFetchingResults(false);
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isFetchingResults && <CustomBackdrop open={isFetchingResults} />}

      <Stack
        direction='row'
        spacing={2}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: '5%',
        }}
      >
        <AdminsAutocompleteDropdown
          showCurrentAdmin={
            currentAdminIsManager() || currentAdminIsSeniorManager()
          }
          adminOptions={{
            access_type: ['allowed', 'restricted'],
            roles: [constants.MANAGER, constants.SENIOR_MANAGER],
          }}
          label='Select a manager'
          value={filters.admin_id}
          onChangeHandler={(e, newValue) => {
            setFilters({ ...filters, admin_id: newValue.value });
          }}
          sx={{
            width: 182,
          }}
          size='small'
        />
        <TextField
          value={filters.date}
          type='month'
          label='Month and Year'
          size={'small'}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setFilters({ ...filters, date: e.target.value })}
        />
      </Stack>

      <Stack
        direction='column'
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        {Object.keys(teamData).length > 0 && (
          <div
            style={{
              marginLeft: '180px',
              display: 'flex',
              minWidth: '180px',
            }}
          >
            {Array(getDaysInMonth(...filters.date.split('-')))
              .fill(0)
              .map((item, index) => {
                const [year, month] = filters.date.split('-'); // "2023-03"
                const firstDay = new Date(
                  `${year}-${month}-01 00:00:00+0530`
                ).getDay(); // gives first day of month
                index += firstDay;
                return (
                  <Box
                    sx={{
                      width: 44,
                      height: 44,
                      pt: -2,
                      border: '0.01px solid #CBD9D9',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {weekDays[index % 7]}
                  </Box>
                );
              })}
          </div>
        )}
        {Object.keys(teamData).map((admin) => {
          const [year, month] = filters.date.split('-'); // "2023-03"
          const monthlyData = teamData[admin];

          return (
            <Stack
              direction='row'
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Typography
                variant='body1'
                component='p'
                minWidth='180px'
                maxWidth='180px'
                sx={{
                  border: '0.4px solid #CBD9D9',
                  height: 44,
                  pl: '0.8vh',
                  pt: '0.8vh',
                }}
              >
                {admin}
              </Typography>

              {Array(getDaysInMonth(...filters.date.split('-')))
                .fill(0)
                .map((item, index) => {
                  const currentDateNumber = index + 1;
                  const currentDate = new Date(
                    `${year}-${month}-${currentDateNumber} 00:00:00+0530`
                  );
                  let tileProps = {
                    color: '#E59CBD',
                    label: null,
                    borderBottom: 'default',
                  };
                  const formatedEachDate =
                    getDatabaseFormattedDate(currentDate);
                  holidays.forEach((holiday) => {
                    if (holiday.date === formatedEachDate) {
                      tileProps['color'] = '#B3C3C5';
                    }
                  });

                  let title = '';
                  if (currentDateNumber in monthlyData) {
                    const { leave_notes = null, leave_type = null } =
                      monthlyData[currentDateNumber];
                    if (leave_type) {
                      title = (
                        <div>
                          {splitByUnderscoresAndCapitalize(leave_type)} <br />{' '}
                          {leave_notes ?? ''}
                        </div>
                      );
                    }
                    tileProps = getWorkLogProps(monthlyData[currentDateNumber]);
                  }

                  return (
                    <Tooltip
                      arrow
                      title={title}
                      placement='top'
                      TransitionComponent={Zoom}
                    >
                      <Box
                        sx={{
                          width: 44,
                          height: 44,
                          border: '0.01px solid #CBD9D9',
                          borderBottom: `5px solid ${tileProps.borderBottom}`,
                          pt: -2,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor:
                            currentDate < new Date()
                              ? tileProps.color
                              : 'white',
                          '&:hover': {
                            cursor: tileProps.label ? 'pointer' : 'default',
                            opacity: [0.9, 0.8, 0.7],
                          },
                        }}
                      >
                        {currentDateNumber}
                      </Box>
                    </Tooltip>
                  );
                })}
            </Stack>
          );
        })}

        {Object.keys(teamData).length === 0 && (
          <Typography
            variant='body1'
            component='p'
            textAlign='center'
          >
            No data found.
          </Typography>
        )}
      </Stack>
    </>
  );
};

export default TeamWorkLog;
