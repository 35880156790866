import React from 'react';
import './Capcount.css';

const Capcount = ({ capCount }) => {
  return (
    <>
      <span className='wrapper protest-riot-regular'>
        <span>Total CAPs in this Drive:</span>
      </span>
      <span className='cap-count-bounce'>{capCount}</span>
    </>
  );
};

export default Capcount;
