import moment from 'moment';
import { getStatusLabel } from '../../../utils/common';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { base_url } from '../../Mode';
import { useEffect, useState } from 'react';
import CustomLoader from '../../../pages/customLoader';

const TenMinConnectTable = ({ triggerRefreshFor10MinConnect }) => {
  const [quickLeadData, setQuickLeadData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllQuickLead();
  }, [triggerRefreshFor10MinConnect]);

  const getAllQuickLead = () => {
    setLoading(true);
    axios(`${base_url()}/api/lead_claims`)
      .then((res) => {
        setQuickLeadData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const rows = quickLeadData?.map((e) => {
    return {
      ...e,
      lead_id: e.lead_id ? e.lead_id : '-',
      sent_by_admin:
        e.sent_by_admin.fname || e.sent_by_admin.lname
          ? `${e.sent_by_admin.fname} ${e.sent_by_admin.lname}`
          : '-',
      claimed_at: e.claimed_at
        ? moment(e.claimed_at).format('MMM DD, YYYY hh:mm A')
        : '-',
      claimed_by_admin:
        e.claimed_by_admin &&
        (e.claimed_by_admin.fname || e.claimed_by_admin.lname)
          ? `${e.claimed_by_admin.fname} ${e.claimed_by_admin.lname}`
          : '-',
      status: e.lead.status ? e.lead.status : '-',
      lead_claim_status:
        e.lead_claim_attempts[0] && e.lead_claim_attempts[0].status
          ? e.lead_claim_attempts[0].status
          : 'Pending',
    };
  });

  const getLeadClaimStatusLable = (value) => {
    if (value == 'accepted') {
      return (
        <div
          style={{
            backgroundColor: 'green',
            color: '#fff',
            margin: 'auto',
            padding: '2px 10px 2px 10px',
            borderRadius: '20px',
          }}
        >
          Accepted
        </div>
      );
    }
    return (
      <div
        style={{
          backgroundColor: '#e8e8e8',
          margin: 'auto',
          padding: '2px 10px 2px 10px',
          borderRadius: '20px',
        }}
      >
        {value}
      </div>
    );
  };

  const columns = [
    {
      field: 'lead_id',
      headerName: 'Lead Id',
      width: 80,
      renderCell: (params) => {
        return <div style={{ margin: 'auto' }}>#{params.value}</div>;
      },
      headerAlign: 'center',
    },
    {
      field: 'sent_by_admin',
      headerName: 'Assigned By',
      width: 230,
      renderCell: (params) => (
        <div style={{ margin: 'auto' }}>{params.value}</div>
      ),
      headerAlign: 'center',
    },
    {
      field: 'claimed_by_admin',
      headerName: 'Claimed By',
      width: 230,
      renderCell: (params) => (
        <div style={{ margin: 'auto' }}>{params.value}</div>
      ),
      headerAlign: 'center',
    },
    {
      field: 'lead_claim_status',
      headerName: 'Lead Claim Status',
      width: 180,
      renderCell: (params) => getLeadClaimStatusLable(params.value),
      headerAlign: 'center',
    },
    {
      field: 'claimed_at',
      headerName: 'Claimed At',
      width: 200,
      renderCell: (params) => (
        <div style={{ margin: 'auto' }}>{params.value}</div>
      ),
      headerAlign: 'center',
    },
    {
      field: 'status',
      headerName: 'Current Lead Status',
      width: 220,
      renderCell: (params) => (
        <div style={{ margin: 'auto' }}>{getStatusLabel(params.value)}</div>
      ),
      headerAlign: 'center',
    },
  ];

  if (loading) {
    return <CustomLoader height={'50vh'} />;
  }

  return (
    <>
      {rows && columns && (
        <div style={{ height: 600, width: '100%', marginTop: '20px' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            alignItems='center'
          />
        </div>
      )}
    </>
  );
};
export default TenMinConnectTable;
