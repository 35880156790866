import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { base_url } from '../../../../Mode';
import AudioPlayer from '../../AudioPlayer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid gray',
  boxShadow: 24,
  p: 4,
};

const ResultModal = ({
  isOpenResultModal,
  setIsOpenResultModal,
  selectedRequest,
}) => {
  const handleClose = () => setIsOpenResultModal(false);
  const [mockcallResult, setMockcallResult] = useState();

  useEffect(() => {
    if (selectedRequest) {
      handleSelectedRequestDetails();
    }
  }, []);
  const handleSelectedRequestDetails = async () => {
    const url =
      base_url() + `/api/training-mockcalls/submissions/${selectedRequest?.id}`;
    axios
      .get(url)
      .then((res) => {
        setMockcallResult(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Modal
        open={isOpenResultModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Box display='flex'>
            <Box>
              {mockcallResult?.status === 'accepted' ? (
                <img
                  src={'/images/Approved.png'}
                  width='90px'
                />
              ) : mockcallResult?.status === 'rejected' ? (
                <img
                  src={'/images/rejected.png'}
                  width='90px'
                />
              ) : (
                <img
                  src={'/images/pending.jpg'}
                  width='90px'
                />
              )}
            </Box>
            <Box
              pt={4}
              pl={2}
            >
              <Typography fontWeight='700'>
                {mockcallResult?.status === 'accepted'
                  ? 'Your mockcall has been approved.'
                  : mockcallResult?.status === 'rejected'
                  ? 'Your mockcall has been rejected.'
                  : 'The result of your mockcall is pending.'}
              </Typography>
            </Box>
          </Box>
          <Box mt={1}>
            <Typography fontWeight='700'>Mockcall recording</Typography>
            {mockcallResult?.file_path ? (
              <Box
                mt={1}
                mb={1}
                sx={{
                  border: '1px solid gray',
                  borderRadius: '8px',
                  width: '21vw',
                  display: 'flex',
                }}
              >
                <AudioPlayer audioSrc={mockcallResult?.file_path} />
              </Box>
            ) : (
              <Box>
                <Typography style={{ color: 'red' }}>
                  The recording is not uploaded yet!
                </Typography>
              </Box>
            )}
          </Box>
          <Box mt={1}>
            <Typography fontWeight='700'>Interviewer's Feedback</Typography>
            <Box>
              {mockcallResult?.feedback_by_interviewer ? (
                <Typography>
                  {mockcallResult?.feedback_by_interviewer}
                </Typography>
              ) : (
                <Typography style={{ color: 'red' }}>
                  You haven't posted a feedback.{' '}
                </Typography>
              )}
            </Box>
          </Box>
          <Box mt={1}>
            <Typography fontWeight='700'>Trainer's Feedback</Typography>
            <Box>
              {mockcallResult?.feedback_by_trainer ? (
                <Typography>{mockcallResult?.feedback_by_trainer}</Typography>
              ) : (
                <Typography style={{ color: 'red' }}>
                  The trainer has not uploaded a feedback yet!
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ResultModal;
