import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';

const QuestionStatus = ({
  index,
  questionId,
  questionAttempts,
  selectedQuestion,
  questionUrl,
  expectedAnswers,
  handleQuestionClick,
  attempts,
}) => {
  function getQuestionStatus(moduleQuestionId) {
    const attemptsForQuestion = questionAttempts.filter(
      (item) => item.id === moduleQuestionId
    );

    if (attemptsForQuestion.length === 0) {
      return 'pending';
    }

    const hasCorrectAttempt = attemptsForQuestion.some(
      (attempt) => attempt.result === 'correct'
    );

    return hasCorrectAttempt ? 'correct' : 'incorrect';
  }

  return (
    <Box
      style={{
        paddingLeft: '15px',
        paddingTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
      }}
      key={index}
      onClick={() => {
        handleQuestionClick(
          questionId,
          index + 1,
          questionUrl,
          expectedAnswers
        );
      }}
    >
      <Box style={{ display: 'flex' }}>
        <Box
          style={{ marginTop: '2px' }}
          mr={1}
        >
          <CheckIcon
            style={{
              color:
                getQuestionStatus(questionId) === 'correct' ? 'green' : 'gray',
              fontSize: '18px',
            }}
          />
        </Box>
        <Box>
          <Typography
            style={{
              fontWeight: 500,
              cursor: 'pointer',
              color: selectedQuestion == questionId ? '#009af0' : '',
            }}
          >
            Question {index + 1}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box
          key={index}
          style={{
            border:
              getQuestionStatus(questionId) === 'correct'
                ? '1px solid green'
                : getQuestionStatus(questionId) === 'incorrect'
                ? '1px solid red'
                : '1px solid #ffcc00',
            marginRight: '5px',
            marginBottom: '5px',
            minWidth: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '-10px',
            width: '80px',
            borderRadius: '4px',
            background:
              getQuestionStatus(questionId) === 'correct'
                ? 'lightgreen'
                : getQuestionStatus(questionId) === 'incorrect'
                ? '#ffb3b3'
                : '#ffff99',
          }}
        >
          <Typography style={{ fontSize: '14px' }}>
            {getQuestionStatus(questionId)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionStatus;
