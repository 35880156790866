import { Typography, Box } from '@mui/material';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
export const CongratulationComponent = () => {
  return (
    <Box
      sx={{
        boxShadow: 3,
        width: '30%',
        height: '30%',
        bgcolor: (theme) =>
          theme.palette.mode === 'dark' ? '#101010' : '#fff',
        color: (theme) =>
          theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
        p: 1,
        ml: '35%',
        mt: '15%',
        borderRadius: 2,
        textAlign: 'center',
        fontSize: '0.875rem',
        fontWeight: '700',
      }}
    >
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        style={{ margin: 'auto', width: '100%', marginBottom: '5%' }}
      >
        <VerifiedRoundedIcon sx={{ color: 'green', fontSize: '4rem' }} />
        <Typography
          variant='h6'
          style={{ marginLeft: 8, color: 'green' }}
        >
          Congratulations!
        </Typography>
        <Typography
          variant='h7'
          style={{ marginLeft: 8, color: 'black' }}
        >
          You have completed all modules.
        </Typography>
        <Typography
          variant='h7'
          style={{ marginLeft: 8, color: 'black' }}
        >
          Please contact your manager for next step.
        </Typography>
      </Box>
    </Box>
  );
};
