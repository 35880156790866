import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  CircularProgress,
  Typography,
  Container,
  Box,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { base_url } from '../Mode';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';

const statusStyles = {
  red_alert: {
    backgroundColor: '#ec2a2a',
    color: '#ec2a2a',
  },
  supervision: {
    backgroundColor: '#ffc075',
    color: '#ffc075',
  },
  green_zone: {
    backgroundColor: '#49c66c',
    color: '#49c66c',
  },
  default: {
    backgroundColor: 'gray',
    color: 'white',
  },
};

const commonStyles = {
  fontWeight: '600',
  border: '2px solid rgb(194 163 163 / 51%)',
};

const styles = {
  container: (status) => ({
    ...(statusStyles[status] || statusStyles.default),
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    alignItems: 'center',
    width: 'calc(294px - 20px)',
    padding: '10px',
    fontWeight: '600',
    cursor: 'pointer',
    color: '#fff',
  }),
  popoverContent: {
    padding: '16px',
    fontWeight: '600',
    fontFamily: 'Menlo, Consolas, monospace',
  },
  popoverHeader: {
    fontWeight: '600',
    marginBottom: '12px',
    textAlign: 'center',
  },
  zone: {
    marginBottom: '16px',
  },
  zoneHeader: (status) => ({
    fontWeight: '600',
    marginBottom: '4px',
    color: statusStyles[status]?.color || 'black',
  }),
  table: (status) => ({
    width: '300px',
    borderCollapse: 'collapse',
    marginBottom: '12px',
    borderRadius: '10px',
    overflow: 'hidden',
    border: '2px solid rgb(194 163 163 / 51%)',
  }),
  tableCell: {
    ...commonStyles,
    padding: '8px',
    textAlign: 'left',
    width: '150px',
  },
  firstRow: (status) => ({
    backgroundColor: statusStyles[status]?.backgroundColor || 'white',
  }),
  triangle: {
    position: 'absolute',
    top: '26px',
    left: '-18px',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '20px 20px 20px 0',
    borderColor: 'transparent #fff transparent transparent',
  },
};

const IcStatus = () => {
  const [adminData, setAdminData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchAdminData = async () => {
    try {
      const response = await axios.get(`${base_url()}/api/get_ic_status`);
      setAdminData(response?.data);
    } catch (err) {
      console.error('Error fetching admin data:', err);
      setErrorMessage(
        'An error occurred during showing your status and popup info box'
      );
      setHasError(true);
    }
  };

  useEffect(() => {
    fetchAdminData();
  }, []);

  useEffect(() => {
    if (adminData) {
      setStatus(
        adminData.status === 'red_alert'
          ? 'Red zone'
          : adminData.status === 'green_zone'
          ? null
          : 'Supervision'
      );
    }
  }, [adminData]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const supervisionSince = adminData?.statusApplicableFrom
    ? Math.ceil(
        (new Date() - new Date(adminData.statusApplicableFrom)) /
          (1000 * 60 * 60 * 24)
      )
    : null;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const formattedDate = moment(adminData?.statusApplicableFrom).format(
    'MMMM D, YYYY'
  );

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {!hasError && (
        <Container style={{ margin: 0, padding: 0 }}>
          {supervisionSince && adminData?.status !== 'green_zone' ? (
            <Box sx={styles.container(adminData?.status)}>
              <Typography variant='body1'>
                {status} since {supervisionSince} days
              </Typography>
              {adminData?.status !== 'green_zone' && (
                <Box onClick={handlePopoverOpen}>
                  <InfoOutlinedIcon
                    style={{ marginTop: '2px' }}
                    fontSize='small'
                  />
                </Box>
              )}
            </Box>
          ) : null}

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            style={{ top: '-60px', left: '50px' }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{
              style: {
                overflow: 'visible',
                fontFamily: 'Menlo, Consolas, monospace',
                fontWeight: '600',
              },
            }}
          >
            <Box sx={styles.triangle} />
            <Box sx={styles.popoverContent}>
              <Typography
                sx={styles.popoverHeader}
                style={{
                  color: statusStyles[adminData?.status]?.color || 'black',
                }}
              >
                {status} since {formattedDate}
              </Typography>
              {adminData?.status === 'supervision' && (
                <TableContainer
                  component={Paper}
                  style={{
                    border: '2px solid rgb(194 163 163 / 51%)',
                    boxShadow:
                      '0px 0px 8px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                  }}
                >
                  <Table
                    sx={{ minWidth: 300 }}
                    aria-label='supervision table'
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={3}
                          align='center'
                          style={commonStyles}
                        >
                          Supervision
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={commonStyles}>
                      <TableRow>
                        <TableCell
                          rowSpan={3}
                          align='center'
                          style={{
                            ...commonStyles,
                            borderRight: '2px solid rgb(194 163 163 / 51%)',
                          }}
                        >
                          Current Drive
                        </TableCell>
                        <TableCell
                          align='center'
                          style={commonStyles}
                        >
                          &ge;4 CAPs
                        </TableCell>
                        <TableCell
                          align='center'
                          style={{
                            ...commonStyles,
                            backgroundColor: '#49c66c',
                          }}
                        >
                          Green Zone
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align='center'
                          style={commonStyles}
                        >
                          3 CAPs
                        </TableCell>
                        <TableCell
                          align='center'
                          style={{
                            ...commonStyles,
                            backgroundColor: '#ffc075',
                          }}
                        >
                          Will remain in Supervision
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align='center'
                          style={commonStyles}
                        >
                          Less than 3 CAPs
                        </TableCell>
                        <TableCell
                          align='center'
                          style={{
                            ...commonStyles,
                            backgroundColor: '#ec2a2a',
                          }}
                        >
                          Will go in Red Alert
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {adminData?.status === 'red_alert' && (
                <TableContainer
                  component={Paper}
                  style={{
                    boxShadow:
                      '0px 0px 8px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                  }}
                >
                  <Table
                    sx={{ minWidth: 300, ...commonStyles }}
                    aria-label='red zone table'
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={3}
                          align='center'
                          style={commonStyles}
                        >
                          Red Zone
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          rowSpan={2}
                          align='center'
                          style={{
                            ...commonStyles,
                            borderRight: '2px solid rgb(194 163 163 / 51%)',
                          }}
                        >
                          Current Drive
                        </TableCell>
                        <TableCell
                          style={commonStyles}
                          align='center'
                        >
                          2 CAPs in 2 Weeks
                        </TableCell>
                        <TableCell
                          align='center'
                          style={{
                            ...commonStyles,
                            backgroundColor: '#49c66c',
                          }}
                        >
                          Green Zone
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align='center'
                          style={commonStyles}
                        >
                          &lt; 2 CAPs in 2 Weeks
                        </TableCell>
                        <TableCell
                          align='center'
                          style={{
                            ...commonStyles,
                            backgroundColor: '#ec2a2a',
                          }}
                        >
                          Termination
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </Popover>
        </Container>
      )}
    </>
  );
};

export default IcStatus;
