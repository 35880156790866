import React from 'react';

const CustomLoader = ({ height }) => {
  return (
    <div
      className='wrapper'
      style={{
        height: `${height}vh`,
      }}
    >
      <div className='loader'></div>
    </div>
  );
};

export default CustomLoader;
