import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Link } from 'react-router-dom';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LeadFunnelModal from '../../components/lead-funnel/LeadFunnelModal';

const LeadsSummaryTable = ({ summaryData }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openLeadFunnel, setOpenLeadFunnel] = useState(false);
  const { student, graduate, working_professional, total } = summaryData;
  const [copyIds, setCopyIds] = useState([]);

  const toggleLeadFunnelModal = () => {
    setOpenLeadFunnel((p) => !p);
  };

  const calculateEffective = (count, weight) => {
    const effectiveCount = Math.floor(count / weight);
    return effectiveCount;
  };

  const calculateEffectiveLeadid = (count, weight, ...leadIdArrays) => {
    const myArray = Object.values(leadIdArrays);
    const effectiveCount = Math.floor(count / weight);
    const qualifiedEffectiveLeadIds = [];

    for (const leadIds of myArray) {
      const leadCount = Math.floor(weight * effectiveCount);
      qualifiedEffectiveLeadIds.push(...leadIds.slice(0, leadCount));
    }

    return {
      qualifiedEffectiveLeadIds,
    };
  };

  const studentEffectiveQualifiedLeadID = calculateEffectiveLeadid(
    summaryData.student.qualifiedLeadsCount,
    5,
    summaryData.student.qualifiedLeadsIds.split(',').map(Number)
  );
  const GraduateEffectiveQualifiedLeadID = calculateEffectiveLeadid(
    summaryData.graduate.qualifiedLeadsCount,
    3,
    summaryData.graduate.qualifiedLeadsIds.split(',').map(Number)
  );
  const WorkingProfessionalEffectiveQualifiedLeadID = calculateEffectiveLeadid(
    summaryData.working_professional.qualifiedLeadsCount,
    1,
    summaryData.working_professional.qualifiedLeadsIds.split(',').map(Number)
  );
  const studentUnEffectiveQualifiedLeadID = calculateEffectiveLeadid(
    summaryData.student.unqualifiedLeadsCount,
    5,
    summaryData.student.unqualifiedLeadsIds.split(',').map(Number)
  );
  const GraduateUnEffectiveQualifiedLeadID = calculateEffectiveLeadid(
    summaryData.graduate.unqualifiedLeadsCount,
    3,
    summaryData.graduate.unqualifiedLeadsIds.split(',').map(Number)
  );
  const WorkingProfessionalUnEffectiveQualifiedLeadID =
    calculateEffectiveLeadid(
      summaryData.working_professional.unqualifiedLeadsCount,
      1,
      summaryData.working_professional.unqualifiedLeadsIds
        .split(',')
        .map(Number)
    );

  const EffectiveQualifiedLeadId = [
    ...studentEffectiveQualifiedLeadID.qualifiedEffectiveLeadIds,
    ...WorkingProfessionalEffectiveQualifiedLeadID.qualifiedEffectiveLeadIds,
    ...GraduateEffectiveQualifiedLeadID.qualifiedEffectiveLeadIds,
  ];

  const UnEffectiveQualifiedLeadId = [
    ...studentUnEffectiveQualifiedLeadID.qualifiedEffectiveLeadIds,
    ...WorkingProfessionalUnEffectiveQualifiedLeadID.qualifiedEffectiveLeadIds,
    ...GraduateUnEffectiveQualifiedLeadID.qualifiedEffectiveLeadIds,
  ];

  const unqualifiedWeight = {
    student: 5,
    graduate: 3,
    working_professional: 1,
  };
  const neweffective = (studentCount, graduatecount, wpcount) => {
    const student = Math.round(Math.floor(studentCount / 5));
    const graduate = Math.round(Math.floor(graduatecount / 3));
    const result = student + graduate + wpcount;
    return result;
  };

  const copyLeadIds = (leadIds) => {
    const idsArray = Array.isArray(leadIds) ? leadIds : [leadIds];
    const textToCopy = idsArray.join(', ');

    navigator.clipboard.writeText(textToCopy).then(
      () => {
        setIsSuccess(true);
        setSuccessMessage('Lead IDs copied successfully');
      },
      (err) => {
        setHasError(true);
        setErrorMessage('Failed to copy lead IDs');
      }
    );
  };
  const TotalStudentLead = [
    ...student.qualifiedLeadsIds,
    ...student.unqualifiedLeadsIds,
  ];
  const leadmodifier = (lead) => {
    const modifiedLead = lead.replace(/^,|,$/g, '');
    return modifiedLead;
  };

  const renderCell = (count, leadIds, category) => {
    const isCountGreaterThan1500 = count > 1500;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span style={{ fontSize: '18px', margin: '0 5px' }}>{count}</span>
        {category !== 'Total' && !isCountGreaterThan1500 && (
          <>
            <Tooltip title={`View ${category} Leads`}>
              <IconButton
                size='small'
                component={Link}
                target='_blank'
                to={`/bucket-search?id=${leadIds}`}
              >
                <OpenInNewIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </>
        )}
        {category === 'Total' && !isCountGreaterThan1500 && (
          <Tooltip title={`View ${category} Leads`}>
            <IconButton
              size='small'
              component={Link}
              target='_blank'
              to={`/bucket-search?id=${leadIds}`}
              disabled={isCountGreaterThan1500}
            >
              <OpenInNewIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        )}
        <>
          <Tooltip title={`Copy ${category} lead IDs`}>
            <IconButton
              size='small'
              onClick={() => copyLeadIds(leadIds)}
              disabled={count === 0}
            >
              <ContentCopyIcon fontSize='small' />
            </IconButton>
          </Tooltip>
          {/* leadfunnel */}
          <Tooltip title={`Copy ${category} lead IDs`}>
            <IconButton
              size='small'
              onClick={() => {
                setCopyIds(leadmodifier(leadIds));
                toggleLeadFunnelModal();
              }}
              disabled={count === 0}
            >
              {leadIds.length <= 30000 && <FilterAltIcon fontSize='small' />}
            </IconButton>
          </Tooltip>
        </>
      </div>
    );
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      {openLeadFunnel && (
        <LeadFunnelModal
          isOpen={openLeadFunnel}
          onClose={toggleLeadFunnelModal}
          copyIds={copyIds}
        />
      )}
      <TableContainer
        component={Paper}
        sx={{ border: '1px solid #ddd' }}
      >
        <Table sx={{ border: '1px solid #ddd' }}>
          <TableBody>
            <TableRow>
              <TableCell sx={{ borderRight: '1px solid #ddd' }}></TableCell>
              <TableCell
                sx={{ borderRight: '1px solid #ddd', textAlign: 'center' }}
              >
                <b>Student</b>
              </TableCell>
              <TableCell
                sx={{ borderRight: '1px solid #ddd', textAlign: 'center' }}
              >
                <b>Graduate</b>
              </TableCell>
              <TableCell
                sx={{ borderRight: '1px solid #ddd', textAlign: 'center' }}
              >
                <b>Working Professional</b>
              </TableCell>
              <TableCell
                sx={{ borderRight: '1px solid #ddd', textAlign: 'center' }}
              >
                <b>Total</b>
              </TableCell>
              <TableCell
                sx={{
                  borderRight: '1px solid #ddd',
                  backgroundColor: '#EEF5FF',
                  textAlign: 'center',
                }}
              >
                <b>Effective</b>
              </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: '#EEF5FF' }}>
              <TableCell
                sx={{ borderRight: '1px solid #ddd', textAlign: 'center' }}
              >
                <b>PQL</b>
              </TableCell>
              <TableCell sx={{ borderRight: '1px solid #ddd' }}>
                {renderCell(
                  student.qualifiedLeadsCount,
                  student.qualifiedLeadsIds,
                  'Student'
                )}
              </TableCell>
              <TableCell sx={{ borderRight: '1px solid #ddd' }}>
                {renderCell(
                  graduate.qualifiedLeadsCount,
                  graduate.qualifiedLeadsIds,
                  'Graduate'
                )}
              </TableCell>
              <TableCell sx={{ borderRight: '1px solid #ddd' }}>
                {renderCell(
                  working_professional.qualifiedLeadsCount,
                  working_professional.qualifiedLeadsIds,
                  'Working Professional'
                )}
              </TableCell>
              <TableCell sx={{ borderRight: '1px solid #ddd' }}>
                {renderCell(
                  total.qualifiedLeadsCount,
                  total.qualifiedLeadsIds,
                  'Total'
                )}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: '1px solid #ddd',
                  backgroundColor: '#2196f3',
                  textAlign: 'center',
                }}
              >
                <span
                  style={{
                    color: 'white',
                    fontSize: '1.3rem',
                  }}
                >
                  {neweffective(
                    student.qualifiedLeadsCount,
                    graduate.qualifiedLeadsCount,
                    working_professional.qualifiedLeadsCount
                  )}
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ borderRight: '1px solid #ddd', textAlign: 'center' }}
              >
                <b>Non-PQL</b>
              </TableCell>
              <TableCell sx={{ borderRight: '1px solid #ddd' }}>
                {renderCell(
                  student.unqualifiedLeadsCount,
                  student.unqualifiedLeadsIds,
                  'Student'
                )}
              </TableCell>
              <TableCell sx={{ borderRight: '1px solid #ddd' }}>
                {renderCell(
                  graduate.unqualifiedLeadsCount,
                  graduate.unqualifiedLeadsIds,
                  'Graduate'
                )}
              </TableCell>
              <TableCell sx={{ borderRight: '1px solid #ddd' }}>
                {renderCell(
                  working_professional.unqualifiedLeadsCount,
                  working_professional.unqualifiedLeadsIds,
                  'Working Professional'
                )}
              </TableCell>
              <TableCell sx={{ borderRight: '1px solid #ddd' }}>
                {renderCell(
                  total.unqualifiedLeadsCount + total.unClassifiedLeadsCount,
                  total.unqualifiedLeadsIds + ',' + total.unClassifiedLeadsIds,
                  'Total'
                )}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: '1px solid #ddd',
                  backgroundColor: '#EEF5FF',
                  textAlign: 'center',
                }}
              >
                <span
                  style={{
                    fontSize: '1.3rem',
                  }}
                >
                  {neweffective(
                    student.unqualifiedLeadsCount,
                    graduate.unqualifiedLeadsCount,
                    working_professional.unqualifiedLeadsCount
                  )}
                </span>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                sx={{ borderRight: '1px solid #ddd', textAlign: 'center' }}
              >
                <b>Total</b>
              </TableCell>
              <TableCell sx={{ borderRight: '1px solid #ddd' }}>
                {renderCell(
                  student.qualifiedLeadsCount + student.unqualifiedLeadsCount,
                  student.qualifiedLeadsIds + ',' + student.unqualifiedLeadsIds,
                  'Student'
                )}
              </TableCell>
              <TableCell sx={{ borderRight: '1px solid #ddd' }}>
                {renderCell(
                  graduate.qualifiedLeadsCount + graduate.unqualifiedLeadsCount,
                  graduate.qualifiedLeadsIds +
                    ',' +
                    graduate.unqualifiedLeadsIds,
                  'Graduate'
                )}
              </TableCell>
              <TableCell sx={{ borderRight: '1px solid #ddd' }}>
                {renderCell(
                  working_professional.qualifiedLeadsCount +
                    working_professional.unqualifiedLeadsCount,

                  working_professional.qualifiedLeadsIds +
                    ',' +
                    working_professional.unqualifiedLeadsIds,
                  'Working Professional'
                )}
              </TableCell>
              <TableCell sx={{ borderRight: '1px solid #ddd' }}>
                {renderCell(
                  total.qualifiedLeadsCount +
                    total.unqualifiedLeadsCount +
                    total.unClassifiedLeadsCount,
                  total.qualifiedLeadsIds +
                    ',' +
                    total.unqualifiedLeadsIds +
                    ',' +
                    total.unClassifiedLeadsIds,
                  'Total'
                )}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: '1px solid #ddd',
                  backgroundColor: '#EEF5FF',
                  textAlign: 'center',
                }}
              >
                <span
                  style={{
                    fontSize: '1.3rem',
                  }}
                >
                  {neweffective(
                    student.qualifiedLeadsCount + student.unqualifiedLeadsCount,
                    graduate.qualifiedLeadsCount +
                      graduate.unqualifiedLeadsCount,
                    working_professional.qualifiedLeadsCount +
                      working_professional.unqualifiedLeadsCount
                  )}
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default LeadsSummaryTable;
