import { Stack, Typography, Button } from '@mui/material';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import Box from '@mui/material/Box';
// import EditNoteIcon from '@mui/icons-material/EditNote';
import SaveIcon from '@mui/icons-material/Save';
import DiscardIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useState } from 'react';

const ViewRecordedAudio = ({
  recordedText,
  onRecordAgain,
  onSubmit,
  audioBlob,
  handleSaveEditedTranscript,
  isSubmit,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(recordedText);
  return (
    <Stack marginBottom={4}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
        gap={3}
      >
        {audioBlob ? (
          <Box mt={3}>
            <audio
              name='audioFile'
              controls
            >
              <source
                src={URL.createObjectURL(audioBlob)}
                type='audio/wav'
              />
              Your browser does not support the audio format.
            </audio>
          </Box>
        ) : (
          <Typography
            variant='p'
            fontSize={'14px'}
            textAlign={'center'}
            component='div'
            gutterBottom
          >
            No recorded audio available.
          </Typography>
        )}

        <Box mt={3}>
          <Button
            variant='outlined'
            style={{
              width: '200px',
              height: '50px',
              color: 'black',
              borderColor: 'black',
            }}
            startIcon={<KeyboardVoiceIcon />}
            onClick={onRecordAgain}
          >
            Record Again
          </Button>
        </Box>
      </Box>
      {isEditing ? (
        <>
          <TextareaAutosize
            value={editedText}
            onChange={(e) => setEditedText(e.target.value)}
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => e.preventDefault()}
            style={{
              borderRadius: '10px',
              minHeight: '100px',
              minWidth: '80%',
              overflowY: 'auto',
              border: '1px solid #ccc',
              padding: '16px',
              marginRight: '8px',
              resize: 'none',
            }}
          />
          <Box>
            <SaveIcon
              style={{
                cursor: 'pointer',
                marginRight: '4px',
              }}
              onClick={() => {
                handleSaveEditedTranscript(editedText);
                setIsEditing(false);
              }}
            />
            <DiscardIcon
              style={{ cursor: 'pointer' }}
              onClick={() => setIsEditing(false)}
            />
          </Box>
        </>
      ) : (
        <>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={{
                borderRadius: '10px',
                minHeight: '100px',
                minWidth: '80%',
                overflowY: 'auto',
                border: '1px solid #ccc',
                padding: '16px',
              }}
            >
              <Typography
                variant='p'
                fontSize={'14px'}
                component='div'
                gutterBottom
              >
                {recordedText}
              </Typography>
            </Box>
            {/* <EditNoteIcon
            style={{ cursor: 'pointer' }}
            onClick={() => setIsEditing(true)}
          /> */}
            <Box>
              <Button
                ml={1}
                variant='contained'
                color='primary'
                onClick={() => setIsEditing(true)}
              >
                Edit
              </Button>
            </Box>
          </Box>
        </>
      )}
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {isSubmit ? (
          <Box mt={2}>
            <CircularProgress />
          </Box>
        ) : (
          <Button
            variant='contained'
            style={{
              width: '150px',
              height: '35px',
              marginTop: '25px',
            }}
            color='primary'
            onClick={onSubmit}
          >
            Submit
          </Button>
        )}
      </Box>
    </Stack>
  );
};

export default ViewRecordedAudio;
