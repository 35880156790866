import { Button } from '@mui/material';
import { base_url } from '../Mode';
import axios from 'axios';
import React from 'react';
import { useCurrentLead } from '../CurrentLeadContext';
import constants from '../../utils/constants';

const { SCREENING_CALL_ATTENDED, OFFER_LETTER_PAYMENT_LINK_SENT } = constants;

export const CreateAndUpdateOfferLetter = ({
  setOpenOfferLetterModal,
  setActiveOLInfo,
  setHasError,
  setErrorMessage,
}) => {
  const { currentLead } = useCurrentLead();

  const handleCreateOfferLetter = async () => {
    setOpenOfferLetterModal(true);
  };

  const handleUpdateOfferLetter = async () => {
    axios
      .get(`${base_url()}/api/leads/${currentLead.id}/offer-letters/active`)
      .then((res) => {
        setActiveOLInfo(res.data);
        setOpenOfferLetterModal(true);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch the offer letter."
        );
      });
  };

  return (
    <>
      {currentLead.status === SCREENING_CALL_ATTENDED && (
        <Button
          onClick={handleCreateOfferLetter}
          variant='contained'
          color='success'
          size='small'
        >
          Send offer letter
        </Button>
      )}

      {currentLead.status === OFFER_LETTER_PAYMENT_LINK_SENT && (
        <Button
          onClick={handleUpdateOfferLetter}
          variant='contained'
          color='success'
          size='small'
        >
          Update offer letter
        </Button>
      )}
    </>
  );
};
