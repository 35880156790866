import React, { useState, createContext, useContext } from 'react';

const AuthContext = createContext();

function AuthComponent({ children }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('token')));
  const isUserLoggedIn = !!user;
  return (
    <AuthContext.Provider value={[user, setUser, isUserLoggedIn]}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthComponent;
export const useAuthStateValue = () => useContext(AuthContext);
