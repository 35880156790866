import { useState, useEffect } from 'react';
import Filters from './Filters.jsx';
import PaymnetTable from './PaymentTable.jsx';

const PaymentStatus = () => {
  const [theme, setTheme] = useState('light');
  const [paymentData, setPaymentData] = useState([]);

  return (
    <div className={`container ${theme}`}>
      <Filters setPaymentData={setPaymentData} />
      <PaymnetTable paymentData={paymentData} />
    </div>
  );
};

export default PaymentStatus;
