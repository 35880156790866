import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import React from 'react';

export function ConfirmInteractionDeletionModal({
  openDeleteInteractionConfirmation,
  setOpenDeleteInteractionConfirmation,
  handleDelete,
}) {
  return (
    <Dialog
      open={openDeleteInteractionConfirmation}
      onClose={() => setOpenDeleteInteractionConfirmation(false)}
    >
      <DialogContent>
        <DialogContentText>
          Interaction once deleted, cannot be retrieved again. Are you sure you
          want to delete this interaction? This will also delete all the files
          associated with this interaction.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => setOpenDeleteInteractionConfirmation(false)}>
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          autoFocus
        >
          Yes, I'm sure
        </Button>
      </DialogActions>
    </Dialog>
  );
}
