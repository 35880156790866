import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios';
import { base_url } from '../Mode';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import { hrmscandidateSources } from '../../utils/common';

const defaultStyle = {
  width: 300,
};

export default function CandidatesSourceAutocompleteDropdown({
  value,
  onChangeHandler,
  label = 'Candidate Source',
  sx = defaultStyle,
  ...restProps
}) {
  const [inputValue, setInputValue] = useState(null);
  const [sources, setSources] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getCandidateSources();
    return () => {};
  }, []);

  useEffect(() => {
    if (value === null) {
      setInputValue(null);
    }
    return () => {};
  }, [value]);

  const getCandidateSources = () => {
    axios
      .get(base_url() + '/api/hrms/hrms_sources')
      .then((res) => {
        const sourcesdata = (res.data ?? []).map((e) => {
          return {
            label: hrmscandidateSources[e.platform],
            value: e.id,
          };
        });
        setSources(sourcesdata);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message);
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <Autocomplete
        {...restProps}
        value={inputValue}
        onChange={(event, newValue) => {
          setInputValue(newValue);
          onChangeHandler(event, newValue);
        }}
        options={sources}
        getOptionLabel={(option) => option.label ?? ''}
        sx={sx}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
          />
        )}
        disableClearable
      />
    </>
  );
}
