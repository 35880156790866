import { Box, Typography } from '@mui/material';
import React from 'react';

const WorkingSkillsBox = ({ workingSkillsRef, currentLeadData }) => {
  const { fname, lname, tech_stack } = currentLeadData;
  const fullname = fname + (lname !== null ? ` ${lname}` : '');
  return (
    <>
      {tech_stack && (
        <Box
          ref={workingSkillsRef}
          className='workingSkillsRef'
          sx={{
            zIndex: 4,
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'opacity .1s ease',
            opacity: 0,
          }}
        >
          <Typography
            sx={{
              zIndex: 4,
              color: '#EBE3F8',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: "'Inter', 'sans-serif'",
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundImage:
                'linear-gradient(90deg, #9F6BF5 26.74%, #C6AEEC 72.24%)',
            }}
          >
            {fullname} has worked on
          </Typography>

          <Typography
            sx={{
              zIndex: 4,
              color: '#FFF',
              textAlign: 'center',
              fontSize: '50px',
              fontWeight: 700,
            }}
          >
            {tech_stack}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default WorkingSkillsBox;
