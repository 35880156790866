import { Button } from '@mui/material';

const CustomButton = ({ variant, text, style, clickHandler }) => {
  const buttonText = text ? text : ' ';

  return (
    <>
      <Button
        variant={variant}
        sx={{
          ...style,
        }}
        onClick={clickHandler}
      >
        {buttonText}
      </Button>
    </>
  );
};
export default CustomButton;
