import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import AudioPlayer from './AudioPlayer';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import { base_url } from '../../Mode';
import QuestionStatus from './QuestionStatus';
import TextToSpeech from './TextToSpeech';

export default function ModuleQuestions({
  id,
  handleSelectedQuestion,
  attempts,
  newStatus,
}) {
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [questions, setQuestions] = useState([]);
  const [questionAttempts, setQuestionAttempts] = useState([]);

  useEffect(() => {
    handleFetchModuleQuestions();
    handleFetchQuestionAttempts();
  }, [id, newStatus]);

  const handleFetchModuleQuestions = () => {
    const url = base_url() + `/api/training-modules/${id}/questions`;
    axios
      .get(url)
      .then((res) => {
        setQuestions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFetchQuestionAttempts = () => {
    const url = base_url() + `/api/training-modules/${id}/question-attempts`;
    axios
      .get(url)
      .then((res) => {
        setQuestionAttempts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleQuestionClick = (
    questionId,
    index,
    questionUrl,
    expectedAnswers
  ) => {
    setSelectedQuestion(questionId);
    handleSelectedQuestion(questionId, index, questionUrl, expectedAnswers);
    const questionData = {
      id: questionId,
      index: index,
      questionUrl: questionUrl,
      expectedAnswers: expectedAnswers,
    };
  };

  return (
    <div>
      {questions.map((item, index) => {
        return (
          <Box
            key={index}
            onClick={() =>
              handleQuestionClick(
                item.id,
                index + 1,
                item.questions,
                item.expected_answers
              )
            }
            style={{ cursor: 'pointer' }}
          >
            <QuestionStatus
              index={index}
              questionId={item.id}
              questionAttempts={questionAttempts}
              selectedQuestion={selectedQuestion}
              questionUrl={item.questions}
              expectedAnswers={item.expected_answers}
              handleQuestionClick={handleQuestionClick}
              attempts={attempts}
              newStatus={newStatus}
            />
            {/* <AudioPlayer audioSrc={`${item.questions}`} /> */}
            <TextToSpeech
              questionId={item.id}
              text={`${item.questions}`}
            />
            <Divider />
          </Box>
        );
      })}
    </div>
  );
}
