import React from 'react';
import { Typography, Box, Button, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

const NotificationDetails = ({ notificationDetail }) => {
  const metadata =
    Object.keys(notificationDetail).length > 0 &&
    JSON.parse(notificationDetail.metadata);

  return (
    <Box
      mt={0}
      sx={{
        width: '40%',
        border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: '#fff',
        padding: '1em',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      {metadata && Object.keys(metadata).length > 0 ? (
        <>
          <Typography
            variant='h6'
            sx={{
              color: '#555',
              marginBottom: '1em',
              textAlign: 'center',
            }}
          >
            Notification details
            <hr />
          </Typography>
          <Stack
            flexDirection='column'
            spacing={2}
          >
            {metadata &&
              Object.keys(metadata).map((key) =>
                key === 'leads' ? (
                  <Button
                    key={key}
                    variant='contained'
                    size='small'
                    target='_blank'
                    component={Link}
                    to={`/bucket-search?id=${metadata[key].join(',')}`}
                  >
                    {`View ${metadata[key].length} leads`}
                  </Button>
                ) : (
                  <Typography
                    key={key}
                    sx={{ color: '#777' }}
                  >
                    {`${key}: ${metadata[key]}`}
                  </Typography>
                )
              )}
          </Stack>
        </>
      ) : (
        <Typography
          variant='h6'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '1.25em 0',
            color: '#C1C1C1',
          }}
        >
          Click on the notification to view the details
        </Typography>
      )}
    </Box>
  );
};

export default NotificationDetails;
