import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { base_url } from '../../components/Mode';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';

const Filters = ({ setPaymentData }) => {
  const [leadId, setLeadId] = useState('');
  const [utrNumber, setUtrNumber] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [status, setStatus] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [emiProvider, setEmiProvider] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const statusOptions = {
    in_progress: 'in_progress',
    approved: 'approved',
    converted: 'converted',
    finance_handover_initiated: 'finance_handover_initiated',
    finance_handover_rejected: 'finance_handover_rejected',
    pgm_handover_initiated: 'pgm_handover_initiated',
    pgm_handover_approved: 'pgm_handover_approved',
    pgm_handover_rejected: 'pgm_handover_rejected',
  };

  const paymentTypeOptions = {
    none: 'bank_transfer',
    tech_background: 'online_payment',
    non_tech_background: 'emi',
  };
  const emiProviderOptions = {
    propelld: 'propelld',
    kuhoo: 'kuhoo',
    liquiloans: 'liquiloans',
    eduvanz: 'eduvanz',
    other: 'other',
    varthana: 'varthana',
  };

  useEffect(() => {
    handleFilter();
  }, [leadId, utrNumber, fromDate, toDate, status, paymentType, emiProvider]);

  const handleFilter = async () => {
    try {
      const response = await axios.get(
        base_url() + `/api/post-sales-payments/filter`,
        {
          params: {
            leadId,
            utrNumber,
            fromDate,
            toDate,
            status,
            paymentType,
            emiProvider,
          },
        }
      );

      setPaymentData(response.data);
    } catch (error) {
      setHasError(true);
      setErrorMessage(
        error.response?.data?.message || 'Could not fetch filters.'
      );
      console.error('Error filtering post-sales payments:', error);
    }
  };

  const handleReset = () => {
    setLeadId('');
    setUtrNumber('');
    setFromDate('');
    setToDate('');
    setStatus('');
    setPaymentType('');
    setEmiProvider('');
  };

  return (
    <>
      {' '}
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <Grid
        container
        spacing={{ xs: 2, md: 1 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid
          item
          xs={3}
        >
          <FormControl fullWidth>
            <TextField
              size='small'
              type='text'
              name='lead-id'
              placeholder='Lead ID'
              onChange={(e) => {
                setLeadId(e.target.value);
              }}
              value={leadId}
            />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={3}
        >
          <FormControl fullWidth>
            <TextField
              size='small'
              type='text'
              name='utr-number'
              placeholder='UTR Number'
              onChange={(e) => {
                setUtrNumber(e.target.value);
              }}
              value={utrNumber}
            />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={3}
        >
          <TextField
            size='small'
            type='datetime-local'
            name='payment_from'
            label='From'
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: '100%',
              marginBottom: '10px',
            }}
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
            value={fromDate}
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <TextField
            size='small'
            type='datetime-local'
            name='payment_to'
            label='To'
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: '100%',
              marginBottom: '10px',
            }}
            onChange={(e) => {
              setToDate(e.target.value);
            }}
            value={toDate}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={{ xs: 2, md: 1 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid
          item
          xs={3}
        >
          <FormControl
            id='tech_stack_type'
            size='small'
            fullWidth
          >
            <InputLabel id='tech_stacks-label'>Status</InputLabel>
            <Select
              name='tech_stack_type'
              label='Status'
              labelId='tech_stack_type'
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              value={status}
            >
              {Object.keys(statusOptions).map((key) => (
                <MenuItem
                  key={key}
                  value={key}
                >
                  {statusOptions[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={3}
        >
          <FormControl
            id='payment_type'
            size='small'
            fullWidth
          >
            <InputLabel id='payment_type-label'>Payment Type</InputLabel>
            <Select
              name='payment_type'
              label='Payment Type'
              labelId='payment_type'
              onChange={(e) => {
                setPaymentType(e.target.value);
              }}
              value={paymentType}
            >
              {Object.keys(paymentTypeOptions).map((key) => (
                <MenuItem
                  key={key}
                  value={key}
                >
                  {paymentTypeOptions[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={3}
        >
          <FormControl
            id='emi_provider'
            size='small'
            fullWidth
          >
            <InputLabel id='emi_provider-label'>EMI Provider</InputLabel>
            <Select
              name='emi_provider'
              label='EMI Provider'
              labelId='emi_provider'
              onChange={(e) => {
                setEmiProvider(e.target.value);
              }}
              value={emiProvider}
            >
              {Object.keys(emiProviderOptions).map((key) => (
                <MenuItem
                  key={key}
                  value={key}
                >
                  {emiProviderOptions[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={3}
        >
          <Button
            variant='outlined'
            px={10}
            onClick={() => handleReset()}
          >
            Reset Filters
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Filters;
