import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import MarketingInsights from './MarketingInsights';
import OverviewDashboard from './MetabaseMarketingDashboard';
import TechStacks from './TechStacks';
import ImportLeads from '../Admins/ImportLeads/ImportLeads';
import Campaigns from './Campaigns';
import LeadConnect10min from './LeadConnect10min';
import { currentAdminIsHr } from '../../utils/common';
import BulkHotLeadsAssign from './BulkHotLeadsAssign';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function MarketingDashboard() {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (e, newTabValue) => {
    setCurrentTab(newTabValue);
  };
  return (
    <Box sx={{ mr: 5, marginTop: '-2.5rem' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        {currentAdminIsHr() ? (
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant='scrollable'
            scrollButtons='auto'
          >
            <Tab label='Campaign' />
          </Tabs>
        ) : (
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant='scrollable'
            scrollButtons='auto'
          >
            <Tab label='Insights' />
            <Tab label='Tech Stacks' />
            <Tab label='Import Leads' />
            <Tab label='Overview' />
            <Tab label='Campaign' />
            <Tab label='10 Min Connect' />
            <Tab label='Hot Leads' />
          </Tabs>
        )}
      </Box>
      <TabPanel
        value={currentTab}
        index={0}
      >
        {currentAdminIsHr() ? <Campaigns /> : <MarketingInsights />}
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={1}
      >
        <TechStacks />
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={2}
      >
        <ImportLeads />
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={3}
      >
        <OverviewDashboard />
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={4}
      >
        <Campaigns />
      </TabPanel>

      <TabPanel
        value={currentTab}
        index={5}
      >
        <LeadConnect10min />
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={6}
      >
        <BulkHotLeadsAssign />
      </TabPanel>
    </Box>
  );
}
