/**
 * 1. If `Program Type`(id = 6) is 'Super 30', then `Cohort Proposed`(id = 17) cannot be empty and `PC coaching duration`(id = 7) should not be filled.
 * 2. If `Program Type`(id = 6) is anything but 'Super 30', then `PC coaching duration`(id = 7) cannot be empty.
 *
 */
export const salesHandoverForm = {
  1: {
    id: 1,
    title: 'Learner Name',
    type: 'text',
  },
  2: {
    id: 2,
    title: 'Lead ID (Fill only number after #)',
    type: 'number',
  },
  3: {
    id: 3,
    title: 'Email ID',
    type: 'email',
  },
  4: {
    id: 4,
    title: 'Contact Number',
    type: 'number',
  },
  5: {
    id: 5,
    title: 'Alternate Number',
    type: 'number',
  },
  6: {
    id: 6,
    title: 'Program Type',
    type: 'radio',
    options: [
      'Super 30',
      'Personalised Coaching',
      'Program management personal coaching',
    ],
  },
  7: {
    id: 7,
    title: 'If Personalized Coaching duration for the coaching (Months)',
    type: 'radio',
    options: ['4', '5', '6', '8', '10', '12'],
  },
  8: {
    id: 8,
    title: 'Programming Language',
    type: 'text',
  },
  9: {
    id: 9,
    title: 'Year of Graduation',
    type: 'text',
  },
  10: {
    id: 10,
    title: 'Field of Graduation',
    type: 'text',
  },
  11: {
    id: 11,
    title: 'Work Experience',
    type: 'text',
  },
  12: {
    id: 12,
    title: 'Job Role',
    type: 'text',
  },
  13: {
    id: 13,
    title: 'Notice Period (Days) Mandatory if Working Professional',
    type: 'radio',
    options: ['30', '45', '60', '90'],
  },
  14: {
    id: 14,
    title: 'Resume link',
    type: 'file',
  },
  15: {
    id: 15,
    title: 'Leetcode',
    type: 'text',
  },
  // 16: {
  //   id: 16,
  //   title: 'Discord',
  //   type: 'text',
  // },
  17: {
    id: 17,
    title: 'Cohort proposed',
    type: 'text',
  },
  18: {
    id: 18,
    title: 'Comment',
    type: 'text',
  },
};
