import {
  Box,
  Button,
  FormControl,
  Modal,
  TextField,
  Typography,
  Select,
  InputLabel,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { base_url } from '../Mode';
import MoneyIcon from '../../images/money.png';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import { currentAdminIsAdmin } from '../../utils/common';
const moment = require('moment');
const initalState = {
  lead_id: '',
  drive: '',
  date: new Date().toISOString().split('T')[0],
  lead_name: '',
  lead_email: '',
  assignee: '',
  manager: '',
};
const AddCAPModal = ({
  editCAPData,
  editCAP,
  setEditCAP,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
  setTriggerRefresh,
  triggerRefresh,
  handleClose,
  handleOpen,
  open,
  currentDriveName,
  drivesDropdown,
  IndependentContributorDropdown,
  managerDropdown,
}) => {
  const [modalData, setModalData] = useState(initalState);

  useEffect(() => {
    if (!editCAP) {
      getCapDetailsByLeadId(modalData?.lead_id);
    }
  }, [modalData?.lead_id]);

  useEffect(() => {
    if (editCAPData && editCAP) {
      fillModalDataFromEditCAPData();
    } else {
      setModalData(initalState);
    }
  }, [editCAPData, editCAP]);

  const fillModalDataFromEditCAPData = () => {
    const {
      id,
      assignee,
      date,
      drive,
      lead_email,
      lead_id,
      lead_name,
      manager,
    } = editCAPData;

    setModalData({
      id: id || '',
      assignee: assignee || '',
      date: date ? new Date(date).toISOString().split('T')[0] : '',
      drive: drive || '',
      lead_email: lead_email || '',
      lead_id: lead_id || '',
      lead_name: lead_name || '',
      manager: manager || '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const createCAPDetail = async () => {
    try {
      const response = await axios
        .post(`${base_url()}/api/cap_tracking`, modalData)
        .then((res) => {
          setIsSuccess(true);
          setSuccessMessage('CAP detail added successfully');
          setTriggerRefresh(!triggerRefresh);
          setModalData(initalState);
        })
        .catch((err) => {
          console.log(err);
          setHasError(true);
          setErrorMessage(
            `Error in adding CAP details, ${err?.response?.data?.message || ''}`
          );
        });
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorMessage(
        `Error in adding cap detail ${error?.response?.data?.message || ''}`
      );
    }
    handleClose();
  };

  const getCapDetailsByLeadId = async (leadId) => {
    try {
      if (!leadId) {
        return;
      }

      const response = await axios
        .get(`${base_url()}/api/cap_tracking/${leadId}/search`)
        .then((res) => {
          setModalData((prevState) => ({
            ...prevState,
            ...res?.data[0],
          }));
        })
        .catch((err) => {
          console.log(err);
          setHasError(true);
          setErrorMessage(
            `Error in fetching lead detail lead id is missing  ${
              err?.response?.data?.message || ''
            }`
          );
        });
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorMessage(
        `Error in fetching data of lead ${error?.response?.data?.message || ''}`
      );
    }
  };

  const handleEditCAPDetail = async () => {
    try {
      const response = await axios
        .patch(
          `${base_url()}/api/cap_tracking/${modalData?.lead_id}`,
          modalData
        )
        .then((res) => {
          setIsSuccess(true);
          setSuccessMessage('CAP detail updated successfully');
          setTriggerRefresh(!triggerRefresh);
          handleClose();
          setModalData(initalState);
          setEditCAP(!editCAP);
        })
        .catch((err) => {
          console.log(err);
          setHasError(true);
          setErrorMessage(
            `Error in adding cap details ${err?.response?.data?.message || ''}`
          );

          handleClose();
        });
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorMessage(
        `Error in adding cap details ${error?.response?.data?.message || ''}`
      );

      handleClose();
    }
  };

  const hanldeCancel = () => {
    handleClose();
    setEditCAP(!editCAP);
    setModalData(initalState);
  };

  const modalStyle = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 10,
    p: 4,
    borderRadius: '4%',
  };

  const gradientStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '25%',
    background: 'linear-gradient(to bottom, #bbdefb, #ffffff)',
    borderRadius: '4% 4% 0 0',
    zIndex: -1,
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '5px',
    right: '10px',
  };

  const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
  const minDate = currentAdminIsAdmin() ? '1900-01-01' : yesterday;

  return (
    <div>
      <Button
        onClick={handleOpen}
        variant='contained'
      >
        {' '}
        + Add CAP
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={modalStyle}>
          <IconButton
            sx={closeButtonStyle}
            onClick={(e) => hanldeCancel()}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                gap: 2,
              }}
            >
              <img
                style={{ height: '50px', width: '50px' }}
                name={'icons'}
                alt={'icons'}
                src={MoneyIcon}
              />
              <Typography sx={{ alignContent: 'center', fontWeight: 'bold' }}>
                {editCAP ? 'Edit Cap' : 'Add CAP'}
              </Typography>
            </Box>
            <FormControl sx={{ minWidth: 120, marginBottom: '10px' }}>
              <TextField
                required
                size='small'
                label='Lead ID'
                name='lead_id'
                value={modalData?.lead_id}
                onChange={handleChange}
                disabled={editCAP}
              />
            </FormControl>
            <Box sx={{ display: 'flex' }}>
              <FormControl
                sx={{ width: 200, marginRight: '50px', marginBottom: '7px' }}
              >
                <FormControl sx={{ width: 200, marginRight: '20px' }}>
                  <InputLabel
                    id='select-drive'
                    sx={{
                      paddingRight: 1,
                    }}
                  >
                    Select Drive
                  </InputLabel>
                  <Select
                    labelId='select-drive'
                    id='action-select'
                    name='drive'
                    value={
                      modalData?.drive ? modalData.drive : currentDriveName
                    }
                    label='Select IC'
                    size='small'
                    onChange={handleChange}
                  >
                    {drivesDropdown()}
                  </Select>
                </FormControl>
              </FormControl>
              <FormControl sx={{ width: 200 }}>
                <TextField
                  type='date'
                  label='Date'
                  size='small'
                  name='date'
                  value={modalData?.date}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: minDate },
                  }}
                />
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <FormControl
                sx={{ width: 200, marginRight: '20px', marginBottom: '7px' }}
              >
                <TextField
                  size='small'
                  label='Lead Name'
                  name='lead_name'
                  value={modalData?.lead_name}
                  onChange={handleChange}
                  disabled={true}
                />
              </FormControl>
              <FormControl sx={{ width: 200, marginLeft: '30px' }}>
                <TextField
                  size='small'
                  label='Email'
                  name='lead_email'
                  value={modalData?.lead_email}
                  onChange={handleChange}
                  disabled={true}
                />
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <FormControl sx={{ width: 200, marginRight: '20px' }}>
                <InputLabel
                  size='small'
                  id='select-ic-label'
                  sx={{
                    paddingRight: 1,
                  }}
                >
                  Select IC
                </InputLabel>
                <Select
                  labelId='select-ic-label'
                  id='action-select'
                  name='assignee'
                  value={modalData?.assignee}
                  label='Select IC'
                  size='small'
                  onChange={handleChange}
                >
                  {IndependentContributorDropdown()}
                </Select>
              </FormControl>
              <FormControl sx={{ width: 200, marginLeft: '30px' }}>
                <InputLabel
                  size='small'
                  id='managers-label'
                  sx={{
                    marginBottom: 2,
                    paddingRight: 1,
                  }}
                >
                  Managers
                </InputLabel>
                <Select
                  labelId='managers-label'
                  id='manager-select'
                  name='manager'
                  value={modalData?.manager}
                  label='Managers'
                  size='small'
                  onChange={handleChange}
                >
                  {managerDropdown()}
                </Select>
              </FormControl>
            </Box>
            {editCAP ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '15px',
                  }}
                >
                  <Button
                    variant='contained'
                    onClick={() => handleEditCAPDetail()}
                    sx={{ marginRight: '20px', width: '150px' }}
                  >
                    Save
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() => hanldeCancel()}
                    sx={{ width: '150px' }}
                  >
                    Cancel
                  </Button>
                </Box>
              </>
            ) : (
              <Button
                variant='contained'
                onClick={() => createCAPDetail()}
                sx={{ width: '150px', marginTop: '20px' }}
              >
                Add
              </Button>
            )}
          </Box>
          <div style={gradientStyle}></div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddCAPModal;
